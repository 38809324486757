import { Route, Routes } from "react-router-dom";
import { CommunicationPreForm, PinChangeForm } from "../../pages/Business";

const Settings = () => {
  return (
    <Routes>
      {/* Settings >PinChange */}
      <Route path="/pin-change-form" element={<PinChangeForm />} />
      {/* Settings >Communications */}
      <Route
        path="/communication-pro-form"
        element={<CommunicationPreForm />}
      />
    </Routes>
  );
};

export default Settings;
