

import croplogo from "../../assets/images/croplogo.png";
import "./BusinessLogin.css"; // You can remove this if not needed

const BusineessLoginLayout = ({sideImage,children}) => {

    const leftImage = () => {
        return (
          <>
            <figure className="image is-hidden-mobile smart-layout-side-image">
              <img
                src={sideImage}
                alt="Login Logo"
                className="image login-image"
              />
            </figure>
          </>
        );
      };
  
 
  const loginLogo = () => {
    return (
      <div>
        <figure className="crop-image is-flex is-justify-content-center">
          <img className="crop-login-image" src={croplogo} alt="Crop Logo" />
        </figure>
      </div>
    );
  };
 


  const loginFormNew = () => {
    return (
      <div className="card crop-business-login-box">
        <div className="crop-login-form-main">
          {loginLogo()}
          {children}
        </div>
        <div className="crop-business-login-top-box"></div>
        <div className="crop-business-login-bottom-box"></div>
        <div className="crop-business-login-left-box"></div>
      </div>
    );
  };

  return (
    <>
      <div className="container is-fluid smart-crop-container smart-over-flow-hidden smart-business-login">
        <div className="columns is-gapless">
          <div key={"BusinessLogin-column-one"} className="column is-6">
            {leftImage()}
          </div>
          <div key={"BusinessLogin-column-two"} className="column is-6">
            {loginFormNew()}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusineessLoginLayout;
