import { useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartSoftTable } from "soft_digi";
import { SERVICES_COMPLAINT_URLS } from "../../../../api/Services/ServiceUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { COMPLAINT_LOGO } from "../../../../services/ImageService";
import { showNotification } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";

const ComplaintForm = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading } = useSiteContext();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const options = [
    { value: "1", label: "Compaint" },
    { value: "2", label: "Request" },
  ];
  const pcmOptions = [
    { value: "Mobile", label: "Mobile" },
    { value: "Email", label: "Email" },
  ];

  // const numericValidations = [
  //   {
  //     type: "required",
  //     msg: "Please enter correct value",
  //   },
  // ];

  const handleFormSubmit = () => {
    // console.log("formdata", formData);
    formData.complaint_mode = "BUSINESS";
    formData.complaint_type = formData.complaint_type.value;
    formData.complaint_pcm = formData.complaint_pcm.value;
    setFormSubmit(true);
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    let url = SERVICES_COMPLAINT_URLS.INSERT;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        // console.log(response.data);
        // console.table("testing", response.data);
        showNotification("success", "Complaint Saved Successfully");
        setLoading(false);
        setFormData({});
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const formElements = [
    // {
    //   type: "TEXT_BOX", //Textarea should come
    //   width: "12",
    //   name: "complaint_number",
    //   element: {
    //     inputType: "BORDER_LABEL_FOCUS",
    //     label: "Complaint Number",
    //     validations: numericValidations,
    //   },
    // },
    // {
    //   type: "TEXT_BOX", //Textarea should come
    //   width: "12",
    //   name: "complaint_id",
    //   element: {
    //     inputType: "BORDER_LABEL_FOCUS",
    //     label: "Complaint Id",
    //     validations: numericValidations,
    //   },
    // },
    {
      type: "SELECT_BOX",
      width: "12",
      name: "complaint_type",
      element: {
        placeHolder: "Complaint Type",
        options: options,
      },
    },

    {
      type: "TEXTAREA",
      width: "12",
      name: "offer_breif",
      element: { placeHolder: "Brief Description" },
    },

    {
      type: "SELECT_BOX",
      width: "12",
      name: "complaint_pcm",
      element: {
        placeHolder: "Preferred Communication Medium",
        options: pcmOptions,
      },
    },
  ];

  const columns = [
    { title: " ", index: "" },
    { title: " ", index: "" },
    { title: "Request from", index: "Requestfromline" },
    { title: "Description", index: "Descriptionline" },
    { title: "Request number", index: "Requestnumberline" },
    { title: "Request status", index: "Requeststatusline" },
  ];
  const data = [
    {
      Requestfromline: " ",
      Descriptionline: " ",
      Requestnumberline: " ",
      Requeststatusline: "",
    },
  ];
  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span>
        <span className="ml-5"> Create Complaint</span>
      </p>

      <div className="container smart-crop-business-forms">
        <div  key={"ComplaintForm-columns-one"} className="columns ">
          <div key={"ComplaintForm-column-one"} className="column is-5 ml-5 mt-5 smart-flex-container">
            <div>
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
              <div className="is-flex is-justify-content-center mt-6">
                <SmartSoftButton
                  label="Submit"
                  onClick={() => handleFormSubmit()}
                  classList={["smart-crop-action-button"]}
                  // disabled={!isEmptyObject(formErrors)}
                />
              </div>
            </div>
          </div>

          <div className="column-6 Request-Form-Right-image ">
            <div className="card-image ">
              <img
                className="Request-Formmy-card-view-form-image"
                src={COMPLAINT_LOGO}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="is-flex is-justify-content-end p-4 ">
        <SmartSoftButton
          label="Create request"
          onClick={() => handleFormSubmit()}
          classList={["smart-crop-action-button"]}
          // disabled={!isEmptyObject(formErrors)}
        />
      </div>
      <div key={"ComplaintForm-columns-two"} className="columns">
        <div key={"ComplaintForm-column-two"} className="column">
          <SmartSoftTable
            columns={columns}
            data={data}
            tableProps={{ className: "crop-table-layout" }}
          />
        </div>
      </div>
    </>
  );
};

export default ComplaintForm;
