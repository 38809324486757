import { useEffect, useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { ORDER_ITEMS_URLS } from "../../../../api/OrderUrls";
import { ALLOW_FLOAT } from "../../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";

const OrderCancellation = ({
  data,
  setLoading,
  closeModal,
  loadOrderData,
  refreshUser,
}) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [dataOption, setDataOption] = useState([]);

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    // refund_percentage
    if (name === "refund_percentage") {
      formData.refund_amount = (value / 100) * formData.total_price;
      let result = Number(formData.refund_amount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      formData.refund_amount =
        data && data.order_type == "REDEEM"
          ? parseInt(formData.refund_amount)
          : result;
    }
    if (name === "refund_amount") {
      formData.refund_percentage = (value / formData.total_price) * 100;
      let result = Number(formData.refund_percentage).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      );
      formData.refund_percentage = result;
    }
    // offer_total
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    // console.log(" name ", name, "   value ", value);
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const loadTableData = () => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { status: data.status, order_type: data.offer_mode };
    const subscription = post(
      ORDER_ITEMS_URLS.GET_STATUS_DROP_DOWN,
      post_data,
      handleError
    ).subscribe((response) => {
      //console.log(response);
      setDataOption(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
    //console.log(data);
    let total = {
      total_price:
        data.order_type == "REDEEM" ? data.order_crops : data.total_price,
    };
    setFormData(total);
  }, []);

  const handleLogin = (e) => {
    setFormSubmit(true);
    // if (!isEmptyObject(formErrors)) {
    //   return false;
    // }
    if (!ValidateFormNew(formData, [...formElements()])) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Processing....Please Wait");
    let url = ORDER_ITEMS_URLS.UPDATE_BUSINESS;
    // if (data.status === 30 || data.status === 35)
    //   url = ORDER_ITEMS_URLS.UPDATE_RETURN;
    let data_in = { ...formData, id: data.ID };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        showAlertAutoClose(response.data.msg, "success");
        setLoading(false);
        loadOrderData();
        closeModal();
        refreshUser();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const Validations = {
    status: [SmartValid.required("Status is Required")],
    remarks: [SmartValid.required("Remarks is Required")],
    percentage: [SmartValid.required("Percentage is Required")],
  };

  const options = [
    { value: "APPROVED", label: "APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
  ];

  // const status_options = [
  //   { value: "IN-PROGRESS", label: "IN-PROGRESS" },
  //   { value: "ORDER-DELIVERED", label: "ORDER-DELIVERED" },
  //   { value: "ORDER-PROCESSED", label: "ORDER-PROCESSED" },
  // ];
  const cancelationElements = [
    {
      type: "SELECT_BOX",
      width: "12",
      name: "status",
      element: {
        label: "Status",
        validations: Validations.status,
        options: options,
        isRequired: true,
      },
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "remarks",
      element: {
        label: "Remarks",
        // validations: Validations.remarks,
        // isRequired: true,
      },
    },
  ];

  const inprogressElements = [
    // {
    //   type: "TEXT_BOX",
    //   width: "12",
    //   name: "order_eta",
    //   leftIcon: "fa-user",
    //   element: {

    //     label: "Estimate Time",
    //     isRequired: true,

    //   },
    // },
    {
      type: "SELECT_BOX",
      width: "12",
      name: "status",
      leftIcon: "fa-user",
      element: {
        // inputType: "BORDER_LABEL_FOCUS",
        label: "Update Status",
        isRequired: true,
        options: dataOption,
        // pattern: ALLOW_ALPHABET_SPACE,
        validations: Validations.status,
      },
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "remarks",
      element: {
        label: "Remarks",
        // validations: Validations.remarks,
        // isRequired: true,
      },
    },
  ];

  const deliveredElements = [
    {
      type: "SELECT_BOX",
      width: "12",
      name: "status",
      leftIcon: "fa-user",
      element: {
        // inputType: "BORDER_LABEL_FOCUS",
        label: "Update Status",
        isRequired: true,
        options: dataOption,
        validations: Validations.status,
        // pattern: ALLOW_ALPHABET_SPACE,
      },
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "remarks",
      element: {
        label: "Remarks",
        // validations: Validations.remarks,
        // isRequired: true,
        max: 255,
      },
    },
  ];

  const returnElements = [
    /*
    {
      type: "SELECT_BOX",
      width: "12",
      name: "status",
      element: {
        label: "Status",

        options: options,
      },
    },*/
    {
      type: "TEXT_BOX",
      width: "12",
      name: "total_price",
      leftIcon: "fa-user",
      element: {
        // inputType: "BORDER_LABEL_FOCUS",
        label:
          data && data.order_type == "REDEEM" ? "Total CROP's" : "Total Amount",
        // isRequired: true,
        inputProps: {
          disabled: true,
        },
        // pattern: ALLOW_ALPHABET_SPACE,
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "refund_percentage",
      leftIcon: "fa-user",
      element: {
        // inputType: "BORDER_LABEL_FOCUS",
        label: "Refund Percentage (%)",
        isRequired: true,
        pattern: ALLOW_FLOAT,
        // validations: Validations.percentage,
        max: 3,
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "refund_amount",
      leftIcon: "fa-user",
      element: {
        // inputType: "BORDER_LABEL_FOCUS",
        label:
          data && data.order_type == "REDEEM"
            ? "Refund CROP's"
            : "Refund Amount",
        // inputProps: {
        //   disabled: true,
        // },
        // isRequired: true,
        // pattern: ALLOW_ALPHABET_SPACE,
      },
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "remarks",
      element: {
        label: "Remarks",
        // validations: Validations.remarks,
        // isRequired: true,
        max: 255,
      },
    },
  ];

  const returnElementsApprove = [
    {
      type: "SELECT_BOX",
      width: "12",
      name: "status",
      element: {
        label: "Status",
        isRequired: true,
        options: options,
        validations: Validations.status,
      },
    },

    {
      type: "TEXTAREA",
      width: "12",
      name: "remarks",
      element: {
        label: "Remarks",
        // validations: Validations.remarks,
        // isRequired: true,
        max: 255,
      },
    },
  ];

  const formElements = () => {
    //console.log("dt " , data);
    let status = parseInt(data?.status);
    if (status === 90) {
      return cancelationElements;
    } else if (status === 10 || status === 96) {
      return inprogressElements;
    } else if (status === 15 || status === 20) {
      return deliveredElements;
    } else if (status === 30) {
      return returnElementsApprove;
    } else if (status === 35) {
      return returnElements;
    } else {
      return [];
    }
    // else if(data.status==35){
    //   return returnElements;
    // }
  };

  return (
    <div>
      <div className="smart-customer-input">
        {/* <div className="is-flex is-justify-content-space-between">
          <div className="is-size-4 has-text-weight-bold mt-4">
           Order Cancellation
          </div>
          <div className="mb-3" onClick={closeModal}>
            <div className="customer-modal-close mb-3 ">
              <i class="fa fa-times has-text-white" aria-hidden="true"></i>
            </div>
          </div>
        </div> */}
        <div className="mb-3 smart-crop-business-forms">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements()}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>
        <div className="mb-3 is-flex is-justify-content-center">
          <SmartSoftButton
            label="Update"
            classList={["crop-login-action-button"]}
            onClick={() => handleLogin()}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderCancellation;
