import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import SmartStripePayment from "../../../../components/site/SmartStripePayment";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { changeDateTimeZoneFormat } from "../../../../services/core/CommonService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { decrypt_data, getSessionStorage, setSessionStorage } from "../../../../services/sessionService";
import { post } from "../../../../services/smartApiService";

const PaymentOrder = () => {
  const [data, setData] = useState({});
  const location = useLocation(); 
  const getStoreId=()=>{
    const loc = location.state || {};
    if(loc.id==undefined){
      // get it from session storeage
     return getSessionStorage("transaction_id")
    }else{
      setSessionStorage("transaction_id",loc.id)
      return loc.id;
    }
  }

  
  const id = getStoreId();  
  //console.log("id = " , id);
  //const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  const { setLoading,isLoading } = useSiteContext();
  const navigate = useNavigate();
  // console.log("decrypted id = ",decrypted_id, " id = " , id);
  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: decrypted_id };
    const subscription = post(
      MANAGE_OFFER_URLS.GET_ONE_BID,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [decrypted_id]);

  const handleSuccess=(data)=>{
     //console.log("success data " , data);
     setLoading(true, "Payment Completed...Verifying the Payment....Please Wait");
     const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage);
        setLoading(false);
      };
      let post_data = { id: decrypted_id,client_secret:data };
      const subscription = post(
        MANAGE_OFFER_URLS.PAYMENT_UPDATE,
        post_data,
        handleError
      ).subscribe((response) => {
        // navigate("/manage-services/market-offers-invoice/"+id);
        navigate("/manage-services/market-offers-list");
        // manage-services/market-offers-list
        showAlertAutoClose("Bid Payment Success","success");
        //setData(response.data);
        setLoading(false);
      });
      return () => {
        subscription.unsubscribe();
      };
  }
  const displayAmount = (totalPrice) => {
    return Number(totalPrice).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  // const StartDate = moment(data?.start_date).format( "DD-MM-YYYY" );
  // const EndDate = moment(data?.end_date).format( "DD-MM-YYYY" );
  const orderInfoDisplay = () => {
    return (
      <>
        <table className="table is-fullwidth">
          <tr>
            <td>Offer Title</td>
            <td>{data?.offer_title}</td>
          </tr>
          <tr>
            <td>Base Bid Price (A$)</td>
            <td>{displayAmount(data?.base_bid_price)}</td>
          </tr>
          <tr>
            <td>Bid Price (A$)</td>
            <td>{displayAmount(data?.bid_price)}</td>
          </tr>
          <tr>
            <td>GST (A$)</td>
            <td>{displayAmount(data?.bid_gst)}</td>
          </tr>
          <tr>
            <td>Total (A$)</td>
            <td>{displayAmount(data?.bid_total)}</td>
          </tr>
          <tr>
            <td>From & To</td>
            <td>{changeDateTimeZoneFormat(data?.start_date)} && {changeDateTimeZoneFormat(data?.end_date) }</td>
          </tr>
        </table>
      </>
    );
  };

  return (
    <>
      <div className="container">
        <div className="is-size-4 is-flex is-justify-content-center has-text-weight-bold mt-4">
         Bid Payment
        </div>
        <div className="columns">
          <div key={"PaymentOrder-column-one"} className="column is-8">
            <div className="card p-5">
              {data && data.payment_client && (
                <SmartStripePayment clientSecret={data?.payment_client} isLoading={isLoading} setLoading={setLoading} 
                handleSuccess={handleSuccess} />
              )}
            </div>
          </div>
          <div key={"PaymentOrder-column-two"} className="column is-4">
            <div className="card">{orderInfoDisplay()}</div>
          </div>
        </div>

      </div>
    </>
  );
};

export default PaymentOrder;
