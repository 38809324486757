import { useEffect, useState } from "react";
import { SmartSoftForm, SmartValid } from "soft_digi";
import { SETTINGS_COME_IMAGE } from "../../../../services/ImageService";
// import { useNavigate } from "react-router-dom";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { NOTIFICATION_SETTINGS } from "../../../../api/Services/ServiceUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose, showNotification } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";

const CommunicationPreForm = () => {
  // const navigate = useNavigate();
  // const baseNav = "/home";
  // const navigateLink = (index) => {
  //   navigate(baseNav + "/" + index);
  // };

  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  const { setLoading } = useSiteContext();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  // const handleErrorChange = (name, value) => {
  //   setFormErrors((prev) => {
  //     // Create a copy of the previous state
  //     const updatedFormData = { ...prev };
  //     // Check if the value is null or empty
  //     if (value === null || value === "") {
  //       // If true, remove the property from the updated state
  //       delete updatedFormData[name];
  //     } else {
  //       // Otherwise, update or add the property in the updated state
  //       updatedFormData[name] = value;
  //     }

  //     return updatedFormData;
  //   });
  // };
 const pre_process_data=(data)=>{
     let acc_communication = [];
     if(data.user_app_notification==="Yes"){
      acc_communication.push("1");
     }
     if(data.user_sms_notification==="Yes"){
      acc_communication.push("2");
     }
     if(data.user_email_notification==="Yes"){
      acc_communication.push("3");
     }
     return {app_communication : acc_communication};
 }

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = get(
      NOTIFICATION_SETTINGS.NOTIFICATION_GET_ONE,
      handleError
    ).subscribe((response) => {
      setFormData(pre_process_data(response.data));
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadTableData();
  }, []);


  const handleFormSubmit = () => {
    console.log(formData);
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElement)) {
     // console.log("error is avialble");
      return false;
    }
    let data_in = {};   
    data_in.user_app_notification = formData.app_communication?.indexOf("1")==-1 ? 'No' : 'Yes'
    data_in.user_sms_notification = formData.app_communication?.indexOf("2")==-1 ? 'No' : 'Yes'
    data_in.user_email_notification = formData.app_communication?.indexOf("3")==-1 ? 'No' : 'Yes'
  //  console.log("formdata", data_in);
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let url = NOTIFICATION_SETTINGS.NOTIFICATION_INSERT;
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Notification Updated Successfully", "success");
        setLoading(false);
        setFormSubmit(false);
        loadTableData()

      }
    );
    return () => {
      subscription.unsubscribe();
    };
    // setFormSubmit(true);
    // console.log("form errors ", formErrors);
  };

  const handleErrorChange = (name, value) => {
    // console.log(" name " ,name , "   value " , value)
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const validations = [
   // SmartValid.required("Required"),
    SmartValid.custom((value) => {     
      return value && Array.isArray(value) && value.length > 0 ? "": "Required";
    }),   
  ]
  const formElement = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "app_communication",
      element: {
        options: [
          { value: "1", "label": "App Notification" },
          { value: "2", "label": "SMS " },
          { value: "3", "label": "Email " }
        ],
        isMulti: true,
        validations: validations
      },

    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Save",
        classList: ["is-rounded", "smart-crop-action-button ml-6"],
        // onClick: () => handleLogin(),
        onClick: () => handleFormSubmit(),
        //  disabled:{!isEmptyObject(formErrors)}
      },
    }
  ];

  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
        <span className="ml-5">Communication Preference</span>
      </p>

      <div className="container p-6">
        <div className="columns  ">
          <div key={"CommunitionPreFrom-column-one"}
            className="column is-6  is-flex is-justify-content-center is-align-content-center app-communication-form">

            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElement}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
            />


            <div>
              {/* <div key={"main-field-1"} className="is-flex">
                <div className="my-3">
                  <SmartSoftCheckRadioSwitch
                    options={options}
                    name="user_app_notification"
                    value={formData?.user_app_notification || null}
                    onChange={(value) =>
                      handleInputChange("user_app_notification", value)
                    }
                    isRight={true}
                  />
                </div>

                <div className="general-label is-flex is-flex-direction-column m-3">
                  <p key={"main=label-1"} className="main-general-label">
                    App Notification{" "}
                  </p>
                </div>
              </div>

              <div key={"main-field-2"} className="is-flex">
                <div className="my-3">
                  <SmartSoftCheckRadioSwitch
                    options={options}
                    name="user_sms_notification"
                    value={formData?.user_sms_notification || null}
                    onChange={(value) =>
                      handleInputChange("user_sms_notification", value)
                    }
                    isRight={true}
                  />
                </div>

                <div className="general-label is-flex is-flex-direction-column m-3">
                  <p key={"main=label-2"} className="main-general-label">
                    SMS Notification{" "}
                  </p>
                </div>
              </div>

              <div key={"main-field-3"} className="is-flex">
                <div className="my-3">
                  <SmartSoftCheckRadioSwitch
                    options={options}
                    name="user_email_notification"
                    value={formData?.user_email_notification || null}
                    onChange={(value) =>
                      handleInputChange("user_email_notification", value)
                    }
                    isRight={true}
                  />
                </div>

                <div className="general-label is-flex is-flex-direction-column m-3">
                  <p key={"main=label-3"} className="main-general-label">
                    Email Notification{" "}
                  </p>
                </div>
              </div>

              <div className="is-flex ml-6 mt-3">
                <SmartSoftButton
                  label="Save"
                  onClick={() => handleFormSubmit()}
                  classList={["smart-crop-action-button"]}
                // disabled={!isEmptyObject(formErrors)}
                />
              </div> */}
            </div>
          </div>
          <div key={"CommunitionPreFrom-column-two"} className="column-6 smart-flex-container">
            <div className="card-image ">
              <img
                className="my-card-view-form-image"
                src={SETTINGS_COME_IMAGE}
                alt="Settings come"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunicationPreForm;
