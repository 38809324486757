import React from 'react'
import './ServiceSettingsToolTip.css';

const PickupAndDeliveryToolTip = ({ text }) => {
  return (
    <div className="tooltip">
      <div className="tooltip-content">{text}</div>
    </div>
  );
}

export default PickupAndDeliveryToolTip