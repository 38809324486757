import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { ORDER_ITEMS_URLS } from "../../../../api/OrderUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import {
  encrypt_data,
  setSessionStorage,
} from "../../../../services/sessionService";
import { post } from "../../../../services/smartApiService";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import { displayFiftyDots } from "../../../../services/core/CommonService";
import {
  CANCEL_ORDER,
  COMPLETE_ORDER,
  NEW_ORDERS,
  RETURN_ORDER,
} from "../../../../services/ImageService";

const Orders = () => {
  const [data, setData] = useState([]);
  const { tabtype } = useParams();
  // const [formData, setFormData] = useState({});
  // const [formSubmit, setFormSubmit] = useState(false);
  // const [formErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const navigate = useNavigate();
  const [tab, setTab] = useState("NEW");

  // this is api to the table data
  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData([]);
      setLoading(false);
    };
    let post_data = {
      type: tab,
      user_type: "BUSINESS",
    };
    const subscription = post(
      ORDER_ITEMS_URLS.GET_ALL,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      //  console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
    setSessionStorage("crop_business_order_type", tab);
  }, [tab]);

  useEffect(() => {
    //console.log("tab type", tabtype);
    if (tabtype && tabtype.length > 2) {
      setTab(tabtype);
    }
  }, []);

  const handleTabs = (index) => {
    setTab(index);
  };

  // const handleInputChange = (name, value) => {
  //   //console.log(" name " ,name , "   value " , value)
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  // const handleFormSubmit = (type) => {
  //   //  console.log("formdata", formData);
  //   setFormSubmit(true);
  //   setLoading(true, "Loading....Please Wait");
  //   const handleError = (errorMessage) => {
  //     showAlertAutoClose(errorMessage, "error");
  //     setLoading(false);
  //   };
  //   // let data_in = { ...formData, type: type };
  //   // const subscription = post(
  //   //   ORDER_URLS.GET_ALL_CUSTOMER,
  //   //   data_in,
  //   //   handleError
  //   // ).subscribe((response) => {
  //   //   setLoading(false);
  //   //   setData(response.data);
  //   // });
  //   // return () => {
  //   //   subscription.unsubscribe();
  //   // };
  //   // // console.log("form errors ", formErrors);
  // };
  const order_click = (id) => {
    let id_encr = encodeURIComponent(encrypt_data("" + id));
    navigate("/manage-program/order-info/" + id_encr);
  };

  const order_id = (data) => {
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <span
        className="has-text-link Orders-Order-number"
        onClick={() => order_click(id)}
      >
        {data?.order_id}
      </span>
    );
  };
  const offer_crops = (data) => {
    let type = data.order_type !== undefined ? data.order_type : "";
    return type === "REDEEM" ? (
      <span className="has-text-danger">{data.order_crops}</span>
    ) : (
      <span className="has-text-success">{data.order_crops}</span>
    );
  };
  const crop_id = (data) => {
    return (
      <>
        <div>{data?.customer_name}</div>
        {/* <div className="has-text-link">{data?.crop_id}</div> */}
      </>
    );
  };

  const offer_title_disp = (data) => {
    // console.log("data" ,data)
    let varient_data = data.variant_info;
    const parsedData = JSON.parse(varient_data);
    // console.log("varient_data" ,parsedData)
    return (
      <>
        <p>
          {data.offer_title}
          <br />
          {data.variant_info != null &&
            parsedData.map((item, index) => {
              return (
                <span key={index}>
                  <b>{item.variant_group}</b> : {item.variant_name}
                  {/* <br /> */}
                </span>
              );
            })}
          {data.custom_note?.length > 0 && (
            <div className="customer-note-View">
              <span className="customer-note-text">Custom Note: </span>
              {displayFiftyDots(data?.custom_note)}
            </div>
          )}
        </p>
      </>
    );
  };

  const columns = [
    { title: "Customer Name", index: "customer_name", valueFunction: crop_id },
    // { title: "CROP ID", index: "crop_id" },
    //{ title: "Offer Code", index: "offer_code" },
    { title: "Order Number", index: "order_id", valueFunction: order_id },
    {
      title: "Offer Title",
      index: "offer_title",
      width: "30",
      valueFunction: offer_title_disp,
    },
    { title: "Order Type", index: "order_type" },
    { title: "Type", index: "offer_mode" },
    { title: "Qty", index: "quantity" },
    { title: "Price", index: "total_price" },
    { title: "CROPs", index: "order_crops", valueFunction: offer_crops },
    {
      title: "Date",
      index: "order_time",
      type: "date",
      dateFormat: "DD-MM-YYYY",
      autoTimeZone: true,
    },
    { title: "Status", index: "status_short" },
  ];

  const tabs = [
    //  { index: "CROP", label: "Customer Orders" },
    {
      index: "NEW",
      label: "New Orders",
      icon: "fa fa-shopping-bag",
      image: NEW_ORDERS,
    },
    {
      index: "COMPLETED",
      label: "Completed Orders",
      icon: "fa fa-file-text-o",
      image: COMPLETE_ORDER,
    },
    {
      index: "CANCELLED",
      label: "Cancelled Orders",
      icon: "fa fa-ban",
      image: CANCEL_ORDER,
    },
    {
      index: "RETURN",
      label: "Returned Orders",
      icon: "fa fa-retweet",
      image: RETURN_ORDER,
    },
  ];

  const tabs_display = () => {
    return (
      <div className="tabs smart-business-tabs ">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={
                  tab === item.index ? "order-active-tab" : "tabs-list-items"
                }
              >
                <a href="#">
                  <span
                    className={
                      tab === item.index
                        ? "order-active-tab-text pr-5 pl-5"
                        : "pr-5 pl-5"
                    }
                  >
                    {item.label}
                  </span>
                  <span className="icon ">
                    {/* <i className={item.icon} aria-hidden="true"></i> */}
                    <img
                      className="image smart-order-tab-images"
                      src={item.image}
                      alt=""
                    />
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div>
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          {/* <span className="icon mt-1">
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span> */}
          {/* <span className="ml-5"> Customer Orders</span> */}
          <div className="pl-1">
            <SmartHeader
              title={"Manage Program"}
              sub_title={"Customer Orders"}
            />
          </div>
        </p>
        <div className="column is-12">
          <div className="content has-background-white smart-customer-input">
            {tabs_display()}
            <SmartSoftTable
              columns={columns}
              data={data}
              tableProps={{ className: "crop-bussiness-table-layout" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
