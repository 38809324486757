const ORDER_URLS = {
    INSERT:"/business/orders/insert",
    INSERT_DIRECT:"/business/orders/insert_direct",
    RETURN_DIRECT:"/business/orders/return_direct",
    GET_ALL:"/services/request/get_all/admin",
    GET_ALL_USER:"/services/request/get_all/user",
    UPDATE:"/business/orders_items/update",
    GET_ONE:"/services/request/get_one",
    GET_ALL_DIRECT:"/business/orders/get_all_direct",
    GET_ALL_ITEMS:"/business/orders_items/get_all_with_order"
}

const SERVICES_COMPLAINT_URLS = {
    INSERT:"/services/complain/insert",
    GET_ALL_ADMIN:"/services/complain/get_all/admin",
    GET_ALL_USER:"/services/complain/get_all_user",
    UPDATE:"/services/complain/update",
    GET_ONE:"/services/complain/get_one",
}
const ORDER_ITEMS_URLS = { 
    GET_ALL: "/business/orders_items/get_all",
    GET_ONE: "/business/orders_items/get_one",
    UPDATE_BUSINESS:"/business/orders_items/update_business",
    UPDATE_RETURN:"business/orders_items/update_return_business",
    GET_STATUS_DROP_DOWN:"/business/orders/get_all_status"
}

export {
    ORDER_URLS,   
    ORDER_ITEMS_URLS,
    SERVICES_COMPLAINT_URLS
}