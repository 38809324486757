import { useEffect, useState } from "react";
import { SmartSoftDate, SmartSoftInput, SmartSoftTable } from "soft_digi";
import { SUPPORT_BUSINESS_URLS } from "../../../../api/Services/ServiceUrls";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { changeDateTimeZone } from "../../../../services/core/CommonService";
import {
  filterDate,
  filterIncludes,
} from "../../../../services/core/FilterService";
import { DownLoadPdf } from "../../../../services/fileService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import MissingCropForm from "./MissingCropForm";

const ProcessMissingCropsTable = () => {
  const { setLoading, closeModal, openModal } = useSiteContext();
  const [tabData, setTabData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [formData, setFormData] = useState({});
  const [minEndDate, setMinEndDate] = useState();
  // const [tableData, setTableData] = useState({});
  const nameFunction = (row) => {
    switch (row["status"]) {
      case 5:
        return <span className="has-text-link">Open</span>;
      case 9:
        return <span className="has-text-success">Closed</span>;
      case 10:
        return <span className="has-text-success">Closed</span>;
    }
  };
  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "start_date") {
      setMinEndDate(value);
      handleInputChange("end_date", "");
    }
  };

  const openNewForm = (row) => {
    let modalObject = {
      title: "Process Missing CROPs: Request ID " + row["request_id"],
      body: (
        <MissingCropForm
          loadTableData={loadTableData}
          data={row}
          setLoading={setLoading}
          closeModal={closeModal}
        />
      ),
      modalClass: "crop-admin-modal smart-modal-50",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const handleMissingFile = (item) => {
    setLoading(true, "Loading... Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let post_data = { id: item.ID };
    const subscription = post(
      SUPPORT_BUSINESS_URLS.GET_ONE,
      post_data,
      handleError
    ).subscribe((response) => {
      //console.log(response);
      if (response.data.invoice_image) {
        DownLoadPdf(response.data.invoice_image, "rfc.pdf");
      }
      //  setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const display_invoice_button = (data) => {
    return (
      <div onClick={() => handleMissingFile(data)} className="">
        <i
          className="fa fa-download is-size-5 smart-edit-icon"
          aria-hidden="true"
        ></i>
      </div>
    );
  };

  const missingCrops = (data) => {
    return (
      <>
        {data?.status !== 10 ? (
          <div
            className="is-flex"
            // onClick={() => openNewForm(data)}
          >
            <figure>{data["request_id"] || ""}</figure>
          </div>
        ) : (
          <div> {data.request_id}</div>
        )}
      </>
    );
  };
  const missingCrops_points = (data) => {
    return (
      <>
        {data?.status !== 10 && data?.status !== 9 && (
          <div
            className="is-flex has-text-link pointer"
            onClick={() => openNewForm(data)}
          >
            Outcome
          </div>
        )}
      </>
    );
  };

  const columns = [
    {
      title: "S. No.",
      index: "sno",
      width: "5",
      type: "sno",
      // isSortable: true
    },
    {
      title: "Request ID",
      index: " request_id",
      width: "15",
      valueFunction: missingCrops,
    },
    {
      title: "Invoice Number",
      index: "invoice_number",
      width: "15",
      // valueFunction: requestFunction,
    },
    // {
    //   title: "Invoice Amount",
    //   index: "invoice_amount",
    // },

    {
      title: "Invoice Date",
      index: "invoice_date",
      width: "15",
      dateFormat: "DD-MM-YYYY",
      type: "date",
      autoTimeZone: true,
    },
    {
      title: "Invoice Copy",
      index: "invoice_image",
      width: "15",
      // valueFunction: invoice_image,
      valueFunction: display_invoice_button,
    },
    {
      title: "Reason For Claim",
      index: "reason_missing",
      width: "15",
      //  isSortable: true ,
    },
    {
      title: "Status",
      index: "status",
      valueFunction: nameFunction,
      // isSortable: true,
    },
    {
      title: "Action",
      index: "action",
      width: "10",
      valueFunction: missingCrops_points,
    },
  ];

  // const data = [
  //   {
  //     sno: "1",
  //     offertitle: "",
  //     invoicedate: "04/05/2023",
  //     invoicecopy: "6B772157-0001",
  //     reasonforclaim: "Missing crop Claim",
  //     status: "rejected",
  //   },
  //   {
  //     sno: "2",
  //     offertitle: "",
  //     invoicedate: "04/05/2023",
  //     invoicecopy: "6B772157-0001",
  //     reasonforclaim: "missing",
  //     status: "approved",
  //   },
  //   {
  //     sno: "3",
  //     offertitle: "",
  //     invoicedate: "28/04/2023",
  //     invoicecopy: "E7E25D3D-0001",
  //     reasonforclaim: "purchase",
  //     status: "approved",
  //   },
  //   {
  //     sno: "4",
  //     offertitle: "",
  //     invoicedate: "28/04/2023",
  //     invoicecopy: "E7E25D3D-0001",
  //     reasonforclaim: "purchase",
  //     status: "approved",
  //   },
  // ];

  const loadTableData = () => {
    setLoading(true, "Loading.... Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };

    let post_data = { ...formData };
    post_data["start_date"] = post_data["start_date"]
      ? changeDateTimeZone(formData.start_date)
      : "";
    post_data["end_date"] = post_data["end_date"]
      ? changeDateTimeZone(formData.end_date)
      : "";
    const subscription = post(
      SUPPORT_BUSINESS_URLS.GET_ALL_BUSINESS,
      post_data,
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      setFilterData(response.data);
      //if (formData.search_string) searchData();
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  useEffect(() => {
    searchData();
  }, [formData]);

  const filterFunction = (item) => {
    let output = filterDate(
      item,
      "invoice_date",
      formData.start_date,
      formData.end_date
    );
    output =
      output &&
      filterIncludes(
        item,
        ["request_id", "invoice_number", "reason_missing"],
        formData.search_string
      );
    return output;
  };

  const searchData = () => {
    const out_data = tabData.filter(filterFunction);
    setFilterData(out_data);
  };

  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
        {/* <span className="ml-5">Process Missing CROPs</span> */}
        <div className="pl-1">
          <SmartHeader title={"Support"} sub_title={"Process Missing CROPs"} />
        </div>
      </p>
      <div className="columns is-12 processMissingCrops-media-screen  mt-4 smart-crop-business-forms ">
        <div className="column is-3" key={"1"}>
          <SmartSoftDate
            key="text-1"
            label="From"
            value={formData?.start_date || ""}
            onChange={(value) => handleInputChange("start_date", value)}
            inputProps={{ isFocussed: true }}
            dateFormat="dd/MM/yyyy"
            placeHolder="DD/MM/YYYY"
            maxDate={new Date()}
          />
        </div>

        <div className="column is-3" key={"2"}>
          <SmartSoftDate
            key="text-2"
            label="To"
            value={formData?.end_date || ""}
            onChange={(value) => handleInputChange("end_date", value)}
            inputProps={{ isFocussed: true }}
            dateFormat="dd/MM/yyyy"
            placeHolder="DD/MM/YYYY"
            minDate={minEndDate}
            maxDate={new Date()}
          />
        </div>
        <div className="smart-customer-earn-crop-input column is-4">
          <SmartSoftInput
            key="text-4"
            width="6"
            label="Search"
            placeHolder="Search by Invoice No"
            value={formData?.search_string || ""}
            onChange={(value) => handleInputChange("search_string", value)}
          />
        </div>
        {/* <div className="smart-customer-button mr-3 column is-2 mt-2 ">
                  <SmartSoftButton
                    label="Search"
                    classList={["smart-crop-action-button", "my-5"]}
                    onClick={()=>searchData()}
                  />
                </div> */}
      </div>
      <div className="columns">
        <div className="column mx-3">
          <SmartSoftTable
            columns={columns}
            data={filterData}
            tableProps={{ className: "crop-bussiness-table-layout" }}
          />
        </div>
      </div>
      {tabData.length > 1 && filterData && filterData.length < 1 && (
        <div className="no-record-found-text-two">
          There are no contents to display for your current search
        </div>
      )}
    </>
  );
};

export default ProcessMissingCropsTable;
