import { useNavigate } from "react-router-dom";
import { BUSINESS_CODE, CROP_FOOTER, CROP_LOGO, CROP_PAID } from "../../../../services/ImageService";

const MyCropsView = () => {
  const navigate = useNavigate();
  const baseNav = "/my-account";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const MyCropTax = () => {
    return (
      <>
        <div className="container mx-6 my-6">
          <div>
            <img src={CROP_LOGO} alt="logo" />
          </div>
          <div
            key={"MyCropsview-columns-one"}
            className="columns crop-services-background has-text-white"
          >
            <div key={"MyCropsview-column-two"} className=" column is-6">
              <p>CROP SERVICE PTY LTD</p>
              <p>ABN 4366424011</p>
            </div>
            <div
              key={"MyCropsview-column-three"}
              className="column is-5 has-background-white has-text-black"
            >
              <p className="is-size-2 has-text-weight-semibold is-flex is-justify-content-center">
                TAX INVOICE
              </p>
            </div>
            <div key={"MyCropsview-column-four"} className="column is-1"></div>
          </div>
          <div>{MyCropsBilling()}</div>
        </div>
      </>
    );
  };

  const MyCropsBilling = () => {
    return (
      <>
        <div className="container">
          <div
            key={"MyCropsview-columns-five"}
            className="columns has-text-weight-semibold"
          >
            <div key={"MyCropsview-column-six"} className="column">
              <p>Billing Address:</p>
              <div
                key={"MyCropsview-column-seven"}
                className="column is-2 is-offset-10"
              >
                <p>Invoice No: 0001</p>
                <p>Date: 10-Jul-23</p>
              </div>
              <div key={"MyCropsview-column-eight"} className="columns">
                <div key={"MyCropsview-column-nine"} className="column is-8">
                  <div className="mb-4">
                    <p>Bill To:</p>
                    <p>Customer or Business Name</p>
                    <p>ABN if Business</p>
                  </div>
                  <p>Reference:</p>
                  <p>Purchase Invoice</p>
                </div>
                <div key={"MyCropsview-column-ten"} className="column is-4">
                  <img
                    className="image is-128x128"
                    src={CROP_PAID}
                    alt="paid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>{MyCropsTable()}</div>
        </div>
      </>
    );
  };

  const MyCropsTable = () => {
    return (
      <>
        <div>
          <table className="crop-view-table-layout">
            <thead>
              <tr>
                <th>#</th>
                <th>Item Description</th>
                <th>Product Code</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Balaji</td>
                <td>C15AX8G</td>
                <td>2</td>
                <td>10.00</td>
                <td>20.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          key={"MyCropsview-columns-eleven"}
          className="columns has-text-weight-bold"
        >
          <div key={"MyCropsview-column-twelve"} className="column is-4 mt-6">
            <div className="mb-5">
              <p>Thank You for your business</p>
            </div>
            <p>T&C Apply</p>
            <div className="mt-6">
              <p>Payment Info</p>
              <p>Account Name: CROP Services</p>
              <p>BSB: 083 004</p>
              <p>Account Number: 408785753</p>
            </div>
          </div>
          <div
            key={"MyCropsview-column-thirteen"}
            className="column is-2 is-flex is-justify-content-center is-align-items-center"
          >
            <img src={BUSINESS_CODE} alt="QR" />
          </div>
          <div
            key={"MyCropsview-column"}
            className="column is-6 is-flex is-justify-content-center"
          >
            <p>
              <table className="mt-6">
                <thead>
                  <tr>
                    <th>Sub Total:</th>
                    <td>20.00</td>
                  </tr>{" "}
                  <tr>
                    <th>GST:</th>
                    <td>0.00</td>
                  </tr>
                  <tr>
                    <th>Total:</th>
                    <td>0.00</td>
                  </tr>
                  <tr className="crop-services-background mx-5 my-5">
                    <th>Amount Payable:</th>
                    <td>$20.00</td>
                  </tr>
                  <p>Amount in words(A$): Twenty Only</p>
                </thead>
              </table>
            </p>
          </div>
        </div>
        <div>
          <img src={CROP_FOOTER} alt="crop_footer" className="mr-6" />
        </div>
      </>
    );
  };

  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        <span
          className="icon mt-1"
          onClick={() => navigateLink("my-crops-list")}
        >
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span>
        <span className="ml-5">View My CROPs Activity</span>
      </p>
      <div>{MyCropTax()}</div>
    </>
  );
};

export default MyCropsView;
