import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { CROP_LOGO, CROP_PAID } from "../../../../services/ImageService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { decrypt_data } from "../../../../services/sessionService";
import { post } from "../../../../services/smartApiService";


const MarketOfferInvoice = () => {
    const [data, setData] = useState({});
    const { id } = useParams();
    const decodeId = decodeURIComponent(id);
    const decrypted_id = decrypt_data(decodeId);
    const { setLoading } = useSiteContext();

    const loadTableData = () => {
        //setLoading(true, "Loading....Please Wait");
        const handleError = (errorMessage) => {
          showAlertAutoClose(errorMessage);
          setLoading(false);
        };
        let post_data = { id: decrypted_id };
        const subscription = post(
          MANAGE_OFFER_URLS.GET_ONE_BID,
          post_data,
          handleError
        ).subscribe((response) => {
          setData(response.data);
          setLoading(false);
        });
        return () => {
          subscription.unsubscribe();
        };
      };
    
      useEffect(() => {
        loadTableData();
      }, [decrypted_id]);


    const MyCropTax = () => {
        return (
          <>
            <div className="container mx-6 my-6">
              <div>
                <img src={CROP_LOGO} alt="logo" />
              </div>
              <div key={"MarketofferInvoice-columns-one"} className="columns crop-services-background has-text-white">
                <div key={"MarketofferInvoice-column-one"} className=" column is-6">
                  <p>CROP SERVICE PTY LTD</p>
                  <p>ABN 4366424011</p>
                </div>
                <div key={"MarketofferInvoice-column-two"} className="column is-5 has-background-white has-text-black">
                  <p className="is-size-3 has-text-weight-semibold is-flex is-justify-content-center">
                    SALES INVOICE
                  </p>
                </div>
                <div key={"MarketofferInvoice-column-three"} className="column is-1"></div>
              </div>
              <div>{MyCropsBilling()}</div>
            </div>
          </>
        );
      };
      
      const MyCropsBilling = () => {
        return (
          <>
            <div className="container">
              <div key={"MarketofferInvoice-columns-two"} className="columns has-text-weight-semibold">
                <div key={"MarketofferInvoice-column-four"} className="column">
                  <p>Billing Address:</p>
                  <div key={"MarketofferInvoice-column-five"} className="column is-2 is-offset-10">
                    <p>Invoice No: 0001</p>
                    <p>Date:{data?.payment_date}</p>
                  </div>
                  <div key={"MarketofferInvoice-columns-three"} className="columns">
                    <div  key={"MarketofferInvoice-column-six"} className="column is-8">
                      <div className="mb-4">
                        <p>Bill To:</p>
                        <p>{data?.business_name}</p>
                        <p>ABN if Business</p>
                      </div>
                      <p>Reference:</p>
                      <p>Bid Invoice</p>
                    </div>
                    <div key={"MarketofferInvoice-column-seven"} className="column is-4">
                      <img className="image is-128x128" src={CROP_PAID} alt="paid" />
                    </div>
                  </div>
                </div>
              </div>
              <div>{MyCropsTable()}</div>
            </div>
          </>
        );
      };
      const MyCropsTable = () => {       
      
        return (
          <>
            <div>
              <table className="crop-view-table-layout">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Offer Title</th>
                    <th>Bid Price</th>                  
                  </tr>
                </thead>
                <tbody>                
                    <tr>
                      <td>1</td>
                      <td>{data?.offer_title}</td>      
                      <td>{data?.bid_price}</td>                     
                    </tr>                 
                </tbody>
              </table>
            </div>
            <div key={"MarketofferInvoice-columns-four"} className="columns has-text-weight-bold">
              <div key={"MarketofferInvoice-column-eight "} className="column is-4 mt-6">
                <div className="mb-5">
                  <p>Thank You for your business</p>
                </div>
                <p>T&C Apply</p>
                <div className="mt-6">
                  <p>Payment Info</p>
                  <p>Account Name: CROP Services</p>
                  <p>BSB: 083 004</p>
                  <p>Account Number: 408785753</p>
                </div>
              </div>
              <div key={"MarketofferInvoice-column-nine"} className="column is-2 is-flex is-justify-content-center is-align-items-center">
                QR
              </div>
              <div key={"MarketofferInvoice-column-ten"} className="column is-6 is-flex is-justify-content-center">
                <p>
                  <table className="mt-6">
                    <thead>
                      <tr>
                        <th>Sub Total:</th>
                        <td>{data.bid_price}</td>
                      </tr>{" "}
                      <tr>
                        <th>GST:</th>
                        <td>0.00</td>
                      </tr>
                      <tr>
                        <th>Total:</th>
                        <td>{data.bid_price}</td>
                      </tr>
                      <tr className="crop-services-background mx-5 my-5">
                        <th>Amount Payable:</th>
                        <td>${data.bid_price}</td>
                      </tr>
                      <p>Amount in words(A$): </p>
                    </thead>
                  </table>
                </p>
              </div>
            </div>
          </>
        );
      };

  return (
    <>
      <div className="container mt-3">
        <div>{MyCropTax()}</div>
      </div>
    </>
  );
};

export default MarketOfferInvoice;
