import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { CROP_CUSTOMER_HOME_IMAGE_2 } from "../../services/ImageService";
import "./ImageCarousel.css";

const ImageCarousel = (props) => {
  const {
    data = [],
    isMulti = false,
    showImages = 3,
    showButtons = false,
    buttonsClassName = "",
    dots = false,
    showImageNumber = 0,
    containerClass = "iamge-sldier-single-div",
    deleteImage,
    imageEditClick,
  } = props;

  const [index, setIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const gotoSlide = (slideNumber) => {
    sliderRef.slickGoTo(slideNumber);
  };

  useEffect(() => {
    gotoSlide(showImageNumber);
    // if (data && data.length > 0) {
    //   setContent(data[0].content)
    // }
  }, [showImageNumber]);

  useEffect(() => {
    //console.log(" image number " , showImageNumber)
    gotoSlide(0);
    // console.log("image data " , data);
    setCurrentIndex(0);
   // console.log("length of data " , data.length);

  }, [data]);

  const settings = {
    customPaging: function (i) {
      // console.log("customer paheong " , i);
      return (
        <a>
          <img src={CROP_CUSTOMER_HOME_IMAGE_2} alt="" />
        </a>
      );
    },
    infinite: false,
    speed: 500,
  };

  const beforeChange = (prev, index) => {
    setIndex(index);
    //console.log("prev ", prev , " index " , index);
  };

  const prevNextButtons = () => {
    return (
      <div className={buttonsClassName}>
        <button
          disabled={index == 0}
          className="button is-previous "
          onClick={previous}
          key="prev"
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <button
          disabled={index == data?.length - 1}
          className="button is-next "
          onClick={next}
          key="next"
        >
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
    );
  };
  //console.log(settings);

  if (showButtons === false) {
    settings["autoplay"] = false;
    settings["autoplaySpeed"] = 5000;
    settings["cssEase"] = "linear";
  }
  if (isMulti === true) {
    settings["slidesToShow"] = 3;
    settings["slidesToScroll"] = 1;
    settings["centerMode"] = false;
    settings["infinite"] = false;
  }
  if (dots === true) {
    settings["dots"] = true;
  }

  settings["beforeChange"] = beforeChange;

  const deleteImageClick = (indexToRemove) => {
    if (deleteImage && deleteImage instanceof Function) {
      deleteImage(indexToRemove);
    }
  };

  const handleEdit = (index) => {
    if (imageEditClick && imageEditClick instanceof Function) {
      imageEditClick(index);
    }
  };

  const edit_options = () => {
    return (
      <div className="image-edit-options">
        <i
          className="fa fa-trash  image-edit-options-icon"
          onClick={() => deleteImageClick(currentIndex)}
        ></i>
        <i
          className="fa fa-pencil-square-o  mt-1 image-edit-options-icon "
          onClick={() => handleEdit(currentIndex)}
        ></i>
      </div>
    );
  };

  const enableButtons =
    imageEditClick && imageEditClick instanceof Function ? true : false;

  const currentIndexContent =
    data && data[currentIndex] ? data[currentIndex].content : "";

    const getClassBasedOnLength = () => {
      if (data.length === 2) {
        return 'react-slick-two-images';
      } else if (data.length === 3) {
        return 'react-slick-three-images';
      } else {
        return 'multiple-images';
      }
    };

  return (
    <>
      {currentIndexContent && (
        <div className="smart-image-edit-buttons-div is-flex is-justify-content-center">
          <figure className="image  smart-carosel-images-container">
            <img
            
              src={`data:image/png;base64,${currentIndexContent}`}
              alt=""
            />
          </figure>
          {enableButtons && edit_options()}
        </div>
      )}
      <div className={getClassBasedOnLength()}>
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
      >
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className={isMulti ? "image-slider-multi-div" : containerClass}
              onClick={() => setCurrentIndex(index)}
            >
              {item.data}
            </div>
          );
        })}
      </Slider>
      </div>
    </>
  );
};

export default ImageCarousel;
