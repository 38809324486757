import React from 'react'
import './ToolTip.css';

const ToolTip = ({ text }) => {
  return (
    <div className="tooltip">
      <div className="tooltip-content">{text}</div>
    </div>
  );
}

export default ToolTip