import { useState } from "react";
import {  SmartSoftForm } from "soft_digi";
import { SmartValid, ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { ALLOW_ALPHABET_SPACE } from "../../../../services/PatternSerivce";

const AddVariantForm = ({ closeModal,addVariantNew,options }) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const FormValidations = {   
    title: [SmartValid.required("Variant Name is Required")],   
    // describe: [SmartValid.required("Description is Required")],
  };

  const handleFormSubmit = () => {
    //console.log("formdata", formData);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    console.log("options " , options);
    const check_exists = options.find(item=>item.name==formData.name);
    if(check_exists){
      showAlertAutoClose("Variant Already Exists", "error");
      return false;
    }
    addVariantNew(formData.name);
    closeModal()
  };

  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "name",
      element: {
        label: "",
        placeHolder:"Enter Variant Name",
        validations: FormValidations.title,
        pattern:"[A-Za-z0-9 ]*"
        // pattern:"[A-Za-z0-9 ,]*"
      },
    },
   
  ];
  
  const formElement = [
   
    {
      type: "BUTTON",
      width: "12",
      name: "submit",
      element: {
        label: "Add",
        classList: ["smart-customer-primary-button"],
        onClick: handleFormSubmit,
      },
    },
  ];


  return (
    <>
      <div className="">
        <div className="columns is-multiline is-mobile">
          <div className="column is-10 has-text-centered is-flex-mobile">
            <div className="variant-Additiion-text-popup has-text-centered">
              Variant Addition
            </div>
          </div>
          <div className="column is-2 is-flex-mobile">
            <div className="mb-1 ml-6 has-text-right" onClick={closeModal}>
              <div className="customer-modal-close ">
                <i class="fa fa-times has-text-white" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="columns mb-3">
          <div className="column is-9">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElements}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
            />
          </div>
          <div className="column is-3">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElement}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVariantForm;
