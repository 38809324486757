import { useState } from 'react'
import QRCode from 'react-qr-code'


const QrCode = ({item,size}) => {
    const[data,setData]=useState("crop")
  return (
   <>

<QRCode className='qr-code'
       value={item}
       size={size} 
       fgColor="#000" 
       bgColor=" #00000000"
       />
   </>

   
  )
}

export default QrCode