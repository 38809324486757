import { useState } from 'react'
import { useSiteContext } from '../../../../contexts/SiteProvider';
import QrCode from '../../../../components/site/QrCode';


const QRCodeOpen = ({item}) => {
    const[data,setData]=useState("crop")
    const { openModal, closeModal } = useSiteContext();

  return (
   <>

<div className="columns ml-6">
        
            <div className="column is-10 is-flex is-justify-content-center">

            <div className="ml-5">
          <QrCode item={item} size={180} />
          </div>
          
          
        </div>
       

          
          <div className="mb-1 ml-6 has-text-right" onClick={closeModal}>
                  <div className="customer-modal-close">
                 <i class="fa fa-times has-text-white" aria-hidden="true"></i>
               </div>
          </div >
      </div>
   </>
  )
}

export default QRCodeOpen