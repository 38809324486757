import { useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { SERVICES_COMPLAINT_URLS } from "../../../../api/Services/ServiceUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import {
  // get,
  post,
} from "../../../../services/smartApiService";

const RequestAndComplaint = ({ loadTableData, handleTabs }) => {
  const [formData, setFormData] = useState({});
  const [optionData, setoptionData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal, refreshUser } = useSiteContext();

  const handleInputChange = (name, value) => {
    // console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "type") {
      loadDropDwnData(value);
    }
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const loadDropDwnData = (value) => {
    console.log(formData);
    const handleError = (errorMessage) => {
      // showAlertAutoClose(errorMessage,"error");
      setLoading(false);
    };
    let url = SERVICES_COMPLAINT_URLS.GET_DROP_DOWN;
    let data_in = {
      type: "BUSINESS_" + value?.value,
    };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        //  console.log(response);
        setoptionData(response.data);
      }
    );
  };
  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    setLoading(true, "Submitting....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let url = SERVICES_COMPLAINT_URLS.INSERT;
    let data_in = { ...formData };
    data_in["complaint_mode"] = "BUSINESS";
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        //console.log(response.data);
        loadTableData();
        // console.table("testing", response.data);
        if (formData.type.value == "Request") {
          showAlertAutoClose("Request Submitted Successfully", "success");
        } else {
          showAlertAutoClose("Complaint Submitted Successfully", "success");
        }
        setLoading(false);
        setFormData({});
        closeModal();
        refreshUser();
        handleTabs("OLD");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    // console.log("form errors ", formErrors);
  };

  const options = [
    { value: "Complaint", label: "Complaint" },
    { value: "Request", label: "Request" },
  ];
  // const pcmOptions = [
  //     { value: "Mobile", label: "Mobile" },
  //     { value: "Email", label: "Email" },
  // ];
  const Validations = {
    status: [SmartValid.required("Description is Required")],
    Type: [SmartValid.required("Type is Required")],
    Category: [SmartValid.required("Category is Required")],
  };
  const formElements = [
    {
      type: "SELECT_BOX",
      width: "12",
      name: "type",
      element: {
        placeHolder: "Type",
        label: "Type",
        options: options,
        isRequired: true,
        validations: Validations.Type,
      },
    },
    {
      type: "SELECT_BOX",
      width: "12",
      name: "complaint_type",
      element: {
        label: "Category",
        placeHolder: "Category",
        options: optionData,
        isRequired: true,
        validations: Validations.Category,
      },
    },

    {
      type: "TEXTAREA",
      width: "12",
      name: "complaint_description",
      element: {
        label: "Description",
        isRequired: true,
        placeHolder: " Description",
        validations: Validations.status,
      },
    },
  ];

  return (
    <>
      <div className="container smart-crop-business-forms">
        <div className="columns ">
          <div className="column  ml-5 mt-5 smart-flex-container">
            <div>
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
              <div className="is-flex is-justify-content-center mt-6">
                <SmartSoftButton
                  label="Submit"
                  onClick={() => handleFormSubmit()}
                  classList={["smart-customer-primary-button"]}
                  // disabled={!isEmptyObject(formErrors)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestAndComplaint;
