import { SmartSoftTable } from "soft_digi";

const CustomerTranscation = ({ products, setProducts, type, returnItem }) => {
  const updateItemQuantity = (itemId, amount) => {
    setProducts((prevItems) =>
      prevItems.map((item) =>
        item.ID === itemId
          ? { ...item, quantity: Math.max(1, parseInt(item.quantity) + amount) } // Ensure quantity is not less than 1
          : item
      )
    );
  };

  const removeItem = (id) => {
    setProducts(products.filter((item) => item.ID !== id));
  };

  const addFunction = (item) => {
    //console.log("item " , item);
    return (
      <>
        <div className="is-flex crop-customer-trans-count">
          <div
            className="smart-crop-customer-count"
            onClick={() => updateItemQuantity(item.ID, -1)}
          >
            <i className="fa fa-minus" aria-hidden="true"></i>
          </div>
          <p className="is-size-6 mt-1 mx-2">{item.quantity}</p>

          <div
            className="smart-crop-customer-count"
            onClick={() => updateItemQuantity(item.ID, 1)}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
          </div>
        </div>
      </>
    );
  };

  const getSingleCrop = (item) => {
    if (type == "EARN") {
      return item["earn_crop_points"];
    } else if (type == "REDEEM") {
      return item["redeem_crop_points"];
    } else if (type === "RETURN") {
      return item["order_crops"];
    }
    return 0;
  };

  const get_total_crops = () => {
    // console.log(products);
    const totalPrice = products
      ? products.reduce(
          (acc, item) => acc + item.quantity * getSingleCrop(item),
          0
        )
      : 0;
    return totalPrice;
  };

  const get_total_price = () => {
    const totalPrice = products
      ? products.reduce(
          (acc, item) => acc + item.quantity * item.total_price,
          0
        )
      : 0;
    return totalPrice;
  };

  const rounded_value = (input) => {
    let roundedTotal = Math.round(input * 100) / 100;
    return roundedTotal;
  };

  const priceFunction = (item) => {
    return rounded_value(item["total_price"] * item["quantity"]);
  };

  const cropFunction = (item) => {
    return getSingleCrop(item) * parseFloat(item["quantity"]);
  };

  const buttons = [
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-times-circle-o",
      classList: ["delete-color is-clickable is-size-5"],
      onClick: (data) => {
        removeItem(data["ID"]);
      },
    },
  ];

  const offer_title_disp = (data) => {
    // console.log("offer data " , data);
    return (
      <>
        <p className="m-3">
          {data.offer_title}
          <div className="mb-4">
            {data.variantList &&
              Array.isArray(data.variantList) &&
              data.variantList.map((item) => {
                return (
                  <span className="is-size-7 ml-2">
                    <b>{item.variant_group}</b> : {item.variant_name}
                  </span>
                );
              })}
          </div>
        </p>
      </>
    );
  };

  const columns = [
    {
      title: "Offer Title",
      index: "offer_title",
      width: "40",
      valueFunction: offer_title_disp,
    },
    {
      title: "Qty",
      index: "qty",
      width: "20",
      classHead: "has-text-centered",
      classBody: "has-text-centered",
      valueFunction: (item) => addFunction(item),
    },
    {
      title: "Price (A$)",
      index: "price",
      width: "20",
      classHead: "has-text-centered",
      classBody: "has-text-centered",
      valueFunction: (item) => priceFunction(item),
    },
    {
      title: "CROPs",
      index: "earn_crop_points",
      width: "10",
      classHead: "has-text-centered",
      classBody: "has-text-centered",
      valueFunction: (item) => cropFunction(item),
    },
    {
      title: "",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "5",
    },
  ];

  const redeem_columns = [
    {
      title: "Offer Title",
      index: "offer_title",
      width: "70",
      valueFunction: offer_title_disp,
    },
    {
      title: "QTY",
      index: "qty",
      width: "15",
      classHead: "has-text-centered",
      classBody: "has-text-centered",
      valueFunction: (item) => addFunction(item),
    },
    {
      title: "CROPs",
      classHead: "has-text-centered",
      classBody: "has-text-centered",
      index: "earn_crop_points",
      width: "15",
      valueFunction: (item) => cropFunction(item),
    },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "5",
    },
  ];

  const return_columns = [
    {
      title: "Offer Title",
      index: "offer_title",
      width: "70",
    },
    {
      title: "QTY",
      index: "quantity",
      width: "15",
      classBody: "has-text-centered",
      classHead: "has-text-centered",
    },
    {
      title: "Price(A$)",
      index: "earn_crop_points",
      classBody: "has-text-centered",
      classHead: "has-text-centered",
      width: "15",
      valueFunction: (item) => priceFunction(item),
    },
  ];

  // order_type
  const return_redeem_columns = [
    {
      title: "Offer Title",
      index: "offer_title",
      width: "70",
    },
    {
      title: "QTY",
      index: "quantity",
      width: "15",
      classBody: "has-text-centered",
      classHead: "has-text-centered",
    },
    {
      title: "CROPs",
      index: "earn_crop_points",
      classBody: "has-text-centered",
      classHead: "has-text-centered",
      width: "15",
      valueFunction: (item) => cropFunction(item),
    },
  ];

  const getColumns = () => {
    if (type == "EARN") {
      return columns;
    } else if (type == "REDEEM") {
      return redeem_columns;
    } else if (type == "RETURN") {
      return returnItem && returnItem.order_type === "REDEEM"
        ? return_redeem_columns
        : return_columns;
    } else {
      return [];
    }
  };

  const footerTest = () => {
    return (
      <>
        {type === "EARN" && (
          <tfoot>
            <tr>
              <th></th>
              <th className="has-text-centered">Total</th>
              <th className="has-text-centered">
                {rounded_value(get_total_price())}
              </th>
              <th className="has-text-centered">
                {rounded_value(get_total_crops())}
              </th>
              <th></th>
            </tr>
          </tfoot>
        )}
        {type === "RETURN" && (
          <tfoot>
            <tr>
              <th></th>
              <th className="has-text-centered">Total</th>
              <th className="has-text-centered">
                {returnItem && returnItem.order_type === "REDEEM"
                  ? rounded_value(get_total_crops())
                  : rounded_value(get_total_price())}
              </th>
              <th></th>
            </tr>
          </tfoot>
        )}
        {type === "REDEEM" && (
          <tfoot>
            <tr>
              <th></th>
              <th className="has-text-centered">Total</th>
              <th className="has-text-centered">
                {rounded_value(get_total_crops())}
              </th>
              <th></th>
            </tr>
          </tfoot>
        )}
      </>
    );
  };

  return (
    <>
      {products && products.length > 0 && (
        <div className="">
          <SmartSoftTable
            columns={getColumns()}
            data={products}
            tableProps={{
              className:
                "crop-bussiness-table-layout crop-business-transactions-table",
            }}
            footerConfig={[]}
            paginationProps={{ pageSize: 2000 }}
            footerFunction={footerTest}
          />
          <br />
        </div>
      )}
    </>
  );
};

export default CustomerTranscation;
