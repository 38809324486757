import React from 'react'
import BusinessFooter from './BusinessFooter'
import './BusinessLayoutCss.css';
import BusinessLoginHeader from './BusinessLoginHeader';

const HeaderAndFooterLayout = ({ children }) => {
  return (
<div key="container_one"
      className="container is-fluid smart-crop-customer-container ">
<div className="card smart-customer-header" key="header_one">
  <BusinessLoginHeader />
</div>
<div key="header_two" className="smart-crop-customer-middle">
  { children }
</div>
<div key="header_three" className="smart-crop-customer-footer">
<BusinessFooter />
</div>

</div>
  )
}

export default HeaderAndFooterLayout