
import { BUSSINESS_HEAD_TOP_RIGHT_lOGO } from '../../../services/ImageService'

const ViewComentsCard = () => {

  const items=[
    {
      image:  <img src={BUSSINESS_HEAD_TOP_RIGHT_lOGO} alt='top-right-logo' />,
      name:"Gopinath Black_Hat Hacker",
      status:"not good",
      date:"2023-09-28 T11:12:43.938z",
    },
    {
      image:  <img src={BUSSINESS_HEAD_TOP_RIGHT_lOGO} alt='top-right-logo' />,
      name:"Gopinath Black_Hat Hacker",
      status:"not good",
      date:"2023-09-28 T11:12:43.938z",
    },
    {
      image:  <img src={BUSSINESS_HEAD_TOP_RIGHT_lOGO} alt='top-right-logo' />,
      name:"Gopinath Black_Hat Hacker",
      status:"not good",
      date:"2023-09-28 T11:12:43.938z",
    },
    {
      image:  <img src={BUSSINESS_HEAD_TOP_RIGHT_lOGO} alt='top-right-logo' />,
      name:"Gopinath Black_Hat Hacker",
      status:"not good",
      date:"2023-09-28 T11:12:43.938z",
    },
  ]
  return (
    <>
    <div  key={" ViewComentsCard-columns-one"} className='columns m-2'>
      {items.map((item) =>
      <div  key={" ViewComentsCard-column-two"} className='column is-3'>
        <div className='view-comments-card shadow-sm'>
          <div className="">{item.image}</div>
          <div className=" smart-view-comments-text">{item.name}</div>
          <div className='is-flex  has-text-centered ml-6 mb-2'>
          <i class="fa fa-star-o mx-1 has-text-warning" aria-hidden="true"></i>
          <i class="fa fa-star-o mx-1 " aria-hidden="true"></i>
          <i class="fa fa-star-o mx-1" aria-hidden="true"></i>
          <i class="fa fa-star-o mx-1" aria-hidden="true"></i>
          <i class="fa fa-star-o mx-1" aria-hidden="true"></i>
          </div>
          <div className='smart-view-comments-date-text'>{item.status}</div>
          <div className='smart-view-comments-date-text'>{item.date}</div>
        </div>
      </div>
      )}
      
    </div>
    </>
  )
}

export default ViewComentsCard
