import { Link, useLocation, useNavigate } from 'react-router-dom';

const SmartHeader = ({title,sub_title}) => {
    const navigate = useNavigate();
    const baseNav = "";
  
    const navigateLink = (index) => {
      navigate(baseNav + "/" + index);
    };
    const location = useLocation();
    const hideButtonPaths = ["/faq","/trems-and-conditions","/privacy-and-data-policy","/about-us-new"];
    const shouldHideButton = hideButtonPaths.includes(location.pathname);
  return (
    <>
     <nav className="breadcrumb  " aria-label="breadcrumbs">
          <ul>
            <li>
            <div className='is-flex'>
                <div className='breadcrumb-home is-flex'>
             
                  <i className="fa fa-home mt-1 mr-1" aria-hidden="true" onClick={() => navigateLink("dashboard")}></i>
           
               {!shouldHideButton &&<span
                  onClick={() => navigateLink("dashboard")}
                >
                  Home
                </span>}
                {shouldHideButton && 
                <Link className="smart-customer-text" to="https://cropglobalservices.com/">Home</Link>
              }
              
                <i class="fa fa-angle-right mt-1 mx-1" aria-hidden="true"></i>
                </div>
                <span className="smart-customer-text">
             {title} 
                </span>
               {title&& <i class="fa fa-angle-right mt-1 mx-1" aria-hidden="true"></i>} 
                <span className="smart-customer-text">
             {sub_title} 
                </span>
              </div>
            </li>
           
          </ul>
        </nav>
    </>
  )
}

export default SmartHeader