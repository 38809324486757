import React, { useEffect } from "react";
import "./AboutUsNew.css";
// import SmartHeader from "../../../components/site/SmartHeader";
import { Link } from "react-router-dom";

import {
  ABOUT_US_TO_IMAGE,
  ABOUT_US_GIFT_IMAGE,
  ABOUT_US_LOCK,
} from "../../../services/ImageService";
import SmartHeader from "../../../components/site/FormElements/SmartHeader";
const AboutUsNew = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendMail = (e, index) => {
    let mail_to_send = "admin.cropglobalservices.com";
    if (index == "ge" || index == "be") {
      mail_to_send = "info@cropglobalservices.com";
    }
    const mailto = "mailto:" + mail_to_send;
    window.location.href = mailto;
    e.preventDefault();
  };


  return (
    <>
      <div className="AboutUsNew">
        <div className="container-AboutUsNew">
          <div className="columns">
            <div className="column">
              <div className="smart-bussiness-header ml-5">
                <SmartHeader sub_title={"About Us"} />
              </div>
            </div>
          </div>

          <div className="columns top-section ">
            <div className="column is-6 mediya-screen-Aboutus-text">
              <span className="About-us-header-both ">
                <div className="About-us-header baumans-font-family">
                  About Us
                </div>
                <div className="About-us-header-content">
                  CROP Services Pty Ltd is an independent loyalty and rewards
                  management company with an aim and objective of consolidating
                  ‘Customer Loyalty Programs’ across businesses and trades.
                  Headquartered in Melbourne, Australia the company aspires to
                  extend a unified loyalty program to customers and trades
                  alike.
                </div>
              </span>
            </div>
            <div className="column is-6  has-text-centered ">
              <span className="">
                <img
                  src={ABOUT_US_TO_IMAGE}
                  className="mediya-screen-Aboutus-top-image"
                  alt=""
                  style={{ width: "50%" }}
                />
              </span>
            </div>
          </div>

          <div className="columns unify-mediya-screen-Aboutus">
            <div className="column">
              <div className="header-text-AboutUsNew baumans-font-family">
                Unify to Simplify
              </div>
              <div className="sub-header-text-AboutUsNew alex-font-family">
                Loyalty Without Borders
              </div>
              <div className="content-one-AboutUsNew">
                Customer loyalty in its current state and form is diversified
                and territorial in nature. Businesses currently offer loyalty
                benefits confined either to their sector or their associated
                affiliations and partnerships. This current positioning is
                age-old and well poised for market disruption. We intend to
                diminish the sectoral boundaries of customer loyalty through a
                ‘One Community One Approach’ model that is not only trade or
                sector agnostic but also extends progressive rewards for long
                term associations. Our offerings are simple, user-friendly and
                easily accessible through our website or mobile app.
              </div>
            </div>
          </div>

          <div className="columns innovation-mediya-screen">
            <div className="column is-6 is-flex is-align-items-center has-text-centered">
              <span className="image-gift ">
                <img
                  src={ABOUT_US_GIFT_IMAGE}
                  alt=""
                  style={{ width: "50%" }}
                />
              </span>
            </div>
            <div className="column is-6 has-text-centered is-flex is-align-items-center">
              <span className="content-rght">
                <div className="header-text-AboutUsNew baumans-font-family">
                  Passion for Innovation
                </div>
                <div className="sub-header-text-AboutUsNew alex-font-family">
                  Seamless Experience and Rewards
                </div>
                <div className="content-one-AboutUsNew">
                  Our team comprises of a bunch of enthusiastic individuals that
                  are passionate about innovation and customer experience. We're
                  constantly thinking of new ways to deliver an experience that
                  is unique, delightful and memorable. Its perhaps this
                  inclination for innovation that curated the need for Community
                  Rewards Operating Program ‘CROP’ – A program that empowers you
                  and rewards your daily spends. In our quest to unify loyalty,
                  we will continue to develop the program from strength to
                  strength and while we do this, be rest assured that our
                  progress, in no way, affects the CROPs accrued by you and the
                  balance in your account.
                </div>
              </span>
            </div>
          </div>

          <div className="columns Treasure-mediya-screen">
            <div className="column is-6 has-text-centered is-flex is-align-items-center">
              <span className="content-rght">
                <div className="header-text-AboutUsNew baumans-font-family">
                  Your Trust, Our Treasure
                </div>
                <div className="sub-header-text-AboutUsNew alex-font-family">
                  Privacy That Puts You First
                </div>
                <div className="content-one-AboutUsNew">
                  The only other thing that we are passionate about is your
                  privacy. So, if you have shared your contact information, your
                  likes and dislikes, your preferences and interests or any
                  other personal information that helps us customise
                  personalised offers for you – then be rest assured that your
                  data is safe and stays only with us. You can refer to our 
                 <Link to={"/privacy-data-rights-new"}>  <u className="has-text-link"> Privacy Policy</u> </Link> for a deeper understanding of our data
                  confidentiality practices as also Email us on 
                 <u className="has-text-link pointer" onClick={(event) => sendMail(event)}> privacy@cropglobalservices.com</u> for any specific query.
                </div>
              </span>
            </div>
            <div className="column is-6 is-flex is-align-items-center has-text-centered  ">
              <span className="image-gift ">
                <img
                  src={ABOUT_US_LOCK}
                  className=""
                  alt=""
                  style={{ width: "50%" }}
                />
              </span>
            </div>
          </div>

          <div className="register-content">
            Whether your are a customer or a business owner you can{" "}
            <span className="register-border-botton-line"> register </span> for
            our
            <br /> membership. To understand our offers or avail full benefits
            of our program <Link to={"/sign-up"}> <u className="has-text-link"> register</u> </Link>
            <br /> as a member today!!
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsNew;
