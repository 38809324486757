
import { useNavigate } from 'react-router-dom';
import './HomeView.css';
const ManageService =(open,openNav)=>{
  //  const [sidebarOpen,setSidebarOpen] = useState(open);
   const navigate = useNavigate();
  //  const location = useLocation();
   const handleNavigation = (link) => {
       navigate(link);
   };
  //  const handleToggle = () => {
  //      setSidebarOpen(prevState => !prevState);
  //      openNav()
  //  };

  //  useEffect(() => {
  //     setSidebarOpen(open)
  // }, [open]);


     
  const ManageServicesItems=[
   {
     id: 1,
     label: "My Offers",
     icon: <i class="fa fa-trash" aria-hidden="true"></i>,
     link:"/manage-services/my-offer-list"
   },
   {
     id: 2,
     label: "Create Offer",
     icon: <i class="fa fa-pencil-square-o" aria-hidden="true"></i>,
     link:"/manage-services/create-offers-form/0"
   },
   {
     id: 3,
     label: "My Bids",
     icon: <i class="fa fa-shopping-basket" aria-hidden="true"></i>,
     link:"/manage-services/market-offers-list"
   },
   {
     id: 4,
     label: "Service Settings",
     icon: <i class="fa fa-cog" aria-hidden="true"></i>,
     link:"/manage-services/service-settings-form"
   },
 ]





   
return(
  <>
     <div className=' home-sub-container is-fullhd'>
            <div className='sub-title   ml-6'>
            Manage Services
            </div>
            <div key={"ManageService-columns-one"} className='columns ml-5'>

               {ManageServicesItems.map((item,key)=>(
                      <div key={key} className='column manage-service-button is-2 '>
                        <div onClick={() => handleNavigation(item?.link)} >
                           <div className='business-home-view-button'>
                        <div className='is-size-3 primary-text'>{item.icon}</div>
                        <p className='smart-button-list'>{item.label}</p>
                        </div>
                        </div>
                      </div>


               ) )};

              
                
            </div>
    
        </div>
  </>
)
}

// const ManageProgram =(open,openNav)=>{
//    const [sidebarOpen,setSidebarOpen] = useState(open);
//    const navigate = useNavigate();
//   //  const location = useLocation();
//    const handleNavigation = (link) => {
//        navigate(link);
//    };
//   //  const handleToggle = () => {
//   //      setSidebarOpen(prevState => !prevState);
//   //      openNav()
//   //  };

//    useEffect(() => {
//       setSidebarOpen(open)
//   }, [open]);

//    const ManageProgramItems=[
//       {
//         id: 1,
//         label: "Offer CROPs",
//         icon: <i class="fa fa-gift" aria-hidden="true"></i>,
//         link:"/manage-program/offer-crops-form"
//       },
//       {
//         id: 2,
//         label: "Extend Bonus CROPs",
//         icon: <i class="fa fa-american-sign-language-interpreting" aria-hidden="true"></i>,
//         link:"/manage-program/extend-bonus-form"
//       },
//       {
//         id: 3,
//         label: "Slash Redemption CROPs",
//         icon: <i class="fa fa-gift" aria-hidden="true"></i>,
//         link:"/manage-program/slash-redemption-form"
//       },
//       {
//         id: 4,
//         label: "Happy Hours",
//         icon: <i class="fa fa-clock-o" aria-hidden="true"></i>,
//         link:"/manage-program/happy-hours-form"
//       },
//       {
//         id: 5,
//         label: "Other Services",
//         icon: <i class="fa fa-cogs" aria-hidden="true"></i>,
//         link:"/manage-program/other-services-form"
//       },
//     ]
//   return(
//     <>
//        <div className=' home-sub-container is-fullhd'>
//           <div className='sub-title  ml-6'>
//           MANAGE PROGRAM
//           </div>
//           <div key={"ManageService-columns-two"} className='columns ml-5'>

//       {ManageProgramItems.map((item,key)=>(
//        <div key={key} className='column manage-service-button is-2 '>
//          <div onClick={() => handleNavigation(item?.link)} >
//             <div className='business-home-view-button'>
//          <div className='is-size-3 primary-text'>{item.icon}</div>
//          <p className='smart-button-list'>{item.label}</p>
//          </div>
//          </div>
//        </div>


// ) )};


 
// </div>
  
//       </div>
//     </>
//   )
//   }
  const ManageAccount =(open,openNav)=>{
  //  const [sidebarOpen,setSidebarOpen] = useState(open);
   const navigate = useNavigate();
  //  const location = useLocation();
   const handleNavigation = (link) => {
       navigate(link);
   };
  //  const handleToggle = () => {
  //      setSidebarOpen(prevState => !prevState);
  //      openNav()
  //  };

  //  useEffect(() => {
  //     setSidebarOpen(open)
  // }, [open]);


   
 const MyAccountItems=[
   {
     id: 1,
     label: "My CROPs Activity",
     icon: <i class="fa fa-trash" aria-hidden="true"></i>,
     link:"/my-account/my-crops-list"
   },
   {
     id: 2,
     label: "Statement",
     icon: <i class="fa fa-pencil-square-o" aria-hidden="true"></i>,
     link:"/my-account/statement-form"
   },
   {
     id: 3,
     label: "My CROP Card",
     icon: <i class="fa fa-folder" aria-hidden="true"></i>,
     link:"/my-account/my-card-form-view"
   },
  
 ]

    return(
      <>
         <div className=' home-sub-container is-fullhd'>
            <div className='sub-title   ml-6'>
            MY ACCOUNT
            </div>
            <div key={"ManageService-columns-three"} className='columns ml-5'>

               {MyAccountItems.map((item,key)=>(
                      <div key={key} className='column manage-service-button is-2 '>
                        <div onClick={() => handleNavigation(item?.link)} >
                           <div className='business-home-view-button'>
                        <div className='is-size-3 primary-text'>{item.icon}</div>
                        <p className='smart-button-list'>{item.label}</p>
                        </div>
                        </div>
                      </div>


               ) )};

              
                
            </div>
    
        </div>
      </>
    )
    }
    const Settings =(open,openNav)=>{
      // const [sidebarOpen, setSidebarOpen] = useState(open);
      const navigate = useNavigate();
      // const location = useLocation();
      const handleNavigation = (link) => {
          navigate(link);
      };
      // const handleToggle = () => {
      //     setSidebarOpen(prevState => !prevState);
      //     openNav()
      // };
   
    //   useEffect(() => {
    //      setSidebarOpen(open)
    //  }, [open]);
   
      const SettingsItems=[
         {
           id: 1,
           label: "Password Change",
           icon: <i class="fa fa-key" aria-hidden="true"></i>,
           link:"/settings/pin-change-form"
         },
         {
           id: 2,
           label: "Communication Preference",
           icon: <i class="fa fa-pencil-square-o" aria-hidden="true"></i>,
           link:"/settings/communication-pro-form"
         },
        
       ]
      return(
        <>
           <div className=' home-sub-container is-fullhd'>
              <div className='sub-title   ml-6'>
              SETTINGS
              </div>
              <div key={"ManageService-columns-four"} className='columns ml-5'>

               {SettingsItems.map((item,key)=>(
                      <div key={key} className='column manage-service-button is-2 '>
                        <div onClick={() => handleNavigation(item?.link)} >
                           <div className='business-home-view-button'>
                        <div className='is-size-3 primary-text'>{item.icon}</div>
                        <p className='smart-button-list'>{item.label}</p>
                        </div>
                        </div>
                      </div>


               ) )};

              
                
            </div>
          </div>
        </>
      )
      }

const HomeView = () => {
  return (
    <>
   <p className="smart-bussiness-header pb-3 pl-3 pt-4">
           <span className="icon mt-1">
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span>
          <span className='ml-5'> Home</span>
         
        </p>
{/* 
          <div className=' smart-bussiness-header '>
              <div className='card-header-icon'><i class="fa fa-caret-left is-size-4" aria-hidden="true"></i></div>
              <div className='card-label mt-2 ml-6'>Home</div>
              </div> */}
   <div className='main-container '>
      <ManageService />
      {/* <ManageProgram /> */}
      <ManageAccount />
      <Settings />
  

   </div>
    
    </>
  )
}

export default HomeView