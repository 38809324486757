import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import { DASHBOARD_URLS } from "../../../api/Business/Dashboard";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showNotification } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";

import { SmartSoftCheckRadioSwitch } from "soft_digi";

const ChartComponent = () => {
  const [tabData, setTabData] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [category, setCategory] = useState("2");
  const { setLoading } = useSiteContext();
  const goToNextYear = () => {
    setCurrentYear((prevYear) => prevYear + 1);
  };

  // Function to update current year to previous year
  const goToPrevYear = () => {
    setCurrentYear((prevYear) => prevYear - 1);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      // showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    //   let data_in = currentYear;
    const subscription = post(
      DASHBOARD_URLS.GET_CHART_COUNTS,
      { categeory: category },
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.log("chart data",response.data)
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [category]);

  const data = {
    labels: tabData?.labels,
    datasets: [
      {
        label: "Offers - Sold",
        data: tabData?.earn,
        backgroundColor: "#156082",
        borderColor: "#156082",
        borderWidth: 1,
      },
      {
        label: "Offers - Redeemed",
        data: tabData?.redeem,
        backgroundColor: "#E97132",
        borderColor: "#E97132",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        // text:'Bar chart with Data and Options'
      },
      filler: {
        propagate: true,
      },
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
          },
        },
      },
    },
  };
  const week = [{ value: "1" }];
  return (
    <div>
      <div className="business-chart p-4">
        <div className="is-flex is-justify-content-space-between">
          <h2 className="has-text-weight-bold mt-2">Performance Trend</h2>

          <div className="is-flex">
            <span className="mr-3 has-text-weight-bold mt-2">Weekly</span>
            <span className="mt-0 pt-0">
              <SmartSoftCheckRadioSwitch
                options={week}
                switchMode={true}
                isRight={true}
                inputProps={{ isRounded: true }}
                name="switchone"
                value={category}
                onChange={(value) => setCategory(value)}
              />
            </span>
            <span className="ml-4 has-text-weight-bold mt-2">Monthly</span>
          </div>

          {/* <div className="is-size-6 has-text-wight-bold mt-2">
            <span className="pointer" onClick={goToPrevYear}>
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </span>

            <span className="has-text-weight-bold"> {currentYear}</span>
            <span className="ml-2 pointer" onClick={goToNextYear}>
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </span>
          </div> */}
        </div>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default ChartComponent;
