import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
// import { BUSINESS_TRANSACTION } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import CustomerTrancationProduct from "./CustomerTrancationProduct";
import CustomerTranscation from "./CustomerTranscation";
import "./CustomerTransactions.css";
import { ORDER_URLS } from "../../../../api/OrderUrls";
import {
  getSessionStorage,
  setSessionStorage,
} from "../../../../services/sessionService";
import { USER_URLS } from "../../../../api/Admin/AurhUrls";
import { ALLOW_FLOAT } from "../../../../services/PatternSerivce";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";

const CustomerTransitionScanTable = () => {
  const navigate = useNavigate();
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [products, setProducts] = useState([]);
  const [item, setItem] = useState({});
  const [formData, setFormData] = useState({});
  const [udata, setUData] = useState({});
  const { setLoading } = useSiteContext();

  const location = useLocation();

  const getStoreId = () => {
    const loc = location.state || {};
    if (loc.id == undefined) {
      // get it from session storeage
      return getSessionStorage("transaction_id");
    } else {
      setSessionStorage("transaction_id", loc.id);
      return loc.id;
    }
  };

  const handleInputChange = (name, value) => {
    if (name == "crop_type") {
      setProducts([]);
      if (value && value.length > 1) {
        value = [value[1]];
      }
      if (value && value[0] == "2") {
        if (!formData.crop_sub_type) {
          setFormData((prev) => ({
            ...prev,
            ["crop_sub_type"]: { value: "REDEEM", label: "Redemption" },
          }));
        }
      }
    }
    if (name === "crop_sub_type") {
      setProducts([]);
    }
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const loadUserData = (e) => {
    setLoading(true, "Loading.... Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading.... Please Wait");
    let url = USER_URLS.USER_GET_ONE_CUSTOMER;
    const id = getStoreId();
    let post_data = {
      id: id,
    };
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setLoading(false);
        console.log(response);
        setUData(response.data);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const getKey = () => {
    let key_value =
      formData.crop_type && formData.crop_type[0] ? formData.crop_type[0] : "0";
    let output = "";
    if (key_value == "1") {
      output = "EARN";
    } else if (key_value == "2") {
      output =
        formData.crop_sub_type && formData.crop_sub_type.value
          ? formData.crop_sub_type.value
          : "";
    }
    return output;
  };

  useEffect(() => {
    loadUserData();
  }, []);

  const handleBack = () => {
    navigate("/manage-program/customer-transitions-scan");
  };

  const CRIDIT_options = [
    { value: "1", label: "Credit CROPs" },
    { value: "2", label: "Debit CROPs" },
  ];

  const redeem_options = [
    { value: "RETURN", label: "Return Order" },
    { value: "REDEEM", label: "Redemption" },
  ];

  const get_total_crops = () => {
    const totalPrice = products
      ? products.reduce(
          (acc, item) => acc + item.quantity * item["order_crops"],
          0
        )
      : 0;
    return totalPrice;
  };

  const get_total_price = () => {
    const totalPrice = products
      ? products.reduce(
          (acc, item) => acc + item.quantity * item.total_price,
          0
        )
      : 0;
    return totalPrice;
  };

  const get_total_gst_cost = () => {
    console.log("products ", products);
    const totalPrice = products
      ? products.reduce(
          (acc, item) =>
            acc + (item.quantity * item.total_price * item.gst_value) / 100,
          0
        )
      : 0;
    // let total = Number(totalPrice).toLocaleString(undefined, {
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2,
    // });
    return totalPrice;
  };

  const get_total_cost = () => {
    let gst_cost = get_total_gst_cost();
    let total_price = get_total_price();
    return parseFloat(gst_cost) + parseFloat(total_price);
  };

  const transactionTable = () => {
    return (
      <div className="">
        <CustomerTranscation
          products={products}
          setProducts={setProducts}
          type={getKey()}
          returnItem={item}
        />
      </div>
    );
  };

  const handleSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, checkformElements)) {
      return false;
    }
    let type = getKey();
    let data_in = {
      order_type: type,
      order_data: [...products],
      invoice_number: formData.invoice_number,
      customer_id: udata.ID,
      order_id: item && item.ID ? item.ID : 0,
      refund_percentage: formData.refund_percentage,
    };
    setLoading(true, "Posting.... Please wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let url =
      getKey() == "RETURN"
        ? ORDER_URLS.RETURN_DIRECT
        : ORDER_URLS.INSERT_DIRECT;
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);
        //console.log("response ", response);
        showAlertAutoClose("Transaction posted successfully", "success");
        setProducts([]);
        setFormData((prev) => ({ ...prev, ["crop_type"]: "" }));
        setFormData((prev) => ({ ...prev, ["invoice_number"]: "" }));
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const rounded_value = (input) => {
    let roundedTotal = Math.round(input * 100) / 100;
    return roundedTotal;
  };

  const rightSideFunction = () => {
    return (
      <div>
        <table className="table is-fullwidth crop-business-transaction-text">
          <thead>
            <tr>
              <th>GST (A$)</th> <td>{rounded_value(get_total_gst_cost())}</td>
            </tr>
            <tr>
              <th>Total Amount (A$)</th>{" "}
              <td>{rounded_value(get_total_cost())}</td>
            </tr>
          </thead>
        </table>
      </div>
    );
  };

  const FormValidations = {
    invoice_no: [SmartValid.required("Invoice No is Required")],
    title: [SmartValid.required("Refund is Required")],
  };

  const checkformElements = [
    {
      type: "CHECK_RADIO",
      name: "crop_type",
      width: "12",
      element: {
        options: CRIDIT_options,
        isRight: false,
        isMulti: true,
        classList: [
          "has-text-weight-bold is-flex is-justify-content-space-around",
        ],
      },
    },
    {
      type: "SELECT_BOX",
      name: "crop_sub_type",
      width: "6",
      element: {
        label: "Reason:",
        isHorizontal: true,
        options: redeem_options,
        // classList: ["has-text-weight-bold"],
      },
      hideFunction: (value) => {
        let credit_crop =
          formData.crop_type && formData.crop_type[0]
            ? formData.crop_type[0]
            : 0;
        return parseInt(credit_crop) == 2 ? false : true;
      },
    },
    {
      type: "LABEL",
      width: "6",
      labelFunction: () => {
        return (
          <div>
            {" "}
            Invoice Number : {item.invoice_number ? item.invoice_number : ""}
          </div>
        );
      },
      hideFunction: (value) => {
        let type = getKey();
        return type == "RETURN" && products && products.length > 0
          ? false
          : true;
      },
    },
    {
      type: "LABEL",
      width: "12",
      labelFunction: transactionTable,
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "invoice_number",
      element: {
        label: "Invoice No",
        //  inputType: "BORDER_LABEL",
        isRequired: true,
        isHorizontal: true,
        pattern: "[A-Za-z0-9 ]*",
        validations: FormValidations.invoice_no,
        max: 10,
      },
      hideFunction: (value) => {
        let type = getKey();
        return (type == "EARN" || type == "REDEEM") &&
          products &&
          products.length > 0
          ? false
          : true;
      },
    },

    {
      type: "LABEL",
      width: "6",
      labelFunction: rightSideFunction,
      hideFunction: (value) => {
        let type = getKey();
        return type == "EARN" && products && products.length > 0 ? false : true;
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "refund_percentage",
      element: {
        label: "Refund (%)",
        // isHorizontal: true,
        validations: FormValidations.title,
        inputType: "BORDER_LABEL_FOCUS",
        isRequired: true,
        pattern: ALLOW_FLOAT,
        max: 3,
      },
      hideFunction: (value) => {
        let type = getKey();
        return type == "RETURN" && products && products.length > 0
          ? false
          : true;
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "refund",
      element: {
        inputType: "BORDER_LABEL",
        label: item && item.order_type === "REDEEM" ? "" : "Refund Amount (A$)",
        isHorizontal: true,
        inputProps: { disabled: true },
        valueFunction: (data) => {
          let refund_percentage =
            formData && formData.refund_percentage
              ? parseFloat(formData.refund_percentage)
              : 0;
          let totalprice =
            item && item.order_type === "REDEEM"
              ? get_total_crops()
              : get_total_price();
          return rounded_value(totalprice * (refund_percentage / 100));
        },
      },
      hideFunction: (value) => {
        let type = getKey();
        return type == "RETURN" && products && products.length > 0
          ? false
          : true;
      },
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Submit",
        classList: ["customer-login-action-button smart-crop-action-button"],
        onClick: () => handleSubmit(),
      },
      hideFunction: (value) => {
        return products && products.length > 0 ? false : true;
      },
    },
  ];

  return (
    <>
      <div>
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          {/* <span className="icon mt-1" onClick={handleBack}>
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
          {/* <span className="ml-5">Customer Transactions</span> */}
          <div className="pl-1">
            <SmartHeader
              title={"Manage Program"}
              sub_title={"Customer Transactions"}
            />
          </div>
        </p>
      </div>

      <div className="smart-soft-customer-transcastion">
        <div className="columns">
          <div className="column is-6 customer-transaction-left-content poppins-font-family">
            <div className="column is-12">
              <div className="smart-customer-id">
                <div className="columns is-multiline">
                  <div className="column is-4 has-text-weight-bold">
                    Customer Name:
                  </div>
                  <div className="column is-8 smart-customer-transaction-boder">
                    {udata?.first_name}
                  </div>
                  <div className="column is-4 has-text-weight-bold">
                    CROP ID:
                  </div>
                  <div className="column is-8 smart-customer-transaction-boder">
                    {udata?.crop_id}
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-12 mt-4">
              <div className="">
                <SmartSoftForm
                  formData={formData}
                  setFormData={handleInputChange}
                  elements={checkformElements}
                  formSubmit={formSubmit}
                  handleErrorChange={handleErrorChange}
                />
              </div>
            </div>
          </div>
          <div className="column is-6 dmsans-font-family">
            {formData.crop_type && formData.crop_type[0] && (
              <CustomerTrancationProduct
                key={getKey()}
                type={getKey()}
                products={products}
                setProducts={setProducts}
                subtype={formData.crop_sub_type}
                customer_id={udata.ID}
                setItem={setItem}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerTransitionScanTable;
