// LoginLayout.js
import { useState } from 'react';
import { useSiteContext } from '../../contexts/SiteProvider';
import { CROP_LOGO_LIGHT } from "../../services/ImageService";
import BusinessHeader from './BusinessHeader';
import './BusinessLayoutCss.css';
import BusinessSideNav from './BusinessSideNav';
import { isVisible } from '@testing-library/user-event/dist/utils';

const BusineesLayout = ({ children }) => {
  const { user, setUser, logout, setLoading } = useSiteContext();
  const [page, setPage] = useState("home");
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    logout();
  };

  const handleNavigation = (page) => {
    setPage(page);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle the state
  };

  return (
    <div className="container is-fluid smart-crop-container">
      <div className='smart-crop-sidenav'>
      <button className="close-sidebar-btn" onClick={toggleSidebar}>
            <i className="fa fa-times"></i>
          </button>
        <div className="is-flex is-justify-content-center mt-5">
          <img className="crops-logo" src={CROP_LOGO_LIGHT} alt='crop logo light' />
        </div>
        <BusinessSideNav page={page} />
      </div>
      <div className={`sidebar ${isOpen ? 'smart-crop-middle-open' : 'smart-crop-middle'}`}>
        <div className={`sidebar ${isOpen ? 'smart-crop-header-open' : 'smart-crop-header'}`}>
          <BusinessHeader user={user} toggleSidebar={toggleSidebar} setPage={handleNavigation} handleLogout={handleLogout} setLoading={setLoading} />
        </div>
        <div className='smart-crop-business-container'>
          <div className='smart-crop-content'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusineesLayout;
