import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import SmartHeader from "../../../components/site/FormElements/SmartHeader";

const RatedOffersTable = () => {

  const navigate = useNavigate();
  const baseNav = "/business/promos";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  const buttons = [
    {
      label: "View Comments",
      leftIcon: "",
      type: "",
      classList: ["button is-info is-inverted"],
      onClick: (data) => {
        // console.log("data", data);
        navigateLink("view-comments-card");
      },
    },
  ];

  const columns = [
    { title: "S.No", index: "sno", isSortable: true, type: "sno" },
    { title: "Image", index: "image", isSortable: true },
    { title: "Offer Title", index: "offertitle", isSortable: true },
    { title: "Star Rating", index: "starrating", isSortable: true },
    { title: "Likes", index: "likes", isSortable: true },
    { title: "Count", index: "count", isSortable: true },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      isSortable: true,
    },
  ];

  const data = [
    {
      sno: "1",
      image: "",
      offertitle: "Mobiles and Watch with Toy",
      starrating: "4",
      likes: "4",
      count: "",
      action: "View Comments",
    },
  ];
  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
        {/* <span className="ml-5">Rated Offers</span> */}
        <div className="pl-1">
            <SmartHeader title={"More"} sub_title={"Rated Offers"} />
          </div>
      </p>
      <div>
        <SmartSoftTable
          columns={columns}
          data={data}
          tableProps={{ className: "crop-bussiness-table-layout" }}
        />
      </div>
    </>
  );
};

export default RatedOffersTable;
