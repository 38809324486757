import { useEffect, useState } from "react";
import { SmartSoftButton, SmartSoftForm } from "soft_digi";
import {
  SmartValid,
  ValidateFormNew,
} from "soft_digi/dist/services/smartValidationService";
import { BUSINESS_SETTINGS } from "../../../../api/Business/OffersUrls";
import SmartFileDisplay from "../../../../components/site/FormElements/SmartFileDisplay";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { ALLOW_NUMERIC } from "../../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";

const ReturnPolicy = ({ dataIn, loadSettingsData }) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, refreshUser } = useSiteContext();

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name == "user_return_policy") {
      handleInputChange("user_return_policy_days", "");
      handleInputChange("refer_policy_doc", "");
    }
  };

  useEffect(() => {
    // console.log("data in", dataIn);
    setFormData(dataIn || {});
  }, [dataIn]);

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const FormValidations = {
    return_policy: [SmartValid.required("Required")],
  };

  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, policyElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Updating...Please Wait");
    let url = BUSINESS_SETTINGS.INSERT;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose("Settings Saved Successfully", "success");
        setLoading(false);
        refreshUser();
        loadSettingsData();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    // console.log("form errors ", formErrors);
  };

  const policyoptions = [
    { value: "1", label: "YES" },
    { value: "2", label: "NO" },
  ];

  const filePreviewFunctionDisplay = (inputValue) => {
    // console.log("teting " , inputValue);
    return (
      <SmartFileDisplay
        files={formData?.refer_policy_doc}
        updateImages={(images) => handleInputChange("refer_policy_doc", images)}
      />
    );
  };

  const policyElements = [
    {
      type: "CHECK_RADIO",
      width: "4",
      name: "user_return_policy",
      isHorizontal: true,
      element: {
        label: "Return Policy Applicable",
        options: policyoptions,
        isMulti: true,
        type: "radio",
        validations: FormValidations.return_policy,
        isHorizontal: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: "3",
      name: "user_return_policy_days",
      element: {
        //placeHolder: "Numberof day",
        label: "Permitted Time (In Days)",
        inputType: "BORDER_LABEL",
        validations: FormValidations.return_policy,
        isRequired: true,
        pattern: ALLOW_NUMERIC,
      },
      hideFunction: (value) => {
        //console.log("fomrdata " , formData);
        let user_return_policy = formData?.user_return_policy;
        // console.log("delivery ", delivery);
        return parseInt(user_return_policy) === 1 ? false : true;
      },
    },

    {
      type: "FILE",
      width: "5",
      name: "refer_policy_doc",
      element: {
        //placeHolder: "Numberof day",
        placeHolder: (
          <p>
            <i className="fa fa-cloud-upload mr-1"></i>Upload Return Policy
            Document<span className="has-text-danger">*</span>
          </p>
        ),
        // placeHolder: "",
        fileNameEnable: false,
        // leftIcon: "fa fa-cloud-upload",
        isMulti: false,
        isRequired: true,
        accept: "application/pdf",
        classList: ["smart-business-image-file"],
        filePreview: true,
        filePreviewFunction: filePreviewFunctionDisplay,
        validations: FormValidations.return_policy,
      },
      hideFunction: (value) => {
        let user_return_policy = formData?.user_return_policy;
        return parseInt(user_return_policy) === 1 ? false : true;
      },
    },
  ];

  return (
    <>
      <div className="smart-crop-business-forms ">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={policyElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />

        <div className="is-justify-content-end  is-flex">
          <SmartSoftButton
            label="Save"
            // leftIcon="fa fa-download"
            classList={["smart-crop-action-button"]}
            onClick={handleFormSubmit}
          />
        </div>

        {/* <div>
          <p className="has-text-link is-size-6 mr-4 ml-5">
            Return Policy Applicable
          </p>
        </div>
        <div className="is-flex mr-3 ml-5 mt-3 mb-2 ">
         
        </div> */}
        {/* <div className=" ml-5">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={numberelement}
        formSubmit={formSubmit}
        handleErrorChange={handleErrorChange}
      />
    </div> */}
      </div>
      {/* <div className="card-content smart-business-image-file is-flex is-justify-content-space-between">
        <div className="">
          <SmartSofFile
            placeHolder="Uploading file"
            value={formData?.refer_policy_doc}
            onChange={(value) => handleInputChange("refer_policy_doc", value)}
            errorEnable={formSubmit}
            fileNameEnable={false}
            leftIcon="fa fa-cloud-upload"
            isMulti={true}
          />

          <div className="card-image m-4 pt-2" key="one-6">
            <SmartImageDisplay
              srcType="DATA"
              data={formData?.refer_policy_doc || []}
              isMulti={true}
              imageClass="is-12"
              updateImages={(images) =>
                handleInputChange("refer_policy_doc", images)
              }
            />
          </div>
        </div>
        <div className="">
          <SmartSoftButton
            label="Save"
            leftIcon="fa fa-download"
            classList={["smart-crop-action-button"]}
            onClick={handleFormSubmit}
          />
        </div>
      </div> */}
      {/* <div className="is-justify-content-end  is-flex">
    <SmartSoftButton
      label="Save"
      leftIcon="fa fa-download"
      classList={["smart-crop-action-button"]}
      onClick={handleFormSubmit}
    />
  </div> */}
    </>
  );
};

export default ReturnPolicy;
