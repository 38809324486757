
import React from 'react'
import './ServiceSettingsToolTip.css';
const WorkingDaysHolidaysToolTip = ({ text }) => {
  return (
    <div className="tooltip">
      <div className="tooltip-content">{text}</div>
    </div>
  );
}

export default WorkingDaysHolidaysToolTip