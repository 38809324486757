import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { FOLDER_ICON } from "../../../services/ImageService";

const MyPromosTable = () => {
  const navigate = useNavigate();
  const basenav = "/business/promos";

  const navigateLink = (index) => {
    navigate(basenav + "/" + index);
  };
  const nameImage = () => {
    return (
      <>
        <div className="is-flex">
          <figure className=" image is-32x32 mr-2">
            <img src={FOLDER_ICON} alt="icon" />
          </figure>
        </div>
      </>
    );
  };

  const columns = [
    { title: "S.No", index: "sno", isSortable: true, type: "sno" },
    { title: "Image", index: "image", isSortable: true, valueFunction: nameImage },
    { title: "Offer Title", index: "offertitle", isSortable: true },
    { title: "Star Rating", index: "starrating", isSortable: true },
    { title: "Likes", index: "likes", isSortable: true },
    {
      title: "From Date",
      index: "fromdate",
      type: "date",
      dateFormat: "DD-MM-YYYY",
      isSortable: true,
    },
    {
      title: "To Date",
      index: "todate",
      isSortable: true,
      type: "date",
      dateFormat: "DD-MM-YYYY",
    },
    {
      title: "Status",
      index: "status",
      isSortable: true,
    },
  ];

  const data = [
    {
      sno: "1",
      image: "",
      offertitle: "Prd1",
      starrating: "0",
      likes: "0",
      fromdate: "2022-10-17",
      todate: "2022-10-18",
      status: "Expired",
    },
    {
      sno: "2",
      image: "",
      offertitle: "Newones",
      starrating: "0",
      likes: "0",
      fromdate: "2022-10-18",
      todate: "2022-11-19",
      status: "Pending",
    },
    {
      sno: "3",
      image: "",
      offertitle: "Product",
      starrating: "0",
      likes: "0",
      fromdate: "2022-11-19",
      todate: "2022-12-20",
      status: "Pending",
    },
  ];

  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4 is-flex is-justify-content-space-between is-align-items-baseline">
        <div>
          <span className="icon mt-1">
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span>
          <span className="ml-5">My Promos</span>
        </div>
        <button
          className="button has-text-weight-medium is-rounded smart-crop-action-button mr-5"
          onClick={() => navigateLink("create-promos-offer-form")}
        >
          Create New Promos
        </button>
      </p>
      <div>
        <SmartSoftTable
          columns={columns}
          data={data}
          tableProps={{ className: "crop-bussiness-table-layout" }}
        />
      </div>
    </>
  );
};

export default MyPromosTable;
