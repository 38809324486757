const ADMIN_OPERATIONS_LOYALTY = {
     GET_ALL:"admin/loyalty/get_all",
     GET_ONE:"admin/loyalty/get_one",
     INSERT:"admin/loyalty/insert",
     UPDATE:"admin/loyalty/update",
     DELETE:"admin/loyalty/delete_one",
     GET_ALL_SELECT:"admin/loyalty/get_all_select"
};

const ADMIN_OPERATIONS_INTERESTS = {
    GET_ALL:"admin/interest/get_all",
    GET_ONE:"admin/interest/get_one",
    INSERT:"admin/interest/insert",
    UPDATE:"admin/interest/update",
    DELETE:"admin/interest/delete_one",
    GET_ALL_SELECT:"admin/interest/get_all_select"
};

const ADMIN_OPERATIONS_HOLIDAYS = {
    GET_ALL:"admin/holidays/get_all",
    GET_ONE:"admin/holidays/get_one",
    INSERT:"admin/holidays/insert",
    UPDATE:"admin/holidays/update",
    DELETE:"admin/holidays/delete_one"
};

const ADMIN_OPERATIONS_SECTOR = {
    GET_ALL:"admin/sector/get_all",
    GET_ONE:"admin/sector/get_one",
    INSERT:"admin/sector/insert",
    UPDATE:"admin/sector/update",
    DELETE:"admin/sector/delete_one",
    GET_ALL_SELECT:"admin/sector/get_all_select",
    GET_IMAGE_PURCHASE:"admin/sector/get_image_purchase",
    GET_IMAGE_REDEEM:"admin/sector/get_image_redeem",
};

const ADMIN_SETTINGS= {
    GET_ALL:"/services/site/get_all",   
    INSERT:"/services/site/insert",   
};

const SERVICE_SETTINGS={
    INSERT_HOUR:"/business/operating_hours/insert_hour",
    INSERT_HOLIDAY:"/business/operating_hours/insert_holiday",
    GET_ALL_HOLIDAY:"/business/operating_hours/get_all_holidays",
    GET_ALL:"/business/operating_hours/get_all",
    GET_HOLIDAYS_DROP_DOWN:"/admin/holidays/get_all_select",
    DELETE_HOLIDAY:"/business/operating_hours/delete_one",

    INSERT_BLUEDAY:"/business/blue_days/insert_hour",
    INSERT_BLUE_HOLIDAY:"/business/blue_days/insert_holiday",
    GET_ALL_BLUE_HOLIDAY:"/business/blue_days/get_all_holidays",
    GET_ALL_BLUE:"/business/blue_days/get_all"
}

const BUSINESS_TRANSACTION={
    GET_ONE:"/user/get_one",
}



export {
    ADMIN_OPERATIONS_LOYALTY,
    ADMIN_OPERATIONS_INTERESTS,
    ADMIN_OPERATIONS_HOLIDAYS,
    ADMIN_OPERATIONS_SECTOR,
    ADMIN_SETTINGS,
    SERVICE_SETTINGS,
    BUSINESS_TRANSACTION
}
