import { useState } from "react";
import { SmartSoftForm, SmartValid } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { BUSINESS_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";

const FirstPassWord = ({ data_in, setLoading, closeModal }) => {
  const [formData, setFormData] = useState(data_in);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [verifyOtp, setverifyOtp] = useState(false);

  const handleInputChange = (name, value) => {
    //console.log(" name ", name, "   value ", value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }
      return updatedFormData;
    });
  };
  // const loadTableData = () => {
  //   const handleError = (errorMessage) => {
  //     showAlertAutoClose(errorMessage);
  //     setLoading(false);
  //   };
  //   const subscription = get(
  //     CUSTOMER_MY_ACCOUNT.SEND_OTP_MOBILE,
  //     handleError
  //   ).subscribe((response) => {
  //     console.log(response)
  //     setLoading(false);
  //   });
  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // };
  // useEffect(() => {
  //   loadTableData()
  // }, []);
  const handleFormEmailOtpSend = () => {
    const handleError = (errorMessage) => {
      // showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Sending OTP....Please Wait");

    let url = BUSINESS_LOGIN_URLS.SEND_OTP_FORGOT;
    let login_data = { ...formData };
    login_data["user_type"] = "BUSINESS";
    const subscription = post(url, login_data, handleError).subscribe(
      (response) => {
        handleInputChange("otp_id", response.data.otp_id);
        handleInputChange("uid", response.data.uid);
        setFormSubmit(false);
        showAlertAutoClose("OTP sent successfully", "success");
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };

  const handleVerify = () => {
    //setFormSubmit(true);
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElementsOne)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      handleInputChange("otp_value", "");
      setLoading(false);
    };
    setLoading(true, "Verifying the details....Please Wait");
    // console.log("fdata ", formData);
    let url = BUSINESS_LOGIN_URLS.RESET_PASSWORD_OTP;
    const subscription = post(
      url,
      { ...formData, user_type: "BUSINESS" },
      handleError
    ).subscribe((response) => {
      let msg = response?.data?.msg || "Password set Successful";
      showAlertAutoClose(msg, "success");
      setFormSubmit(false);
      setLoading(false);
      closeModal();
      // navigate("/login");
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const verifyValidations = {
    otp: [
      SmartValid.required("OTP Required"),
      SmartValid.minLength("Minimum 6 Digit", 6),
    ],
    password: [
      SmartValid.required("Password is Required"),
      // SmartValid.minLength("Minimum 8 Digit",8),
      // SmartValid.maxLength("Maximum 15 Digit",15),
    ],
    cnf_password: [
      SmartValid.required("Password is Required"),
      // SmartValid.minLength("Minimum 8 Digit",8),
      // SmartValid.maxLength("Maximum 15 Digit",15),
      SmartValid.custom((value) => {
        return formData.password !== value
          ? "Password & Confirm Password are not Same"
          : "";
      }),
    ],
  };

  const formElementsOne = [
    {
      type: "OTP",
      width: "12",
      name: "otp_value",
      element: {
        digits: 6,
        label: "Enter OTP",
        isRequired: true,
        pattern: ALLOW_NUMERIC,
        validations: verifyValidations.otp,
        max: 6,
        resendOtpFunction: (callback) => {
          handleFormEmailOtpSend(null);
          callback();
        },
        otpButtonText: (status, timer) => {
          return status ? "Resend Code (" + timer + " sec)" : "Resend Code";
        },
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "password",
      element: {
        placeHolder: "Enter Password",
        validations: verifyValidations.password,
        passwordValidator: true,
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "confirm_password",
      element: {
        placeHolder: "Confirm Password",
        validations: verifyValidations.cnf_password,
        passwordValidator: true,
      },
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Set Password",
        classList: ["crop-login-action-button ", "business-login-btn"],
        onClick: () => handleVerify(),
      },
    },
  ];

  const smartChangeForms = () => {
    return (
      <>
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElementsOne}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </>
    );
  };

  return (
    <>
      <div>
        <div className="smart-customer-input">
          <div className="is-flex is-justify-content-space-between mr-3">
            <div className="is-size-4 has-text-weight-bold mb-4">
              Set Password
            </div>
            <div className="mb-3" onClick={closeModal}>
            <div className="customer-modal-close">
              <i class="fa fa-times has-text-white" aria-hidden="true"></i>
            </div>
          </div>
            {/* <div className="mb-3" onClick={closeModal}>
              <div className="customer-modal-close mb-3 ">
                <i class="fa fa-times has-text-white" aria-hidden="true"></i>
              </div>
            </div> */}
          </div>
          {isVerify !== true ? smartChangeForms() : ""}
        </div>
      </div>
    </>
  );
};

export default FirstPassWord;
