import { useEffect, useState } from "react";
import {
  SmartSofFile,
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftForm,
  SmartValid,
} from "soft_digi";
import { USER_URLS } from "../../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  // ALLOW_ALPHABET,
  ALLOW_ALPHABET_SPACE,
  ALLOW_NUMBERS_EXCEPT_ZERO,
  ALLOW_NUMERIC,
} from "../../../../services/PatternSerivce";
import {
  showAlertAutoClose,
  showNotification,
} from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";

import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import SmartImageEditor from "../../../../components/site/FormElements/SmartImageEditor";
import { base64ToFileSize } from "../../../../services/core/FileService";
import { getImageContent } from "../../../../services/fileService";
import {
  admin_interest_select,
  admin_loyalty_select,
  admin_states_select,
} from "../../../../services/site/SelectBoxServices";
import EmailChange from "../../MyProfile/EmailChange";
import MobileChange from "../../MyProfile/MobileChange";
import { DeleteAccount } from "./DeleteAccount";

const MyProfileForm = () => {
  // const [data, setData] = useState(null);
  const [formAddressData, setFormAddressData] = useState({});
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const [loyalty, setLoyalty] = useState([]);
  const [states, setStates] = useState([]);
  const [interest, setInterest] = useState([]);
  const { openModal, closeModal, refreshUser } = useSiteContext();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name == "profile_image" && value) {
      const lastIndex = value.length - 1;
    }
  };
  const resetData = () => {
    handleInputChange("profile_image", "");
    refreshUser();
  };
  const handleInputAddressChange = (name, value) => {
    setFormAddressData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = get(USER_URLS.GET_ONE_PROFILE, handleError).subscribe(
      (response) => {
        // setFormData(response.data.userData);
        setFormAddressData(response.data.addrData);
        // console.log(response.data)
        let mergeObj = {
          ...response.data.addrData,
          ...response.data.bankData,
          ...response.data.userData,
        };
        setFormData(mergeObj);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const loadSelectOptions = () => {
    // load sector options
    admin_loyalty_select((data) => setLoyalty(data));
    //
    admin_interest_select((data) => setInterest(data));
    // states selection
    admin_states_select((data) => setStates(data));
  };
  useEffect(() => {
    loadSelectOptions();
    loadTableData();
  }, []);

  useEffect(() => {
    refreshUser();
  }, [formData]);

  const openImageModalOnEdit = (value) => {
    if (value) {
      const lastIndex = value.length - 1;
      openImageModal(value[lastIndex], lastIndex);
    }
  };

  const openImageModalOnEditNew = (val) => {
    let value = [val];
    if (value) {
      const lastIndex = value.length - 1;
      openImageModal(value[lastIndex], lastIndex);
    }
  };

  const deleteImage = () => {
    setFormData((prev) => ({ ...prev, profile_image: null }));
  };

  const setImage = (image_content, imageData, index) => {
    let name = "profile_image";
    imageData.content = image_content.split(",")[1];
    // const existing_data = formData.brand_logo ? [...formData.brand_logo] : [];
    //existing_data[index] = imageData;
    setFormData((prev) => ({ ...prev, [name]: imageData }));
  };

  const openImageModal = (imageData, index) => {
    if (imageData) {
      let modelObject = {
        body: (
          <SmartImageEditor
            src={imageData.content}
            setImage={(image) => setImage(image, imageData, index)}
            closeModal={closeModal}
            enableOthers={false}
          />
        ),
        modelClass: "customer-model-layout smart-modal-90",
        bodyClose: false,
      };
      //console.log("modal object " , modelObject)
      openModal(modelObject);
    }
  };

  const handleFormSubmit = () => {
    setFormSubmit(true);

    if (
      !ValidateFormNew(formData, [
        ...formElements,
        // ...bank_details,
        ...addressElements,
      ])
    ) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };

    // setLoading(true, "Profile Details are Updating....Please Wait");
    setLoading(true, "Updating....Please Wait");
    let url = USER_URLS.UPDATE_BUSINESS_PROFILE;
    let age = (formData.age = "25");
    let Sector = (formData.sector_name = "Shop");

    // let data_in = { ...formData, ...formAddressData, age, Sector };
    let data_in = { ...formData, age, Sector };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Details Updated successfully", "success");
        setLoading(false);
        loadTableData();
        refreshUser();
      }
    );

    formData.id = formData.ID;
    formData.newsletter = formData?.newsletter !== "Yes" ? "No" : "Yes";
    formData.newsletter = formData?.newsletter !== "Yes" ? "No" : "Yes";
    formData.terms_conditions =
      formData?.terms_conditions !== "Yes" ? "No" : "Yes";
    formData.active_status = "ACTIVE";
    formData.age = "25";
    // const subscription = post(USER_URLS.UPDATE_BUSINESS_PROFILE,formData, handleError).subscribe(
    //   (response) => {
    //     console.log(response.data)
    //     setFormData(response.data)
    //     // setTabData(response.data);
    //     setLoading(false);
    //   }
    // );
    return () => {
      subscription.unsubscribe();
    };
  };
  const Validations = {
    business_name: [SmartValid.required("Business Name is Required")],

    bank_id: [SmartValid.required("BSB is Required")],
    bank_name: [SmartValid.required("Bank Name is Required")],
    acc_no: [SmartValid.required("Account Number is Required")],
    addr_one: [SmartValid.required("Address 1 is Required")],
    // add_2: [SmartValid.required("Address 2  is Required")],
    city: [SmartValid.required("Address 3 is Required")],
    state: [SmartValid.required("State is Required")],
    pincode: [SmartValid.required("Pin code is Required")],
    image: [SmartValid.required("Please Upload Image")],
    business_name: [SmartValid.required("Business Name is Required")],
    first_name: [SmartValid.required("First Name is Required")],
    last_name: [SmartValid.required("Last Name is Required")],
    image: [
      SmartValid.custom((value) => {
        // allowed profile size 800 kb
        let image_size = base64ToFileSize(value && value.content ? value.content : "");
        return image_size > 800 ? "Size should be less that 800 KB" : "";
      }),
    ],
  };

  const formElements = [
    {
      type: "",
      width: "4",
      name: "",
      element: { placeHolder: "" },
    },

    {
      type: "TEXT_BOX",
      width: "4",
      name: "business_name",
      element: {
        inputType: "BORDER_LABEL_FOCUS",
        // validations: Validations.business_name,
        // isRequired: true,
        label: "Business Name",
        pattern: ALLOW_ALPHABET_SPACE,
        max: 255,
        inputProps: {
          disabled: true,
        },
      },
    },

    {
      type: "",
      width: "4",
      name: "",
      element: { placeHolder: "" },
    },

    {
      type: "TEXT_BOX",
      width: "4",
      name: "first_name",
      element: {
        inputType: "BORDER_LABEL_FOCUS",
        validations: Validations.first_name,
        label: "First Name",
        isRequired: true,
        pattern: ALLOW_ALPHABET_SPACE,
        max: 255,
      },
    },

    {
      type: "TEXT_BOX",
      width: "4",
      name: "middle_name",
      element: {
        inputType: "BORDER_LABEL_FOCUS",
        label: "Middle Name",
        pattern: ALLOW_ALPHABET_SPACE,
        max: 255,
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "last_name",
      element: {
        inputType: "BORDER_LABEL_FOCUS",
        validations: Validations.last_name,
        label: "Last Name",
        isRequired: true,
        pattern: ALLOW_ALPHABET_SPACE,
        max: 255,
      },
    },
  ];
  const header = () => {
    const handleMobileClick = () => {
      setLoading(true, "Loading.... Please Wait");
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage);
        setLoading(false);
      };
      const subscription = get(USER_URLS.SENT_OTP_EMAIL, handleError).subscribe(
        (response) => {
          // console.log(response);
          // setData(response.data);
          // setIsVerify(true)
          setLoading(false);
          openNewForm(response.data);
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    };
    const handleEmailClick = () => {
      setLoading(true, "Loading.... Please Wait");
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage);
        setLoading(false);
      };
      const subscription = get(
        USER_URLS.SEND_OTP_MOBILE,
        handleError
      ).subscribe((response) => {
        // console.log(response);
        setLoading(false);
        openNewFormemail(response.data);
      });
      return () => {
        subscription.unsubscribe();
      };
    };


    const openNewForm = (dataIn) => {
      let modelObject = {
        body: (
          <MobileChange
            data={dataIn}
            setLoading={setLoading}
            closeModal={closeModal}
            loadProfileData={loadTableData}
            handleMobileClick={handleMobileClick}
            refreshUser={refreshUser}
          />
        ),
        modelClass: "customer-model-layout smart-modal-90",
        bodyClose: true,
      };
      openModal(modelObject);
    };
    const openNewFormemail = (dataIn) => {
      let modelObject = {
        body: (
          <EmailChange
            data={dataIn}
            setLoading={setLoading}
            closeModal={closeModal}
            handleEmailClick={handleEmailClick}
            loadProfileData={loadTableData}
            refreshUser={refreshUser}
          />
        ),
        modelClass: "customer-model-layout smart-modal-90",
        bodyClose: true,
      };
      openModal(modelObject);
    };

    
   

    return (
      <>
        <div className="header">
          <p className="smart-bussiness-header pb-3 pl-3 pt-4">
            {/* <span className="icon mt-1">
              <i className="fa fa-caret-left" aria-hidden="true"></i>
              <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
            </span> */}
            <span className="ml-5"> My Profile</span>
          </p>

          {/* <div key={"MyProfileFrom-columns-one"} className="columns m-0"> */}
          {/* <div key={"MyProfileFrom-columns-one"} className="column is-6 is-offset-4">
                  <div className="card-content has-text-centered">
                    <figure class="image is-128x128 m-0 is-rounded ml-5 mb-2">
                      <img
                        class="is-rounded "
                        src={getImageContent(formData?.profile_image)}
                        alt="test"
                      />
                       </figure>
                      <div className="smart-business-image-file">


                    <SmartSofFile
                      placeHolder="Upload New Photo"
                      
                      value={formData?.profile_image}
                      onChange={(value) => handleInputChange("profile_image", value)}
                      errorEnable={formSubmit}
                      fileNameEnable={false}
                    />
                    </div>
                  </div>
                  </div>
                </div> */}
          <div className="columns  columns-gaplessis-centered m-0">
            <div className="column is-4 MyProfileFormcolunm  px-6   mb-6">
              <div className="is-flex is-justify-content-center mr-3">
                <figure className="image  smart-profile-logo-image ml-1 mb-3 ">
           <img
                    
                    src={getImageContent(formData?.profile_image)}
                    alt=""
                  />
                </figure>
                {formData && formData.profile_image &&  formData.profile_image.content &&
                <div className="smart-crop-image-edit">
                  <i
                    className="fa fa-pencil-square-o  image-edit-options"
                    onClick={() =>
                      openImageModalOnEditNew(formData?.profile_image)
                    }
                  ></i>
                  <i
                    className="fa fa-trash image-edit-options "
                    onClick={() => deleteImage()}
                  ></i>
                </div>
                }
              </div>
              <div className="smart-business-image-file-two is-flex is-justify-content-center">
                <SmartSofFile
                  placeHolder="Upload Logo"
                  value={formData?.profile_image}
                  onChange={(value) => {
                    openImageModalOnEdit(value);
                    handleInputChange("profile_image", value);
                  }}
                  errorEnable={formSubmit}
                  fileNameEnable={false}
                  validations={Validations.image}
                  // leftIcon="fa fa-cloud-upload"
                  isMulti={true}
                  isRequired={true}
                />
              </div>
            </div>

            <div className="column  is-4 has-text-centered mt-6 mb-6">
              <div className="mt-6">
                <span className="has-text-weight-bold">
                  <i
                    class="fa fa-mobile mt-2pr-2 is-size-3 has-text-info"
                    aria-hidden="true"
                  ></i>
                </span>
                <div className="pb-5 pt-2 has-text-weight-medium">
                  {formData?.mobile_no}
                </div>
              </div>

              <SmartSoftButton
                label="Update Mobile"
                classList={["smart-crop-action-button"]}
                onClick={() => openNewForm()}
                // onClick={handleFormSubmit}
              />
            </div>

            <div className="column is-4 has-text-centered mt-6 mb-5">
              <div className="mt-6">
                <span className="has-text-weight-bold">
                  <i
                    class="fa fa-envelope pr-2 is-size-4 has-text-info"
                    aria-hidden="true"
                  ></i>
                </span>
                <div className="pb-5 pt-3 has-text-weight-medium">
                  {formData?.email_id}
                </div>
              </div>

              <SmartSoftButton
                label="Update Email "
                classList={["smart-crop-action-button"]}
                onClick={() => openNewFormemail()}

                // onClick={handleFormSubmit}
              />
            </div>
          </div>
          {/* </div> */}

          <div className=" mx-6">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElements}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
              // onChange={(value) => handleInputChange("input_four", value)}
            />
          </div>
        </div>
      </>
    );
  };

  // const Validations = {
  //   business_name: [SmartValid.required("Business Name is Required")],

  //   bank_id: [SmartValid.required("BSB is Required")],
  //   bank_name: [SmartValid.required("Bank Name is Required")],
  //   acc_no: [SmartValid.required("Account Number is Required")],
  //   addr_one: [SmartValid.required("Address 1 is Required")],
  //   // add_2: [SmartValid.required("Address 2  is Required")],
  //   city: [SmartValid.required("City is Required")],
  //   // state: [SmartValid.required("State is Required")],
  //   pincode: [SmartValid.required("Pin code is Required")],
  //   image: [SmartValid.required("Please Upload Image")],
  //   business_name:[SmartValid.required("Please Upload Image")],

  // };
  const bank_details = [
    {
      type: "TEXT_BOX",
      width: "4",
      name: "bank_id",
      element: {
        validations: Validations.bank_id,
        max: 6,
        label: "BSB",
        inputType: "BORDER_LABEL_FOCUS",
        isRequired: true,
        pattern: ALLOW_NUMERIC,
      },
    },

    {
      type: "TEXT_BOX",
      width: "4",
      name: "bank_name",
      element: {
        validations: Validations.bank_name,
        label: "Bank Name",
        inputType: "BORDER_LABEL_FOCUS",
        isRequired: true,
        pattern: ALLOW_ALPHABET_SPACE,
        max: 255,
      },
    },

    {
      type: "TEXT_BOX",
      width: "4",
      name: "accnt_no",
      element: {
        validations: Validations.acc_no,
        max: 17,
        pattern: ALLOW_NUMERIC,
        label: "Account Number",
        // max: 20,
        inputType: "BORDER_LABEL_FOCUS",
        isRequired: true,
      },
    },
    // {
    //   type: "TEXT_BOX",
    //   width: "4",
    //   name: "no_of_outlets",
    //   element: {
    //   validations: numericValidations,pattern:ALLOW_NUMERIC,
    //   label:"Number of Outlets",
    //   inputType: "BORDER_LABEL_FOCUS",
    //   isRequired:true,}
    // },
  ];
  const BankDetails = () => {
    return (
      <>
        <div className="p-0 m-0">
          <div className="ml-6 has-text-weight-bold my-2">Bank Details</div>

          <div className="mx-6">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={bank_details}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
              onChange={(value) => handleInputChange("input_four", value)}
              value={formData?.input_Appliednotto || null}
            />
          </div>
        </div>
      </>
    );
  };

  const addressElements = [
    {
      type: "TEXT_BOX",
      width: "4",
      name: "addr_one",
      element: {
        label: "Address Line 1",
        validations: Validations.addr_one,
        isRequired: true,
        inputType: "BORDER_LABEL_FOCUS",
        // pattern: ALLOW_ALPHABET_SPACE,
        max: 255,
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "addr_two",
      element: {
        label: "Address Line 2",
        // validations: Validations.add_2,
        inputType: "BORDER_LABEL_FOCUS",
        // pattern: ALLOW_ALPHABET_SPACE,
        max: 255,
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "city",
      element: {
        label: "Address Line 3",
        pattern: ALLOW_ALPHABET_SPACE,
       // validations: Validations.city,
       // isRequired: true,
        inputType: "BORDER_LABEL_FOCUS",
        // pattern: ALLOW_ALPHABET,
        max: 255,
      },
    },
    {
      type: "SELECT_BOX",
      width: "4",
      name: "state",
      element: {
        placeHolder: "State",
        isRequired: true,
        inputType: "BORDER_LABEL",
        label: "State",
        validations: Validations.state,
        options: states,
      },
    },

    {
      type: "TEXT_BOX",
      width: "4",
      name: "pincode",
      element: {
        label: "Pincode",
        pattern: ALLOW_NUMBERS_EXCEPT_ZERO,
        validations: Validations.pincode,
        isRequired: true,
        inputType: "BORDER_LABEL_FOCUS",
        max: 4,
      },
    },
  ];
  const Address = () => {
    return (
      <>
        <div>
          <div className="ml-6 has-text-weight-bold my-2">Address</div>

          <div className="mx-6">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={addressElements}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
              // onChange={(value) => handleInputChange("input_four", value)}
            />
          </div>
        </div>
      </>
    );
  };
  const loyaltyInterestForm = () => {
    const secForm = [
      {
        type: "SELECT_BOX",
        width: "6",
        name: "loyalty",
        element: {
          label: "Loyalty Program:",
          options: loyalty,
          // isHorizontal: true,
          // inputType: "BORDER_LABEL",
          isMulti: true,
          classList: ["smart-input-label-width-60"],
        },
      },
      {
        type: "SELECT_BOX",
        width: "6",
        name: "interest",
        element: {
          label: "Interests ",
          options: interest,
          isMulti: true,
          // inputType: "BORDER_LABEL",
          //isHorizontal: true,
          classList: ["smart-input-label-width-60"],
        },
      },
    ];

    return (
      <>
        <div className="container px-6 mt-2">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={secForm}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>
      </>
    );
  };

  // const LoyaltyProgram = () => {
  //   const checkbox = [
  //     { value: "1", label: "Accor" },
  //     { value: "2", label: "Fastbreak" },
  //     { value: "3", label: "BP Rewards" },
  //     { value: "4", label: "Club Jetstar" },
  //     { value: "5", label: "Emirates Skyward" },
  //     { value: "6", label: "Hotels.com" },
  //     { value: "7", label: "Mantra Hotels" },
  //     { value: "8", label: "Marriott Bonvoy" },
  //   ];
  //   return (
  //     <>
  //       <div>
  //         <span className="ml-6 has-text-weight-bold">Loyalty Program</span>

  //         <div className="m-6">
  //           <SmartSoftCheckRadioSwitch
  //             isMultiple={true}
  //             options={checkbox}
  //             name="checkboxAppliednotto"
  //             value={formData?.input || null}
  //             onChange={(value) => handleInputChange("input", value)}
  //           />
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  // const Interest = () => {
  //   const checkbox_one = [
  //     { value: "1", label: "Book" },
  //     { value: "2", label: "Public speaking" },
  //     { value: "3", label: "Food" },
  //   ];
  //   return (
  //     <>
  //       <div>
  //         <span className="ml-6">Interests</span>

  //         <div className="m-6  smart-profile-form-switch is-flex is-justify-content-space-between">
  //           <SmartSoftCheckRadioSwitch
  //             isMultiple={true}
  //             options={checkbox_one}
  //             name=""
  //             value={formData?.input || null}
  //             onChange={(value) => handleInputChange("input", value)}
  //           />
  //         </div>
  //       </div>
  //     </>
  //   );
  // };
  const terms_and_conditions = () => {
    const loyalty = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    return (
      <>
        <div className="container px-6 mt-2">
          <SmartSoftCheckRadioSwitch
            isMulti={true}
            options={loyalty}
            type={"radio"}
            label="Market Notification:"
            name="marketting"
            isHorizontal="true"
            value={formData?.marketting}
            onChange={(value) => handleInputChange("marketting", value)}
          />

          <SmartSoftCheckRadioSwitch
            isMulti={true}
            options={loyalty}
            type={"radio"}
            label="Newsletter:"
            name="newsletter"
            isHorizontal="true"
            isRight
            value={formData?.newsletter}
            onChange={(value) => handleInputChange("newsletter", value)}
          />
        </div>
      </>
    );
  };

  // const Notify = () => {
  //   const markettingOtp = [{ value: "Yes", label: "Market Notification" }];
  //   const newsletterOpt = [{ value: "Yes", label: "News letter Subscription" }];
  //   const terms_conditionsOpt = [
  //     { value: "Yes", label: "Accept Terms & Conditions" },
  //   ];
  //   const notification = [
  //     {
  //       type: "CHECK_RADIO",
  //       width: "12",
  //       name: "marketting",
  //       element: {
  //         options: markettingOtp,
  //         isHorizontal: true,
  //         classList: ["smart-input-label-width-60"],
  //         isRight: false,
  //       },
  //     },
  //     {
  //       type: "CHECK_RADIO",
  //       width: "12",
  //       name: "newsletter",
  //       element: {
  //         options: newsletterOpt,
  //         isHorizontal: true,
  //         classList: ["smart-input-label-width-60"],
  //         isRight: false,
  //       },
  //     },
  //     {
  //       type: "CHECK_RADIO",
  //       width: "12",
  //       name: "terms_conditions",
  //       element: {
  //         options: terms_conditionsOpt,
  //         isHorizontal: true,
  //         classList: ["smart-input-label-width-60"],
  //         isRight: false,
  //       },
  //     },
  //   ];
  //   return (
  //     <>
  //       <div>
  //         <span className="ml-6">Notification And Subscription</span>

  //         <div className="m-6">
  //           {/* <SmartSoftCheckRadioSwitch
  //               isMulti={true}
  //               options={checkbox_two}
  //               name=""
  //               value={formData?.input || null}
  //               onChange={(value) => handleInputChange("input", value)}
  //             /> */}
  //           <SmartSoftForm
  //             formData={formData}
  //             setFormData={handleInputChange}
  //             elements={notification}
  //             formSubmit={formSubmit}
  //             handleErrorChange={handleErrorChange}
  //           />

  //           <div className="mt-6">
  //             <SmartSoftButton
  //               label="Save"
  //               onClick={() => handleFormSubmit()}
  //               classList={["smart-crop-action-button "]}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };
  const openDeleteAccount = () => {
    let modelObject = {
      body: (
        <DeleteAccount
          // data={dataIn}
          setLoading={setLoading}
          closeModal={closeModal}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  
  return (
    <div className="smart-crop-business-forms">
      {header()}
      {/* {BankDetails()} */}
      {Address()}
      {loyaltyInterestForm()}
      {/* {LoyaltyProgram()} */}
      {/* {Interest()} */}
      {/* {Notify()} */}
      {terms_and_conditions()}
      <div className="has-text-centered mb-6">
        <SmartSoftButton
          label="Update Profile"
          classList={["smart-crop-action-button"]}
          onClick={handleFormSubmit}
        />
               <div  className="account-text has-text-danger has-text-centered mt-4">
                <span onClick={() => openDeleteAccount()}>
                  Delete My Account?</span>
               </div>
      </div>
    </div>
  );
};

export default MyProfileForm;
