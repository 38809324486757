import { Route, Routes } from "react-router-dom";
import { Comments, RatingForm, RatingTable } from "../../pages/Business";

const MoreButton = () => {
  return (
    <Routes>
      <Route path="/rating-list" element={<RatingTable />} />
      <Route path="/rating-form" element={<RatingForm />} />
      <Route path="/comments" element={<Comments />} />
    </Routes>
  );
};

export default MoreButton;
