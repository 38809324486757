import { useEffect, useState } from "react";
import { getImageContent } from "../../../services/fileService";
import { get, post } from "../../../services/smartApiService";
import "./SmartFormElements.css";
const SmartImageDisplay = (props) => {
  const {
    srcType = "DATA",
    url = "",
    data,
    id = 0,
    imageClass = "",
    isMulti = false,
    updateImages,
    default_img="",
    imageEditClick
  } = props;
  //console.log("props  ", props);
  const [src, setSrc] = useState(data);

  const loadImageData = () => {
    // setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      setSrc("");
    };
    const subscription = post(url, { id: id }, handleError).subscribe(
      (response) => {
        let data_image = response.data.content!==undefined ? response.data.content : response.data;
        //  console.log(response);
        setSrc(data_image);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadImageGetData = () => {
    // setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      setSrc("");
    };
    const subscription = get(url,handleError).subscribe(
      (response) => {
        let data_image = response.data.content!==undefined ? response.data.content : response.data;
        //  console.log(response);
        if(data_image && data_image.length > 15){
          setSrc(data_image);
        }
        
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    if (srcType === "URL" && url.length > 10 && id > 0) {
      loadImageData();
    }else if (srcType === "URL_GET" && url.length > 10) {
      loadImageGetData();
    }else{
     // console.log("Src " , data);
      setSrc(data);
    }
  }, [props]);

  const get_image = ()=>{

    if(src && src.length > 17){
      return `data:image/png;base64,${src}`;
    }else{
      return default_img;
    }
  }

  const singleImage = () => {
    return (
      <figure className={"image " + imageClass}>
        <img src={get_image()} alt="" />
      </figure>
    );
  };

  const deleteImage=(indexToRemove)=>{
    const updatedItems = [...src];
    // Remove the element at the specified index
    updatedItems.splice(indexToRemove, 1);
    //
    if(updateImages)
      updateImages(updatedItems);
  }

 const handleEdit=(index)=>{
   if(imageEditClick && imageEditClick instanceof Function){
    imageEditClick(index);
   }
 }


  const edit_options=(index)=>{
    return (
      <div className="image-edit-options">       
     
        <i className="fa fa-trash  image-edit-options-icon" onClick={()=>deleteImage(index)}></i>
        <i className="fa fa-pencil-square-o  mt-1 image-edit-options-icon " onClick={()=>handleEdit(index)}></i>
      </div>
    )
  }

  const singleImageDisplay=(item,index)=>{
     return <div className={"column smart-image-display-options "+ imageClass} key={index}>
        <img src={getImageContent(item)} alt="" />
      {edit_options(index)}
     </div>
  }

  const multipleImages = () => {
    return(
      <div className="columns is-multiline">
        {
          src.map((item,index)=>{
          return  singleImageDisplay(item,index)
          })
        }
      </div>
    )
    
  };

  return isMulti ? multipleImages() : singleImage();
};

export default SmartImageDisplay;
