import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartSoftButton, SmartSoftForm } from 'soft_digi';
import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import { useSiteContext } from '../../../../contexts/SiteProvider';
import { showNotification } from "../../../../services/notifyService";
import { decrypt_data } from '../../../../services/sessionService';
import { post } from "../../../../services/smartApiService";
import SmartHeader from '../../../../components/site/FormElements/SmartHeader';


const AmendForm = () => {

  const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const navigate = useNavigate();
  const baseNav = "/manage-services";
 
  const navigateLink=(index)=>{
    navigate(baseNav + "/" + index);
  }

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = () => {
    setLoading(true, "Loading.... Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    let post_data={"id":decrypted_id}
    const subscription = post(
      MANAGE_OFFER_URLS.GET_ONE,
      post_data,
      handleError
    ).subscribe((response) => {
      // console.log(response.data)
      setFormData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const numericValidations = [
    {
      type: "required",
      msg: "Please enter correct value",
    },
  
  ];

  
  const handleFormSubmit = () => {
    // console.log("formdata" , formData);
    setFormSubmit(true);
    // console.log("form errors ", formErrors);
  };

  const options = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  const formElements = [
    
    {
       type:"TEXT_BOX",
       width:"4",
       name:"offer_title",
       element:{
         placeHolder:"Title",
         validations:numericValidations
       }
      },
      {
       type:"TEXT_BOX",
       width:"4",
       name:"brand",
       element:{
         placeHolder:"Brand",
         validations:numericValidations
       }
      },

      {
        type:"TEXT_BOX",
        width:"4",
        name:"sd_admin_sector_id",
        element:{
          placeHolder:"Sector",
          validations:numericValidations
        }
       },

       {
        type:"SELECT_BOX",
        width:"4",
        name:"select_one",
        element:{
          placeHolder:"Apply",
          options:options
        }
       },
       {
        type:"TEXT_BOX",
        width:"4",
        name:"price",
        element:{
          placeHolder:"Original Price",
          validations:numericValidations
        }
       },

       {
        type:"TEXT_BOX",
        width:"4",
        name:"discount",
        element:{
          placeHolder:"Discount Percentage",
          validations:numericValidations
        }
       },

      //  {
      //   type:"TEXT_BOX",
      //   width:"4",
      //   name:"input_two",
      //   element:{
      //     placeHolder:"Quantity",
      //     validations:numericValidations
      //   }
      //  },

       {
        type:"DATE",
        width:"4",
        name:"input_two",
        element:{
          placeHolder:"Product Available From Date",
          validations:numericValidations,
          
        }
       },

       {
        type:"DATE",
        width:"4",
        name:"input_two",
        element:{
          placeHolder:"Product Available To Date",
          validations:numericValidations
        }
       },

       {
        type:"TEXTAREA",
        width:"4",
        name:"offer_desc",
        element:{
          placeHolder:"Detailed Description",
          validations:numericValidations
          
        }
       },
       {
        type:"TEXTAREA",
        width:"4",
        name:"offer_breif",
        element:{
          placeHolder:"Brief Description",
          validations:numericValidations
        }
       },

       {
        type:"TEXT_BOX",
        width:"4",
        name:"input_two",
        element:{
          placeHolder:"Brief Description",
          validations:numericValidations
        }
       },
   ]

  return (
    <>
    <div className='container'>
    <p className="smart-bussiness-header pb-3 pl-3 pt-4">
           <span className="icon mt-1" onClick={()=>navigateLink("my-offer-list")}>
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span>
          <div className="pl-1">
            <SmartHeader title={"Manage Services"} sub_title={"Amend Offer"} />
          </div>
        </p>
  
     
      
      
         <div className='is-flex is-justify-content-center'>
         <SmartSoftButton
          label="Upload Product Images"
          onClick={() => handleFormSubmit()}
          classList={['m-3',"is-primary", "is-small"]}
          // disabled={!isEmptyObject(formErrors)}
        />

         </div>
      

        <div className='column multiline'>

        <SmartSoftForm 
            formData={formData} 
            setFormData={handleInputChange} 
            elements={formElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
            value={formData?.input_Applied || null}
            onChange={(value) => handleInputChange("input_Applied", value)}
         />
        </div> 
        


       <div className='is-flex is-justify-content-space-between'>
        <div className='is-flex is-justify-content-space-between m-3'>
        <SmartSoftButton
          label="Submit"
        
          classList={['smart-crop-action-button']}
          // disabled={!isEmptyObject(formErrors)}
        />
        </div>

        <div className='is-flex is-justify-content-space-between'>
        <SmartSoftButton
          label="View Offers"
          onClick={()=>navigateLink('my-offer-view')}
          classList={['smart-crop-action-button',"mr-2"]}
          // disabled={!isEmptyObject(formErrors)}
        />
         <SmartSoftButton
          label="Market Offers"
          onClick={()=>navigateLink('market-offer-form/:id')}
          classList={["smart-crop-action-button","ml-2","mr-2"]}
          // disabled={!isEmptyObject(formErrors)}
        />
        </div>

       </div>
        



          
            

          
            
          

       

   

      
   
  </div>

    </>
  )
}

export default AmendForm