import { useState } from "react";
import { SmartSoftButton, SmartSoftForm } from "soft_digi";
import { SmartValid, ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { ALLOW_ALPHABET_SPACE } from "../../../../services/PatternSerivce";

const AddComponentForm = ({ closeModal, parent_index, addComponentNew, options }) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const FormValidations = {
    title: [SmartValid.required("Component Name is Required")],
    // describe: [SmartValid.required("Description is Required")],
  };

  const handleFormSubmit = () => {
    //console.log("formdata", formData);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const check_exists = options.find(item => item.name == formData.name);
    if (check_exists) {
      showAlertAutoClose("Component Already Exists", "error");
      return false;
    }
    addComponentNew(parent_index, formData.name);   
    closeModal()
  };

  const formElements = [
    {
      type: "TEXT_BOX",
      width: "9",
      name: "name",
      element: {
        label: "",
        placeHolder: "Enter Component Name",
        validations: FormValidations.title,
        pattern:"[A-Za-z0-9 ,]*"
      },
    },
    {
      type: "BUTTON",
      width: "3",
      name: "submit",
      element: {
        label: "Add",
        classList: ["smart-customer-primary-button"],
        onClick: handleFormSubmit,
      },
    },
  ];

  return (
    <>
      <div className="">
        <div className="columns is-mobile is-multiline">
          <div className="column is-10 has-text-centered is-flex-mobile">
            <div className="variant-Additiion-text-popup">
              Component Addition
            </div>
          </div>
          <div className="column is-2 is-flex-mobile">
            <div className="mb-1 ml-6 has-text-right" onClick={closeModal}>
              <div className="customer-modal-close ">
                <i class="fa fa-times has-text-white" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="is-flex is-justify-content-space-between"> */}

        {/* </div> */}
        <div className="">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>
        <p>
          <small className="smart-error is-size-7">
            Note: You may add multiple items with (,) seperated
          </small>
        </p>
      </div>
    </>
  );
};

export default AddComponentForm;
