import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


const SideNavComponenet = ({ menuIcon, menuImage, menuName, navs, open, openNav , subMenu=true}) => {
    const [sidebarOpen, setSidebarOpen] = useState(open);
    const navigate = useNavigate();
    const location = useLocation();
    const handleNavigation = (link) => {
        navigate(link);
    };
    const handleToggle = () => {
        setSidebarOpen(prevState => !prevState);
        openNav()
    };

    useEffect(() => {
        setSidebarOpen(open)
    }, [open]);
    return (
        <div className='crop-bussiness-side-nav-menu menu'>
            <div className={`roboto-font-family ${subMenu ? 'menu-label' : 'menu-label-heading'}`} 
             onClick={handleToggle}>

                

                <img src={menuImage} alt="" className="menu-image-sideNav" /> 
                <span className="menu-name">{menuName}</span>
               

           

            {/* <i className={menuIcon} aria-hidden="true"></i>  */}
         {subMenu?  <i className={"fa side-nav-more-icon " + (sidebarOpen ? "fa-caret-down" : "fa-caret-right")} aria-hidden="true"></i> : ''}      
            </div>
            {sidebarOpen && subMenu && 
                <ul className="menu-list">
                    {navs.map((item, key) => (
                        <li key={key} onClick={() => handleNavigation(item?.link)}
                            className={location.pathname === item.link ? 'list-item-active' : 'list-items '}>
                            <span className='is-flex poppins-font-family business-side-nav-list'>
                                <span className='icon is-size-7  business-side-nav-icon '>{item.icon} </span>
                                <span className='is-size-7 has-text-weight-semibold ml-2'>{item.label}</span>
                            </span>
                        </li>
                    ))
                    }
                </ul>
            }
        </div>
    )


}

export default SideNavComponenet;