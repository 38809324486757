import {
  Elements,
  PaymentElement,
  useElements,
  // LinkAuthenticationElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { STRIPE_PKEY } from "../../config/default";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_51N6UkvAxecHTIAC0OQKDR6XX4Zxyiy4hfdIoRG3KDNvUoCcr0NnMtGqPgOt14r2Lk9myZk4eDBLaOLVP8gkYAYke00TVXXhHiQ');
const stripePromise = loadStripe(STRIPE_PKEY);
//import StripeCheckout from 'react-stripe-checkout';

const CheckoutForm = ({ setLoading, isLoading, handleSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  // const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          handleSuccess(clientSecret);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href, //"http://localhost:3000/whish-list/view-cart",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <br />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="button is-success"
      >
        <span id="button-text">Pay now</span>
      </button>
      {/* Show any error or success messages */}
      {message && (
        <div style={{ color: "green", fontSize: 25 }} id="payment-message">
          {message}
        </div>
      )}
    </form>
  );
};

const SmartStripePayment = ({
  clientSecret,
  setLoading,
  isLoading,
  handleSuccess,
}) => {
  const appearance = {
    theme: "stripe",
  };
  //  const [csec, setCsec] = useState(null);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    appearance: appearance,
  };
  /*
  const loadWishListData = (e) => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error")
    //  setLoading(false);      
    };
   // setLoading(true, "Loading....Please Wait");
    const subscription = get("/auth/fetch", handleError).subscribe(
      (response) => {
       // setLoading(false);
       //setCsec(response.data)
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
*/

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            setLoading={setLoading}
            isLoading={isLoading}
            handleSuccess={handleSuccess}
          />
        </Elements>
      )}
    </>
  );
};

export default SmartStripePayment;

/*
const [paymentError, setPaymentError] = useState(null);
  const [product] = useState({
    name:"sample stripe testing",
    amount:300,

  })

  const handleError=(msg)=>{
     console.log(msg)
  }


  async function handleToken(token,address){
    const subscription =   post("auth/test_payment",{ token: token,address:address},handleError)
    .subscribe(
        (response) => {
          console.log("response " , response);             
         // showAlertAutoClose("Offer Moved to Cart", "success");
        }
      );
  }

  return (
    <StripeCheckout 
      className="button is-primary"
      stripeKey='pk_test_51N6UkvAxecHTIAC0OQKDR6XX4Zxyiy4hfdIoRG3KDNvUoCcr0NnMtGqPgOt14r2Lk9myZk4eDBLaOLVP8gkYAYke00TVXXhHiQ'
      token={handleToken}
      amount={product.amount * 100}
      name={product.name}
     
    />
  );

*/
