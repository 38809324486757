import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  // SmartSOftTextArea,
  SmartSoftButton,
} from "soft_digi";
import { SERVICE_SETTINGS } from "../../../../api/Admin/OperationsUrl";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { INTERROGATION_IMAGE } from "../../../../services/ImageService";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import BlueHolidayForm from "./BlueHolidayForm";
import ToolTip from './ToolTip';
const BlueDays = () => {
  const navigate = useNavigate();
  const navigateLink = (index) => {
    navigate();
  };
  const [selectedDay, setSelectedDay] = useState([]);
  const [tab, setTab] = useState("weekdays");
  const { setLoading } = useSiteContext();
  const [showTooltip, setShowTooltip] = useState(false);


  const post_process_data = (data_in) => {
    let selected_days = [];   
    data_in.forEach((item) => {
        if(item.open_close==1){
        selected_days.push(""+item.day_number);
       }
      // console.log("selected days " , selected_days);
    });

    return selected_days;
  };

  const loadData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let url = SERVICE_SETTINGS.GET_ALL_BLUE;
    const subscription = post(url, {}, handleError).subscribe((response) => {
      console.log(response);
      // setTabData(response.data);
      let output = post_process_data(response.data);   
      setSelectedDay(output);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleDayClikConfirm = (selection,index) => {
    if (selection === "yes") {
      let exists_data = [...selectedDay];
      const indexInArray = exists_data?.indexOf(index);
      if(indexInArray === -1){
        exists_data.push(index)
      }else{
        exists_data = exists_data.filter((item) => item !== index);
      }
     console.log("exists data " , exists_data);
      setSelectedDay(exists_data);
    }
  }
  const handleDayClick = (index) => {
    let exists_data = [...selectedDay];
      const indexInArray = exists_data?.indexOf(index);
      if(indexInArray === -1){
        showYesOrNoAlert(
          "Selected Day will enable the Blue Day. Do you wish to save the changes?",
          (selection) => handleDayClikConfirm(selection,index),
          "info"
        );
      }else{
        showYesOrNoAlert(
          "Selected Day will disable the Blue Day. Do you wish to save the changes?",
          (selection) => handleDayClikConfirm(selection,index),
          "info"
        );
      }
    
  //   let exists_data = [...selectedDay];
  //   const indexInArray = exists_data?.indexOf(index);
  //   if(indexInArray === -1){
  //     exists_data.push(index)
  //   }else{
  //     exists_data = exists_data.filter((item) => item !== index);
  //   }
  //  console.log("exists data " , exists_data);
  //   setSelectedDay(exists_data);
  };

  const preprocess_data = () => {    
    let output = [];
    days.forEach((item) => {      
       const dayindex = selectedDay.indexOf(item.value)!==-1 ? 1 : 0;
      //  console.log(" stme_inde " , stime_index, " etime " , etime_index);
      output.push({
        day_type: "WEEKDAY",
        open_close:dayindex,
        day_number: item.value,
      });
    });
    return output;
  };
  const handle_redirection = ( url, post_data, handleError) => {
   // if (selection === "yes") {
      setLoading(true, "Saving....Please Wait");
      post(url, post_data, handleError).subscribe(
        (response) => {
          showAlertAutoClose("Settings Saved Successfully", "success");
          setLoading(false);
        },
        (error) => {
          handleError("Error saving settings");
        }
      );
    // } else {
    //   setLoading(false);
    // }
  };
 
  const handleOperatingFormSubmit = () => {  
   // setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
   
    let url = SERVICE_SETTINGS.INSERT_BLUEDAY;
    let post_data = preprocess_data();
    handle_redirection(url, post_data, handleError);
    // showYesOrNoAlert(
    //   "Selected Day(s) will apply as a Blue Day. Do you wish to save the changes?",
    //   (selection) => handle_redirection(selection, url, post_data, handleError),
    //   "success"
    // );
    // const subscription = post(url, post_data, handleError).subscribe(
    //   (response) => {     
    //     setLoading(false);
    //   }
    // );
    // return () => {
    //   subscription.unsubscribe();
    // };
  };

  const days = [
    { value: "1", label: "SU" },
    { value: "2", label: "MO" },
    { value: "3", label: "TU" },
    { value: "4", label: "WE" },
    { value: "5", label: "TH" },
    { value: "6", label: "FR" },
    { value: "7", label: "SA" },
  ];

 

  const getDayClassName = (dayNumber) => {
    let class_list = ["operatinghours-rounded-day bluedays-rounded-day"];

    if (dayNumber === "1" || dayNumber === "6" || dayNumber === "7") {
      class_list.push("bluedays-weekends");
    }   
    const selected_index = selectedDay?.indexOf(dayNumber);
    if (selected_index!==-1) {
      class_list.push("bluedays-selected");
    }
    return class_list.join(" ");
  };

  const weekdays = () => {
    return (
      <>
      <div className="operatinghours-rounded-days-container">
        {days.map((day, index) => (
          <div
            key={index}
            className={getDayClassName(day.value)}
            onClick={() => handleDayClick(day.value)}
          >
            {day.label}
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        ))}
      </div>
      <div className="is-justify-content-end  is-flex mt-6 ">
      <SmartSoftButton
          label="Save"
          // leftIcon="fa fa-download"
          classList={["smart-crop-action-button"]}
          onClick={handleOperatingFormSubmit}
        />
        </div>
        </>
    )
  }

  const tabs = [
    {
      index: "weekdays",
      label: "Regular Days",
      icon: <i className="fa fa-calendar-plus-o" aria-hidden="true"></i>,
      link: "pickup",
    },
    {
      index: "holidays",
      label: "Holidays",
      icon: <i className="fa fa-calendar-minus-o" aria-hidden="true"></i>,
      //link: "holidays",
    },
  ]

  const handleTabs = (index) => {
    setTab(index);   
  };

  const tabs_display = () => {
    return (
      <div className="tabs smart-business-tabs mt-4 mx-4">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={
                  tab === item.index ? "order-active-tab" : "tabs-list-items"
                }
              >
                <a href="#" onClick={() => navigateLink(item.link)}>
                  <span
                    className={
                      tab === item.index ? "order-active-tab-text " : ""
                    }
                  >
                   
                  </span>
                  <span
                    className={
                      tab === item.index ? "order-active-tab-text ml-3" : "ml-3"
                    }
                  >
                    {item.label}
                  </span>
                 <span className="icon is-small">
                    {item.icon} 
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
        
      </div>
    );
  };

  return (
    <>
      <div className="">
        <p className="smart-bussiness-header-two pb-3 pl-3 pt-4">
          {/* <span className="icon mt-1">
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span> */}
          {/* <span className="ml-5">Blue Days </span>  */}
          <div className="pl-1">
            <SmartHeader title={"Manage Program"} sub_title={"Blue Days"} />
          </div>
          {/* <span  className="ml-1 pointer"><img  src={INTERROGATION_IMAGE} alt=""></img></span>         */}
          <span
        className="ml-1 pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ position: 'relative' }}
      >
        <img src={INTERROGATION_IMAGE} alt="" />
        {showTooltip && (
          <div className="tooltip-container">
            <ToolTip text="Applying a Blue Day will restrict any Customer Redemption order on that day" />
          </div>
        )}
      </span>
        </p>
      
        {tabs_display()}
        <div className="p-5">
          {tab === "weekdays" && weekdays()}
          {tab === "holidays" && <BlueHolidayForm />}
        </div>
      </div>
    </>
  );
};

export default BlueDays;
