import { useState } from "react";
import { SmartSoftForm } from "soft_digi";

const RequestAndComplaintView = ({ closeModal, data }) => {

  const [formData, setFormData] = useState(data);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);


  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const invoiceElements = [
    {
      type: "TEXT_BOX",
      width: "6",
      name: "type",
      element: {
        label: "Type",

        inputProps: {
          disabled: true,
        },
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "last_modified_time",
      element: {
        label: "Last Modified",

        inputProps: {
          disabled: true,
        },

      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "comp_type",
      element: {
        label: "Category",

        inputProps: {
          disabled: true,
        },


      },
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "complaint_description",
      element: {
        label: "Description",

        inputProps: {
          disabled: true,
        },


      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "complaint_status",
      element: {
        label: "Status",
        inputProps: {
          disabled: true,
        },


      },
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "complaint_resolution",
      element: {
        label: "Resolution Remarks",
        inputProps: {
    disabled: true,
  }, 
      },
    },
  ]
  return (
    <>
      <div className="">
        <div className="is-flex is-justify-content-space-between">
          <div className="is-size-4">
            Request or Complaint
          </div>
          <div className="mb-3" onClick={closeModal}>
            <div className="customer-modal-close">
              <i class="fa fa-times has-text-white" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={invoiceElements}
            formSubmit={formSubmit}
          />
        </div>
      </div>
    </>
  )
}

export default RequestAndComplaintView