import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  C_LOGO,
  DASHBOARD_CARD,
  DASHBOARD_CARD_CARD_4,
  // DASHBOARD_CHART,
  DASHBOARD_CROP_CARD_1,
  DASHBOARD_CROP_CARD_2,
  DASHBOARD_CROP_CARD_3,
} from "../../../services/ImageService";
// import { SmartSoftCheckRadioSwitch } from "soft_digi";
import { DASHBOARD_URLS } from "../../../api/Business/Dashboard";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { displayDots } from "../../../services/core/CommonService";
import { showNotification } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
import ChartComponent from "./ChartComponent";

const DashboardCards = () => {
  const [tabData, setTabData] = useState([]);
  const { setLoading,
    //  openModal, closeModal
     } = useSiteContext();
  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setTabData([]);
      setLoading(false);
    };
    let type = 2;
    const subscription = post(
      DASHBOARD_URLS.GET_COUNTS,
      {type:type},
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);
  const navigate = useNavigate();
  const baseNav = "/manage-services";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

   const baseNave = "/manage-program";
   const navigateLinke = (index) => {
     navigate(baseNave + "/" + index);
   };
  return (
    <>
    <div className="dashboard-container"></div>
      <div key={"DashboardCards-column-one"} className="column  is-3 p-0">
        <div className="dashboard-card-view dashboard-my-offer-card">
          <div className="dashboard-card-items">
            <img
              className="business-dashboard-card-image"
              src={DASHBOARD_CARD}
              alt=""
            />
            <div className="dashboard-card-main-items p-4">
              <div className=" is-flex   has-text-weight-bold has-text-white is-size-5">
                <div className="dashboard-card-title mr-4">My Offers</div>
                <div className="dashboard-card-icon">
                  <i
                    onClick={() => navigateLink("my-offer-list")}
                    className="fa fa-arrow-circle-right ml-6 mt-1 "
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="dashboard-card-footer is-flex mt-3 ">
                <img src={DASHBOARD_CROP_CARD_1} alt=""/>
                <div className="ml-4 has-text-weight-bold has-text-white is-size-3">
                  {tabData.offerCount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div key={"DashboardCards-column-two"} className="column  is-3 p-0">
        <div className="dashboard-card-view dashboard-my-order-card">
          <div className="dashboard-card-items">
            <img
              className="business-dashboard-card-image"
              src={DASHBOARD_CARD}
              alt=""
            />
            <div className="smart-dashboard-card-switch ">
              {/* {MyOfferSwitch()} */}
            </div>
            <div className="dashboard-card-main-items p-4">
              <div className=" is-flex   has-text-weight-bold has-text-white is-size-5">
                <div className="dashboard-card-title mr-4">My Orders</div>
                <div className="dashboard-card-icon">
                  <i
                    onClick={() => navigateLinke("orders")}
                    className="fa fa-arrow-circle-right ml-6 mt-1 "
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="dashboard-card-footer is-flex mt-3 ">
                <img src={DASHBOARD_CROP_CARD_2} alt="" />
                <div className="ml-4 has-text-weight-bold has-text-white is-size-3">
                  {tabData.orderCount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div key={"DashboardCards-column-three"} className="column is-3 p-0 ">
        <div className="dashboard-card-view dashboard-crops-offered">
          <div className="dashboard-card-items">
            <img
              className="business-dashboard-card-image"
              src={DASHBOARD_CARD}
              alt=""
            />
            <div className="dashboard-card-main-items p-4">
              <div className=" is-flex   has-text-weight-bold has-text-white is-size-5">
                <div className="dashboard-card-title mb-3">CROP Offered</div>
              </div>
              <div className="dashboard-card-footer is-flex mt-3 ">
                <img src={DASHBOARD_CROP_CARD_3} alt=""/>
                <div className="mt-2">
                  <img className="ml-3 " src={C_LOGO} alt="" />
                </div>
                <div className="ml-1 mt-2 has-text-weight-bold has-text-white is-size-6">
                  <span className=""> {tabData.cropOffered}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div key={"DashboardCards-column-four"} className="column is-3 p-0">
        <div className=" dashboard-card-view dashboard-crops-redeemed">
          <div className="dashboard-card-items">
            <img
              className="business-dashboard-card-image"
              src={DASHBOARD_CARD}
              alt=""
            />
            <div className="dashboard-card-main-items p-4">
              <div className="has-text-weight-bold has-text-white is-size-5">
                <div className="dashboard-card-title mb-4">CROP Redeemed</div>
              </div>
              <div className="dashboard-card-footer is-flex mt-3 ">
                <img src={DASHBOARD_CARD_CARD_4} alt="" />
                <div className="mt-2">
                  {" "}
                  <img className="ml-3 mt-2" src={C_LOGO} alt="" />
                </div>
                <div className="ml-1 mt-4 has-text-weight-bold has-text-white is-size-6">
                  {tabData.cropRedeemed}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const OverView = () => {
  return (
    <>
      <ChartComponent />
    </>
  );
};

const SalesTable = () => {
  const [earnData, setEarnData] = useState([]);
  const [redeemData, setRedeemData] = useState([]);
  const { setLoading } = useSiteContext();

  //Earn LoadData
  const earnLoadData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    let post_data = { type: "EARN" };
    const subscription = post(
      DASHBOARD_URLS.GET_TOP_SALES_REDEEMED,
      post_data,
      handleError
    ).subscribe((response) => {
      setEarnData(response.data);
      // console.log("earn data",response.data)
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };


  const redeemedLoadData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setRedeemData([]);
      setLoading(false);
    };
    let post_data = { type: "REDEEM" };
    const subscription = post(
      DASHBOARD_URLS.GET_TOP_SALES_REDEEMED,
      post_data,
      handleError
    ).subscribe((response) => {
      // console.log("redmeed data",response)
      setRedeemData(response.data);

      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };




  useEffect(() => {
    earnLoadData()
    redeemedLoadData();
  }, []);

  return (
    <>
      <div className="dashboard-sales-table">
        <div className="has-text-weight-bold ">Offer Analysis</div>
        <div className="special-offer-label  has-text-centered mt-5">
          {/* Sales -Top Five */}
          Top 5 - Sale
        </div>
        <div className="dashboard-table ">
          <table className="">
            <thead>
              <tr>
                <th>Title</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody className="five-star-table">
              {earnData.map((item, index) => (
                <>
                  <tr key={index}>
                    <td>{displayDots(item?.offer_title).split("|")[0]}</td>
                    <td className="has-text-centered">{item?.quant}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
        <hr />
        {/* Redeem Table */}
        <div className="special-offer-label-two  has-text-centered">
          {/* Redeemed -Top Five */}
          Top 5 - Redeemed
        </div>
        <div className="dashboard-table-two">
          <table className=" ">
            <thead>
              <tr>
                <th>Title</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody className="redeem-table five-star-table">
              {/* {redeemedData.json()} */}
              {redeemData.map((item, index) => (
                <>
                  <tr key={index}>
                    <td>{displayDots(item?.offer_title)}</td>
                    <td className="has-text-centered">{item?.quant}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <div className="rated-table-footer has-text-right mt-4">
            Reflection based on data for last 30 days
          </div>
        </div>
      </div>
    </>
  );
};

// const MyOfferSwitch = () => {
//   const [formData, setFormData] = useState({});
//   const [formSubmit, setFormSubmit] = useState(false);
//   const [formErrors, setFormErrors] = useState(false);
//   const Month = [{ value: "1", label: "Month" }];
//   const Year = [{ value: "2", label: "Year" }];

//   const handleInputChange = (name, value) => {
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };
//   return (
//     <>
//       <div className="is-flex">
//         <div className="my-offer-month-switch is-rounded">
//           <SmartSoftCheckRadioSwitch
//             options={Month}
//             switchMode={true}
//             isRight={true}
//             name="switchone"
//             value={formData?.switchone || ""}
//             onChange={(value) => handleInputChange("switchone", value)}
//           />
//         </div>
//         <div className="my-offer-year-switch">
//           <SmartSoftCheckRadioSwitch
//             options={Year}
//             switchMode={true}
//             isRight={true}
//             name="switch"
//             value={formData?.switch || ""}
//             onChange={(value) => handleInputChange("switch", value)}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

const BusinessDashboard = () => {
  return (
    <>
     <p className="smart-bussiness-header pb-3 pl-3 pt-4">
  
        <span className="ml-5">Account Overview</span>
      </p>
      <div className="business-dashboard-container mt-2 p-4 poppins-font-family">
      {/* <div className="mb-5 is-size-5 has-text-weight-bold">Account Overview</div> */}
        <div key={"DashboardCards-columns-one"} className="columns">
          {DashboardCards()}
        </div>
        <div key={"DashboardCards-columns-two"} className="columns">
          <div key={"DashboardCards-column-five"} className="column is-9">
            {OverView()}
          </div>
          <div key={"DashboardCards-column-six"} className="column is-3">
            {SalesTable()}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessDashboard;
