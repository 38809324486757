import { useEffect, useState } from "react";
import { SmartSoftButton, SmartSoftSelect } from "soft_digi";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose, showYesOrNoAlert } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
// import { USER_URLS } from "../../../../api/Admin/AurhUrls";
import { SERVICE_SETTINGS } from "../../../../api/Admin/OperationsUrl";
import { admin_states_select } from "../../../../services/site/SelectBoxServices";
import { changeDateTimeZoneFormat } from "../../../../services/core/CommonService";

const BlueHolidayForm = () => {
  const [selectedDay, setSelectedDay] = useState([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [formData, setFormData] = useState({});
  const [formDataNew, setFormDataNew] = useState({});
  const { setLoading, closeModal } = useSiteContext();
  const [states, setStates] = useState([]);
  const [holidays, setHolidays] = useState([]);

  const handleDayClick = (index) => {
    let exists_data = [...selectedDay];
    const indexInArray = exists_data?.indexOf(index);
    let msg =  "Selected Day will enable the Blue Day. Do you wish to save the changes?";
    if(indexInArray !== -1){
      msg =  "Selected Day will disable the Blue Day. Do you wish to save the changes?";
    }
    showYesOrNoAlert(
      msg,
      (selection) => {
        if (selection === "yes") {
        if (indexInArray === -1) {
          exists_data.push(index);
        } else {
          exists_data = exists_data.filter((item) => item !== index);
        }
        // console.log("exists data ", exists_data);
        setSelectedDay(exists_data);
      }
      },
      "info"
    );

   
  };

  const handleInputNewChange = (name, value) => {
    //console.log("name " , name , " value " , value);
    setFormDataNew((prev) => ({ ...prev, [name]: value }));
  };

  const yearOptions = () => {
    let out = [];
    for (let i = 2024; i < 2030; i++) {
      out.push({
        value: i,
        label: i,
      });
    }
    return out;
  };

  const markAll = () => {
    let output = [];
    holidays.forEach((item) => {
      output.push(item.ID);
    });
    setSelectedDay(output);
  };

  const preprocess_data = () => {
    let output = [];
    holidays.forEach((item) => {
      const dayindex = selectedDay.indexOf(item.ID) !== -1 ? 1 : 0;
      output.push({
        day_type: "HOLIDAY_ONE",
        open_close: dayindex,
        day_number: item.value,
        sd_admin_holidays_id: item.ID,
      });
    });
    return output;
  };

  const handleOperatingFormSubmit = () => {
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Saving....Please Wait");
    let url = SERVICE_SETTINGS.INSERT_BLUE_HOLIDAY;
    let post_data = preprocess_data();
    console.log(post_data);
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose("Settings Saved Successfully", "success");
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const post_process_data = (data_in) => {
    let selected_days = [];
    data_in.forEach((item) => {
      if (item.open_close == 1) {
        selected_days.push(item.ID);
      }
    });
    return selected_days;
  };

  useEffect(() => {
    admin_states_select((data) => setStates(data));
    //  loadData();
  }, []);

  const loadHolidaysData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    // let post_data = {};
    let url = SERVICE_SETTINGS.GET_ALL_BLUE_HOLIDAY;
    const subscription = post(url, formDataNew, handleError).subscribe(
      (response) => {
        let _data_in = response.data;
        setSelectedDay(post_process_data(response.data));
        setHolidays(_data_in);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadHolidaysData();
  }, [formDataNew]);

  const getTimestamp = (date) => {
    return date ? date.getTime() : null; // Returns timestamp in milliseconds
  };

  const compareTime = (stime, etime) => {
    return getTimestamp(stime) !== getTimestamp(etime) ? true : false;
  };

  const holidayTopField = () => {
    return (
      <div className="columns smart-crop-business-forms">
        <div className="column is-6">
          <SmartSoftSelect
            label="State"
            placeHolder="State"
            inputType="BORDER_LABEL_FOCUS"
            classList={["has-text-left"]}
            value={formDataNew?.state || ""}
            inputProps={{ isFocussed: true }}
            options={states}
            onChange={(value) => handleInputNewChange("state", value)}
          />
        </div>
        <div className="column is-6">
          <SmartSoftSelect
            label="Year"
            placeHolder="Year"
            inputType="BORDER_LABEL_FOCUS"
            value={formDataNew?.year || ""}
            classList={["has-text-left"]}
            inputProps={{ isFocussed: true }}
            options={yearOptions()}
            onChange={(value) => handleInputNewChange("year", value)}
          />
        </div>
      </div>
    );
  };

  const holiday_checked = (id) => {
    const selected_index = selectedDay?.indexOf(id);
    return selected_index != -1 ? true : false;
  };

  const businessHoursDisplay = () => {
    return (
      <>
        <div className="smart-crop-business-forms">
          <table className="blue-day-holiday-table">
            <tr>
              <td></td>
              <td></td>
              <td
                className="rightalign has-text-weight-medium pr-2 "
                onClick={() => markAll()}
              >
                Apply to All <input className="chaeckbox-bluedays " type="checkbox" />
              </td>
            </tr>
            {holidays.map((day, index) => (
              <tr key={index}>
                <td className="p-2">{day.holiday_name}</td>
                <td className="p-2"> {changeDateTimeZoneFormat(day.holiday_date,"DD-MM-YYYY")}</td>
                <td
                  className="rightalign p-2"
                  onClick={() => handleDayClick(day.ID)}
                >
                  <input className="chaeckbox-bluedays" type="checkbox" checked={holiday_checked(day.ID)} />
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className="is-justify-content-end  is-flex mt-3">
          <SmartSoftButton
            label="Save"
            // leftIcon="fa fa-download"
            classList={["smart-crop-action-button"]}
            onClick={handleOperatingFormSubmit}
          />
        </div>
      </>
    );
  };

  return (
    <div className="OperatinghoursAndCustomDays">
      {holidayTopField()}
      {holidays && holidays.length > 0 && businessHoursDisplay()}
    </div>
  );
};

export default BlueHolidayForm;
