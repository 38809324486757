import { Route, Routes } from "react-router-dom";
import {
  ExtendBonusForm,
  HappyHoursForm,
  OfferCropsForm,
  Orders,
  OtherServiceForm,
  SlashRedemptionForm,
} from "../../pages/Business";
import BlueDays from "../../pages/Business/ManageProgram/BlueDay/BlueDays";
import CustomerTransitionScanTable from "../../pages/Business/ManageProgram/CustomerTransitions/CustomerTransitionScanTable";
import CustomerTransitionsScan from "../../pages/Business/ManageProgram/CustomerTransitions/CustomerTransitionsScan";
import OrderInfo from "../../pages/Business/ManageProgram/Orders/OrderInfo";

const ManageProgram = () => {
  return (
    <Routes>
      {/* Manage Program > Offers Crops */}
      <Route path="/offer-crops-form" element={<OfferCropsForm />} />
      <Route path="/blue-days" element={<BlueDays />} />
      {/* Manage Program > Extend Bonus Crops */}
      <Route path="/extend-bonus-form" element={<ExtendBonusForm />} />
      {/* Manage Program > Slash Redemption Crops */}
      <Route path="/slash-redemption-form" element={<SlashRedemptionForm />} />
      {/* Manage Program > Happy Hours */}
      <Route path="/happy-hours-form" element={<HappyHoursForm />} />
      {/* Manage Program > Other Services */}
      <Route path="/other-services-form" element={<OtherServiceForm />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/orders/:tabtype" element={<Orders />} />
      <Route
        path="/customer-transitions-scan"
        element={<CustomerTransitionsScan />}
      />
      <Route
        path="/customer-transitions-scan-table"
        element={<CustomerTransitionScanTable />}
      />
      <Route path="/order-info/:id" element={<OrderInfo />} />
    </Routes>
  );
};

export default ManageProgram;
