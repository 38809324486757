import { useLocation, useNavigate } from "react-router-dom";
import {
  ADMIN_USER_LOGO,
  BUSSINESS_HEAD_TOP_CENT_lOGO,
  // BUSSINESS_HEAD_TOP_D_lOGO,
  CROP_TOP_LOGO,
  CROP_C_IMG,
  CROP_LOGO_LIGHT,
  CROP_BALANCE,
} from "../../services/ImageService";

import { useEffect, useState } from "react";
import { CUSTOMER_MY_ACCOUNT } from "../../api/Business/OffersUrls";
import SmartImageDisplay from "../../components/site/FormElements/SmartImageDisplay";
import { showAlertAutoClose } from "../../services/notifyService";
import { get } from "../../services/smartApiService";
import BusinessNotification from "./BusinessNotification";

const BusinessHeader = ({
  user,
  handleLogout,
  setPage,
  toggleSidebar,
  setLoading,
}) => {
  let img_url = CUSTOMER_MY_ACCOUNT.GET_PROFILE_IMAGE;
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const basenav = "/";
  const location = useLocation();
  const { pathname } = location;
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    toggleSidebar()
  };
  // console.log("user " , user)
  const navigateLink = (index) => {
    // navigate(basenav + "/" + index);
    navigate(basenav + index);
  };

  const handleSetPage = (page) => {
    setPage(page);
    if (page === "home") {
      navigateLink("dashboard");
    } else if (page === "help") {
      navigate("/help/contact-us-view");
    }
  };

  const loadProfileData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = get(
      CUSTOMER_MY_ACCOUNT.GET_PROFILE,
      handleError
    ).subscribe((response) => {
      // console.log(response.data)
      setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadProfileData();
    // toggleSidebar();
  }, [location.pathname]);
  const handleLogoutNew = () => {
    navigate("/");
    handleLogout();
  };

  const getPage = () => {
    if (
      pathname.includes("/manage-services/") ||
      pathname.includes("/manage-program/") ||
      pathname.includes("/my-account/") ||
      pathname.includes("/settings/") ||
      pathname.includes("/home/")
    ) {
      return "home";
    } else if (pathname.includes("/promos/")) {
      return "promos";
    } else if (pathname.includes("/help/")) {
      return "help";
    } else {
      return "";
    }
  };

  const HeaderProfile = () => {
    return (
      <>
        <div className="ml-3 profile-button-dropdown dropdown   is-hoverable  pb-0 mt-1 mb-1 ">
          <button className=" crop-business-laytout-top-button is-flex profile-hi-message-hover ">
            <div className="business-profile-image  ">
              <SmartImageDisplay
                srcType="DATA"
                // url={img_url}
                data={user?.profile_image}
                // imageClass="is-48x48  is-rounded pointer"
                default_img={ADMIN_USER_LOGO}
              />
            </div>
            <div className="has-text-weight-bold ml-2 mt-1 is-flex ">
              <div>Hi {user?.ename}</div>
              <i class="fa fa-sort-desc ml-1 is-size-6 " aria-hidden="true"></i>
            </div>
          </button>
          {/*         
          <img
            src={BUSSINESS_HEAD_TOP_RIGHT_lOGO}
            alt="Business Head Top Right Logo"
          /> */}
          <div className="dropdown-menu dropdown-menu-margin mr-6">
            <div className=" dropdown-content-profile pb-3 ">
              <div className="dropdown-item p-0">
                <div className="columns mb-0">
                  {/* <div className="column is-2 mt-2">
                    <figure className="image is-48x48">
                      <img
                        className="is-rounded"
                        src={BUSSINESS_HEAD_TOP_RIGHT_lOGO}
                      />
                    </figure>
                 
                  </div> */}
                  <div className="column is-12 has-text-centered">
                    {/* <img
                        className="is-rounded"
                        src={BUSSINESS_HEAD_TOP_RIGHT_lOGO}
                      /> */}
                    <div className="is-centered is-flex is-justify-content-center ml-3">
                      <SmartImageDisplay
                        srcType="DATA"
                        // url={img_url}
                        data={user?.profile_image}
                        imageClass="is-64x64 is-rounded header-user-image-image"
                        default_img={ADMIN_USER_LOGO}
                      />
                    </div>

                    {/* <div className="has-text-weight-bold ">
                      Hi {user?.ename}
                    </div> */}

                    {/* <div className="mt-0">
                      <b>CROP: </b>
                      {user?.crop_balance}
                    </div> */}
                  </div>
                </div>
                <div className="business-profile-border mt-0"></div>
                {/* <p
                  className="business-profile-test my-1  has-text-centered is-clickable"
                  onClick={() => navigateLink("home/my-profile-form")}
                >
                  <i
                    className="fa fa-user-circle-o mr-1  business-home-icon-text-color"
                    aria-hidden="true"
                  ></i>
                  Profile
                </p> */}
                {/* <div
                  className="business-profile-test my-1  has-text-centered is-clickable"
                  onClick={() => navigateLink("dashboard")}
                >
                  <i className="fa fa-th-large mr-1 business-home-icon-text-color" aria-hidden="true"></i>
                  My Dashboard
                </div> */}
                {/* <div
                  className="business-profile-test my-1  has-text-centered is-clickable"
                  onClick={() => navigateLink("settings/pin-change-form")}
                >
                  <i className="fa fa-th-large mr-1 business-home-icon-text-color" aria-hidden="true"></i>
                  Settings
                </div> */}
                {/* <div
                  className="business-profile-test my-1  has-text-centered is-clickable"
                  onClick={handleLogoutNew}
                >
                  <i className="fa fa-sign-out mr-1 business-home-icon-text-color" aria-hidden="true"></i>
                  Logout
                </div> */}

                <div className="mt-3" key="g9">
                  <ul className=" has-text-left profile-business-icon-page px-2">
                    <li
                      className="customer-profile-test pointer"
                      onClick={() => navigateLink("home/my-profile-form")}
                    >
                      <span className="customer-home-icon-text-color">
                        <i
                          className="fa fa-user-circle-o"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span>My Profile</span>
                    </li>
                    {/* <li
                      className="customer-profile-test pointer"
                      onClick={() => navigateLink("dashboard")}
                    >
                      <span className="customer-home-icon-text-color">
                        <i className="fa fa-th-large" aria-hidden="true"></i>
                      </span>
                      <span>Dashboard</span>
                    </li> */}
                    <li
                      className="customer-profile-test pointer"
                      onClick={() => navigateLink("settings/pin-change-form")}
                    >
                      <span className="customer-home-icon-text-color">
                        <i className="fa fa-cog" aria-hidden="true"></i>
                      </span>
                      <span>Settings</span>
                    </li>
                    <li
                      className="customer-profile-test pointer"
                      onClick={handleLogoutNew}
                    >
                      <span className="customer-home-icon-text-color">
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                      </span>
                      <span>Logout</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const topButtons = [
    { index: "home", title: "Home" },
    // { index: "promos", title: "Promos" },
    // { index: "more", title: "More" },
    { index: "help", title: "Help" },
  ];

  const getActiveLink = (index) => {
    return getPage() === index ? "is-active" : "";
  };

  return (
    <div className="columns smart-head-nav-crop is-vcentered">
   
      <div className="column is-5 is-flex p-0">
          
      {isVisible && (
            <>
             
                <img src={CROP_C_IMG} alt="" 
                className="crop-heading-image pb-2 is-hidden-mobile"
                />
                </>
          )}

        <div className="icon crop-business-theme-more ml-5 mt-4">
          <div 
            onClick={() => toggleVisibility()}
            className="smart-header-icon-business"
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </div>
        </div>

      </div>

      <div className="column  is-hidden-mobile">
        <span className="icon-text">
          <span className="icon crop-business-theme-more is-hidden-mobile">
            {/* <i className="fa fa-address-book" aria-hidden="true"></i> */}
            <img src={CROP_TOP_LOGO} alt="Business Head Top Cent Logo" />
          </span>
          <span className="crop-bussiness-theme-title">{user?.euserid}</span>
        </span>
      </div>
      <div className="column mx-0 px-0 is-hidden-mobile">
        <span className="icon-text pointer">
          <span
            className="icon"
            onClick={() => navigateLink("my-account/statement-form")}
          >
            <img
              src={CROP_BALANCE}
              alt="Business Head Top Cent Logo"
            />
          </span>
          <span
            className="crop-bussiness-theme-title "
            onClick={() => navigateLink("my-account/statement-form")}
          >
            {user?.crop_balance}
          </span>
        </span>
      </div>
    
    

  
      <BusinessNotification
        noteData={user?.notification}
        loadProfileData={loadProfileData}
      />
      {HeaderProfile()}

     
    </div>
  );
};

export default BusinessHeader;
