import { useEffect, useState } from "react";
import { useSiteContext } from "../../../../contexts/SiteProvider";
// import { getImageContent } from "../../../../services/fileService";
// import { admin_sector_select } from "../../../../services/site/SelectBoxServices";
import AddComponentForm from "./AddComponentForm";
import AddVariantForm from "./AddVariantForm";
import { showYesOrNoAlert } from "../../../../services/notifyService";
import { SmartSoftCheckRadioSwitch } from "soft_digi";
import Variants from "./../ServiceSettings/Variants";

// assets/images/croplogo.png

const VariantsForm = ({
  variant_mode,
  default_in,
  discount,
  handleInputChange,
  data_in,
  price,
  priceApply,
}) => {
  const { openModal, closeModal, setLoading } = useSiteContext();
  //const [category, setCategory] = useState("");

  // const { id } = useParams();
  // const decodeId = id !== 0 ? decodeURIComponent(id) : 0;
  // const decrypted_id = id !== 0 ? decrypt_data(decodeId) : 0;
  // const navigate = useNavigate();
  // const [formData, setFormData] = useState({
  //     earn_available: "Yes",
  // });
  // const [formSubmit, setFormSubmit] = useState(false);
  // const [formErrors, setFormErrors] = useState(false);

  // const v_data = [
  //   {
  //     index: "size",
  //     name: "size",
  //     check: 1,
  //     children: [
  //       {
  //         index: "size_xs",
  //         name: "xs",
  //         check: 1,
  //         price: "10",
  //       },
  //       {
  //         index: "size_sm",
  //         name: "sm",
  //         check: 0,
  //         price: "10",
  //       },
  //     ],
  //   },
  // ];

  const [data, setData] = useState([]);

  const updateChildrenPrices = () => {
    const updatedData = data.map((parent) => {
      const updatedChildren = parent.children.map((child) => ({
        ...child,
        price: 0,
      }));
      return {
        ...parent,
        children: updatedChildren,
      };
    });
    setData(updatedData);
  };

  useEffect(() => {
    if (priceApply == "1") {
      updateChildrenPrices();
    }
    // console.log("price appy " , priceApply)
  }, [priceApply]);

  useEffect(() => {
    // console.log("data in ", data_in);
    if (data_in && Array.isArray(data_in) && data.length < 1) {
      setData(data_in);
    }
  }, [data_in]);

  useEffect(() => {
    handleInputChange("variants", data);
  }, [data]);

  const openComponentForm = (parent_index) => {
    const options = data.find((parent) => parent.index == parent_index);
    let modelObject = {
      body: (
        <AddComponentForm
          closeModal={closeModal}
          parent_index={parent_index}
          addComponentNew={addComponentNew}
          options={options?.children}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90 modal-card",
      bodyClose: true,
    };
    openModal(modelObject);
  };

  const openVariantForm = () => {
    const options = [...data];
    let modelObject = {
      body: (
        <AddVariantForm
          closeModal={closeModal}
          addVariantNew={addVariantNew}
          options={options}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90 modal-card",
      bodyClose: true,
    };
    openModal(modelObject);
  };

  const handle_redirection = (selection) => {
    if (selection === "yes") {
      const openVariantForm = () => {
        const options = [...data];
        let modelObject = {
          body: (
            <AddVariantForm
              closeModal={closeModal}
              addVariantNew={addVariantNew}
              options={options}
            />
          ),
          modelClass: "customer-model-layout smart-modal-90 modal-card",
          bodyClose: true,
        };
        openModal(modelObject);
      };
      return openVariantForm();
    } else {
    }
  };

  const openVariantOptionForm = () => {
    // showYesOrNoAlert("Do you want add Variants?", (selection) =>
    handle_redirection("yes");
    // );
  };

  const updateChildCheckFlags = (event, item) => {
    const parentIndex = item.index;
    const newCheckValue = event.target.checked ? 1 : 0;
    const newData = data.map((parent) => {
      if (parent.index === parentIndex) {
        const newChildren = parent.children.map((child) => ({
          ...child,
          check: newCheckValue,
        }));
        return {
          ...parent,
          check: newCheckValue,
          children: newChildren,
        };
      }
      return parent;
    });
    setData(newData);
  };

  const addVariantNew = (name) => {
    let _index = name.replace(/\s+/g, "");
    const newData = [...data];
    newData.push({
      index: _index,
      name: name,
      check: 0,
      price: "",
      children: [],
    });
    setData(newData);
  };

  const addComponentNew = (parent_index, name) => {
    // console.log("componenet eddition " , name)
    const newData = data.map((parent) => {
      if (parent.index === parent_index) {
        const newChildren = [...parent.children];
        let splitted_data = name.split(",");
        for (let i = 0; i < splitted_data.length; i++) {
          if (splitted_data[i] && splitted_data[i].trim().length > 0) {
            let children_index =
              parent_index + "_" + splitted_data[i].replace(/\s+/g, "");
            newChildren.push({
              index: children_index,
              name: splitted_data[i],
              check: 0,
              price: 0,
              children: [],
            });
          }
        }
        return {
          ...parent,
          children: newChildren,
        };
      }
      return parent;
    });
    setData(newData);
  };

  const handleInputPriceChange = (event, subitem) => {
    let parent_index = subitem.index.split("_")[0];
    let check_value = event.target.value;
    //check_value=5;
   // check_value = parseFloat(check_value);
    check_value = check_value
    .replace(/[^0-9.-]/g, '')         // Remove any character that is not a digit, dot, or hyphen
    .replace(/(\..*)\./g, '$1')       // Allow only one dot
    .replace(/(?!^)-/g, '');   


    const newData = data.map((parent) => {
      if (parent.index === parent_index) {
        const newChildren = parent.children.map((child) => {
          if (child.index === subitem.index) {
            return { ...child, price: check_value };
          }
          return child;
        });
        return {
          ...parent,
          children: newChildren,
        };
      }
      return parent;
    });
    setData(newData);
  };

  const handleCheckboxChange = (event, subitem) => {
    let parent_index = subitem.index.split("_")[0];
    const check_value = event.target.checked ? 1 : 0;
    const newData = data.map((parent) => {
      if (parent.index === parent_index) {
        const newChildren = parent.children.map((child) => {
          if (child.index === subitem.index) {
            return { ...child, check: check_value };
          }
          return child;
        });
        const allChecked = newChildren.every((child) => child.check === 1);
        const noneChecked = newChildren.every((child) => child.check === 0);
        let parentCheck = 2;
        if (allChecked) parentCheck = 1;
        else if (noneChecked) parentCheck = 0;
        return {
          ...parent,
          check: parentCheck,
          children: newChildren,
        };
      }
      return parent;
    });
    setData(newData);
  };
  const displayAmount = (totalPrice) => { 
    return Math.round(totalPrice * 100) / 100;
  };
  const totalPrice = (item_price) => {
    let price_test = !isNaN(parseFloat(price)) ? parseFloat(price) : 0;

    let item_price_test = !isNaN(parseFloat(item_price)) ? parseFloat(item_price) : 0;
    let item_discount_test = !isNaN(parseFloat(discount)) ? parseFloat(discount) : 0;


    let tot_price = parseFloat(price_test) + parseFloat(item_price_test);
    let discountPrice = (tot_price * item_discount_test) / 100;
    let total_price = tot_price - discountPrice;
    return displayAmount(total_price);
  };

  const singleChildDisplay = (subitem, subindex) => {
    return (
      <li key={subitem.index} className="smart-variant-single-li">
        <div className="columns  pr-1">
          <div className="column  is-4">
            <div class="field">
              <input
                class="is-checkradio"
                id={subitem.index}
                type="checkbox"
                name={subitem.index}
                checked={subitem.check == 1 ? true : false}
                onChange={(event) => handleCheckboxChange(event, subitem)}
              />
              <label for={subitem.index}>{subitem.name}</label>
            </div>
          </div>
          <div className="column is-2">
            {subitem.check == 1 && <p> A$ {price} + </p>}
          </div>
          <div className="column is-3">
            {subitem.check == 1 && (
              <input
                className="input"
                type="text"
                maxlength="6"
                value={subitem.price}
                placeholder="A$"
                onChange={(event) => handleInputPriceChange(event, subitem)}
                disabled={priceApply == "1" ? true : false}
              />
            )}
          </div>
          <div className="column is-3 ">
            {subitem.check == 1 && (
              <input
                className="input"
                type="text"
                disabled={true}
                value={totalPrice(subitem.price)}
              />
            )}
          </div>
        </div>
      </li>
    );
  };

  const singleParent = (item, index) => {
    return (
      <li key={index}>
        <div className="is-flex is-flex-direction-row is-justify-content-space-between p-1 mb-2">
          <div>
            <div class="field">
              <input
                class="is-checkradio"
                id={item.index}
                type="checkbox"
                name={item.index}
                checked={item.check == 1 ? true : false}
                onChange={(event) => updateChildCheckFlags(event, item)}
              />
              <label for={item.index}>{item.name}</label>
            </div>
          </div>
          <div>
            <button
              className="Add-button-opencomponentform"
              onClick={() => openComponentForm(item.index)}
            >
              <i className="fa fa-plus pointer" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <ul>
          {Array.isArray(item.children) &&
            item.children.map((subitem, subindex) => {
              return singleChildDisplay(subitem, subindex);
            })}
        </ul>
      </li>
    );
  };

  const create_variant = [
    { value: "1", label: "" },
    { value: "2", label: "" },
  ];
  return (
    <>
    
      <div>
        <div className="columns">
          <div className="column my-0 pb-0">
            <div className="is-flex">
              <div className="apply-variant-text ">Apply Variants </div>
              <div className="ml-5 mt-1">
                <SmartSoftCheckRadioSwitch
                  options={create_variant}
                  switchMode={true}
                  isRight={true}
                  inputProps={{ isRounded: true }}
                  name="switchone"
              
                  value={variant_mode}
                  onChange={(value) => handleInputChange("variant_mode", value)}
                />
              </div>
            </div>
          </div>
          {variant_mode === "1" && (
            <div className="column my-0 pb-0 has-text-right pr-4">
              <div
                onClick={() => openVariantOptionForm()}
                className=" add-variants-text  mt-3"
              >
                {/* <i
                     className="fa fa-plus ml-3 pointer"
                     aria-hidden="true"
                     onClick={() => openVariantOptionForm()}
                   ></i> */}
                   <span className="pointer">Add Variants</span>
                
              </div>
            </div>
          )}
        </div>
      </div>

      {variant_mode === "1" && (
        <div className="columns is-multiline">
          {data.map((item, index) => {
            return (
              <div className="column is-6 ">
                <div className="card card-variantsform-card p-2  ">
                  <ul className="smart-variant-add-form">
                    {singleParent(item, index)}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default VariantsForm;
