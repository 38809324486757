import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  // SmartSOftTextArea,
  SmartSoftButton,
  SmartSoftForm,
  SmartValid,
} from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { SERVICE_SETTINGS } from "../../../../api/Admin/OperationsUrl";
import { BUSINESS_SETTINGS } from "../../../../api/Business/OffersUrls";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { INTERROGATION_IMAGE } from "../../../../services/ImageService";
import {
  // ALLOW_ALPHABET,
  ALLOW_FLOAT,
  ALLOW_NUMERIC,
} from "../../../../services/PatternSerivce";
import {
  showAlertAutoClose,
  showNotification,
} from "../../../../services/notifyService";
import { admin_states_select } from "../../../../services/site/SelectBoxServices";
import { get, post } from "../../../../services/smartApiService";
import HolidayForm from "./HolidayForm";
import OperatinghoursAndCustomDays from "./OperatinghoursAndCustomDays";
import PickupAndDeliveryToolTip from "./PickupAndDeliveryToolTip";
import ReturnPolicy from "./ReturnPolicy";

const ServiceSettingsForm = () => {
  const navigate = useNavigate();
  // const baseNav = "/home";
  const navigateLink = (index) => {
    navigate();
  };

  const [formData, setFormData] = useState({});
  // const [CustomFormData, setCustomFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal, openModal, refreshUser, user } =
    useSiteContext();
  const [tab, setTab] = useState("pickup", "returnpolicy");
  // const [tabData, setTabData] = useState([]);
  const [state, setState] = useState(null);
  const [tableData, setTableData] = useState({});
  const [states, setStates] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  //Calculation

  const loadSettingsData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = {};
    let url = BUSINESS_SETTINGS.GET_ALL;
    const subscription = get(url, post_data, handleError).subscribe(
      (response) => {
        // console.log("response " , response.data);
        setFormData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadSettingsData();
  }, []);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "user_delivery_aud") {
      let total_props = parseFloat(user?.redeemFraction) * value;
      let user_delivery_crop_value = !isNaN(total_props) ? total_props : 0;
      setFormData((prev) => ({
        ...prev,
        user_delivery_crop_value: user_delivery_crop_value,
      }));
    }
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const ValidationPickup = {
    user_return_policy: [SmartValid.required("Return Policy is Required")],
  };

  // const handleValidation =()=>{
  //   debugger
  //   if (tab ==="pickup"){
  //     if (!ValidateFormNew(formData,formElements)) {
  //       return false;
  //     }
  //   }
  //   else {
  //     if (!ValidateFormNew(formData,policyElements)) {
  //       return false;
  //     }
  //   }
  // }

  //   const handleFormSubmitPolicy = () => {
  //     if (!ValidateFormNew(formData, policyElements)) {
  //       return false;
  //     }
  //     else
  //     handleFormSubmit()
  // }
  // const handleFormSubmitPickup = () =>{
  //   if (!ValidateFormNew(formData,formElements)) {
  //     return false;
  //   }
  //   else
  //   handleFormSubmit()
  // }

  const handleFormSubmit = () => {
    setFormSubmit(true);
    // handleValidation()

    if (tab === "pickup") {
      if (!(formData.user_pickup == 1 || formData.user_delivery == 1)) {
        showAlertAutoClose(
          "Can you please select Pickup or Delivery or Both",
          "error"
        );
        return false;
      }
      if (!ValidateFormNew(formData, [...formElements])) {
        return false;
      }
    } else {
      if (!ValidateFormNew(formData, [...policyElements])) {
        return false;
      }
    }

    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Updating...Please Wait");
    let url = BUSINESS_SETTINGS.INSERT;
    let _data = formData;
    if(formData.user_delivery && formData.user_delivery==1){

    }else{
      _data["user_delivery_aud"] = 0;
      _data["user_delivery_crop_value"]=0;

    }
    const subscription = post(url, _data, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose("Settings Saved Successfully", "success");
        setLoading(false);
        refreshUser();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    // console.log("form errors ", formErrors);
  };

  const Interrogation = () => {
    return (
      <div className="">
        Delivery Against CROPs
        <span
          className="ml-1 pointer"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ position: "relative" }}
        >
          <img src={INTERROGATION_IMAGE} alt="" />
          {showTooltip && (
            <div className="tooltip-container">
              <PickupAndDeliveryToolTip text=" Activating this feature would allow redemption of CROPs for delivery service." />
            </div>
          )}
        </span>
      </div>
    );
  };
  const FormValidations = {
    base_price: [SmartValid.requiredNumber("Base Price is Required")],
    bid_price: [SmartValid.requiredNumber("Delivery Charges is Required")],
    in_days: [SmartValid.required("Enter the Days")],
  };

  //Pickup/Delivery form
  const PICKUP_options = [{ value: "1", label: "Pickup" }];
  const DELIVRY_options = [{ value: "1", label: "Delivery" }];
  const DELIVRY_crop_options = [{ value: "1", label: "" }];

  const formElements = [
    {
      type: "CHECK_RADIO",
      name: "user_pickup",
      width: "12",
      element: {
        options: PICKUP_options,
        isRight: false,
      },
    },
    {
      type: "CHECK_RADIO",
      name: "user_delivery",
      width: "2",
      element: {
        options: DELIVRY_options,
        isRight: false,
      },
    },

    {
      type: "TEXT_BOX",
      width: "3",
      name: "user_delivery_aud",
      element: {
        inputType: "BORDER_LABEL",
        // placeHolder: "Delivery A$",
        // validations: FormValidations.bid_price,
        label: "Delivery Charges A$",
        pattern: ALLOW_FLOAT,
        isRequired: true,
        max: 3,
      },
      hideFunction: (value) => {
        //console.log("fomrdata " , formData);
        let user_delivery = formData?.user_delivery;
        // console.log("delivery ", delivery);
        return parseInt(user_delivery) === 1 ? false : true;
      },
    },
    {
      type: "CHECK_RADIO",
      name: "user_delivery_crop",
      width: "1",
      element: {
        options: DELIVRY_crop_options,
        isRight: false,
        isHorizontal: true,
        classList: ["Interrogation-lable-style delivery-option"],
      },
      hideFunction: (value) => {
        //console.log("fomrdata " , formData);
        let user_delivery = formData?.user_delivery;
        // console.log("delivery ", delivery);
        return parseInt(user_delivery) === 1 ? false : true;
      },
    },
    {
      type: "LABEL",
      width: "3",
      labelFunction: Interrogation,
    },
    {
      type: "TEXT_BOX",
      width: "3",
      name: "user_delivery_crop_value",
      element: {
        inputType: "BORDER_LABEL",
        label: "CROPs for Delivery",
        // placeHolder: "Delivery crops",
        pattern: ALLOW_FLOAT,
        inputProps: {
          disabled: true,
        },
      },
      hideFunction: (value) => {
        //console.log("fomrdata " , formData);
        let user_delivery = formData?.user_delivery_crop;
        // console.log("delivery ", delivery);
        return parseInt(user_delivery) === 1 ? false : true;
      },
    },
    // {
    //   type: "BUTTON",
    //   width: 12,
    //   leftIcon: "fa fa-download",
    //   element: {
    //     label: "SAVE",
    //     onClick: () => handleFormSubmit(),
    //   },
    // },
  ];

  //Returnpolicy form
  //const returnpolicy_options = [{ value: "1", label: "ReturnPolicy" }];

  const policyoptions = [
    { value: "1", label: "YES" },
    { value: "2", label: "NO" },
  ];

  const policyElements = [
    {
      type: "CHECK_RADIO",
      width: "6",
      name: "user_return_policy",
      isHorizontal: true,
      element: {
        options: policyoptions,
        isMulti: true,
        type: "radio",
        validations: FormValidations.in_days,
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "user_return_policy_days",
      element: {
        //placeHolder: "Numberof day",
        label: "Permitted Time (In Days)",
        inputType: "BORDER_LABEL_FOCUS",
        // validations: FormValidations.in_days,
        isRequired: true,
        pattern: ALLOW_NUMERIC,
      },
      hideFunction: (value) => {
        //console.log("fomrdata " , formData);
        let user_return_policy = formData?.user_return_policy;
        // console.log("delivery ", delivery);
        return parseInt(user_return_policy) === 1 ? false : true;
      },
    },

    // {
    //   type: "FILE",
    //   width: "6",
    //   name: "user_return_policy_doc",
    //   hideFunction: (value) => {
    //     let user_return_policy = formData?.user_return_policy;
    //     return parseInt(user_return_policy) === 1 ? false : true;
    //   },
    // },
  ];

  //holiday Table
  const deleteData = (id) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = post(
      SERVICE_SETTINGS.DELETE_HOLIDAY,
      { id: id },
      handleError
    ).subscribe((response) => {
      showAlertAutoClose("Updating...Please Wait", "success");
      closeModal();
      loadHolidaysData();
      // setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  const delete_buttons = [
    {
      label: "",
      leftIcon: "fa-trash",
      type: "icon",
      classList: ["smart-delete-icon"],
      onClick: (data) => {
        openDeleteModal(data["ID"]);
        //  viewEditData(data["ID"],"VIEW");
      },
    },
  ];
  const openDeleteModal = (id, name) => {
    let modelObject = {
      title: "Do you want to Delete?",
      body: "Note: The user will be deleted! Action cannot be reverted",
      okFunction: () => {
        deleteData(id);
      },
      cancelFunction: () => {
        closeModal();
        // console.log("cancel function")
      },
    };
    openModal(modelObject);
  };
  const columns = [
    { title: "S.NO", index: "sno", type: "sno" },
    {
      title: "Date ",
      index: "holiday_date",
      type: "date",
      dateFormat: "DD/MM/YYYY",
    },
    // { title: "OPERATING HOURS", index: "day_type" },
    { title: "Holiday Name", index: "holiday_name" },
    { title: "From", index: "start_time" },
    { title: "To", index: "end_time" },
    {
      title: "DELETE",
      index: "delete",
      type: "buttons",
      buttons: delete_buttons,
    },
  ];

  const openNewForm = (data = {}) => {
    let modalObject = {
      body: <HolidayForm loadHolidaysData={loadHolidaysData} dataIn={data} />,
      modalClass: "smart-modal smart-modal-50",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  //tab
  const tabs = [
    {
      index: "pickup",
      label: "Pickup & Delivery",
      icon: <i className="fa fa-bus" aria-hidden="true"></i>,
      link: "pickup",
    },
    {
      index: "returnpolicy",
      label: "Return Policy",
      icon: <i className="fa fa-retweet" aria-hidden="true"></i>,
      //link: "returnpolicy",
    },
    {
      index: "operatinghours",
      label: "Working Days",
      icon: <i className="fa fa-calendar-plus-o" aria-hidden="true"></i>,
      //link: "operatinghours",
    },
    {
      index: "holidays",
      label: "Holidays",
      icon: <i className="fa fa-calendar-minus-o" aria-hidden="true"></i>,
      //link: "holidays",
    },
  ];

  const loadHolidaysData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = {};
    let url = SERVICE_SETTINGS.GET_ALL_HOLIDAY;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        console.log("response ", response.data);
        setTableData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleTabs = (index) => {
    setTab(index);
    // console.log(index)
    if (index === "holidays") {
      admin_states_select((data) => setStates(data));
      loadHolidaysData();
    }
  };

  const tabs_display = () => {
    return (
      <div className="tabs mx-4  smart-business-tabs mt-4 ">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={
                  tab === item.index ? "order-active-tab" : "tabs-list-items"
                }
              >
                <a href="#" onClick={() => navigateLink(item.link)}>
                  <span
                    className={
                      tab === item.index ? "order-active-tab-text " : ""
                    }
                  >
                    {/* {item.icon} */}
                  </span>
                  <span
                    className={
                      tab === item.index ? "order-active-tab-text ml-3" : "ml-3"
                    }
                  >
                    {item.label}
                  </span>
                  <span className="icon is-small">{item.icon}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  //pickupdelivery
  const pickupDeliveryForm = () => {
    return (
      <>
        <div className=" smart-crop-business-forms ">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>
        <br />
        <div className="is-justify-content-end  is-flex">
          <SmartSoftButton
            label="Save"
            // leftIcon="fa fa-download"
            classList={["smart-crop-action-button"]}
            onClick={handleFormSubmit}
          />
        </div>
      </>
    );
  };

  //return Policy

  //operating Hours
  const operatinghoursForm = () => {
    return (
      <>
        <OperatinghoursAndCustomDays />
      </>
    );
  };

  return (
    <>
      <div className="service-settings-form">
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          {/* <span className="icon mt-1">
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span> */}
          {/* <span className="ml-5">Service Settings</span>
           */}
          <div className="pl-1">
            <SmartHeader
              title={"Manage Services"}
              sub_title={"Service Setting"}
            />
          </div>
        </p>

        {tabs_display()}
        <div className="p-5">
          {tab === "pickup" && pickupDeliveryForm()}
          {tab === "returnpolicy" && (
            <ReturnPolicy
              dataIn={formData}
              loadSettingsData={loadSettingsData}
            />
          )}
          {tab === "operatinghours" && operatinghoursForm()}
          {tab === "holidays" && <HolidayForm />}
        </div>
      </div>
    </>
  );
};

export default ServiceSettingsForm;
