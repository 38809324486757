import React, { useState } from 'react'
import { SmartSoftButton, SmartSoftForm, SmartValid } from 'soft_digi';
import { useSiteContext } from '../../../../contexts/SiteProvider';
import { post } from '../../../../services/smartApiService';
import { showAlertAutoClose } from '../../../../services/notifyService';
import { CUSTOMER_MY_ACCOUNT } from '../../../../api/Business/OffersUrls';
import { ValidateFormNew } from 'soft_digi/dist/services/smartValidationService';

export const DeleteAccount = () => {
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const [formData, setFormData] = useState({});
    const { setLoading,closeModal} = useSiteContext();
  
    const handleInputChange = (name, value) => {
      //console.log(" name ", name, "   value ", value)
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const handleErrorChange = (name, value) => {
      setFormErrors((prev) => {
        // Create a copy of the previous state
        const updatedFormData = { ...prev };
        // Check if the value is null or empty
        if (value === null || value === "") {
          // If true, remove the property from the updated state
          delete updatedFormData[name];
        } else {
          // Otherwise, update or add the property in the updated state
          updatedFormData[name] = value;
        }
  
        return updatedFormData;
      });
    };
  
    const handleFormSubmit = () => {
      // window.scrollTo(0, 0);
      // console.log("formdata", formData);
      setFormSubmit(true);
    
      // console.log("form errors " , formErrors);
      // if (!ValidateFormNew(formData,[...formElements,...Elements,...secForm])) {
      //    return false;
      //  }
      if (!ValidateFormNew(formData, formElements)) {
        return false;
      }
      // setFormSubmit(true);
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage, "error");
        setLoading(false);
      };
      setLoading(true, "Deleting....Please Wait");
      let url = CUSTOMER_MY_ACCOUNT.DELETE_ACCOUNT;
      // let data_in = { ...formData, ...formAddressData };
      const subscription = post(url, formData, handleError).subscribe(
        (response) => {
  
          showAlertAutoClose("Request Submitted successfully", "success");
          setLoading(false);
          // loadProfileData();
          setFormSubmit(false);
          closeModal()
        }
      );
      return () => {
        subscription.unsubscribe();
      };
      //console.log("form errors ", formErrors);
    };
    
    
    const Validations = {
      remark: [SmartValid.required("Remark is Required")],
    };
    const formElements = [
      {
        type: "TEXTAREA",
        width: "12",
        name: "remark",
        element: {
          label: "Remark",
          isRequired: true,
          validations: Validations.remark,
        },
      },
    ];
  
   
   

  return (
    <div>
    <div className="smart-customer-input">
      <div className="is-flex is-justify-content-space-between mr-3">
        <div className="is-size-4 has-text-weight-bold mb-4">
         Delete My Account?
        </div>
        <div className="mb-3" onClick={closeModal}>
          <div className="customer-modal-close mb-3 ">
            <i class="fa fa-times has-text-white" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
          onChange={(value) => handleInputChange("input_four", value)}
        />
            <div className="mt-6 is-flex is-justify-content-center">
        <SmartSoftButton
          label="Submit"
          classList={["smart-customer-primary-button"]}
          onClick={handleFormSubmit}
        />
        </div>
     
    </div>
    </div>
  )
}
