import { useState } from "react";
import { Link } from "react-router-dom";
import croplogo from "../../../assets/images/croplogo.png";

import { useSiteContext } from "../../../contexts/SiteProvider";
import {
  REGISTRATION_LOGO,
  SIGNUP_lOGO,
  VARIFICATION_LOGO
} from "../../../services/ImageService";
import { post } from "../../../services/smartApiService";
import "./../CropBussiness.css";

import {
  SmartSoftButton,
  SmartSoftForm,
  // SmartSoftInput,
  SmartValid,
} from "soft_digi";
import { BUSINESS_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../services/notifyService";
import BusinessRegistration from "./BusinessRegistration";
// import SmartPassWord from "../../../components/site/FormElements/SmartPassWord";
// import SmartMobileNumber from "../../../components/site/FormElements/SmartMobileNumber";
// import { isEmptyObject } from "../../../services/core/CommonService";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import {
  maskEmail,
  maskMobileNumber,
} from "../../../services/core/CommonService";
import PrivacyAndDataRightsNew from "../ImportantInformation/PrivacyAndDataRights/PrivacyAndDataRightsNew";

const BusinessSignUpNew = () => {
  // const [formData, setFormData] = useState({ sign_up_type: "1" });
  const [formData, setFormData] = useState({
    sign_up_type: "1",
    type: "BUSINESS",
    country_code: "AU",
  });
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [stage, setStage] = useState("FIRST");
  const { setLoading, openModal, closeModal } = useSiteContext();

  const resetData = () => {
    handleInputChange("mobile_no", "");
    handleInputChange("email_id", "");
    handleInputChange("password", "");
    handleInputChange("confirm_password", "");
  };

  const openNewForm = () => {
    let modelObject = {
      body: <PrivacyAndDataRightsNew header={false} closeModal={closeModal} />,
      modalClass: "crop-customer-modal smart-modal-80",
      bodyClose: true,
    };
    openModal(modelObject);
  };
  
  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name == "sign_up_type" || name == "country_code") {
      resetData();
    }
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const handleLogin = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Sending OTP....Please Wait");
    let url = BUSINESS_LOGIN_URLS.SEND_OTP;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        handleInputChange("otp_id", response.data.otp_id);
        setStage("SECOND");
        setFormSubmit(false);
        showAlertAutoClose(
          "OTP sent successfully",
          "success"
        );
        // showAlertAutoClose("", "OTP sent successfully... Please Enter OTP");
        // console.log("response " , response);
        // showNotification("success", "OTP sent successfully... Please Enter OTP");
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleVerify = () => {
    //setFormSubmit(true);
    setFormSubmit(true);
    if (!ValidateFormNew(formData, stage2_elements)) {
      return false;
    }

    const handleError = (errorMessage) => {
      handleInputChange("otp_value", "");
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };

    setLoading(true, "Verifying OTP.... Please Wait");
    // console.log("fdata ", formData);
    let url = BUSINESS_LOGIN_URLS.VERIFY_BUSINESS_DETAILS;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        //handleInputChange("otp_id",response.otp_id);
        setStage("THIRD");
        setFormSubmit(false);
        // console.log("response " , response);
        showAlertAutoClose(
          "Verification Successful",
          "success"
        );
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const leftImage = () => {
    return (
      <>
        <figure className="image is-centered is-hidden-mobile ">
          <img
            src={SIGNUP_lOGO}
            alt="Login Logo"
            className="image login-image has-background-red"
            style={{ maxWidth: "78%" }}
          />
        </figure>

        {/* <figure className="image is-centered is-hidden-mobile">
          <img
            src={VARIFICATION_LOGO}
            alt="Login Logo"
            className="image login-image-two"
            style={{ maxWidth: "65%" }}
          />
        </figure> */}

        {/* <figure className="image is-centered is-hidden-mobile">
          <img
            src={RESEND_LOGO}
            alt="Login Logo"
            className="image login-image"
            style={{ maxWidth: "65%" }}
          />
        </figure> */}

        {/* <figure className="image is-centered is-hidden-mobile">
          <img
            src={REGISTRATION_LOGO}
            alt="Login Logo"
            className="image login-image"
            style={{ maxWidth: "65%" }}
          />
        </figure> */}
      </>
    );
  };

  const leftLogo = () => {
    let image_src = SIGNUP_lOGO;
    if (stage === "SECOND") {
      image_src = VARIFICATION_LOGO;
    }
    if (stage === "THIRD") {
      image_src = REGISTRATION_LOGO;
    }
    return image_src;
  };

  const leftImagee = () => {
    let image_src = SIGNUP_lOGO;
    if (stage === "SECOND") {
      image_src = VARIFICATION_LOGO;
    }
    if (stage === "THIRD") {
      image_src = REGISTRATION_LOGO;
    }

    return (
      <>
        <figure className="image  is-hidden-mobile leftimage-registration-logo ">
          <img
            src={image_src}
            alt="Login Logo"
            className="image login-image"
            style={{ maxWidth: "85%" }}
          />
        </figure>
      </>
    );
  };

  const loginTitle = () => {
    return (
      <div className="mt-3 mb-5">
        <p className="has-text-centered is-size-4 crop-login-title mt-1 mb-3">
          {" "}
          Sign Up
        </p>
        <p className="has-text-centered mb-2 crop-login-desc inter-font-family">
          {/* Enter Your Credentials to Continue */}
          {/* Sign Up and Get Noticed! */}
          Sign Up and Get Noticed!
        </p>
      </div>
    );
  };

  const loginSignUp = () => {
    return (
      <div>
        <p className="has-text-centered mt-3 has-text-weight-medium">
          {/* Already have an account? */}
          Already a Member?
          <Link to="/" className="">
            <span className="sign-up-text Business-Login-Forget ml-2">
              Login
            </span>
          </Link>
        </p>
      </div>
    );
  };

  const loginLogo = () => {
    return (
      <div>
        <figure className="crop-image is-flex is-justify-content-center">
          <img className="crop-login-image" src={croplogo} alt="Crop Logo" />
        </figure>
      </div>
    );
  };

  const options = [
    { value: "1", label: "Mobile" },
    { value: "2", label: "Email" },
  ];
  // const loginFormValidations = {
  //   email: [SmartValid.required("Email Id is Required")],
  //   password: [SmartValid.required("Password is Required")],
  //   confirm_password: [SmartValid.required("Confirm Password is Required")],
  //   mobile_no: [
  //     SmartValid.required("Mobile Number is Required"),
  //     //  SmartValid.minLength("Number should be minimum 10 digit",9)
  //   ],
  // };
  const loginFormValidations = {
    email: [
      SmartValid.required("Email address is Required"),
      SmartValid.email("Please Enter a Valid Email Address"),
    ],
    otp: [
      SmartValid.required("Otp Required"),
      SmartValid.minLength("Minimum 6 Digit", 6),
    ],
    password: [
      SmartValid.required("Password is Required"),
      // SmartValid.minLength("Minimum 8 Digit", 8),
      // SmartValid.maxLength("Maximum 15 Digit", 15),
    ],
    cnf_password: [
      SmartValid.required("Confirm Password is Required"),
      // SmartValid.minLength("Minimum 8 Digit", 8),
      // SmartValid.maxLength("Maximum 15 Digit", 15),
      SmartValid.custom((value) => {
        return formData.password !== value
          ? "Password & Confirm Password are not Same"
          : "";
      }),
    ],
    mobile_no: [SmartValid.required("Mobile Number is Required")],
  };
  // const age_group_options = [
  //   { value: "1", label: "13-18 Years" },
  //   { value: "2", label: "19-25 Years" },
  //   { value: "3", label: "26-35 Years" },
  //   { value: "4", label: "36-45 Years" },
  //   { value: "5", label: "46-55 Years" },
  //   { value: "6", label: "over 55 Years" },
  // ];
  // const promo_options = [
  //   { value: "Yes", label: "Yes" },
  //   { value: "No", label: "No" },
  // ];
  const formElement = [
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Continue",
        classList: ["crop-login-action-button"],
        onClick: () => handleLogin(),
      },
    },
  ];
  const formElements = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "sign_up_type",
      element: {
        options: options,
        isMulti: true,
        type: "radio",
        classList: ["has-text-weight-semibold "],
         
        //validations:numericValidations
      },
    },
    {
      type: "MOBILE",
      width: "12",
      name: "mobile_no",
      codeName: "country_code",
      classList: "has-text-black",
      element: {
        pattern: ALLOW_NUMERIC,
        //validations:numericValidations
        value: formData?.mobile_no || "",
        codeSelected: formData?.country_code,
        countries: ["AU", "IN"],
        // placeHolder: "Enter Mobile Number",
        isRequired: true,
        label: "Enter Mobile Number",
        inputType: "BORDER_LABEL_FOCUS",

        rightIcon: "fa-mobile",
        validations: loginFormValidations.mobile_no,
                 autoComplete:"new-password"
      },
      hideFunction: (data) => {
        return formData?.sign_up_type !== "1" ? true : false;
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "email_id",
      element: {
        // placeHolder: "Enter email address",
        rightIcon: "fa fa-envelope-o",
        isRequired: true,
        label: "Enter Email ID",
        inputType: "BORDER_LABEL_FOCUS",
        validations: loginFormValidations.email,
                 autoComplete:"new-password"
      },
      hideFunction: (data) => {
        return formData?.sign_up_type === "1" ? true : false;
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "password",
      element: {
        // placeHolder: "Enter Password",
        inputType: "BORDER_LABEL_FOCUS",
        label: "Enter Password",
        isRequired: true,
        validations: loginFormValidations.password,
        passwordValidator: true,
                 autoComplete:"new-password"
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "confirm_password",
      element: {
        // placeHolder: "Re-enter Password",
        label: "Confirm Password",
        inputType: "BORDER_LABEL_FOCUS",
        isRequired: true,
        validations: loginFormValidations.cnf_password,
        passwordValidator: true,
                 autoComplete:"new-password"
      },
    },

    /*
    {
      type: "TEXT_BOX",
      width: "1",
      name: "country_code",     
    },
    {
      type: "TEXT_BOX",
      width: "11",
      name: "option_value",
      element: {
        placeHolder: formData?.sign_up_type == "1" ? "MOBILE NUMBER" : "EMAIL ID",
        rightIcon: formData?.sign_up_type == "1" ? "fa-user" : "fa-envelope-o",
        // validations:numericValidations
      },
    },*/
  ];
  // const secForm = [
  //   {
  //     type: "SELECT_BOX",
  //     width: "6",
  //     name: "age_group",
  //     element: {
  //       label: "Select Age Group",
  //       //  validations: numericValidations,
  //       inputType: "BORDER_LABEL",
  //       isRequired: true,
  //       options: age_group_options,
  //       // inputType:"BORDER_LABEL_FOCUS",
  //       // inputProps:{ isFocussed: true },
  //     },
  //   },
  //   {
  //     type: "DATE",
  //     width: "6",
  //     name: "age",
  //     element: {
  //       label: "Date of Birth (DD/MM)",
  //       //  validations: numericValidations,
  //       inputType: "BORDER_LABEL",
  //       isRequired: true,
  //       dateFormat: "dd/MM",
  //     },
  //   },
  //   {
  //     type: "CHECK_RADIO",
  //     width: "12",
  //     name: "promo_code_status",
  //     element: {
  //       label: "Do you have a promo code ?",
  //       isRequired: true,
  //       options: promo_options,
  //       type: "radio",
  //       isMulti: true,
  //       isHorizontal: true,
  //     },
  //   },
  //   {
  //     type: "TEXT_BOX",
  //     width: "6",
  //     name: "promo_code",
  //     element: {
  //       label: "Promo Code",
  //       // validations: numericValidations,
  //       inputType: "BORDER_LABEL",
  //       isRequired: true,
  //       // inputType:"BORDER_LABEL_FOCUS",
  //       // inputProps:{ isFocussed: true },
  //     },
  //     hideFunction: () => {
  //       let promo_code =
  //         formData?.promo_code_status !== undefined
  //           ? parseInt(formData.promo_code_status)
  //           : 0;
  //       return promo_code === 1 ? false : true;
  //     },
  //   },
  //   /*
  //     {
  //       type: "CHECK_RADIO",
  //       width: "12",
  //       name: "terms_conditions",
  //       element: {
  //         label: "I acknowledge and agree to CROP’s Terms ",
  //         isRequired: true,
  //         options:promo_options,
  //         type:"radio",
  //         isMulti:true,
  //         isHorizontal:true
  //       },
  //     },
  //    */
  // ];
  const formDisplay = () => {
    return (
      <div className="crop-business-login-form crop-business-form crop-business-forms-two">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
        <div className="mb-4">
          <p className="has-text-centered register-text">
            By clicking 'Continue' you agree to CROP
            {/* <span
              onClick={() => openNewForm()}
              className="ml-1  Business-Login-Forget sign-up-text"
            >
                Privacy Policy
              </span> */}
        <label onClick={() => openNewForm()} className="ml-1 sign-up-text pointer">
         {/* <Link to="/privacy-data-rights-new" className="ml-1 "> */}
          Privacy Policy
          {/* </Link> */}
            </label>
            
            <br />
            and confirm that you are a Business User.
          </p>
        </div>
        <div className="Business-signup-Next">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElement}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>
        {/* {formData?.sign_up_type === "1" ? (
          <SmartMobileNumber
            placeHolder="Mobile Number"
            rightIcon="fa-mobile"
            name="mobile_no"
            pattern={ALLOW_NUMERIC}
            max="10"
            handleInputChange={handleInputChange}
            errorEnable={formSubmit}
            validations={loginFormValidations.mobile_no}
            errorUpdate={(value) => handleErrorChange("euserid", value)}
          />
        ) : (
          <SmartSoftInput
            value={formData?.email_id || ""}
            onChange={(value) => handleInputChange("email_id", value)}
            rightIcon="fa-envolope"
            placeHolder="Email ID"
            errorUpdate={(value) => handleErrorChange("euserid", value)}
            errorEnable={formSubmit}
            validations={
              formData?.login_type === "1"
                ? loginFormValidations.cropid
                : loginFormValidations.email
            }
          />
        )} */}

        {/* <SmartPassWord
          key="password"
          name="password"
          value={formData?.password || ""}
          handleInputChange={handleInputChange}
          // label="Enter Password"
          placeHolder="Password"
          passwordValidator={true}
          validations={loginFormValidations.password}
          errorEnable={formSubmit}
          errorUpdate={(value) => handleErrorChange("password", value)}
        /> */}

        {/* <SmartPassWord
          key="cnfpassword"
          name="confirm_password"
          value={formData?.confirm_password || ""}
          handleInputChange={handleInputChange}
          placeHolder="Confirm Password"
          passwordValidator={true}
          validations={loginFormValidations.confirm_password}
          errorEnable={formSubmit}
          errorUpdate={(value) => handleErrorChange("confirm_password", value)}
        /> */}

        {/* <div className="">
          <SmartSoftButton
            label="Send OTP"
            classList={["is-rounded", "crop-login-action-button"]}
            onClick={() => handleLogin()}
            //  disabled={!isEmptyObject(formErrors)}
          />
        </div> */}
      </div>
    );
  };

  const stage1_forms = () => {
    return (
      <>
        {loginTitle()}
        {formDisplay()}
        {loginSignUp()}
      </>
    );
  };
  const getPlaceHolder = () => {
    return formData?.sign_up_type === "2"
      ? maskEmail(formData.email_id ?? "")
      : maskMobileNumber(formData.country_code ?? "", formData.mobile_no ?? "");
  };
  const stage2_elements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        inputProps: { disabled: true },
        placeHolder: getPlaceHolder(),
        rightIcon:
          formData?.sign_up_type == "2" ? "fa fa-envelope-o" : "fa fa-mobile",

        // validations:loginFormValidations.email,
      },
    },
    {
      type: "OTP",
      width: "12",
      name: "otp_value",
      element: {
        digits: 6,
        //  placeHolder: "6 Digit Otp",
        rightIcon: "fa-envelope-o",
        inputType: "BORDER_LABEL",
        // label: "OTP",
        pattern: ALLOW_NUMERIC,
        max: 6,
        validations: loginFormValidations.otp,
        resendOtpFunction: (callback) => {
          handleLogin(null);
          callback();
        },
        otpButtonText: (status, timer) => {
          return status ? "Resend Code (" + timer + " sec)" : "Resend Code";
        },
        validations: [
          SmartValid.required("OTP Required"),
          SmartValid.minLength("Minimum 6 Digit", 6),
        ],
      },
    },
  ];

  const business_verfication_form = () => {
    return (
      <div className="crop-business-login-form crop-business-form">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={stage2_elements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />

        <div className="">
          <SmartSoftButton
            label="Verify"
            classList={["is-rounded", "crop-login-action-button"]}
            onClick={() => handleVerify()}
            //  disabled={!isEmptyObject(formErrors)}
          />
        </div>
      </div>
    );
  };

  const stage2_forms = () => {
    return (
      <>
        <div className="mt-5 mb-6">
          <p className="has-text-centered is-size-4 crop-login-title mt-1 mb-3">
            {" "}
            {/* Verification */}
            Sign up
          </p>
          <p className="has-text-centered mb-2 crop-login-desc has-text-weight-semibold is-size-7">
            {" "}
            We have sent you a one time verification code on your{" "}
            {formData?.sign_up_type === "2" ? "Email" : "Mobile"}.
            <br />
            Please enter the code to authenticate and continue.
            <br />
            {/* The code will expire in 10 minutes. */}
            <br />
            {/* <div className="">The code will expire in 10 minutes. </div> */}
          </p>
        </div>
        {business_verfication_form()}
        {loginSignUp()}
      </>
    );
  };

  const loginFormNew = () => {
    return (
      <div className="card crop-business-login-box">
        <div className="crop-login-form-main">
          {loginLogo()}
          {stage === "FIRST" && stage1_forms()}
          {stage === "SECOND" && stage2_forms()}
          {stage === "Three" && stage2_forms()}
        </div>
        <div className="crop-business-login-top-box"></div>
        <div className="crop-business-login-bottom-box"></div>
        <div className="crop-business-login-left-box"></div>
      </div>
    );
  };

  const first = () => {
    return (
      <>
        <div className="business-sinup-left-img-bg-color is-flex ">
          <div
            key={"BusinessSignUpNew-column-one"}
            className="column  is-6  is-hidden-mobile"
          >
            {leftImagee()}
          </div>
          <div
            key={"BusinessSignUpNew-column-two"}
            className="column  m-0 p-0 is-6"
          >
            {loginFormNew()}
          </div>
        </div>
      </>
    );
  };

  const second = () => {
    return (
      <>
        <div className="background-color-yellow is-flex">
          <div
            key={"BusinessSignUpNew-column-one"}
            className="column is-6 has-text-centered second-business-signup-image is-hidden-mobile"
          >
            {/* {leftImagee()} */}
            <img
              className=""
              src={VARIFICATION_LOGO}
              alt=""
              style={{ maxWidth: "75%" }}
            />
          </div>
          <div
            key={"BusinessSignUpNew-column-two"}
            className="column is-6 m-0 p-0"
          >
            {loginFormNew()}
          </div>
        </div>
      </>
    );
  };

  const third_form = () => {
    return (
      <>
        <div className="is-flex third_form-background-color-green ">
          <div
            key={"BusinessSignUpNew-column-three"}
            className="column  is-6 is-hidden-mobile"
          >
            {leftImagee()}
          </div>
          <div
            key={"BusinessSignUpNew-column-four"}
            className="column is-6 m-0  p-0"
          >
            <div className="card crop-business-registration-box ">
              <div className="crop-login-form-main">
                {loginLogo()}
                <BusinessRegistration dataIn={formData} />
              </div>
              <div className="crop-business-login-top-box"></div>
              <div className="crop-business-login-bottom-box"></div>
              <div className="crop-business-login-left-box"></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={
          formData?.sign_up_type === "1"
            ? "container  is-fluid smart-crop-container  smart-business-login"
            : "container  is-fluid smart-crop-container-email  smart-business-login"
        }
      >
        <div
          key={"BusinessSignUpNew-columns-five"}
          className="columns is-gapless is-vcentered"
        >
          {stage === "FIRST" && first()}
          {stage === "SECOND" && second()}
          {stage === "THIRD" && third_form()}
        </div>
      </div>
    </>
  );

  // leftLogo
};

export default BusinessSignUpNew;
