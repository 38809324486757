import { useState } from "react";
import { SmartSoftButton, SmartSoftForm } from "soft_digi";
import {
  SMAIL_PIC_FIVE,
  SMAIL_PIC_FOUR,
  SMAIL_PIC_FullSTAR,
  SMAIL_PIC_ONE,
  SMAIL_PIC_THREE,
  SMAIL_PIC_TWO,
} from "../../../../services/ImageService";
import { showAlertAutoClose } from "../../../../services/notifyService";

import { useNavigate } from "react-router-dom";
import {
  SmartValid,
  ValidateFormNew,
} from "soft_digi/dist/services/smartValidationService";
import { FEEDBACK_URL } from "../../../../api/Services/ServiceUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { post } from "../../../../services/smartApiService";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";

const AppFeedbackForm = () => {
  // const numericValidations = [
  //   {
  //     type: "required",
  //     msg: "Numeric Value is Required",
  //   },
  //   {
  //     type: "pattern",
  //     msg: "Please Enter Correct Numeric Value",
  //     pattern: "[0-9]+",
  //   },
  // ];
  const [formData, setFormData] = useState({});
  const [feedbackValue, setFeedbackValue] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const navigate = useNavigate();
  // const [type, setType] = useState("password");
  const { setLoading } = useSiteContext();

  const baseNav = "/dashboard";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  // const handleFormSubmit = () => {
  //   // console.log("formdata", formData);
  //   setFormSubmit(true);
  //   // console.log("form errors ", formErrors);
  // };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  // const FeedbackFormValidations = {
  //   title: [SmartValid.required("Title is Required")],

  // };
  // const Validations = {
  //   status: [SmartValid.required("Feedback is Required")],
  // };
  const formElements = [
    {
      type: "TEXTAREA",
      width: "12",
      name: "feedback_remark",
      element: {
        // validations: Validations.status,
        // placeHolder: "Please share comments in support of your feedback",
        placeHolder:"Describe your experience here (Optional)"
        // isRequired: true,
      },
    },
  ];
  const get_feedback_value = (type) => {
    // console.log("HAI", type);
    setFeedbackValue(type);
  };
  const handleSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Submitting....Please Wait");
    // let url = MANAGE_OFFER_URLS.INSERT;
    let url = FEEDBACK_URL.FEEDBACK_INSERT;
    //formData.feedback_value = feedbackValue;
    // console.log(formData);
    // debugger;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setLoading(false);
        // console.log(response);
        handleInputChange("feedback_remark","")
        setFormSubmit(false);
        showAlertAutoClose("Feedback Submitted Successfully", "success");
        setFormData({ feedback_remark: "" });
        // setFeedbackValue("");
        setFormData({});

        setFormSubmit(false);
        navigateLink("");
        
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const AppFeedbackFormHeader = () => {
    return (
      <>
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          {/* <span className="icon mt-1">
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span> */}
          {/* <span className="ml-5 ">Feedback</span> */}
          <div className="pl-1">
            <SmartHeader title={"Feedback"} sub_title={"Rate Our Service"} />
          </div>
        </p>

        <div key={"AppFeedbackFormHeader-columns-one"} className="columns">
          <div key={"AppFeedbackFormHeader-column-one"} className="column">
            <div className="App-Feedback-Form-subheader-title is-flex is-justify-content-center mt-3 poppins-font-family">
              How would you Rate Our Service?
            </div>
          </div>
        </div>
      </>
    );
  };
  const items = [
    {
      rating: 1,
      img_src: SMAIL_PIC_ONE,
      title: "Poor",
    },
    {
      rating: 2,
      img_src: SMAIL_PIC_TWO,
      title: "Average",
    },
    {
      rating: 3,
      img_src: SMAIL_PIC_THREE,
      title: "Good",
    },
    {
      rating: 4,
      img_src: SMAIL_PIC_FOUR,
      title: "Very Good",
    },
    {
      rating: 5,
      img_src: SMAIL_PIC_FIVE,
      title: "Excellent",
    },
  ];
  const AppFeedbackFormimage = () => {
    return (
      <>
        <div key={"AppFeedbackFormHeader-columns-two"} className="columns">
          <div
            key={"AppFeedbackFormHeader-column-two"}
            className="column roboto-font-family is-flex is-justify-content-space-around has-text-centered AppFeedbackFormimage-responsive"
          >
            <div className="columns is-flex media-screen-feedback-columns">
              {items.map((obj, index) => {
                return (
                  <div className="column  media-screen-feedback  mt-4 mb-3" key={index}>
                    <img
                      src={obj?.img_src}
                      alt=""
                      // style={{ maxWidth: "89%" }}
                      className={
                        formData.feedback_value === obj.rating
                          ? "AppFeedbackFormimagehovering-active AppFeedbackFormimagehovering"
                          : ""
                      }
                      onClick={() =>
                        handleInputChange("feedback_value", obj.rating)
                      }
                    />
                    <div className="has-text-centered">{obj.title} </div>
                  </div>
                );
              })}
            </div>
            {/* <div className="column is-1"></div> */}
            {/* <div className="column ">
              <img
                src={SMAIL_PIC_ONE}
                alt=""
                className="AppFeedbackFormimagehovering"
                onClick={() => get_feedback_value("Poor")}
              />

              <div className="has-text-centered">Poor </div>
            </div>

            <div className="column ">
              <img
                src={SMAIL_PIC_TWO}
                alt=""
                className="AppFeedbackFormimagehovering"
                onClick={() => get_feedback_value("Average")}
              />

              <div className="has-text-centered">Average</div>
            </div>

            <div className="column ">
              {" "}
              <img
                src={SMAIL_PIC_THREE}
                alt=""
                className="AppFeedbackFormimagehovering"
                onClick={() => get_feedback_value("Good")}
              />
              <div className="has-text-centered">Good</div>
            </div>

            <div className="column ">
              <img
                src={SMAIL_PIC_FOUR}
                alt=""
                className="AppFeedbackFormimagehovering"
                onClick={() => get_feedback_value("Very Good")}
              />

              <div className="has-text-centered">Very Good</div>
            </div>

            <div className="column ">
              {" "}
              <img
                src={SMAIL_PIC_FIVE}
                alt=""
                className="AppFeedbackFormimagehovering"
                onClick={() => get_feedback_value("Excellent")}
              />
              <div className="has-text-centered">Excellent</div>
            </div> */}
            {/* <div className="column is-1"></div> */}
          </div>
        </div>
      </>
    );
  };

  const AppFeedbackFormtextbox = () => {
    return (
      <>
        <div key={"AppFeedbackFormHeader-columns-three"} className="columns">
          <div key={"AppFeedbackFormHeader-column-three"} className="column">
            <div className="AppFeedbackFormTextarea roboto-font-family smart-crop-business-forms">
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
            </div>
          </div>
        </div>
        <div key={"AppFeedbackFormHeader-columns-four"} className="columns">
          <div key={"AppFeedbackFormHeader-column-four"} className="column">
            <div className="is-flex is-justify-content-center ">
              <SmartSoftButton
                label="Submit"
                classList={["smart-crop-action-button"]}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
        <div key={"AppFeedbackFormHeader-columns-five"} className="columns">
          <div
            key={"AppFeedbackFormHeader-column-five"}
            className="column has-text-centered mr-2 App-Feedback-Form-smail-pic-fullstar"
          >
            <img src={SMAIL_PIC_FullSTAR} alt="" />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {AppFeedbackFormHeader()}
      {AppFeedbackFormimage()}
      {AppFeedbackFormtextbox()}
    </>
  );
};

export default AppFeedbackForm;
