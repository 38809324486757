import { SmartSoftTable } from "soft_digi";
import SmartHeader from "../../../components/site/FormElements/SmartHeader";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showNotification } from "../../../services/notifyService";
import { get, post } from "../../../services/smartApiService";
import { useEffect, useState } from "react";
import { RATTING_URLS } from "../../../api/Services/ServiceUrls";
import Comments from "./Comments";
import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";
import { MANAGE_OFFER_IMAGE_URLS } from "../../../api/Business/OffersUrls";

const RatingTable = () => {
  // const { setLoading } = useSiteContext();
  const [tabData, setTabData] = useState([]);
  const { setLoading, closeModal, openModal } = useSiteContext();

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = post(RATTING_URLS.GET_ALL_RATTING, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);
  const Ratings_Images = (data) => {
    return <div className=""></div>;
  };
  const Title_messages = (data) => {
    return <div className="">{data?.product}
</div>;
  };
  const Ratings_Ratings = (data) => {
    return (
      <div className="">
        <span className="">
          <i
            class="fa fa-star is-size-4 smart-rating-icon "
            aria-hidden="true"
          ></i>
        </span>
        <span className="ml-2">{data?.offer_ratings }</span>
      </div>
    );
  };
  const Ratings_like = (data) => {
    return (
      <div className="">
        <span className="">
          {" "}
          <i
            class="fa fa-thumbs-up is-size-4 smart-edit-icon"
            aria-hidden="true"
          ></i>
        </span>
        <span className="ml-2">{data.offer_likes}</span>
      </div>
    );
  };

  const openNewForm = (data) => {
    let modalObject = {
      body: (
        <Comments
          //setLoading={setLoading}
          closeModal={closeModal}
          data={data}
        />
      ),
      modalClass: "crop-admin-modal smart-modal-80",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const Ratings_View = (data) => {
    return (
      <div className="pointer" onClick={()=>openNewForm(data)}>
        <span className="RatingTable-comment-view">Comments</span>
        {/* <span className="ml-2 RatingTable-comment-view"> ({data?.commen_count})</span> */}
        
      </div>
    );
  };

  const offer_image = (data) => {
    let url = MANAGE_OFFER_IMAGE_URLS.GET_ONE_IMAGE_ID;
    let id = data.sd_offers_id !== undefined ? data.sd_offers_id : 0;
    return (
      <SmartImageDisplay
        srcType="URL"
        url={url}
        id={id}
        // classList={"image-creact-offer"}
        imageClass="is-32x32 "
      />
    );
  };
  const columns = [
    { title: "S.No.", index: "sno", type: "sno" },
    {
      title: "IMAGE",
      index: "imageline",
      valueFunction: offer_image,
      width: 10,
    },
    {
      title: "TITLE",
      index: "descriptionline",
      valueFunction: Title_messages,
      width: 20,
    },
    {
      title: "RATINGS",
      index: "ratingline",
      valueFunction: Ratings_Ratings,
      width: 20,
    },
    {
      title: "LIKES",
      index: "likeline",
      valueFunction: Ratings_like,
      width: 20,
    },
    { title: "VIEW", index: "viewline", valueFunction: Ratings_View },
  ];
  const data = [{ imageline: "" }];
  return (
    <div className="RatingTable">
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span>
        <span className="ml-5">Ratings</span> */}
          <div className="pl-1">
            <SmartHeader title={"More"} sub_title={"Ratings"} />
          </div>
      </p>
      <div className="columns">
      <div className="column mx-3">
        <SmartSoftTable
          columns={columns}
          data={tabData}
          tableProps={{ className: "crop-bussiness-table-layout" }}
        />
        </div>
      </div>
    </div>
  );
};

export default RatingTable;
