import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SmartSoftButton,
  SmartSoftDate,
  SmartSoftInput,
  SmartSoftTable
} from "soft_digi";
import { ALLOW_NUMERIC } from "../../../../services/PatternSerivce";

const MyCropsTable = () => {
  const navigate = useNavigate();
  const baseNav = "/my-account";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const [formData, setFormData] = useState({});
  // const [formSubmit, setFormSubmit] = useState(false);
  // const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // const handleErrorChange = (name, value) => {
  //   setFormErrors((prev) => {
  //     // Create a copy of the previous state
  //     const updatedFormData = { ...prev };
  //     // Check if the value is null or empty
  //     if (value === null || value === "") {
  //       // If true, remove the property from the updated state
  //       delete updatedFormData[name];
  //     } else {
  //       // Otherwise, update or add the property in the updated state
  //       updatedFormData[name] = value;
  //     }

  //     return updatedFormData;
  //   });
  // };

  const viewButton = [
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-eye",
      classList: ["smart-edit-icon"],
      onClick: (data) => {
        navigateLink("my-crops-view");
      },
    },
  ];

  const downloadButton = [
    {
      label: "Download",
      type: "",
      leftIcon: "",
      classList: ["smart-crop-action-button is-small"],
      onclick: (data) => {
        // console.log("data", data);
      },
    },
  ];

  const DebitFunction = (row) => {
    return (
      <>
        <div className="has-text-danger">{row["debit"] || ""}</div>
      </>
    );
  };

  const columns = [
    { title: "S.No", index: "sno", isSortable: true, type: "sno" },
    {
      title: "Date",
      index: "date",
      isSortable: true,
      type: "date",
      dateFormat: "DD-MM-YYYY",
    },
    { title: "Offer No", index: "offerno", isSortable: true },
    { title: "Customer Name", index: "customername", isSortable: true },
    { title: "Credit", index: "credit", isSortable: true },
    {
      title: "Debit",
      index: "debit",
      isSortable: true,
      valueFunction: DebitFunction,
    },
    { title: "Amount(A$)", index: "amount", isSortable: true },
    {
      title: "View",
      index: "view",
      isSortable: true,
      type: "buttons",
      buttons: viewButton,
    },
    {
      title: "Download",
      index: "download",
      isSortable: true,
      type: "buttons",
      buttons: downloadButton,
    },
  ];

  const data = [
    {
      sno: 1,
      date: "2022-09-25",
      offerno: "ICM82382346",
      customername: "Balaji",
      credit: 1000,
      debit: -500,
      amount: 2000,
    },
  ];

  // const Validations = {
  //   from_date: [SmartValid.required("From Date is Required")],
  //   to_date: [SmartValid.required("To Date is Required")],
  // };
  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span>
        <span className="ml-5">My CROPs Activity</span>
      </p>
      <div className="columns is-multiline  smart-crop-business-forms mt-5 mb-3 ml-4">
        <div key={"col-1"} className="is-3 mx-3">
        <SmartSoftDate
          placeHolder="dd-mm-yyyy"
          value={formData?.input || ""}
          onChange={(value) => handleInputChange("input", value)}
          rightIcon="fa fa-calendar"
          label="From"
          isHorizontal
          // validations={Validations.from_date}
        />
        </div>
        <div key={"col-2"} className="is-3 mx-3">
        <SmartSoftDate
          placeHolder="dd-mm-yyyy"
          value={formData?.input_four || ""}
          onChange={(value) => handleInputChange("input_four", value)}
          rightIcon="fa fa-calendar"
          label="To"
          isHorizontal
          // validations={Validations.to_date}
        />
        </div>
        <div key={"col-3"} className="is-3 mx-3 my-3"> <SmartSoftButton
          label="Submit"
          classList={["smart-crop-action-button"]}
        /></div>
        <div key={"col-5"} className="is-3 mx-3"> <SmartSoftInput
          type="text"
          rightIcon="fa fa-search"
          placeHolder="Search Order No"
          pattern={ALLOW_NUMERIC}
        /></div>
      </div>

     
      <div>
        <SmartSoftTable
          data={data}
          columns={columns}
          tableProps={{ className: "crop-bussiness-table-layout" }}
        />
      </div>
    </>
  );
};

export default MyCropsTable;
