import { useState } from 'react';
import SideNavComponenet from "./SideNavComponenet";
import { Link } from 'react-router-dom';
import { BUSINESS_FEEDBACK, BUSINESS_HELP, BUSINESS_HOME_IMAGE, BUSINESS_INFORMATION, BUSINESS_MORE, BUSINESS_SUPPORT, MANAGE_PROGRAM, MANAGE_SERVICE, MY_ACCOUNT, SERVICE_SETTINGS } from '../../services/ImageService';
const BusinessSideNav = ({page}) => {

    const [sidekey, setSideKey] = useState("1");

    const setKey = (index) => {
      //console.log("side index " , index)
       setSideKey(index);
    };

    
  const ManageServicesItems=[
    {
      id: 4,
      label: "Service Setting",
      icon: <i className="fa fa-cog" aria-hidden="true"></i>,
      link:"/manage-services/service-settings-form",
    },
    {
      id: 2,
      label: "Create Offer",
      icon: <i className="fa fa-pencil-square-o" aria-hidden="true"></i>,
      link:"/manage-services/create-offers-form/0"
    },
    {
      id: 1,
      label: "My Offers",
      icon: <i className="fa fa-gift" aria-hidden="true"></i>,
      link:"/manage-services/my-offer-list"
    },
    {
      id: 1,
      label: "Market Offer",
      icon: <i className="fa fa-shopping-basket" aria-hidden="true"></i>,
      link:"/manage-services/market-offer-form/0"
    },
    {
      id: 3,
      label: "My Bids",
      icon: <i className="fa fa-bitbucket" aria-hidden="true"></i>,
      link:"/manage-services/market-offers-list"
    },
 
  ]

  const ManageProgramItems=[

    {
      id: 1,
      label: "Customer Transactions",
      icon: <i className="fa fa-money" aria-hidden="true"></i>,
      link:"/manage-program/customer-transitions-scan"
    },

   
    {
      id: 2,
      label: "Blue Days",
      icon: <i className="fa fa-calendar-check-o" aria-hidden="true"></i>,
      link:"/manage-program/blue-days"
    },


    {
      id: 3,
      label: "Customer Orders",
      icon: <i className="fa fa-shopping-bag" aria-hidden="true"></i>,
      link:"/manage-program/orders"
    },

    {
      id: 4,
      label: "My Account",
      icon: <i className="fa fa-user" aria-hidden="true"></i>,
      link:"/my-account/statement-form"
    },
    {
      id: 5,
      label: "CROP Card",
      icon: <i className="fa fa-credit-card" aria-hidden="true"></i>,
      link:"/my-account/my-card-form-view"
    },
   

   
   
    // {
    //   id: 2,
    //   label: "Extend Bonus CROPs",
    //   icon: <i class="fa fa-american-sign-language-interpreting" aria-hidden="true"></i>,
    //   link:"/manage-program/extend-bonus-form"
    // },

    // {
    //   id: 3,
    //   label: "Slash Redemption CROPs",
    //   icon: <i class="fa fa-gift" aria-hidden="true"></i>,
    //   link:"/manage-program/slash-redemption-form"
    // },
    // {
    //   id: 4,
    //   label: "Happy Hours",
    //   icon: <i class="fa fa-clock-o" aria-hidden="true"></i>,
    //   link:"/manage-program/happy-hours-form"
    // },
    // {
    //   id: 5,
    //   label: "Other Services",
    //   icon: <i class="fa fa-cogs" aria-hidden="true"></i>,
    //   link:"/manage-program/other-services-form"
    // },
  ]

  const MyAccountItems=[
    // {
    //   id: 1,
    //   label: "My CROPs Activity",
    //   icon: <i className="fa fa-trash" aria-hidden="true"></i>,
    //   link:"/my-account/my-crops-list"
    // },
   
   
  ]

  // const SettingsItems=[
  //   {
  //     id: 1,
  //     label: "Password Change",
  //     icon: <i className="fa fa-key" aria-hidden="true"></i>,
  //     link:"/settings/pin-change-form"
  //   },
  //   {
  //     id: 2,
  //     label: "Communication Preference",
  //     icon: <i className="fa fa-pencil-square-o" aria-hidden="true"></i>,
  //     link:"/settings/communication-pro-form"
  //   },
   
  // ]

  const SupportItems=[
    {
      id: 1,
      label: "Contact Us",
      icon: <i className="fa fa-envelope" aria-hidden="true"></i>,
      link:"/help/contact-us-view"
    },
    {
      id: 2,
      label: "Process Missing CROPs",
      icon: <i className="fa fa-clock-o" aria-hidden="true"></i>,
      link:"/help/process-missing-crops-list"
    },
    {
      id: 3,
      label: "Request or Complaint",
      icon: <i className="fa fa-child" aria-hidden="true"></i>,
      link:"/help/request-form"
    },
   
  ]

  

    const InformationItems=[
      {
        id: 1,
        label: "Terms And Conditions",
        icon: <i className="fa fa-file-text-o" aria-hidden="true"></i>,
        link:"/help/terms-condition-new"
      },
      {
        id: 2,
        label: "Privacy And Data Rights",
        icon: <i className="fa fa-universal-access" aria-hidden="true"></i>,
        link:"/help/privacy-data-rights-new"
      },
      {
        id: 3,
        label: "App FAQs",
        icon: <i className="fa fa-question-circle-o" aria-hidden="true"></i>,
        link:"/help/faqs-new"
      },
     
     
    ]

    const FeedbackItems=[
      {
        id: 1,
        label: "Rate Our Service",
        icon: <i className="fa fa-comments" aria-hidden="true"></i>,
        link:"/help/app-feedback-form"
      },
     
      // {
      //   id: 3,
      //   label: "Complaint",
      //   icon: <i class="fa fa-users" aria-hidden="true"></i>,
      //   link:"/help/complaint-form"
      // },
     
    ]

    const MoreButtonItems=[
      // {
      //   id: 1,
      //   label: "My Promos",
      //   icon: <i className="fa fa-home" aria-hidden="true"></i>,
      //   link:"/promos/my-promos-list"
      // },
      {
        id: 2,
        label: "Ratings",
        icon: <i className="fa fa-star-o" aria-hidden="true"></i>,
        link:"/more/rating-list"
      },
      {
        id: 2,
        label: "About Us",
        icon: <i className="fa fa-users" aria-hidden="true"></i>,
        link:"/more-about-us"
      },
     
    ]
    
  return (
   <>
   <div className='main-side-nave-items mt-5'>
     {/** Home Button */}
     {page==="home" && 
     <>
         <SideNavComponenet key="11" 
        menuName={<Link to="/dashboard" className='home-color'>HOME</Link>} menuImage={BUSINESS_HOME_IMAGE} openNav={()=>setSideKey("1")} subMenu={false} />

        <SideNavComponenet key="1"  open={sidekey==="1" ? true : false} 
        menuName={"MANAGE SERVICES"} menuImage={MANAGE_SERVICE}  openNav={()=>setSideKey("1")} navs={ManageServicesItems}  />

        <SideNavComponenet key="2" open={sidekey=="2" ? true : false}  openNav={()=>setKey("2")}
        menuName={"MANAGE PROGRAM"} menuImage={MANAGE_PROGRAM} navs={ManageProgramItems} />

        {/* <SideNavComponenet key="3" open={sidekey==="3" ? true : false} openNav={()=>setKey("3")}
        menuName={"MY ACCOUNT"}  menuImage={MANAGE_SERVICE} navs={MyAccountItems} /> */}

       {/* <SideNavComponenet key="12" 
        menuName={"HELP"} menuImage={BUSINESS_HELP} openNav={()=>setSideKey("")} subMenu={false}  /> */}

          <SideNavComponenet key="6"  open={sidekey==="6" ? true : false} openNav={()=>setSideKey("6")}
        menuName={"HELP"} menuImage={BUSINESS_SUPPORT} navs={SupportItems} />

        <SideNavComponenet key="8"  open={sidekey==="8" ? true : false}  openNav={()=>setSideKey("8")}
        menuName={"Important Information"} menuImage={BUSINESS_INFORMATION} navs={InformationItems} />

       <SideNavComponenet key="7"  open={sidekey==="7" ? true : false} openNav={()=>setSideKey("7")}
        menuName={"Feedback"}  menuImage={BUSINESS_FEEDBACK} navs={FeedbackItems} />

       {/* <SideNavComponenet key="12" 
        menuName={<Link to="/promos/rated-offers-list" className='home-color'>MORE</Link> }  menuIcon={"fa fa-chevron-circle-down"}
        openNav={()=>setSideKey("1")} subMenu={false} /> */}

        <SideNavComponenet key="14" openNav={()=>setSideKey("14")}
        menuName={"MORE"} menuImage={BUSINESS_MORE}  navs={MoreButtonItems}  subMenu={true} />


     </>
     }
     {/* {page==="more" && 
        <>
        <SideNavComponenet key="5" open={sidekey==="5" ? true : false} openNav={()=>setSideKey("5")}
        menuName={"More"} navs={MoreButtonItems} />
        </>
     } */}
     {/* {page==="help" && 
         <>
        <SideNavComponenet key="6"  open={sidekey==="6" ? true : false} openNav={()=>setSideKey("6")}
        menuName={"Support"} navs={SupportItems} />
        <SideNavComponenet key="7"  open={sidekey==="7" ? true : false} openNav={()=>setSideKey("7")}
        menuName={"Feedback"} navs={FeedbackItems} />
        <SideNavComponenet key="8"  open={sidekey==="8" ? true : false}  openNav={()=>setSideKey("8")}
        menuName={"Important Information"} navs={InformationItems} />
      </>
     } */}
   </div>
   </>
  )
}

export default BusinessSideNav

