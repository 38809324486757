import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { SmartSoftButton, SmartSoftCheckRadioSwitch, SmartSoftDate, SmartSoftForm } from 'soft_digi';
import { ALLOW_NUMERIC } from '../../../../services/PatternSerivce';

const SlashRedemptionForm = () => {

  const navigate = useNavigate();
  const baseNav = "/home";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  
  // const options = [
  //   { value: '1', label: 'Days' },
  // ];
  const Days = [
    { value: '1', label: 'All' },
    { value: '2', label: 'Sun' },
    { value: '3', label: 'Men' },
    { value: '4', label: 'Tue' },
    { value: '5', label: 'Wed' },
    { value: '6', label: 'Thu' },
    { value: '7', label: 'Fri' },
    { value: '8', label: 'Set' },
  ]
  // const Bonus_a = [
  //   { value: '2', label: 'BonusCROPs' },
  // ];
  const BonusCROPs = [
    { value: '1', label: 'All' },
    { value: '2', label: 'Redeem Product ' },
    { value: '3', label: 'Title' },
    { value: '4', label: 'Monkey Doll' },
    { value: '5', label: 'Toys3' },
    { value: '6', label: 'Title' },
  ]
  // const Bonus_b = [
  //   { value: '3', label: 'BonusCRO' },
  // ];
  const BonusCRO = [
    { value: '1', label: 'Title ' },
    { value: '2', label: 'Test ' },
    { value: '3', label: 'New Product' },
    { value: '4', label: 'Pearl' },
    { value: '5', label: 'Toys4' },
    { value: '6', label: 'Alphonsa Mango' },
  ]
  // const Bonus_c = [
  //   { value: '4', label: 'BonusCR' },
  // ];
  const BonusCR = [
    { value: '1', label: 'Bat ' },
    { value: '2', label: 'Glows' },
    { value: '3', label: 'Pad' },
    { value: '4', label: 'Prdl' },
    { value: '5', label: 'Newones' },
    { value: '6', label: 'Promo Product' },

  ]
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const [formData, setFormData] = useState({});
  const [formSubmit] = useState(false);
  const [setFormErrors] = useState(false);
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const numericValidations = [
    {
      type: "required",
      msg: "Numeric Value is Required",
    },
    {
      type: "pattern",
      msg: "Please Enter Correct Numeric Value",
      pattern: "[0-9]+",
    },
  ];
  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "input_one",
      element: { placeHolder: '100', validations: numericValidations }
    },
  ]
  return (
    <div className="SlashRedemptionForm">
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        <span className="icon mt-1" onClick={() => navigateLink("home-view")}>
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span>
        <span className="ml-5">Slash Redemption CROPs</span>
      </p>

      <div key={"SlashRedemptionForm-columns-one"} className="columns">
        <div
          key={"SlashRedemptionForm-column-one"}
          className="column is-flex is-justify-content-end is-6"
        >
          <div className=" has-text-right is-flex">
            <SmartSoftDate
              key="text-4"
              label="From Date"
              value={formData?.input_four || ""}
              onChange={(value) => handleInputChange("input_four", value)}
              rightIcon="fa fa-calendar"
              placeHolder="dd/mm/yyyy"
              isHorizontal={true}
              inputProps={{ isFocussed: true }}
            />
          </div>
        </div>
        <div
          key={"SlashRedemptionForm-column-two"}
          className="column is-5 is-flex is-justify-content-start"
        >
          <SmartSoftDate
            key="text-4"
            label="From Date"
            value={formData?.input_four || ""}
            onChange={(value) => handleInputChange("input_four", value)}
            rightIcon="fa fa-calendar"
            placeHolder="dd/mm/yyyy"
            isHorizontal={true}
            inputProps={{ isFocussed: true }}
          />
        </div>
      </div>

      <div
        key={"SlashRedemptionForm-column-three"}
        className="column is-flex is-justify-content-center"
      >
        <span className="ml-6">Days</span>
        <span className="ml-6 pl-6">
          <SmartSoftCheckRadioSwitch
            isMulti={true}
            options={Days}
            name="checkboxone"
            value={formData?.input_day || null}
            onChange={(value) => handleInputChange("input_day", value)}
          />
        </span>
      </div>

      <div key={"SlashRedemptionForm-columns-two"} className="columns">
        <div className="column ml-6 is-6 is-flex">
          <span className="mr-4 mt-2">Slash Redemption CROPs %</span>
          <span>
            {" "}
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElements}
              formSubmit={formSubmit}
              pattern={ALLOW_NUMERIC}
              handleErrorChange={handleErrorChange}
            />
          </span>
        </div>
      </div>

      <span className="ml-6">Slash Redemption CROPs Applied to</span>
      <div
        key={"SlashRedemptionForm-column-four"}
        className="column is-flex is-justify-content-center"
      >
        <span className="ml-6"></span>
        <span className="ml-6 pl-6">
          <SmartSoftCheckRadioSwitch
            isMulti={true}
            options={BonusCROPs}
            name="checkboxBonusCROPs"
            value={formData?.input_Bonus || null}
            onChange={(value) => handleInputChange("input_Bonus", value)}
          />
        </span>
      </div>
      <div
        key={"SlashRedemptionForm-column-five"}
        className="column is-flex is-justify-content-center"
      >
        <span className="ml-6"></span>
        <span className="ml-6 pl-6">
          <SmartSoftCheckRadioSwitch
            isMulti={true}
            options={BonusCRO}
            name="checkboxBonusCRO"
            value={formData?.input_b_two || null}
            onChange={(value) => handleInputChange("input_b_two", value)}
          />
        </span>
      </div>
      <div
        key={"SlashRedemptionForm-column-six"}
        className="column is-flex is-justify-content-center"
      >
        <span className="ml-6"></span>
        <span className="ml-6 pl-6">
          <SmartSoftCheckRadioSwitch
            isMulti={true}
            options={BonusCR}
            name="checkboxBonusCR"
            value={formData?.input_c_three || null}
            onChange={(value) => handleInputChange("input_c_three", value)}
          />
        </span>
      </div>
      <div key={"SlashRedemptionForm-columns-three"} className="columns">
        <div key={"SlashRedemptionForm-column-seven"} className="column ml-6">
          <span>Slash Redemption CRPs not Applied to</span>
        </div>
      </div>
      <div key={"SlashRedemptionForm-columns-four"} className="columns">
        <div key={"SlashRedemptionForm-column-eight"} className="column ml-6">
          <span>Slash Redemption Applied for all Products</span>
        </div>
      </div>
      <div key={"SlashRedemptionForm-columns-five"} className="columns">
        <div key={"SlashRedemptionForm-column-nine"} className="column ml-6">
          <SmartSoftButton
            label="Submit"
            classList={["smart-crop-action-button "]}
          />
        </div>
      </div>
    </div>
  );
}

export default SlashRedemptionForm