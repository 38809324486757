import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftButton } from "soft_digi";

const OfferCropsForm = () => {
  const navigate = useNavigate();
  const baseNav = "/home";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const [formData] = useState({});
  const [setFormSubmit] = useState(false);
  const [formErrors] = useState(false);

  const handleFormSubmit = () => {
    // console.log("formdata", formData);
    setFormSubmit(true);
    // console.log("form errors ", formErrors);
  };

  return (
    <>
      <div className="container">
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          <span className="icon mt-1" onClick={() => navigateLink("home-view")}>
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span>
          <span className="ml-5">Offer CROPs</span>
        </p>

        <div className="is-flex is-justify-content-center">
          <SmartSoftButton
            label="Scan"
            onClick={() => handleFormSubmit()}
            classList={["m-6", "smart-crop-action-button"]}
            // disabled={!isEmptyObject(formErrors)}
          />
        </div>
      </div>
    </>
  );
};

export default OfferCropsForm;
