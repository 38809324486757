import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  // SmartSoftCheckRadioSwitch,
  SmartSofFile,
  SmartSoftButton,
  SmartSoftForm,
  // SmartSOftTextArea,
  SmartValid,
} from "soft_digi";
import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  showAlertAutoClose,
  showNotification,
  showYesOrNoAlert,
} from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
// import { getImageContent } from "../../../../services/fileService";
// import { admin_sector_select } from "../../../../services/site/SelectBoxServices";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import SmartImageDisplay from "../../../../components/site/FormElements/SmartImageDisplay";
import SmartImageEditor from "../../../../components/site/FormElements/SmartImageEditor";
import {
  ALLOW_ALPHABET_SPACE,
  ALLOW_FLOAT,
  ALLOW_FLOAT_NEG,
} from "../../../../services/PatternSerivce";
import {
  decrypt_data,
  encrypt_data,
} from "../../../../services/sessionService";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import VariantsForm from "./VariantsForm";
import { INTERROGATION_IMAGE } from "../../../../services/ImageService";
import PickupAndDeliveryToolTip from "../ServiceSettings/PickupAndDeliveryToolTip";
import ImageCarousel from "../../../../components/site/ImageCarousel";

// assets/images/croplogo.png

const CreateOffersForm = () => {
  const { id } = useParams();
  const decodeId = id !== 0 ? decodeURIComponent(id) : 0;
  const decrypted_id = id !== 0 ? decrypt_data(decodeId) : 0;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    earn_available: "Yes",
  });
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, user, closeModal, openModal, refreshUser } =
    useSiteContext();
  const [showTooltip, setShowTooltip] = useState(false);

  const variants = ["XS", "S", "M", "XS", "S", "M"];

  const baseNav = "/manage-services";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  const resetData = () => {
    handleInputChange("offer_title", "");
    handleInputChange("brand", "");
    handleInputChange("price", "");
    handleInputChange("discount", "");
    handleInputChange("total_price", "");
    handleInputChange("gst_value", "");
    handleInputChange("offer_breif", "");
    handleInputChange("offer_desc", "");
    handleInputChange("earn_available", "Yes");
    handleInputChange("earn_crop_points", "");
    handleInputChange("redeem_available", "");
    handleInputChange("redeem_crop_points", "");
    handleInputChange("brand_logo", "");
    handleInputChange("variants", user.variants);
  };
  const loadSingleData = () => {
    setLoading(true, "Loading.... Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    let post_data = { id: decrypted_id };
    const subscription = post(
      MANAGE_OFFER_URLS.GET_ONE,
      post_data,
      handleError
    ).subscribe((response) => {
      response.data.brand_logo = [];
      for (let j = 0; j < response.data.images.length; j++) {
        let image = response.data.images[j];
        image.content = image.image_content;
        response.data.brand_logo.push(image);
      }
      if (response.data.variant_mode !== undefined) {
        response.data.variant_mode = "" + response.data.variant_mode;
      }
      setFormData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    // console.log("user " , user.variants);
    if (user.pickUpDelivery == 1) {
      showAlertAutoClose(
        "Please Fill Service Settings...Before Creating Offer ",
        "info"
      );
      navigate(baseNav + "/service-settings-form");
    }
    if (decrypted_id) {
      loadSingleData();
    } else {
      //resetData();
      setFormData({ earn_available: "Yes" });
      //console.log("console called ");
      handleInputChange("variants", user.variants);
      // console.log("formdata ", formData);
    }
  }, [id]);

  // useEffect(() => {
  //   // resetData();
  // }, [id]);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name == "brand_logo" && value) {
      const lastIndex = value.length - 1;
      // openImageModal(value[lastIndex],lastIndex);
    }
  };

  const openImageModalOnEdit = (value) => {
    if (value) {
      const lastIndex = value.length - 1;
      openImageModal(value[lastIndex], lastIndex);
    }
  };

  const setImage = (image_content, imageData, index) => {
    let name = "brand_logo";
    imageData.content = image_content.split(",")[1];
    const existing_data = formData.brand_logo ? [...formData.brand_logo] : [];
    existing_data[index] = imageData;
    setFormData((prev) => ({ ...prev, [name]: existing_data }));
  };

  const imageEditClick = (index) => {
    const existing_data = formData.brand_logo ? [...formData.brand_logo] : [];
    openImageModal({ ...existing_data[index] }, index);
  };

  const openImageModal = (imageData, index) => {
    let modelObject = {
      body: (
        <SmartImageEditor
          src={imageData.content}
          setImage={(image) => setImage(image, imageData, index)}
          closeModal={closeModal}
          enableOthers={true}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  // const optionsSelector = [{ value: "1", label: "Apply For Earn Points" }];
  const optionsRedeem = [{ value: "Yes", label: "" }];
  const optionsEarn = [{ value: "Yes", label: "" }];

  // const numericValidations = [
  //   // {
  //   //   type: "required",
  //   //   msg: "Numeric Value is Required",
  //   // },
  //   {
  //     type: "pattern",
  //     msg: "Please Enter Correct Numeric Value",
  //     pattern: "[0-9]+",
  //   },
  // ];

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const Interrogation = () => {
    return (
      <div className="is-flex">
        <div className="ml-2 has-text-weight-medium">
          Apply Price to all Variants
        </div>
        <div
          className="ml-1 pointer mt-1"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ position: "relative" }}
        >
          <img src={INTERROGATION_IMAGE} alt="" />
          {showTooltip && (
            <div className="tooltip-container">
              <PickupAndDeliveryToolTip
                text="A check in the box here will apply the 
              set price across all selected variants"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const FormValidations = {
    upload: [SmartValid.required("Image is Required")],
    title: [SmartValid.required("Title is Required")],
    brand: [SmartValid.required("Brand is Required")],
    price: [SmartValid.requiredNumber("Price is Required")],
    total: [SmartValid.requiredNumber("Total is Required")],
    gst: [SmartValid.required("GST is Required")],
    // describe: [SmartValid.required("Description is Required")],
  };

  const totalPrice = () => {
    if (formData.discount) {
      let discountPrice = (formData.price * formData.discount) / 100;
      let total_price = formData.price - discountPrice;
      if (isNaN(total_price)) {
        total_price = 0;
      }
      let total_discount = Number(total_price).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return total_discount;
    } else {
      let total_price = Number(formData.price).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return !isNaN(total_price) ? total_price : 0;
    }
  };

  const totalPriceOffer = () => {
    let offer_price = !isNaN(parseFloat(formData.price))
      ? parseFloat(formData.price)
      : 0;
    let discount_percentage = !isNaN(parseFloat(formData.discount))
      ? parseFloat(formData.discount)
      : 0;
    let discountPrice = offer_price * (discount_percentage / 100);
    return offer_price - discountPrice;
  };

  const earnCrops = () => {
    let total_cost= parseFloat(totalPriceOffer())
    return parseInt(total_cost * user?.earnFraction);
  };

  const redeemCrops = () => {
    let total_cost= parseFloat(totalPriceOffer())
    return parseInt(total_cost * user?.redeemFraction)
  };
  


  // const earnCrops = () => {
  //   let total_cost = parseFloat(totalPrice());
  //   return parseInt(total_cost * user?.earnFraction);
  // };

  // const redeemCrops = () => {
  //   let total_cost = parseFloat(totalPrice());
  //   return parseInt(total_cost * user?.redeemFraction);
  // };

  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "offer_title",
      element: {
        validations: FormValidations.title,
        // inputType: "BORDER_LABEL",
        label: "Offer Title",
        isRequired: true,
        // rightIcon: "fa-edit",
        placeHolder: "Enter Offer Title",
        max: 255,
        // pattern: "[A-Za-z0-9 ]*",
      },
    },

    {
      type: "TEXT_BOX",
      width: "12",
      name: "brand",
      element: {
        // validations: FormValidations.brand,
        // inputType: "BORDER_LABEL",
        label: "Brand",
        placeHolder: "Enter Brand Name",
        // rightIcon: "fa-edit",
        // isRequired: true,
        // pattern: "[A-Za-z0-9 ]*",
        //  pattern:"[A-Za-z0-9]*",
        max: 255,
      },
    },
  ];

  const variantoptions = [
    { value: "1", label: "YES" },
    { value: "2", label: "NO" },
  ];

  const formElementsOne = [
    {
      type: "TEXT_BOX",
      width: "3",
      name: "price",
      element: {
        validations: FormValidations.price,
        // inputType: "BORDER_LABEL",
        label: "Price (A$)",
        pattern: ALLOW_FLOAT,
        placeHolder: "Enter Price",
        isRequired: true,
        max: 8,
        // rightIcon: "fa-edit",
      },
    },
    {
      type: "TEXT_BOX",
      width: "3",
      name: "discount",
      element: {
        // inputType: "BORDER_LABEL",
        label: "Discount (%)",
        pattern: ALLOW_FLOAT,
        placeHolder: "Enter Discount % ",
        max: 4,
        // rightIcon: "fa-edit",
      },
    },

    {
      type: "TEXT_BOX",
      width: "3",
      name: "total_price",
      element: {
        // inputType: "BORDER_LABEL",
        label: "Net Amount",
        pattern: ALLOW_FLOAT,
        valueFunction: () => {
          return "" + totalPrice();
        },
        inputProps: {
          disabled: true,
        },
      },
    },
    {
      type: "TEXT_BOX",
      width: "3",
      name: "gst_value",
      element: {
        // inputType: "BORDER_LABEL_FOCUS",
        validations: FormValidations.gst,
        label: "GST (%)",
        pattern: ALLOW_FLOAT,
        isRequired: true,
        max: 4,
      },
    },
    {
      type: "CHECK_RADIO",
      width: "0",
      name: "apply_price",
      element: {
        // label: "Closed",
        isHorizontal: true,
        classList: ["smart-variant-add-form-two"],
        // switchMode:true,
        //type:"switch",
        options: [{ value: "1", label: "" }],
      },
    },
    {
      type: "LABEL",
      width: "3",
      labelFunction: Interrogation,
    },
    // {
    //   type: "CHECK_RADIO",
    //   width: "12",
    //   name: "user_return_policy",
    //   isHorizontal: true,
    //   element: {
    //     label:<p><button>+</button>
    //        <label>Apply Variants </label></p>,
    //     options: variantoptions,
    //     isMulti: true,
    //     type: "radio",
    //    // validations: FormValidations.return_policy,
    //     isHorizontal:true
    //   },
    // },

    // {
    //   type: "CHECK_RADIO",
    //   width: "5",
    //   name: "apply_discount",
    //   element: {
    //     // label: "Closed",
    //     isHorizontal: true,
    //     // switchMode:true,
    //     //type:"switch",
    //     options: [{ value: "1", label: "Apply Discount to all Variants" }],
    //   },
    // },
    {
      type: "LABEL",
      width: "12",
      labelFunction: () => {
        return (
          formData && (
            <VariantsForm
              data_in={formData.variants ? formData.variants : {}}
              default_in={user?.variants}
              discount={formData.discount}
              variant_mode={formData.variant_mode}
              handleInputChange={handleInputChange}
              price={formData.price}
              priceApply={formData.apply_price}
            />
          )
        );
      },
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "offer_breif",
      element: {
        validations: FormValidations.brief,
        // inputType: "BORDER_LABEL",
        label: "Brief Description",
        placeHolder: "Brief Description (120 Characters)",
        rows: 3,
        // isRequired: true,
        // label: "Brief Description",
        // pattern: ALLOW_ALPHABET,
        max: 120,
      },
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "offer_desc",
      element: {
        // inputType: "BORDER_LABEL",
        label: "Detailed Description",
        validations: FormValidations.describe,
        placeHolder: "Detailed Description (300 Characters)",
        // isRequired: true,
        rows: 5,
        max: 300,
      },
    },

    {
      type: "CHECK_RADIO",
      width: "6",
      name: "earn_available",
      element: {
        options: optionsEarn,
        label: "Apply for Earn CROPs",
        isHorizontal: true,
        classList: [
          "smart-input-label-width-60",
          "general-label",
          "smart-variant-add-form-two",
        ],
        isRight: true,
        inputProps: {
          disabled: true,
        },
      },
    },

    {
      type: "CHECK_RADIO",
      width: "4",
      name: "redeem_available",
      element: {
        options: optionsRedeem,
        label: "Apply for Redeem CROPs",
        isHorizontal: true,
        classList: [
          "smart-input-label-width-60",
          "general-label",
          "smart-variant-add-form-two",
        ],
        isRight: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "earn_crop_points",
      element: {
        // placeHolder: "Earn Points",
        pattern: ALLOW_FLOAT,
        // inputType: "BORDER_LABEL",
        label: "Earn CROPs",
        valueFunction: () => {
          //  let earn_points = totalPrice();
          return "" + earnCrops();
        },
        inputProps: {
          disabled: true,
        },
      },
      // hideFunction: (value) => {
      //   let earn_available = formData?.earn_available;

      //   return parseInt(earn_available) === 1 ? false : true;
      // },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "redeem_crop_points",
      element: {
        pattern: ALLOW_FLOAT,
        // inputType: "BORDER_LABEL",
        label: "Redeem CROPs",
        inputProps: {
          disabled: true,
        },
        valueFunction: () => {
          return "" + redeemCrops();
        },
      },
      hideFunction: (value) => {
        //console.log("fomrdata " , formData);
        let redeem_available = formData?.redeem_available;
        //console.log("promo code " , prmo_code_status);
        return redeem_available === "Yes" ? false : true;
      },
    },
  ];

  const formCheckBox = [];
  /**
   * handle navigation
   * @param {*} id
   * @param {*} selection
   */
  const handle_redirection = (id, selection) => {
    let id_navigation = id ? encodeURIComponent(encrypt_data("" + id)) : "";
    if (selection === "yes") {
      navigateLink("market-offer-form/" + id_navigation);
    } else {
      // showAlertAutoClose("Offer Created Successfully", "success");
      navigateLink("my-offer-list");
    }
  };

  /**
   *  handles submission
   *
   * @returns
   */
  const handleSubmit = () => {
    setFormSubmit(true);
    // if (!ValidateFormNew(formData, formElements)) {
    //   return false;
    // }
    if (!ValidateFormNew(formData, [...formElements, ...formElementsOne])) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading….. Please Wait");
    let url = MANAGE_OFFER_URLS.INSERT;
    if (decrypted_id) {
      url = MANAGE_OFFER_URLS.OFFER_UPDATE;
      formData.id = decrypted_id;
    }
    // formData.earn_available = formData.earn_available == 1 ? "Yes" : "No";
    formData.earn_available = "Yes";
    formData.redeem_available =
      formData.redeem_available === "Yes" ? "Yes" : "No";
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        refreshUser();
        setLoading(false);
        if (formData?.ID > 0) {
          navigateLink("my-offer-list");
          showAlertAutoClose("Offer Updated Successfully", "success");
        } else {
          showAlertAutoClose("Offer Created Successfully", "success");
          const timer = setTimeout(() => {
            showYesOrNoAlert(
              "Promote for marketing offers?",
              (selection) => handle_redirection(response.data, selection),
              "success"
            );
            // Your script or function call here
          }, 2000);
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  // const checkServiceSettings=()=>{
  //     let pickUpDelivery = user.pickUpDelivery!==undefined ? user.pickUpDelivery :  false;
  //     return pickUpDelivery;
  // }
  const formElementdata = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "size",
      element: {
        options: [{ value: "1", label: "Size" }],
        isMulti: true,
        // validations: validations,
      },
    },
  ];
  const formElementdatas = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "select_all",
      element: {
        options: [{ value: "1", label: "Select All" }],
        isMulti: true,
        // validations: validations,
      },
    },
  ];
  const formSizeElements = [
    {
      type: "CHECK_RADIO",
      width: "3",
      name: "size",
      element: {
        options: [{ value: "1", label: "" }],
      },
    },
  ];
  const formSizeElementOne = [
    {
      type: "TEXT_BOX",
      width: "6",
      name: "redeem_crop_points",
      element: {
        // inputType: "BORDER_LABEL",
        placeHolder: "Enter Price",
        // rightIcon: "fa-edit",
      },
    },

    {
      type: "TEXT_BOX",
      width: "6",
      name: "redeem_crop_points",
      element: {
        // inputType: "BORDER_LABEL",
        placeHolder: "Enter Discount",
        // rightIcon: "fa-edit",
      },
    },
  ];
  const formElementsTwo = [];

  const imageCount =
    formData && formData.brand_logo && Array.isArray(formData.brand_logo)
      ? formData.brand_logo.length
      : 0;

  const getImageData = (idata) => {
    // console.log("data ", idata)
    const output = [];
    for (let i = 0; i < idata.length; i++) {
      let content = idata[i].content;
      output.push({
        data: (
          <figure class="image is-128x128">
            <img
              className="is-square smart-image-full"
              src={`data:image/png;base64,${content}`}
              alt=""
            />
          </figure>
        ),
        content: content,
      });
    }
    return output;
  };

  const deleteImage = (index) => {
    const updatedItems = [...formData.brand_logo];
    // Remove the element at the specified index
    updatedItems.splice(index, 1);
    handleInputChange("brand_logo", updatedItems);
    //updateImages(updatedItems);
  };

  return (
    <>
      <div className="CreateOffersForm">
        <div
          key={"CreateOfferFrom-columns-one"}
          className="columns is-multiline crop-business-form"
        >
          <div key={"CreateOfferFrom-column-one"} className="column">
            <p className="smart-bussiness-header pb-3 pl-3 pt-4">
              <span className="">
                <SmartHeader
                  title={"Manage Services"}
                  sub_title={decrypted_id > 0 ? "Amend Offer" : "Create Offer"}
                />
              </span>
            </p>
          </div>
        </div>

        <div className="columns  Creat-offer-mededia-screen">
          <div key={"CreateOfferFrom-column-two"} className="column is-6 ">
            <div className="column">
              <div
                className="card-content smart-business-image-file is-6 mt-3"
                key="one-5"
              >
                {imageCount < 10 && (
                  <SmartSofFile
                    placeHolder={
                      <p>
                        <i className="fa fa-cloud-upload mr-1"></i>Upload Offer
                        Image<span className="has-text-danger">*</span>
                        {imageCount > 0 ? "(" + imageCount + ")" : ""}
                      </p>
                    }
                    value={formData?.brand_logo}
                    onChange={(value) => {
                      openImageModalOnEdit(value);
                      handleInputChange("brand_logo", value);
                    }}
                    errorEnable={formSubmit}
                    fileNameEnable={false}
                    validations={FormValidations.upload}
                    // leftIcon="fa fa-cloud-upload"
                    isMulti={true}
                    isRequired={true}
                  />
                )}{" "}
                {imageCount >= 10 && (
                  <>
                 
                  <button
                    className="file-label file-cta"
                    disabled
                    title="Exhausted Images"
                  >
                    <p>
                      <i className="fa fa-cloud-upload mr-1"></i>Upload Offer
                      Image
                      <span className="has-text-danger">*</span>
                      {imageCount > 0 ? "(" + imageCount + ")" : ""}
                    </p>
                  </button>
                     {imageCount >= 10 && (
                      <p>
                        You have exhausted the total number of images for this offer
                      </p>
                    )}
                     </>
                )}
              </div>

              <div
                className="card-image product-image-container p-5"
                key="one-6"
              >
                {formData.brand_logo && (
                  <ImageCarousel
                    data={getImageData(formData.brand_logo)}
                    key="singleImage"
                    showButtons={false}
                    buttonsClassName={"product-image-buttons"}
                    isMulti={true}
                    deleteImage={deleteImage}
                    imageEditClick={imageEditClick}
                  />
                )}
                {/* <SmartImageDisplay
                  srcType="DATA"
                  data={formData?.brand_logo || []}
                  isMulti={true}
                  imageClass="is-4  px-2"
                  updateImages={(images) =>
                    handleInputChange("brand_logo", images)
                  }
                  imageEditClick={imageEditClick}
                /> */}
              </div>
            </div>
          </div>

          <div
            key={"CreateOfferFrom-column-three"}
            className="column is-6 offer-title-alingment  pr-6"
          >
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElements}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
            />
          </div>
        </div>

        <div className="column mx-5">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElementsOne}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>
        <div className="column mx-5">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formCheckBox}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>

        <div className="columns ml-0">
          <div className=" column is-12"></div>

          {/* 
            <div className="column has-text-weight-medium">
              <div className="is-flex is-size-4 ml-5">
                <div className="">Apply Variants</div>
                <div
                  onClick={() => navigateLink("")}
                  className="has-text-info ml-5 pointer is-size-6 mt-2"
                >
                  Add Variants
                </div>
              </div>
            <div>
               <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElementdata}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
          </div>
             
            </div> */}
        </div>
      </div>

      <div className="column is-10 ml-5">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElementsTwo}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </div>

      <div className="is-justify-content-end  is-flex pt-4 pr-5 mr-1 pb-5">
        <SmartSoftButton
          label={formData?.ID > 0 ? "Update" : "Create"}
          classList={["smart-crop-action-button"]}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

export default CreateOffersForm;
