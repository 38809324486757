import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ADMIN_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { LOGIN_lOGO } from "../../../services/ImageService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
// import SmartPassWord from "../../../components/site/FormElements/SmartPassWord";
// import SmartMobileNumber from "../../../components/site/FormElements/SmartMobileNumber";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
//import Signup from "./BusinessSignUp";

import {
  SmartSoftForm,
  // SmartSoftButton,
  // SmartSoftInput,
  SmartValid,
} from "soft_digi";
import SmartCheckRadio from "soft_digi/dist/forms/SmartCheckRadio";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import {
  getRemeberMe,
  removeRemeberMe,
  setRemeberMe,
  setSessionStorage,
} from "../../../services/sessionService";
import BusineessLoginLayout from "../../../themes/BusinessLayout/BusineessLoginLayout";
import FirstPassWord from "./FirstPassWord";

const BusinessLogin = () => {
  const [formData, setFormData] = useState({
    login_type: "1",
    country_code: "AU",
    euserid: "B-",
  });
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, setUser, startSessionAct, refreshUser,openModal,closeModal } =
    useSiteContext();

  const resetData = () => {
    handleInputChange("epassword", "");
    handleInputChange("euserid", "");
    handleInputChange("checkbox_remember_me","")
  };
  const resetDataUserid = () => {
    // handleInputChange("epassword", "");
    handleInputChange("euserid", "");
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "login_type" || name == "country_code") {
      resetData();
      if (value == 1) {
        setFormData((prev) => ({ ...prev, euserid: "B-" }));
      }
    }
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const navigate = useNavigate();

  const handleRemeberMe = () => {
    let checkbox_remember_me =
      formData.checkbox_remember_me !== undefined
        ? formData.checkbox_remember_me
        : "0";
    // console.log("check box rmeber me " , checkbox_remember_me, formData);
    if (checkbox_remember_me == "1") {
      setRemeberMe(formData);
    } else {
      removeRemeberMe();
    }
  };

  const handleRemeberMeLoad = () => {
    let remember_data_original = getRemeberMe();
    const remember_data = { ...remember_data_original };
    delete remember_data.epassword;
    if (remember_data) {
      let checkbox_remember_me =
        remember_data.checkbox_remember_me !== undefined
          ? remember_data.checkbox_remember_me
          : "0";
      if (checkbox_remember_me == "1") {
        setFormData(remember_data);
      }
    }
  };
  useEffect(() => {
    handleRemeberMeLoad();
  }, []);

  useEffect(() => {
    if (formData.login_type === "1") {
      if (formData?.euserid?.length < 2) {
        setFormData((prev) => ({ ...prev, euserid: "B-" }));
      }
    }
  }, [formData]);

  const checkUser = () => {
    // if (!ValidateFormNew(formData, formElements)) {
    //   return false;
    // }
    const handleError = (errorMessage) => {
      //  showAlertAutoClose(errorMessage, "error");
      // setLoading(false);
    };
    //setLoading(true, "Checking.... Please Wait");
    let url = ADMIN_LOGIN_URLS.CHECK_USER;
    let data_in = { ...formData };
    data_in["user_type"] = "BUSINESS";
    // data_in["euserid"] = value;
    const subscription = post(url, data_in, handleError, false).subscribe(
      (response) => {
        if (
          response.data &&
          response.data.checkFlag &&
          response.data.checkFlag == 2
        ) {
          // handleInputChange("crop_id", response.data.crop_id);
          // handleInputChange("first_name", response.data.first_name);
          //setStage(2);
        } else {
          // navigate to first set pin box
          let data_in = {};
          data_in["otp_id"] = response.data.otp_id;
          data_in["uid"] = response.data.uid;
          data_in["login_type"] = "3";
          data_in["email_id"] = response.data.email_id;
          data_in["euserid"] = response.data.email_id;
          openNewForm(data_in);
          showAlertAutoClose("OTP sent to Email successfully", "info");
        }
        // setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handle_blur = (value) => {
    //console.log("entered value ", value);
    if (formData.euserid && formData.euserid.length > 5) {
      checkUser();
    }
  };

  const handleLogin = (e) => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      handleInputChange("epassword", "");
    };
    setLoading(true, "Logging in....Please Wait");
    let url = ADMIN_LOGIN_URLS.LOGIN_BUSINESS;
    const subscription = post(url, formData, handleError, false).subscribe(
      (response) => {
        showAlertAutoClose("Log In Successful", "success");
        // showMessage("success", "Logged in Successfully");
        setLoading(false);
        setUser(response.data);
        startSessionAct();
        handleRemeberMe();
        navigate("/dashboard");
        refreshUser();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  // const handleSignup = () => {
  //   // Add your signup logic here
  //   navigate("/sign-up");
  //   console.log("Redirecting to signup...");
  // };

  const leftImage = () => {
    return (
      <>
        <figure className="image is-hidden-mobile">
          <img
            src={LOGIN_lOGO}
            alt="Login Logo"
            className="image login-image"
          />
        </figure>
      </>
    );
  };

  const loginTitle = () => {
    return (
      <div className="mt-2 mb-5">
        <p className="has-text-centered is-size-4 crop-login-title mt-1 mb-3">
          Login
        </p>
        <p className="has-text-centered mb-2 has-text-weight-normal crop-login-desc inter-font-family">
          Your access to a world of Customer Loyalty
        </p>
      </div>
    );
  };

  const loginSignUp = () => {
    return (
      <p className="has-text-centered mt-5 has-text-weight-medium">
        Not a member?
        <Link to="/sign-up" className="pl-1">
          <span className="sign-up-text">Sign up now</span>
        </Link>
      </p>
    );
  };

  const loginFormValidations = {
    email: [
      SmartValid.required("Email Address is Required"),
      SmartValid.email("Please enter a valid Email address"),
    ],
    cropid: [SmartValid.required("Crop Id is Required")],
    password: [SmartValid.required("Password is Required")],
    mobile_no: [
      SmartValid.required("Mobile Number is Required"),
      SmartValid.minLength("Number should be minimum 10 digit"),
    ],
  };

  const goToForgot = (e) => {
    setSessionStorage("formData", formData);
    navigate("/forgot-password");
  };
  const passWordPin = () => {
    return (
      <div className="is-flex is-justify-content-space-between mb-4 has-text-weight-medium">
        <span>
          <SmartCheckRadio
            options={options_remember_me}
            name="checkbox_remember_me"
            value={formData?.checkbox_remember_me}
            onChange={(value) =>
              handleInputChange("checkbox_remember_me", value)
            }
            isRight={false}
          />
        </span>
        <span>
          <span
            className=" Business-Login-Forget"
            href="#"
            onClick={goToForgot}
          >
            Forgot Password?
          </span>
        </span>
      </div>
    );
  };

  const openNewForm = (data_in) => {
    let modelObject = {
      body: (
        <FirstPassWord
          data_in={data_in}
          setLoading={setLoading}
          closeModal={closeModal}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const options = [
    { value: "1", label: "CROP ID" },
    { value: "2", label: "Mobile" },
    { value: "3", label: "Email" },
  ];
  const options_remember_me = [{ value: "1", label: "Remember Me" }];

  const formElements = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "login_type",
      element: {
        options: options,
        isMulti: true,
        type: "radio",
        classList: ["has-text-weight-semibold"],
        //validations:numericValidations
      },
    },
    {
      type: "MOBILE",
      width: "12",
      name: "euserid",
      codeName: "country_code",
      classList: "has-text-black",
      element: {
        pattern: ALLOW_NUMERIC,
        //validations:numericValidations
        codeSelected: formData?.country_code,
        value: formData?.euserid || "",
        countries: ["AU", "IN"],
        placeHolder: "Enter Mobile Number",
        rightIcon: "fa-mobile",
        validations: loginFormValidations.mobile_no,
        disablePaste: true,
        autoComplete: "new-password",
        onBlur: handle_blur,
      },
      hideFunction: (data) => {
        return formData?.login_type !== "2" ? true : false;
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        placeHolder: "Enter CROP ID",
        // leftIconFunction:()=>{
        //   return <span class="icon is-small is-left">
        //    B
        // </span>
        // },
        rightIcon: "fa fa-user-o",
        validations: loginFormValidations.cropid,
        disablePaste: true,
        autoComplete: "new-password",
        onBlur: handle_blur,
        // validations:numericValidations
      },
      hideFunction: (data) => {
        return formData?.login_type !== "1" ? true : false;
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        placeHolder: "Enter Email ID",
        rightIcon: "fa fa-envelope-o",
        validations: loginFormValidations.email,
        disablePaste: true,
        autoComplete: "new-password",
        onBlur: handle_blur,
        // validations:numericValidations
      },
      hideFunction: (data) => {
        return formData?.login_type !== "3" ? true : false;
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "epassword",
      element: {
        placeHolder: "Enter Password",
        validations: loginFormValidations.password,
        disablePaste: true,
        autoComplete: "new-password",
        //  passwordValidator:true
      },
    },
    {
      type: "LABEL",
      labelFunction: passWordPin,
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Login",
        classList: ["crop-login-action-button ", "business-login-btn"],
        // onClick: () => handleLogin(),
        onClick: () => handleLogin(),
        //  disabled:{!isEmptyObject(formErrors)}
      },
    },
    /*
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        placeHolder: formData?.login_type == "1" ? "CROP ID" : formData?.login_type == "2" ? "MOBILE NUMBER" : "EMAIL ID",
        rightIcon: "fa-user"
        // validations:numericValidations
      },   
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "epassword",
      element: {
        placeHolder: "Password",
        rightIcon: "fa-lock"
        // validations:numericValidations
      },
    },*/
  ];

  // const EmailValidation = {
  //     Email: [
  //       SmartValid.required("Please enter a valid Email address"),
  //       SmartValid.email("Please enter a valid Email address"),
  //     ],
  //   };

  const formDisplay = () => {
    return (
      <div className="crop-business-login-form ">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </div>
    );
  };

  /*
  return (
    <>
      <div className="container is-fluid smart-crop-container">
        <div className="columns is-gapless is-vcentered">
          <div key={"BusinessLogin-column-one"} className="column is-6">
            {leftImage()}
          </div>
          <div key={"BusinessLogin-column-two"} className="column is-6">
            {loginFormNew()}
          </div>
        </div>
      </div>
    </>
  );
  */
  return (
    <BusineessLoginLayout sideImage={LOGIN_lOGO}>
      {loginTitle()}
      {formDisplay()}
      {loginSignUp()}
    </BusineessLoginLayout>
  );
};

export default BusinessLogin;
