import { Route, Routes } from "react-router-dom";
import {
  AppFAQsView,
  BusinessDashboard,
  NotificationView,
  FAQnew,
} from "../../pages/Business";
import PrivacyDataRightsView from "../../pages/Business/ImportantInformation/PrivacyAndDataRights/PrivacyDataRightsView";
import TermsConditionView from "../../pages/Business/ImportantInformation/TermsandConditions/TermsConditionView";
import BusinessForgotPass from "../../pages/Business/Login/BusinessForgotPass";
import BusinessLogin from "../../pages/Business/Login/BusinessLogin";
import BusinessRegistration from "../../pages/Business/Login/BusinessRegistration";
import BusinessSignUp from "../../pages/Business/Login/BusinessSignUpNew";
import BusineesLayout from "../../themes/BusinessLayout/BusineesLayout";
import PrivateRoute from "../PrivateRoute";
import HelpButton from "./HelpButton";
import HomeButton from "./HomeButton";
import ManageProgram from "./ManageProgram";
import ManageServices from "./ManageServices";
import MoreButton from "./MoreButton";
import MyAccount from "./MyAccount";
import PromosButton from "./PromosButton";
import Settings from "./Settings";
import HeaderAndFooterLayout from "../../themes/BusinessLayout/HeaderAndFooterLayout";
import Login from "../../pages/Business/BusinessLogin/Login";
import TermAndConditionNew from "../../pages/Business/ImportantInformation/TermsandConditions/TermAndConditionNew";
import PrivacyAndDataRightsNew from "../../pages/Business/ImportantInformation/PrivacyAndDataRights/PrivacyAndDataRightsNew";
import AboutUsNew from "../../pages/Business/ImportantInformation/AboutUsNew";

const BusinessRoute = () => {
  const protected_routes = () => {
    return (
      <PrivateRoute>
        <BusineesLayout>
          <Routes>
            <Route path="/manage-services/*" element={<ManageServices />} />
            <Route path="/manage-program/*" element={<ManageProgram />} />
            <Route path="/my-account/*" element={<MyAccount />} />
            <Route path="/settings/*" element={<Settings />} />
            <Route path="/home/*" element={<HomeButton />} />
            <Route path="/promos/*" element={<PromosButton />} />
            <Route path="/more/*" element={<MoreButton />} />
            <Route path="/help/*" element={<HelpButton />} />      
            <Route path="/notification/:type" element={<NotificationView />} />    
            <Route path="/dashboard/*" element={<BusinessDashboard />} />
            <Route path="/more-about-us" element={<AboutUsNew />} />
          </Routes>
        </BusineesLayout>
      </PrivateRoute>
    );
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HeaderAndFooterLayout>
            <BusinessLogin />
          </HeaderAndFooterLayout>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <HeaderAndFooterLayout>
            <BusinessForgotPass />
          </HeaderAndFooterLayout>
        }
      />
      <Route
        path="/sign-up"
        element={
          <HeaderAndFooterLayout>
            <BusinessSignUp />
          </HeaderAndFooterLayout>
        }
      />
      <Route
        path="/registration"
        element={
          <HeaderAndFooterLayout>
            <BusinessRegistration />
          </HeaderAndFooterLayout>
        }
      />
        <Route
        path="/faq"
        element={
          <HeaderAndFooterLayout>
            <FAQnew />
          </HeaderAndFooterLayout>
        }
      />
       <Route
        path="/trems-and-conditions"
        element={
          <HeaderAndFooterLayout>
            <TermAndConditionNew />
          </HeaderAndFooterLayout>
        }
      />
       <Route
        path="/privacy-and-data-policy"
        element={
          <HeaderAndFooterLayout>
            <PrivacyAndDataRightsNew />
          </HeaderAndFooterLayout>
        }
      />
       <Route
        path="/about-us-new"
        element={
          <HeaderAndFooterLayout>
            <AboutUsNew />
          </HeaderAndFooterLayout>
        }
      />
      <Route path="/*" element={protected_routes()} />
      <Route path="app-faqs" element={<AppFAQsView />} />
      <Route path="/faqs-new" element={<FAQnew />} />
      <Route path="/privacy-data-rights" element={<PrivacyDataRightsView />} />
      <Route
        path="/privacy-data-rights-new"
        element={<PrivacyAndDataRightsNew />}
      />
      {/* <Route path="/about-us-new" element={<AboutUsNew />} /> */}

      <Route path="/terms-condition" element={<TermsConditionView />} />
      <Route path="/terms-condition-new" element={<TermAndConditionNew />} />
    </Routes>
  );
};

export default BusinessRoute;
