import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { SmartSoftButton } from "soft_digi";
import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import ImageCarousel from "../../../../components/site/ImageCarousel";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { displayDots } from "../../../../services/core/CommonService";
import { showNotification } from "../../../../services/notifyService";
import { decrypt_data, encrypt_data, } from "../../../../services/sessionService";
import { post } from "../../../../services/smartApiService";
import { SmartSoftButton } from "soft_digi";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import ImageSlider from "../../../../components/site/ImageSlider";




const MyOffersView = () => {
  const navigate = useNavigate();
  const baseNav = "/manage-services";
  const { id } = useParams();
  const decodeId = id !== 0 ? decodeURIComponent(id) : 0;
  const decrypted_id = id !== 0 ? decrypt_data(decodeId) : 0;
  const { setLoading } = useSiteContext();
  const [offerValue, setOfferValue] = useState(false);
  const [data,setData] =  useState([]);


  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const baseNavNav = "/manage-services";
  const navigateLinkNav = (index) => {
    navigate(baseNavNav + "/" + index);
  };

  const loadSingleData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    let post_data = { id: decrypted_id };
    const subscription = post(
      MANAGE_OFFER_URLS.GET_ONE,
      post_data,
      handleError
    ).subscribe((response) => {
      // console.log(response.data)
      setOfferValue(response.data)
      // setFormData(response.data);
      setLoading(false);
     // getImages();
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const getImages = () => {
    const handleError = (errorMessage) => {
    //  showAlertAutoClose(errorMessage, "error")
      setLoading(false);     
    };
    setLoading(true, "Loading.... Please Wait");
    let url = MANAGE_OFFER_URLS.GET_ALL_IMAGES;
    let data_in = { sd_offers_id: decrypted_id };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);         
        setData(response.data);   
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }



  useEffect(() => {
    // loadSelectOptions();
    if (decrypted_id) {
      loadSingleData();
    }
  }, []);

  const handleMarketRedirection = () => {
    // console.log("Hai")
    // console.log(offerValue)
    let id_navigation = id ? encodeURIComponent(encrypt_data("" + offerValue.ID)) : "";
    navigateLink("market-offer-form/" + id_navigation);
  }
  const handleAmendRedirection = () => {
    let id_navigation = id ? encodeURIComponent(encrypt_data("" + offerValue.ID)) : "";
    navigateLink("create-offers-form/" + id_navigation);
  }

  const getImageData=()=>{
    const output = [];    
   // console.log("images " , offerValue.images);
    for(let i=0;i<offerValue.images.length;i++){
      let content = offerValue.images[i].image_content;
      output.push({
        data: <img className="is-square smart-image-full smart-image-slider-two mt-3" 
        src={`data:image/png;base64,${content}`} alt="" />,
        content: content
      }
    )     
    }
    // console.log("output imaghes " , output,imageData);
     return output
  }

  const imagesDisplay = () => {
     //console.log("Data "  ,data," L = ", data.length);
     return (
       <>
       <div className='product-image-container'>
        {offerValue && offerValue.images && offerValue.images.length > 0 && 
        <ImageCarousel data={getImageData()} 
        key="singleImage" 
      
                    showButtons={false}
                    buttonsClassName={"product-image-buttons"}
                    isMulti={true}       
  /> }         
       </div>
         </>
     )
   }
 



  const imageView = (dataIn) => {
    return (
      <>
        <div className="my-offer-view-card">
          <div className="my-offer-view-card-header">Image</div>
           <div className="my-offer-card-view"> {imagesDisplay()}</div> 
        </div>
      </>
    );
  };
  const OfferDetails = ({ dataIn = {} }) => {
    return (
      <>
        <div className="my-offer-view-card ">
          <div className="my-offer-view-card-header">Offer Details </div>
          <table className="my-offers-details-list ">
            <tbody>
              <tr>
                <td className="">
                  <div class=""> Sector</div>
                </td>
                <td>: {dataIn?.sector_name}</td>
              </tr>
              <tr>
                <td>Title</td>
                <td>
                <div className="is-flex">
                    <div className="">:</div>
                    <div className="">    {displayDots (dataIn?.offer_title)}</div>
                  </div>
                  </td>
                
              </tr>
              {/* <tr>
              <td>Quantity </td>
              <td>: </td>
            </tr> */}
              <tr>
                <td>Brief Description</td>
                <td>
                  <div className="is-flex">
                    <div className="">:</div>
                    <div className="">    {displayDots(dataIn?.offer_breif)}</div>
                  </div>
              
                  </td>
              </tr>
              <tr>
                <td>Detailed Description</td>
                <td className="">
                <div className="is-flex">
                    <div className="">:</div>
                    <div className="">     {displayDots(dataIn?.offer_desc)}</div>
                  </div>
                  
                   </td>
              </tr>
              <tr>
                <td>Price (in A$)</td>
                <td>: ${dataIn?.price}</td>
              </tr>
              <tr>
                <td>Discount %</td>
                <td>: {dataIn?.discount}%</td>
              </tr>
              <tr>
                <td>Sale Price (in A$)</td>
                <td>: ${dataIn?.total_price}</td>
              </tr>
              <tr>
                <td>Likes</td>
                <td>: {dataIn?.offer_likes} </td>
              </tr>
              <tr>
                <td> Star Rating</td>
                <td>: {dataIn?.offer_ratings}</td>
              </tr>
              <tr>
                <td>GST</td>
                <td>: {dataIn?.gst_value}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };
  const CropDetails = ({ dataIn = {} }) => {
    return (
      <>
        <div className="my-offer-view-card">
          <div className="my-offer-view-card-header">CROP Details</div>
          <table className="my-offers-details-list ">
            <tbody>
              <tr>
                <td className="my-offers-details-list-label">
                  <div class="tr-wrapper"> Sector</div>
                </td>
                <td>: {dataIn?.sector_name}</td>
              </tr>
              <tr>
                <td>Title</td>
                <td>: {displayDots (dataIn?.offer_title)}</td>
              </tr>
              {/* <tr>
                <td>Quantity </td>
                <td>: 4</td>
              </tr> */}
              <tr>
                <td>Earn CROPs </td>
                <td>:  {dataIn?.earn_crop_points}</td>
              </tr>
              <tr>
                <td>Redeem CROPs </td>
                <td>: {dataIn?.redeem_crop_points}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };


  return (
    <>
      <div className=" ">
        {/* <div className='card-header-icon'><i class="fa fa-caret-left is-size-5" aria-hidden="true"></i></div> */}
        {/* <div className='card-label mt-2 ml-6'>View Offers</div> */}
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          {/* <span
            className="icon mt-1"
            onClick={() => navigateLinkNav("my-offer-list")}
          >
            <i className="fa fa-caret-left " aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span> */}
          {/* <span className="ml-5">View Offers</span> */}
          <div className="pl-1">
            <SmartHeader title={"Manage Services"} sub_title={"View Offers"} />
          </div>
        </p>
      </div>
      <div className="offers-view-container mt-3 mx-3">
        <div className="columns is-multiline">
          <div key={"MyOffersView-column-one"} className="column is-4 ">
            {/* <imageView dataIn = {offerValue} /> */}
            {imageView(offerValue)}
          </div>
          <div key={"MyOffersView-column-two"} className="column is-4">
            <OfferDetails dataIn={offerValue} />
          </div>
          <div key={"MyOffersView-column-three"} className="column is-4">
            <CropDetails dataIn={offerValue} />
          </div>
          {/* <div key={"MyOffersView-column-four"} className="column is-4">{SpecialDetailsOne()}</div>
          <div key={"MyOffersView-column-five"} className="column is-4">{SpecialDetailsTow()}</div> */}
        </div>
       {offerValue && offerValue.offer_title && (<div className=" has-text-right">
          <SmartSoftButton
            label="Market Offer"
            classList={["smart-crop-action-button", "my-5","mr-5"]}
            onClick={handleMarketRedirection}
            // onClick={() => navigateLink("market-offer-form/:id")}
          />
          <SmartSoftButton
            label="Amend Offer"
            classList={["smart-crop-action-button","my-5","mr-3"]}
            onClick={handleAmendRedirection}
            // onClick={() => navigateLink("amend-form/:id")}
          />
        </div> )} 
      </div>
    </>
  );
};

export default MyOffersView;
