import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  SmartSoftForm,
  SmartValid,
} from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { BUSINESS_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import croplogo from "../../../assets/images/croplogo.png";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { FORGET_lOGO } from "../../../services/ImageService";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../services/notifyService";
import {
  getRemeberMe,
  getSessionStorage
} from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";
import BusineessLoginLayout from "../../../themes/BusinessLayout/BusineessLoginLayout";

const BusinessForgotPass = () => {
  // const [formData, setFormData] = useState({
  //   login_type: "1",
  //   user_type: "BUSINESS",
  // });
  const [formData, setFormData] = useState({
    login_type: "1",
    user_type: "BUSINESS",
    country_code: "AU",
    euserid: "B-",
  });
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const [stage, setStage] = useState("FIRST");

  const resetData = () => {
    handleInputChange("euserid", "");
    //handleInputChange("password","");
    // handleInputChange("confirm_password","");
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name == "login_type") {
      resetData();
      if (value == 1) {
        setFormData((prev) => ({ ...prev, euserid: "B-" }));
      }
    }
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  useEffect(() => {
    if (formData.login_type === "1") {
      if (formData?.euserid?.length < 2) {
        setFormData((prev) => ({ ...prev, euserid: "B-" }));
      }
    }
  }, [formData]);
  
  useEffect(() => {
    let formData_session = getSessionStorage("formData");
    if (formData_session) {
      setFormData(formData_session)
    }
   // handleRemeberMeLoad();
  }, []);

  const handleRemeberMeLoad = () => {
    let remember_data = getRemeberMe();
    //console.log(" rember  " , remember_data);
    if (remember_data) {
      let checkbox_remember_me =
        remember_data.checkbox_remember_me !== undefined
          ? remember_data.checkbox_remember_me
          : "0";
      if (checkbox_remember_me == "1") {
        setFormData(remember_data);
      }
    }
  };


  const handleLogin = (e) => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Sending OTP....Please Wait");
    let url = BUSINESS_LOGIN_URLS.SEND_OTP_FORGOT;
    let login_data = { ...formData };
    login_data["user_type"] = "BUSINESS";
    const subscription = post(url, login_data, handleError, false).subscribe(
      (response) => {
        handleInputChange("otp_id", response.data.otp_id);
        handleInputChange("uid", response.data.uid);
        showAlertAutoClose("OTP Sent Successfully ", "success");
        setLoading(false);
        setStage("SECOND");
        setFormSubmit(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleVerify = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, stage2_elements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Verifying OTP....Please Wait");
    // console.log("fdata ", formData);
    let url = BUSINESS_LOGIN_URLS.RESET_PASSWORD_OTP;
    let login_data = { ...formData };
    login_data["user_type"] = "BUSINESS";
    const subscription = post(url, login_data, handleError).subscribe(
      (response) => {
        let msg = response?.data?.msg || "Password Reset Successful";
        setFormSubmit(false);
        showAlertAutoClose(msg, "success");
        setLoading(false);
        navigate("/");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const navigate = useNavigate();

  const leftImage = () => {
    return (
      <>
        <figure className="image is-centered is-hidden-mobile">
          <img
            src={FORGET_lOGO}
            alt="Login Logo"
            className="image login-image"
            style={{ maxWidth: "78%" }}
          />
        </figure>
      </>
    );
  };

  const loginTitle = () => {
    return (
      <div className="mt-5 mb-6">
        <p className="has-text-centered is-size-5 crop-login-title inter-font-family mt-1 mb-3">
          Forgot Password?
        </p>
        <p className="has-text-centered mb-2 crop-login-desc poppins-font-family">
          {/* Enter Your Credentials to Continue */}
          Enter your details to reset Password
        </p>
      </div>
    );
  };

  const loginSignUp = () => {
    return (
      <div>
        <p className="has-text-centered mt-5 has-text-weight-medium is-size-6">
          {/* Already have an Account? */}
          <Link to="/" className="" >
            Back to Login
          </Link>
        </p>
      </div>
    );
  };

  const loginLogo = () => {
    return (
      <div>
        <figure className="crop-image is-flex is-justify-content-center mt-6">
          <img src={croplogo} hight="180" width="180" alt="Crop Logo" />
        </figure>
      </div>
    );
  };

  const options = [
    { value: "1", label: "CROP ID" },
    { value: "2", label: "Mobile" },
    { value: "3", label: "Email" },
  ];
  // const loginFormValidations = {
  //   email: [SmartValid.required("Email Id is Required")],
  //   cropid: [SmartValid.required("Crop Id is Required")],
  //   mobile_no: [
  //     SmartValid.required("Mobile Number is Required"),
  //     //  SmartValid.minLength("Number should be minimum 10 digit",9)
  //   ],
  // };
  const verifyValidations = {
    otp: [
      SmartValid.required("Otp Required"),
      SmartValid.minLength("Minimum 6 Digit", 6),
    ],
    password: [
      SmartValid.required("Password is Required"),
      // SmartValid.minLength("Minimum 4 Digit", 4),
      SmartValid.maxLength("Maximum 15 Digit", 15),
    ],
    cnf_password: [
      SmartValid.required("Password is Required"),
      // SmartValid.minLength("Minimum 4 Digit", 4),
      SmartValid.maxLength("Maximum 15 Digit", 15),
      SmartValid.custom((value) => {
        return formData.password !== value
          ? "Password & Confirm Password are not Same"
          : "";
      }),
    ],
  };
  const forgotPinValidations = {
    otp: [
      SmartValid.required("Otp Required"),
      SmartValid.minLength("Minimum 6 Digit", 6),
    ],
    email: [SmartValid.required("Email Address is Required")],
    cropid: [SmartValid.required("Crop Id is Required")],
    mobile_no: [
      SmartValid.required("Mobile Number is Required"),
      SmartValid.minLength("Number should be minimum 10 digit", 9),
    ],
  };
  const formElements = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "login_type",
      classList: "has-text-black",
      element: {
        options: options,
        isMulti: true,
        type: "radio",
        classList: ["has-text-weight-semibold"],
        //validations:numericValidations
      },
    },
    {
      type: "MOBILE",
      width: "12",
      name: "euserid",
      codeName: "country_code",
      classList: "has-text-black",
      element: {
        pattern: ALLOW_NUMERIC,
        //validations:numericValidations
        value: formData?.euserid || "",
        codeSelected: formData?.country_code,
        countries: ["AU", "IN"],
        placeHolder: "Enter Mobile Number",
        rightIcon: "fa-mobile",
        validations: forgotPinValidations.mobile_no,
                 autoComplete:"new-password"
      },
      hideFunction: (data) => {
        return formData?.login_type !== "2" ? true : false;
      },
    },

    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        placeHolder: "Enter CROP ID",
        rightIcon: "fa fa-user-o",
        validations: forgotPinValidations.cropid,
        // validations:numericValidations
                 autoComplete:"new-password"
      },
      hideFunction: (data) => {
        return formData?.login_type !== "1" ? true : false;
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        placeHolder: "Enter Email ID",
        rightIcon: "fa fa-envelope-o",
        validations: forgotPinValidations.email,
        // validations:numericValidations
                 autoComplete:"new-password"
      },
      hideFunction: (data) => {
        return formData?.login_type !== "3" ? true : false;
      },
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Reset",
        classList: ["is-rounded", "crop-login-action-button"],
        onClick: () => handleLogin(),
      },
    },
  ];

  const formDisplay = () => {
    return (
      <div className="crop-business-login-form ">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </div>
    );
  };

  const stage2_elements = [
    {
      type: "OTP",
      width: "12",
      name: "otp_value",
      element: {
        digits: 6,
        rightIcon: "fa-envelope-o",
        inputType: "BORDER_LABEL",
        label: "",
        pattern: ALLOW_NUMERIC,
        validations: forgotPinValidations.otp,
        max: 6,
                 autoComplete:"new-password",
        resendOtpFunction: (callback) => {
          handleLogin(null);
          callback();
        },
        otpButtonText: (status, timer) => {
          return status ? "Resend Code (" + timer + " sec)" : "Resend Code";
        },
        validations: [
          SmartValid.required("OTP Required"),
          SmartValid.minLength("Minimum 6 Digit", 6),
        ],
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "password",
      element: {
        placeHolder: "Enter Password",
        validations: verifyValidations.password,
        passwordValidator: true,
                 autoComplete:"new-password"
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "confirm_password",
      element: {
        placeHolder: "Confirm Password",
        validations: verifyValidations.cnf_password,
        passwordValidator: true,
                 autoComplete:"new-password"
      },
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Reset",
        classList: ["is-rounded", "crop-login-action-button"],
        onClick: () => handleVerify(),
      },
    },
  ];
  const verification_form = () => {
 
    return (
      <div className="crop-business-login-form crop-business-form">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={stage2_elements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </div>
    );
  };

  const stage2_forms = () => {
    return (
      <>
        <div className="">
          <p className="has-text-centered is-size-4 crop-login-title  mb-2">
            {" "}
            {/* Verification */}
            Forgot Password?
          </p>
          <p className="has-text-centered mb-5 crop-login-desc poppins-font-family">
            {/* Enter Your Verification Details */}
            Enter verification code to verify your <br /> account and create a
            new Password
          </p>
        </div>
        {verification_form()}
        {loginSignUp()}
      </>
    );
  };

  const stage1_forms = () => {
    return (
      <>
        {loginTitle()}
        {formDisplay()}
        {loginSignUp()}
      </>
    );
  };

  const loginFormNew = () => {
    return (
      <div className="card crop-business-login-box mb-6">
        <div className="crop-login-form-main">
          {loginLogo()}
          {stage === "FIRST" && stage1_forms()}
          {stage === "SECOND" && stage2_forms()}
        </div>
        <div className="crop-business-login-top-box"></div>
        <div className="crop-business-login-bottom-box"></div>
        <div className="crop-business-login-left-box"></div>
      </div>
    );
  };

  // return (
  //   <>
  //     <div className="container login-container is-fluid smart-crop-container">
  //       <div className="columns is-gapless is-vcentered">
  //         <div key={"BusinessForgotPass-column-one"} className="column is-6">
  //           {leftImage()}
  //         </div>
  //         <div key={"BusinessForgotPass-column-two"} className="column is-6">
  //           {loginFormNew()}
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <BusineessLoginLayout sideImage={FORGET_lOGO}>
      {stage === "FIRST" && stage1_forms()}
      {stage === "SECOND" && stage2_forms()}
    </BusineessLoginLayout>
  );
};

export default BusinessForgotPass;
