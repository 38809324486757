import { } from "soft_digi";
// import { useNavigate } from "react-router-dom";
// import { isEmptyObject } from "../../../../services/core/CommonService";
import AccountDetails from "../AccountDetails";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";



const StatementForm = () => {
  // const navigate = useNavigate();
  // const baseNav = "/home";
  // const navigateLink = (index) => {
  //   navigate(baseNav + "/" + index);
  // };

  // const [formData, setFormData] = useState({});
  // const [formSubmit, setFormSubmit] = useState(false);
  // const [formErrors,  setFormErrors] = useState(false);
  // const [type, setType] = useState("date");

  // const handleInputChange = (name, value) => {
  //   //console.log(" name " ,name , "   value " , value)
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  // const handleFormSubmit = () => {
  //   console.log("formdata", formData);
  //   setFormSubmit(true);
  //   console.log("form errors ", formErrors);
  // };

//   const handleFormSubmit = () => {
   
//     setFormSubmit(true);
//    if(!isEmptyObject(formErrors)){
//      return false
//    }
//  };

//  const handleErrorChange = (name, value) => {
//    setFormErrors((prev) => {
//      // Create a copy of the previous state
//      const updatedFormData = { ...prev };
//      // Check if the value is null or empty
//      if (value === null || value === "") {
//        // If true, remove the property from the updated state
//        delete updatedFormData[name];
//      } else {
//        // Otherwise, update or add the property in the updated state
//        updatedFormData[name] = value;
//      }

//      return updatedFormData;
//    });
//  };
//   const FormValidations  ={

//     from_date:[
//      SmartValid.requiredNumber("From Date is Required"),
//     ],
//     to_date:[
//       SmartValid.requiredNumber("To Date is Required"),
//      ]
//  }

  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
        {/* <span className="ml-5"> Statement</span> */}
        <div className="pl-1">
            <SmartHeader title={"Manage Program"} sub_title={"My Account"} />
          </div>
      </p>
      <AccountDetails />

      {/* <div className="container smart-crop-business-forms mt-6">
        <div key={"StatementForm-columns-one"}  className="columns ">
          <div key={"StatementForm-column-two"}className="column is-5 ml-6 smart-flex-container">
            <div className="mt-6">
              <SmartSoftDate
                label="From Date"
                value={formData?.input || ""}
                onChange={(value) => handleInputChange("input", value)}
                rightIcon="fa fa-calendar"
                placeHolder="dd/mm/yyyy"
                inputProps={{ isFocussed: true }}
                validations={FormValidations.from_date}
                errorEnable={formSubmit}
                handleErrorChange={(value)=>handleErrorChange("from_date",value)}
                errorUpdate={(value)=>handleErrorChange("from_date",value)}
              />

              <div>
                <SmartSoftDate
                  label="To Date"
                  value={formData?.input_four || ""}
                  onChange={(value) => handleInputChange("input_four", value)}
                  rightIcon="fa fa-calendar"
                  placeHolder="dd/mm/yyyy"
                  inputProps={{ isFocussed: true }}
                  validations={FormValidations.to_date}
                  errorEnable={formSubmit}
                  handleErrorChange={(value)=>handleErrorChange("to_date",value)}
                  errorUpdate={(value)=>handleErrorChange("to_date",value)}
                />
              </div>

              <div className="is-flex is-justify-content-center mt-5">
                <SmartSoftButton
                  label="Save"
                  onClick={() => handleFormSubmit()}
                  classList={["smart-crop-action-button"]}
                  // disabled={!isEmptyObject(formErrors)}
                />
              </div>
            </div>
          </div>
          <div key={"StatementForm-column-three"} className="column is-6 smart-flex-container">
            <div className="card-image ">
              <img
                className="my-card-view-form-image"
                src={ACCOUNT_STATEMENT}
                alt="Account Statement"
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default StatementForm;
