import { useEffect, useState } from "react";
import { post } from "../../../services/smartApiService";
import { RATTING_URLS } from "../../../api/Services/ServiceUrls";
import { showNotification } from "../../../services/notifyService";
import Rating from './Rating';
import { changeDateTimeZoneWithTime } from "../../../services/core/CommonService";

const Comments = ({closeModal,data,setLoading}) => {
  const [tabData, setTabData] = useState([]);
  console.log("Comments",data)


  const loadSingleData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
     // setLoading(false);
    };
    let post_data = { sd_offers_id: data.sd_offers_id
    };
    const subscription = post(
      RATTING_URLS.GET_ALL_COMMENTS,
      post_data,
      handleError
    ).subscribe((response) => {
       console.log(response.data)
      setTabData(response.data)
      // setFormData(response.data);
     // setLoading(false);

     // getImages();
    });
    return () => {
      subscription.unsubscribe();
    };
  };




  useEffect(() => {
    // loadSelectOptions();
    
      loadSingleData();
  
  }, []);
  return (
    <div className="Comments">
      <div className="smart-bussiness-header  pb-3 pl-3 pt-4">
        {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}

        <span className="ml-5">Comments</span>
        <div className="mb-3 has-text-right" onClick={closeModal}>
          <div className="customer-modal-close mb-3 ">
            <i class="fa fa-times has-text-white" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          {tabData?.map((item) => (
            <div class="card mb-4">
              <div class="card-content">
                <p class="is-size-6 has-text-weight-medium">{item.comment}</p>
              </div>
              <footer class="card-footer p-3 is-flex is-justify-content-space-between">
                <div class=" has-text-weight-medium pl-3 pt-3">
                <span>{changeDateTimeZoneWithTime(item.last_modified_time)}</span>
                  </div>
                {/* <p class=""> */}
                  {/* <span>
                 <i
                   class="fa fa-star has-text-warning is-size-4 "
                   aria-hidden="true"
                 ></i>
                 <i
                   class="fa fa-star has-text-warning is-size-4 ml-2"
                   aria-hidden="true"
                 ></i>
                 <i
                   class="fa fa-star has-text-warning is-size-4 ml-2"
                   aria-hidden="true"
                 ></i>
                 <i
                   class="fa fa-star has-text-warning is-size-4 ml-2"
                   aria-hidden="true"
                 ></i>
               </span> */}
                  <div className="is-flex ">
                    <Rating rating={item.offer_ratings} />

                    <span className="ml-2 mt-2">{item.offer_likes}</span>
                    <span className="ml-3 mt-2 pr-2">
                      <i
                        class="fa fa-thumbs-up is-size-4 has-text-link"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                {/* </p> */}
              </footer>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comments;
