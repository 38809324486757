import { useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
// import { ORDER_ITEMS_URLS, ORDER_URLS } from "../../../../api/OrderUrls";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { SUPPORT_BUSINESS_URLS } from "../../../../api/Services/ServiceUrls";
import { ALLOW_FLOAT } from "../../../../services/PatternSerivce";

const MissingCropForm = ({data,setLoading,closeModal,loadTableData}) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
   // console.log(" name ", name, "   value ", value);
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const handleLogin = (e) => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData,FormElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Processing.... Please Wait");
    let url = SUPPORT_BUSINESS_URLS.UPDATE_CROP;
    let data_in = { ...formData,id:data.ID};
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Request Processed Successfully", "success");
        setLoading(false);
        loadTableData();
       closeModal();
        window.scrollTo(0, 0);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const Validations = {
    status: [SmartValid.required("Status is Required")],
    remarks: [SmartValid.required("Remarks is Required")],
    crop_point: [SmartValid.required("Crops is Required")],
  };

  const options = [
    {value:"APPROVED",label:"Approved"},
    {value:"REJECTED",label:"Rejected"}
  ]
  const FormElements = [
    {
      type: "SELECT_BOX",
      width: "12",
      name: "status",
      element: {
        label: "Status",
         validations: Validations.status,
        options:options,
        isRequired: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "crop_amount",
      element: {
        label: "Enter Invoice Amount",
        validations: Validations.crop_point,
        isRequired: true,
        pattern: ALLOW_FLOAT,
        max: 6,
      },
      hideFunction:(data)=>{
        // console.log("formdata " , formData)
        return formData.status && formData.status.value && formData.status.value==="APPROVED" ? false : true;
      }
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "update_remarks",
      element: {
        label: "Remarks",
        validations: Validations.remarks,
        isRequired: true,
      },
    },
  ];

 

  return (
    <div>
      <div className="smart-customer-input smart-crop-business-forms">
        {/* <div className="is-flex is-justify-content-space-between">
          <div className="is-size-4 has-text-weight-bold mt-4">
           Order Cancellation
          </div>
          <div className="mb-3" onClick={closeModal}>
            <div className="customer-modal-close mb-3 ">
              <i class="fa fa-times has-text-white" aria-hidden="true"></i>
            </div>
          </div>
        </div> */}
        <div className="mb-3">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={FormElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>
        <div className="mb-3 is-flex is-justify-content-center">
          <SmartSoftButton
            label="Update"
            classList={["crop-login-action-button"]}
            onClick={()=>handleLogin()}
          />
        </div>
      </div>
    </div>
  );
};

export default MissingCropForm;
