import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { USER_URLS } from "../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import {
  showAlertAutoClose,
  showNotification,
} from "../../../services/notifyService";
import { admin_sector_select } from "../../../services/site/SelectBoxServices";
import { post } from "../../../services/smartApiService";
import TermAndConditionNew from "../ImportantInformation/TermsandConditions/TermAndConditionNew";

const BusinessRegistration = ({ dataIn }) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [sectors, setSectors] = useState([]);
  // const [loyalty, setLoyalty] = useState([]);
  const navigate = useNavigate();
  const { setLoading, openModal, closeModal } = useSiteContext();
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const loadSelectOptions = () => {
    // load sector options
    admin_sector_select((data) => setSectors(data));
    setFormData(dataIn || {});
  };

  useEffect(() => {
    loadSelectOptions();
  }, []);

  const openNewForm = () => {
    let modelObject = {
      body: <TermAndConditionNew header={false} closeModal={closeModal} />,
      modalClass: "crop-customer-modal smart-modal-80",
      bodyClose: true,
    };
    openModal(modelObject);
  };

  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, [...formElements, ...secForm])) {
      return false;
    }
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Account Creation in Progress.... Please Wait");
    let url = USER_URLS.INSERT_BUSINESS;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose(response.data.msg, "success");
        setLoading(false);
        navigate("/");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  // const numericValidations = [];
  const RegistrationValidations = {
    email_id: [
      SmartValid.required("Email Address is Required"),
      SmartValid.email("Please enter a valid Email address"),
    ],
  };

  const RegistrationFormValidations = {
    firstname: [
      SmartValid.required("First Name  is Required"),
      SmartValid.pattern("Invalid First Name", /^[a-zA-Z\s'-]+$/),
    ],
    // middle_name: [SmartValid.required("Middle name  is Required")],
    last_name: [
      SmartValid.required("Last Name  is Required"),
      SmartValid.pattern("Invalid Last Name", /^[a-zA-Z\s'-]+$/),
    ],
    // email_id: [SmartValid.required("Email Id is Required")],
    business_name: [
      SmartValid.required("Business Name is Required"),
     // SmartValid.pattern("Invalid Business Name", /^[a-zA-Z\s'-]+$/),
    ],
    sd_admin_sector_id: [SmartValid.required("Sector is Required")],
    mobileNo: [SmartValid.required("Mobile Number is Required")],

    check_box: [SmartValid.required("Check box is Required")],
  };
  const options_remember_me = [{ value: "1", label: "" }];
  const formElements = [
    /*
    {
      type: "FILE",
      width: "4",
      name: "image",
      element: {
        label: "Brand Logo",
        validations: numericValidations,
        // inputType:"BORDER_LABEL_FOCUS",
        // inputProps:{ isFocussed: true },
      },
    },*/
    {
      type: "TEXT_BOX",
      width: "6",
      name: "first_name",
      element: {
        label: "First Name",
        inputType: "BORDER_LABEL",
        isRequired: true,
        // pattern: "^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$",
        // pattern: NAME_PATTERN,
        // errorEnable: { formSubmit },
        validations: RegistrationFormValidations.firstname,
        // errorUpdate: (value) => handleErrorChange("euserid", value),
        // inputType:"BORDER_LABEL_FOCUS",
        // inputProps:{ isFocussed: true },
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "middle_name",
      element: {
        label: "Middle Name",
        // errorEnable: { formSubmit },
        // validations: RegistrationFormValidations.middle_name,
        // errorUpdate: (value) => handleErrorChange("euserid", value),
        inputType: "BORDER_LABEL",
        // inputType:"BORDER_LABEL_FOCUS",
        // inputProps:{ isFocussed: true },
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "last_name",
      element: {
        label: "Last Name",

        inputType: "BORDER_LABEL",
        isRequired: true,
        // errorEnable: { formSubmit },
        validations: RegistrationFormValidations.last_name,
        // errorUpdate: (value) => handleErrorChange("euserid", value),
        // inputType:"BORDER_LABEL_FOCUS",
        // inputProps:{ isFocussed: true },
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "email_id",
      element: {
        label: "Email ID",

        inputType: "BORDER_LABEL",
        isRequired: true,
        // inputType:"BORDER_LABEL_FOCUS",
        inputProps: {
          disabled: formData?.sign_up_type === "1" ? false : true,
        },
        // errorEnable: { formSubmit },
        validations: RegistrationValidations.email_id,
        // errorUpdate: (value) => handleErrorChange("euserid", value),
      },
    },
    {
      type: "MOBILE",
      width: "12",
      name: "mobile_no",
      codeName: "country_code",
      classList: "has-text-black",
      element: {
        isRequired: true,
        inputType: "BORDER_LABEL",
        inputProps:true,
        // label:"Mobile Number",
        value: formData?.mobile_no || "",
        pattern: ALLOW_NUMERIC,
        codeSelected: formData?.country_code,
        countries: ["AU", "IN"],
        // placeHolder: "Enter Mobile Number",
        label: "Mobile Number",
        inputType: "BORDER_LABEL_FOCUS",
        isRequired: true,
        rightIcon: "fa-mobile",
        validations: RegistrationFormValidations.mobileNo,
        inputProps: {
          disabled: formData?.sign_up_type === "2" ? false : true,
        },
      },
    },

    /*
    {
      type: "SELECT_BOX",
      width: "4",
      name: "nature",
      element: {
        label: "Nature of Business",
        inputType: "BORDER_LABEL",
        options: options,
        isRequired: true,
      },
    },*/
  ];

  const secForm = [
    {
      type: "TEXT_BOX",
      width: "6",
      name: "business_name",
      element: {
        label: "Registered Business Name",

        inputType: "BORDER_LABEL",
        isRequired: true,
        // errorEnable: { formSubmit },
        validations: RegistrationFormValidations.business_name,
        // errorUpdate: (value) => handleErrorChange("euserid", value),
        // inputType:"BORDER_LABEL_FOCUS",
        // inputProps:{ isFocussed: true },
      },
    },
    {
      type: "SELECT_BOX",
      width: "6",
      name: "sd_admin_sector_id",
      element: {
        label: "Sector",
        inputType: "BORDER_LABEL",
        options: sectors,
        isRequired: true,
        validations: RegistrationFormValidations.sd_admin_sector_id,
      },
    },
    {
      type: "CHECK_RADIO",
      width: 12,
      name: "terms_new",
      element: {  
        classList:["terms_accept_checkbox"],
        options: [{value:"1","label":""}], 
        label: <p><span className="has-text-danger">*</span> I acknowledge and agree to CROP  
        <label onClick={()=>openNewForm()} className=" pointer sign-up-text ml-1">Terms & Conditions</label>
    </p>,               
        validations:[SmartValid.required("Required")] 
        //isRight: true,       
      },
    },
  ];

  const PersonalInfo = () => {
    return (
      <>
        <div className="">
          <p className="has-text-centered is-size-4 crop-login-title mt-1 mb-3">
            {" "}
            Create Account
          </p>
        </div>
        <div className="is-size-6 has-text-weight-bold ml-5 mt-3">
          Personal Details
        </div>
        <div className=" m-5 mb-4 mt-4">
          <div
            key={"BusinessRegistration-column-one"}
            className="columns is-multiline"
          >
            <div className="column">
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
                errorUpdate={(value) => handleErrorChange("euserid", value)}
              />
            </div>

            <div className="is-size-6 has-text-weight-bold column is-12 m-0">
              Business Details
            </div>
            <div
              key={"BusinessRegistration-column-two"}
              className="column is-12"
            >
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={secForm}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
            </div>
            {/* <div
              key={"BusinessRegistration-column-three"}
              className="column is-12 is-centered is-flex"
            >
              <span>
                <SmartCheckRadio
                  options={options_remember_me}
                  name="checkbox_remember_me"
                  value={formData?.checkbox_remember_me}
                  onChange={(value) =>
                    handleInputChange("checkbox_remember_me", value)
                  }
                  isRight={false}
                  validations={RegistrationFormValidations.check_box}
                />
              </span>
              <div className="">
                <span className="has-text-danger">*</span>I acknowledge and
                agree to CROP’s{" "}
                <Link to="/termsand">
                  <u className="">Terms and Conditions</u>
                </Link>
              </div>
            </div> */}
            {/* <div className="columns"> */}
            <div className="column is-12">
              <div className=" ">
                <SmartSoftButton
                  label="Create"
                  width= "12"
                  classList={["crop-login-action-button"]}
                  onClick={() => handleFormSubmit()}
                  //   disabled={!isEmptyObject(formErrors)}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </>
    );
  };

  return <>{PersonalInfo()}</>;
};

export default BusinessRegistration;
