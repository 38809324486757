import { Route, Routes } from "react-router-dom";
import {
  AppFAQsView,
  AppFeedbackForm,
  ComplaintForm,
  ContactUsView,
  PrivacyDataRightsView,
  ProcessMissingCropsTable,
  RequestForm,
  TermsConditionView,
  TermAndConditionNew,
  PrivacyAndDataRightsNew,
  FAQnew,
  AboutUsNew,
} from "../../pages/Business";

const HelpButton = () => {
  return (
    <Routes>
      {/* Help Button /Support  */}
      <Route path="/contact-us-view" element={<ContactUsView />} />
      <Route
        path="/process-missing-crops-list"
        element={<ProcessMissingCropsTable />}
      />
      {/* Help Button /Feedback  */}
      <Route path="/app-feedback-form" element={<AppFeedbackForm />} />
      <Route path="/request-form" element={<RequestForm />} />
      <Route path="/complaint-form" element={<ComplaintForm />} />
      {/* help Button /Important Information  */}
      <Route path="/terms-condition-view" element={<TermsConditionView />} />
      <Route
        path="/privacy-data-right-view"
        element={<PrivacyDataRightsView />}
      />
      <Route
        path="/privacy-data-rights-new"
        element={<PrivacyAndDataRightsNew />}
      />
      <Route path="/app-faqs-view" element={<AppFAQsView />} />
      <Route path="/faqs-new" element={<FAQnew />} />
      <Route path="/about-us-new" element={<AboutUsNew />} />
      <Route path="/terms-condition-new" element={<TermAndConditionNew />} />
    </Routes>
  );
}

export default HelpButton