import { addDays } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SmartSoftButton, SmartSoftForm } from "soft_digi";
import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  ALLOW_FLOAT,
  ALLOW_NUMBERS_EXCEPT_ZERO,
} from "../../../../services/PatternSerivce";
import {
  showAlertAutoClose,
  showNotification,
} from "../../../../services/notifyService";
import { decrypt_data } from "../../../../services/sessionService";
import { post } from "../../../../services/smartApiService";

// import moment from "moment-timezone";
import {
  SmartValid,
  ValidateFormNew,
} from "soft_digi/dist/services/smartValidationService";
import { changeDateTimeZone, changeDateTimeZoneFormat } from "../../../../services/core/CommonService";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import { admin_business_offer_select } from "../../../../services/site/SelectBoxServices";

const MarketOfferForm = () => {
  const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [data, setData] = useState(false);
  const [minEndDate, setMinEndDate] = useState();
  const { setLoading, refreshUser } = useSiteContext();
  const [offers, setOffers] = useState([]);
  // const [toDate, setToDate] = useState(new Date());

  const navigate = useNavigate();

  const baseNav = "/manage-services";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const handleInputChange = (name, value) => {
    if (name === "start_date") {
      setMinEndDate(value);
      clearEndDate();
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const clearEndDate = () => {
    handleInputChange("end_date", "");
    // handleInputChange("start_date", "");
    handleInputChange("base_price", "");
  };

  const handleToDateChange = (value) => {
    if (!formData.start_date || !formData.end_date) {
      return false;
    }
    if (!formData.promos && !formData.top_ranking) {
      return false;
    }
    let post_data = {};
    post_data["promos"] = formData.promos;
    post_data["top_ranking"] = formData.top_ranking;
    // post_data["start_date"] = formData.start_date;
    // post_data["end_date"] = formData.end_date;
    // console.log("start time ", formData.start_date);
    // post_data["start_date_stamp"] = formData.start_date.getTime();
    post_data["end_date"] = changeDateTimeZone(formData.end_date);
    post_data["start_date"] = changeDateTimeZone(formData.start_date);
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = post(
      MANAGE_OFFER_URLS.GET_PRICE,
      post_data,
      handleError
    ).subscribe((response) => {
      handleInputChange("base_price", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    handleToDateChange();
    // addDays(new Date(), 8)
  }, [
    formData.promos,
    formData.top_ranking,
    formData.start_date,
    formData.end_date,
  ]);

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const numericValidations = [
    {
      type: "required",
      msg: "Bid Price is Required",
    },
  ];

  useEffect(() => {
    admin_business_offer_select(0, (data) => setOffers(data));
    setFormData({});
    if (id != "0") {
      loadTableData();
    }
    setMinEndDate(addDays(new Date(), 8));
    // addDays(new Date(), 8)
  }, []);

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    let post_data = { id: decrypted_id, image: 0 };
    const subscription = post(
      MANAGE_OFFER_URLS.GET_ONE,
      post_data,
      handleError
    ).subscribe((response) => {
      //console.log(response.data);
      setFormData(response.data);
      setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formRadioElements)) {
      return false;
    }

    setLoading(true, "Submitting....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let data_in = {};
    let offers_id =
      formData.sd_offers_id && formData.sd_offers_id.value
        ? formData.sd_offers_id.value
        : 0;
    data_in["promos"] = formData.promos;
    data_in["top_ranking"] = formData.top_ranking;
    data_in["end_date"] = changeDateTimeZoneFormat( formData.end_date,"YYYY-MM-DD");
    data_in["start_date"] = changeDateTimeZoneFormat( formData.start_date,"YYYY-MM-DD");
    // data_in["end_date"] = changeDateTimeZone(formData.end_date);
    // data_in["start_date"] = changeDateTimeZone(formData.start_date);
    // data_in["end_date"] = changeDateTimeZone(formData.end_date);
    //data_in["start_date"] = changeDateTimeZone(formData.start_date);
    // data_in["promos"] = data_in["promos"] === undefined ? "No" : "Yes";
    data_in["promos"] = formData["promos"] === "Yes" ? "Yes" : "No";
    data_in["top_ranking"] = formData["top_ranking"] === "Yes" ? "Yes" : "No";
    data_in["sd_offers_id"] = id == "0" ? offers_id : formData.ID;
    data_in["bid_price"] = formData.bid_price;
    data_in["base_price"] = formData.base_price;
    if (formData.top_ranking == "Yes" || formData.promos == "Yes") {
      const subscription = post(
        MANAGE_OFFER_URLS.INSERT_BID,
        data_in,
        handleError
      ).subscribe((response) => {
        //console.log(response.data);
        showAlertAutoClose("Bid Submitted Successfully", "success");
        setLoading(false);
        navigate("/manage-services/market-offers-list");
        refreshUser();
      });
      return () => {
        subscription.unsubscribe();
      };
    } else {
      setLoading(false);
      showAlertAutoClose("Please select Top Ranking, Promos, or both");
    }
  };

  // const options = [
  //   { value: "1", label: "1" },
  //   { value: "2", label: "2" },
  //   { value: "3", label: "3" },
  // ]
  const Validation = {
    startDate: [SmartValid.required("Start Date is Required")],
    endDate: [SmartValid.required("End Date is Required")],
  };
  const optionsRanking = [{ value: "Yes", label: "Apply for Top Ranking" }];
  const optionsPromo = [{ value: "Yes", label: "Apply for Promos" }];

  const formRadioElements = [
    {
      type: "SELECT_BOX",
      width: "12",
      name: "sd_offers_id",
      element: {
        label: "Select Offer",
        placeHolder: "Select Offer",
        inputType: "BORDER_LABEL",
        options: offers,
        // validations: BidAdditionFormValidations.Offer,
      },
      hideFunction: (data) => {
        return id == "0" ? false : true;
      },
    },
    {
      type: "CHECK_RADIO",
      width: "6",
      name: "top_ranking",
      element: {
        options: optionsRanking,
        isHorizontal: true,
        classList: ["smart-input-label-width-60"],
      },
    },
    {
      type: "CHECK_RADIO",
      width: "6",
      name: "promos",
      element: {
        options: optionsPromo,
        isHorizontal: true,
        classList: ["smart-input-label-width-60"],
      },
    },
    {
      type: "DATE",
      width: "6",
      name: "start_date",
      element: {
        label: "Publish Start Date",
        //isHorizontal: true,
        classList: ["smart-input-label-width-60"],
        inputProps: { isFocussed: true },
        dateFormat: "dd/MM/yyyy",
        minDate: addDays(new Date(), 8),
        isRequired: true,
        validations: Validation.startDate,
        maxDate: addDays(new Date(), 37),
      },
    },
    {
      type: "DATE",
      width: "6",
      name: "end_date",
      element: {
        label: "Publish End Date",

        classList: ["smart-input-label-width-60"],
        inputProps: { isFocussed: true },
        dateFormat: "dd/MM/yyyy",
        minDate: minEndDate,
        maxDate: addDays(new Date(), 37),
        isRequired: true,
        validations: Validation.endDate,
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "base_price",
      element: {
        // validations: numericValidations,
        // inputProps: true,
        label: "Base Price (A$)",
        pattern: ALLOW_FLOAT,
        inputProps: { disabled: true },
        // isRequired: true
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "bid_price",
      element: {
        validations: numericValidations,
        // inputType: "BORDER_LABEL_FOCUS",
        inputProps: true,
        label: "Bid Price (A$)",
        pattern: ALLOW_FLOAT,
        isRequired: true,
        max: 8,
      },
    },
  ];

  const tapNavigation = () => {
    return (
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        {/* <span
          className="icon mt-1"
          onClick={() => navigateLink("my-offer-list")}
        >
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
        {/* <span className="ml-5"> Market Offer</span> */}
        <div className="pl-1">
          <SmartHeader title={"Manage Services"} sub_title={"Market Offer"} />
        </div>
      </p>
    );
  };

  const topTitleNav = () => {
    return (
      <div className="is-flex is-justify-content-space-between m-5 smart-crop-primary-text is-size-6 poppins-font-family">
        Offer Title: {data?.offer_title}
        {/* <SmartSoftButton
          label="Cancel"
          onClick={() => navigateLink("my-offer-list")}
          classList={["is-small", "is-rounded", "is-info", "is-outlined"]}
        /> */}
      </div>
    );
  };

  return (
    <>
      {tapNavigation()}
      {id != "0" && topTitleNav()}
      <div className="p-5 smart-crop-business-forms market-offer-form">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formRadioElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
          value={formData?.input_Applied || null}
          onChange={(value) => handleInputChange("input_Applied", value)}
        />
        <div className="is-flex is-justify-content-end m-4">
          <SmartSoftButton
            label="Submit"
            onClick={() => handleFormSubmit()}
            classList={["smart-crop-action-button"]}
            // disabled={!isEmptyObject(formErrors)}
          />
        </div>
      </div>
    </>
  );
};

export default MarketOfferForm;

/*

   <div className="columns crop-business-form">

          <div className="columns is-multiline">
            <div className="column multiline m-4 is-12 ">
             

            </div>
            <div className="column is-12">
              <div className="columns m-4 ">
                <div className="column is-6 " key={"8"}>
                  <SmartSoftDate
                    key="text-4"
                    label="Publish Start Date"
                    value={formData?.start_date || ""}
                    onChange={(value) => handleInputChange("start_date", value, true)}
                    leftIcon="fa-user"
                    inputProps={{ isFocussed: true }}
                    dateFormat="dd/MM/yyyy"
                    minDate={addDays(new Date(), 7)}
                  />
                </div>
                <div className="column is-6 " key={"9"}>
                  <SmartSoftDate
                    key="text-4"
                    label="Publish End Date"
                    value={formData?.end_date || ""}
                    onChange={(value) => handleToDateChange("end_date", value)}
                    leftIcon="fa-user"
                    inputProps={{ isFocussed: true }}
                    dateFormat="dd/MM/yyyy"
                    minDate={toDate}
                  />
                </div>

              </div>
            </div>
            <div className="column multiline m-4 is-12 ">
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
                value={formData?.input_Applied || null}
                onChange={(value) => handleInputChange("input_Applied", value)}
              />

            </div>

          </div>




        </div>
        {/* <div className="column is-3" key={"8"}>
          <SmartSoftDate
            key="text-4"
            label="Publish Start Date"
            value={formData?.start_date || ""}
            onChange={(value) => handleInputChange("start_date", value, true)}
            leftIcon="fa-user"
            inputProps={{ isFocussed: true }}
            dateFormat="dd/MM/yyyy"
            minDate={addDays(new Date(), 7)}
          />
        </div> 
         <div className="column is-3" key={"9"}>
          <SmartSoftDate
            key="text-4"
            label="Publish End Date"
            value={formData?.end_date || ""}
            onChange={(value) => handleToDateChange("end_date", value)}
            leftIcon="fa-user"
            inputProps={{ isFocussed: true }}
            dateFormat="dd/MM/yyyy"
            minDate={toDate}
          />
        </div> 
 <div className="column is-4">
            <div className="ml-6 mt-4">
              
              <table className="is-size-5">
                <tr>
                  <td>Price:</td>
                  <td>{data?.price}/-</td>
                </tr>
                <tr>
                  <td>Discount:</td>
                  <td>{data?.discount}%</td>
                </tr>
                <tr>
                  <td>Brand:</td>
                  <td>{data?.brand}</td>
                </tr>
                <tr>
                  <td>Earn Crop Points:</td>
                  <td>{data?.earn_crop_points}</td>
                </tr>
                <tr>
                  <td>Redeem Crop Points:</td>
                  <td>{data?.redeem_crop_points}</td>
                </tr>
              </table>
            </div>
          </div>
          */
