import { useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid, } from "soft_digi";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
// import {
//     showAlertAutoClose,
//     showYesOrNoAlert,
//   } from "../../../../services/notifyService";
  
  // import { useSiteContext } from "../../../../contexts/SiteProvider";
  import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import { ALLOW_FLOAT } from "../../../../services/PatternSerivce";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
const BidAmed = ({ data, setLoading, closeModal, loadTableData }) => {
    
    const [formData, setFormData] = useState(data);
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const handleInputChange = (name, value) => {
        //console.log(" name ", name, "   value ", value)
        setFormData((prev) => ({ ...prev, [name]: value }));
      };
      const handleErrorChange = (name, value) => {
        setFormErrors((prev) => {
          // Create a copy of the previous state
          const updatedFormData = { ...prev };
          // Check if the value is null or empty
          if (value === null || value === "") {
            // If true, remove the property from the updated state
            delete updatedFormData[name];
          } else {
            // Otherwise, update or add the property in the updated state
            updatedFormData[name] = value;
          }
    
          return updatedFormData;
        });
      };
    //   useEffect(() => {
    //     console.log(data)
    //   }, []);
      const handleFormSubmit = () => {
        setFormSubmit(true);
        if (!ValidateFormNew(formData, [...formElements])) {
          return false;
        }
        // console.log("formdata", formData);
        setFormSubmit(true);
        const handleError = (errorMessage) => {
          showAlertAutoClose(errorMessage, "error");
          setLoading(false);
        };
        setLoading(true, "Updating....Please Wait");
        let post_data = {
            id:data.ID,
            bid_price:formData.new_bid_price

        };
        let url = MANAGE_OFFER_URLS.BID_AMED;
    
        const subscription = post(url, post_data, handleError).subscribe(
          (response) => {
            setFormSubmit(false);
            showAlertAutoClose("Bid Updated Successfully", "success");
            setLoading(false);
            loadTableData();
            closeModal();
          }
        );
        return () => {
          subscription.unsubscribe();
        };
        //console.log("form errors ", formErrors);
      };
      const Validation = {
        bid_prices: [SmartValid.required("Bid Price  is required")],
      };
    const formElements = [
        {
            type: "TEXT_BOX",
            width: "6",
            name: "base_bid_price",
            element: {
            //   inputType: "BORDER_LABEL_FOCUS",
              label: "Base Price",
              inputProps: {
                disabled: true,
              },
            },
          },
          {
            type: "TEXT_BOX",
            width: "6",
            name: "bid_price",
            element: {
            //   inputType: "BORDER_LABEL_FOCUS",
              label: "Bid Price",
              inputProps: {
          disabled: true,
        },
            },
          },
          {
            type: "TEXT_BOX",
            width: "6",
            name: "new_bid_price",
            element: {
            //   inputType: "BORDER_LABEL_FOCUS",
              label: "New Bid Price",
              pattern: ALLOW_FLOAT,
              isRequired: true,
              validations: Validation.bid_prices,
            },
          },
        
      ];
      
    return (
        <>
          <div>
            <div className="smart-customer-input">
              <div className="is-flex is-justify-content-space-between">
                <div className="is-size-4 has-text-weight-bold mt-4">
                  Update Bid
                </div>
                <div className="" onClick={closeModal}>
                  <div className="customer-modal-close">
                    <i class="fa fa-times  has-text-white" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <SmartSoftForm
                formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
          onChange={(value) => handleInputChange("input_four", value)}
        />
        <div className="has-text-centered">
        <SmartSoftButton
          label="Update Bid"
          classList={["smart-crop-action-button"]}
          onClick={handleFormSubmit}
        />
        </div>
       
            </div>
          </div>
        </>
      );

}


export default BidAmed;