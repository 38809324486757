import { Route, Routes } from "react-router-dom";
import { AmendForm, CreateOffersForm, MarketOfferForm, MarketOffersTable, MyOffersTable, MyOffersView, ServiceSettingsForm } from '../../pages/Business';
import MarketOfferInvoice from '../../pages/Business/ManageService/MarketOffers/MarketOfferInvoice';
import PaymentOrder from '../../pages/Business/ManageService/MarketOffers/PaymentOrder';


const ManageServices = () => {
  return ( 
    <Routes>
        {/* Manage Services >My Offers  */}
        <Route path="/my-offer-list" element={<MyOffersTable />} />   
        <Route path="/my-offer-view/:id" element={<MyOffersView />} />   
        <Route path="/market-offer-form/:id" element={<MarketOfferForm />} />    
        <Route path="/amend-form/:id" element={<AmendForm />} />   
        {/* Manage Services >  */}    
        <Route path="/create-offers-form/:id" element={<CreateOffersForm />} />  
        {/* Manage Services >Market Offers  */}
        <Route path="/market-offers-list" element={<MarketOffersTable />} />
        <Route path="/market-offers-payment" element={<PaymentOrder />} /> 
        <Route path="/market-offers-invoice/:id" element={<MarketOfferInvoice />} />   
        {/* Manage Services >Service Settings  */}
        <Route path="/service-settings-form" element={<ServiceSettingsForm />} />  
       
      </Routes>
      
  )
}

export default ManageServices