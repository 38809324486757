// LoginLayout.js
// import React, { useState } from 'react';
// import { useSiteContext } from '../../contexts/SiteProvider';
import { useEffect, useRef, useState } from "react";
import { SERVICES_NOTIFICATION_URLS } from "../../api/Services/ServiceUrls";
import SmartAccordion from "../../components/site/FormElements/SmartAccordion";
import { useSiteContext } from "../../contexts/SiteProvider";
//import "./CustomerLayoutCss.css";
import { showAlertAutoClose } from "../../services/notifyService";
import { post } from "../../services/smartApiService";
import SingleNotification from "./SingleNotification";

const BusinessNotification = ({ noteData, loadProfileData }) => {
  const [active, setActive] = useState(false);
  const { setLoading,refreshUser } = useSiteContext();
  const ref = useRef(null);

  const handleFormSubmit = () => {
    //console.log("formdata", formData);
    // setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      // showNotification("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Updating....Please Wait");
    let url = SERVICES_NOTIFICATION_URLS.MARK_ALL_READ;
    let postData = { status: 5 };
    const subscription = post(url, postData, handleError).subscribe((response) => {
      showAlertAutoClose(response.data.msg, "success");
      setLoading(false);
      setActive(false)
      //loadProfileData();
      refreshUser();
    });
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
    //  console.log("evenet resgistered " , ref.current , " event ", event.target );
      if (ref.current && !ref.current.contains(event.target)) {
       // console.log("event out");
        setActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const card_body = (index) => {
    return <SingleNotification type={index} setActive={setActive} />;
  };

  const getSingleCount = (index) => {
    let item = noteData && noteData.notes ? noteData.notes : [];
    let singleNote = item.find((item) => item.notification_type == index);
    return singleNote && singleNote.count > 0 ? true : false;
  };

 
  const AccountTitle = () => {
    return (
      <>
        <div>
          <div className="position-relative">
            Account Notification
            {getSingleCount("ACCOUNT") && (
              <span className="notification-red-dot"></span>
            )}
          </div>
        </div>
      </>
    );
  };
  const GeneralTitle = () => {
    return (
      <>
        <div>
          <div className="position-relative">
            General Notification
            {getSingleCount("GENERAL") && (
              <span className="notification-red-dot"></span>
            )}
          </div>
        </div>
      </>
    );
  };
  const PurchaseTitle = () => {
    return (
      <>
        <div>
          <div className="position-relative">
          Invoice & Payment Notification
            {getSingleCount("PURCHASE") && (
              <span className="notification-red-dot"></span>
            )}
          </div>
        </div>
      </>
    );
  };
  const RequestTitle = () => {
    return (
      <>
        <div>
          <div className="position-relative">
            Request & Complaint Notification
            {getSingleCount("REQUEST") && (
              <span className="notification-red-dot"></span>
            )}
          </div>
        </div>
      </>
    );
  };

  const data = [
    {
      title: <AccountTitle />,
      bodyFunction: () => card_body("ACCOUNT"),
    },
    {
      title: <GeneralTitle />,
      bodyFunction: () => card_body("GENERAL"),
    },
    {
      title: <PurchaseTitle />,
      bodyFunction: () => card_body("PURCHASE"),
    },
    {
      title: <RequestTitle />,
      bodyFunction: () => card_body("REQUEST"),
    },
  ];

  const notificationCard = () => {
    return (
      <div  ref={ref}
        className={
          active
            ? "column is-1 crop-bussiness-alarm-icon dropdown is-clickable is-active is-right has-text-right"
            : "column is-1 crop-bussiness-alarm-icon dropdown is-right ml-0 has-text-right"
        }
      >
        <i
          className="fa fa-bell pointer"
          aria-hidden="true"
          onClick={() => setActive((active) => !active)}
        ></i>
       {noteData?.total >0 && <span className="notification-bade mr-2">{noteData?.total}</span>}
        <div className="dropdown-menu poppins-font-family">
          <div className="dropdown-content">
            <div className="is-flex is-justify-content-space-between">
              <p className="dropdown-item   has-text-weight-bold is-size-6 ml-2 mt-2">
                Notification
             
              </p>
              <span className=" customer-notification-icons">
              {noteData?.total}
            </span>
              <button className="card-header-icon" aria-label="more options">
                <span
                  className="customer-notification-text is-size-7"
                  onClick={() => handleFormSubmit()}
                >
                  {" "}
                  Mark all as Read
                </span>
                {/* <span className="icon">
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </span> */}
              </button>
            </div>
            <div className="bottom-border"></div>
            <div className="dropdown-item p-0">
              <SmartAccordion data={data} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return notificationCard();
};

export default BusinessNotification;
