import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftButton, SmartSoftTable } from "soft_digi";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";

import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { DownLoadPdf } from "../../../../services/fileService";
import { encrypt_data } from "../../../../services/sessionService";
import BidAmed from "./BidAmed";

const MarketOffersTable = () => {
  const [tabData, setTabData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const { setLoading } = useSiteContext();
  const { openModal, closeModal, refreshUser } = useSiteContext();
  const navigate = useNavigate();
  // const baseNav = "/home";
  // const navigateLink = (index) => {
  //   navigate(baseNav + "/" + index);
  // };
  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = get(
      MANAGE_OFFER_URLS.GET_ALL_BID,
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);


  const statusTags = [
    { value: "SUBMITTED", Label: "SUBMITTED", class: "is-warning" },
    { value: "QUALIFIED", Label: "QUALIFIED", class: "is-info" },
    { value: "PAID", Label: "COMPLETED", class: "is-success" },
    { value: "ACTIVE", Label: "ACTIVE", class: "is-success" },
    { value: "CANCELLED", Label: "CANCELLED", class: "is-danger" },
    { value: "UNSUCCESSFUL", Label: "UNSUCCESSFULL", class: "is-danger" },
    { value: "PUBLISHED", Label: "PUBLISHED", class: "is-success" },
    { value: "COMPLIMENTARY", Label: "COMPLIMENTARY", class: "is-link" },
  ];

  const display_market_offer = (data) => {
    let options = [];
    if (data.top_ranking === "Yes") {
      options.push("TOP RANKING");
    }
    if (data.promos === "Yes") {
      options.push("PROMOS");
    }
    return options.join(", ");
  };

  const submitOrderAndPayment = (id) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading.... Please Wait");
    let url = MANAGE_OFFER_URLS.PAYMENT_INIT;
    let data_in = { id: id };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        //console.log("response " , response);
        let id = encodeURIComponent(encrypt_data("" + response.data.id));
        setLoading(false);
        navigate("/manage-services/market-offers-payment", {
          state: { id: id },
        });
        //navigate("/manage-services/market-offers-payment/" + id);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const downloadReport = (id) => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: id };
    const subscription = post(
      MANAGE_OFFER_URLS.INVOICE_DOWNLOAD,
      post_data,
      handleError
    ).subscribe((response) => {
      console.log(response);
      if (response.data.content) {
        DownLoadPdf(response.data.content, "Invoice.pdf");
      }
      //  setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const invoice = (id) => {
    downloadReport(id);
    // let id_encr = encodeURIComponent(encrypt_data("" + id));
    //navigate("/manage-services/market-offers-invoice/" + id_encr);
  };

  const openNewForm = (data) => {
    let modelObject = {
      body: (
        <BidAmed
          data={data}
          setLoading={setLoading}
          closeModal={closeModal}
          loadTableData={loadTableData}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: true,
    };
    openModal(modelObject);
  };

  const deleteList = (id) => {
    showYesOrNoAlert(
      "Do you wish to Delete this Bid?",
      (selection) => deleteBid(id, selection),
      "danger"
    );
  };

  const deleteBid = (id, action) => {
    if (action === "yes") {
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage, "error");
        setLoading(false);
        // setTabData([]);
      };
      setLoading(true, "Deleting.... Please Wait");
      let url = MANAGE_OFFER_URLS.BID_DELETE;
      let data_in = { id: id.ID };
      const subscription = post(url, data_in, handleError).subscribe(
        (response) => {
          setLoading(false);

          showAlertAutoClose("Bid Deleted Successfully", "success");
          loadTableData();
          refreshUser();
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    }
  };

  const display_action_button = (data) => {
    const from = new Date(data.start_date);
    from.setDate(from.getDate() - 7);
    const current = new Date(currentDate);
    // const differenceInTime = current.getTime() - from.getTime();
    //const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    //let pendingDays = Math.abs(Math.round(differenceInDays));
    //console.log(pendingDays);
    if (data.status_desc === "SUBMITTED") {
      if (current.getTime() <= from.getTime()) {
        return (
          <div className="smart-editgreen-icon is-size-7 ">
            <div onClick={() => openNewForm(data)} className="is-flex">
              {/* <span>Amend</span> */}
              <i
                className="fa fa-pencil is-size-5 ml-3"
                aria-hidden="true"
              ></i>{" "}
            </div>
          </div>
        );
      }
    }

    if (data.status_desc === "QUALIFIED") {
      let id = data.ID !== undefined ? data.ID : 0;
      return (
        <SmartSoftButton
          label="PAY"
          onClick={() => submitOrderAndPayment(id)}
          classList={["is-small", "is-danger"]}
        />
      );
    }
  };

  const display_invoice_button = (data) => {
    if (data.status_desc === "PAID") {
      let id = data.ID !== undefined ? data.ID : 0;
      return (
        <div onClick={() => invoice(id)} className="">
          <i
            className="fa fa-download is-size-5 smart-edit-icon"
            aria-hidden="true"
          ></i>
        </div>
        // <SmartSoftButton
        //   label="View"
        //   onClick={() => invoice(id)}
        //   classList={["is-small", "is-link"]}
        // />
      );
    }
  };
  const cancelButton = (data) => {
    showYesOrNoAlert(
      data.message,
      (selection) => handle_redirection(data, selection),
      "info"
    );
  };

  const handle_redirection = (data, selection) => {
    if (selection === "yes") {
      console.log("You click On Yes button", data);
      let payload = {
        id: data.ID,
      };
      setLoading(true, "Loading....Please Wait");
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage, "error");
        setLoading(false);
      };
      const subscription = post(
        MANAGE_OFFER_URLS.BID_CANCEL,
        payload,
        handleError
      ).subscribe((response) => {
        setLoading(false);
        loadTableData();
        showAlertAutoClose("Bid Cancelled Successfully", "success");
        refreshUser();
      });
      return () => {
        subscription.unsubscribe();
      };
      // navigateLink("market-offer-form/" + id_navigation);
    } else {
      // console.log("You click On No button")
      // navigateLink("my-offer-list");
    }
  };

  // const CancelButtonIcon = [
  //   {
  //     label: "",
  //     leftIcon: "fa fa-times-circle is-size-4",
  //     type: "icon",
  //     classList: ["smart-delete-icon"],
  //   },
  // ];

  const display_cancel_button = (data) => {
    const from = new Date(data.start_date);
    const current = new Date(currentDate);
    const differenceInTime = current.getTime() - from.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    let pendingDays = Math.abs(Math.round(differenceInDays));
    const submitted_date = new Date(data.start_date);
    submitted_date.setDate(submitted_date.getDate() - 7);
    // let pendingDaysConcat =
    //   pendingDays === 1 ? pendingDays + " day" : pendingDays + " days";
    // console.log(current.getTime() >= from.getTime());
    // debugger
    if (current.getTime() <= from.getTime()) {
      if (data.status_desc !== "CANCELLED") {
        if (data.status_desc === "PAID") {
          // let id = data.ID !== undefined ? data.ID : 0;
          if (pendingDays <= 2) {
            // data.message =
            //   "Do you wish to cancel this Bid? " +
            //   pendingDaysConcat +
            //   " only is there" +
            //   " for bids starting " +
            //   data.start_date +
            //   ".  After cancellation you are not getting refund";
            data.message =
              "This cancellation is within 48 hours of publication and would not be entitled to a refund. Do you wish to continue?";
          } else {
            // data.message =
            //   "Do you wish to cancel this Bid? " +
            //   pendingDaysConcat +
            //   " only is there" +
            //   " for bids starting " +
            //   data.start_date +
            //   ".  After cancellation your getting 50% of refund only";
            data.message =
              "This action will incur a cancellation fee amounting to 50% of bid amount. Do you wish to continue?";
          }

          return (
            <div className=" " onClick={() => cancelButton(data)}>
              <div className="is-small is-flex smart-delete-icon ">
                {/* Cancel */}
                <i
                  className="fa fa-times-circle-o  is-size-5"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            // <SmartSoftButton
            //   label="Cancel"
            //   onClick={() => cancelButton(data)}
            //   classList={["is-small", "is-warning"]}
            // />
          );
        } else {
          if (data.status_desc === "SUBMITTED") {
            data.message = "Do you wish to cancel this Bid?";
            // let id = data.ID !== undefined ? data.ID : 0;
            return (
              current.getTime() <= submitted_date.getTime() && (
                <div className=" " onClick={() => deleteList(data)}>
                  <div className="is-small is-flex smart-delete-icon">
                    <i
                      className="fa fa-trash  is-size-5 "
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                // <SmartSoftButton
                //   label="Cancel"
                //   onClick={() => cancelButton(data)}
                //   classList={["is-small", "is-warning"]}
                // />
              )
            );
          }
        }
      }
    }
  };
  // const Buttons = [
  //   {
  //     leftIcon: "fa fa-arrow-up",
  //     onclick: () => {
  //       // console.log("Test");
  //     },
  //   },
  //   {
  //     leftIcon: "fa fa-arrow-down",
  //     onclick: () => {
  //       // console.log("Test");
  //     },
  //   },
  // ];

  const columns = [
    { title: "S.No", index: "sno", type: "sno" },
    { title: "Offer Title", index: "offer_title", width: "20" },
    {
      title: "Market For",
      index: "marketfor",
      valueFunction: display_market_offer,
    },
    { title: "Base Price (A$)", index: "base_bid_price" },
    { title: "Bid Price (A$)", index: "bid_price" },
    {
      title: "From Date",
      index: "start_date",
      // isSortable: true,
      type: "date",
      dateFormat: "DD-MM-YYYY",
      autoTimeZone: true,
    },
    {
      title: "To Date",
      index: "end_date",
      // isSortable: true,
      type: "date",
      dateFormat: "DD-MM-YYYY",
      autoTimeZone: true,
    },
    {
      title: "Invoice",
      index: "invoice",
      valueFunction: display_invoice_button,
    },
    { title: "Status", index: "status_desc", type: "tags", tags: statusTags },

    {
      title: "Action",
      index: "action",
      valueFunction: display_action_button,
    },
    {
      title: "",
      index: " ",
      // type: "buttons",
      // buttons: CancelButtonIcon,
      valueFunction: display_cancel_button,
    },
  ];

  // const data = [
  //   {
  //     sno: "1",
  //     offertitle: "Mobiles",
  //     status: "process",
  //     marketfor: "",
  //     baseprice: 200,
  //     bidprice: 100,
  //     invoice: 10,
  //     fromdate: "2022-09-17",
  //     todate: "2022-10-29",
  //   },
  // ];
  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
        {/* <span className="ml-5">My Bids</span> */}
        <div className="pl-1">
          <SmartHeader title={"Manage Services"} sub_title={"My Bids"} />
        </div>
      </p>
      <div className="columns">
        <div className="column mx-3">
          <SmartSoftTable
            columns={columns}
            data={tabData}
            tableProps={{ className: "crop-bussiness-table-layout" }}
          />
        </div>
      </div>
    </>
  );
};

export default MarketOffersTable;
