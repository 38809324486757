import { Route, Routes } from "react-router-dom";
import { MyCardFormview, MyCropsTable, MyCropsView, StatementForm } from '../../pages/Business';

const MyAccount = () => {
  return (
    <Routes>
    {/* My Account >My Crops */}
    <Route path="/my-crops-list" element={<MyCropsTable />} /> 
    <Route path="/my-crops-view" element={<MyCropsView />} /> 
    {/* My Account >Statement */}
    <Route path="/statement-form" element={<StatementForm />} /> 
    {/* My Account >My Card */}
    <Route path="/my-card-form-view" element={<MyCardFormview />} />
  
  </Routes>
  )
}

export default MyAccount