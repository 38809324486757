
import {
    FACE_BOOK_PIC,
    GOOGLE_PLAY_PIC,
    INSTAGRAM_PIC,
    LINKEDIN_PIC,
    SHAPE_BG_FOUR,
    SHAPE_BG_TWO,
    SNAPE_BG_FIVE,
  } from "../../services/ImageService";
  import { Link } from "react-router-dom";
  import { AppFAQsView, PrivacyDataRightsView, TermsConditionView } from "../../pages/Business";
import { useSiteContext } from "../../contexts/SiteProvider";
// import AboutUsNew from "../../pages/Business/ImportantInformation/AboutUsNew";


  
  const QuickLinks = () => {
  
    const { setLoading, openModal, closeModal } = useSiteContext();


    // const openAboutForm = () => {

    //   let modelObject = {
    //     body: <AboutUsNew header={false} closeModal={closeModal} />,
    //     modalClass: "crop-customer-modal smart-modal-80",
    //     bodyClose: true,
    //   };
    //   openModal(modelObject);
    // };

    return (
      <>
        <div className="column ">
        <p className="customer-footer-head is-size-5 mb-3">Quick Links</p>
          <ul className="customer-footer-type-none has-text-weight-medium">
            <li>
              <Link to="https://cropglobalservices.com/" className="has-text-black ">
                {" "}
                Home
              </Link>
            </li>
            <li>
              <Link
                //  onClick={() =>openAboutForm()}
                to="/about-us-new"
                className="has-text-black "
              >
                {" "}
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="https://cropglobalservices.com/login"
                className="has-text-black "
              >
                Customer Login
              </Link>
            </li>
            <li>
              <Link to="/" className="has-text-black ">
                {" "}
                Business Login
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  };
  const MyCROP = () => {

    const { setLoading, openModal, closeModal } = useSiteContext();


    // const openTermsForm = () => {

    //   let modelObject = {
    //     body: <TermsConditionView header={false} closeModal={closeModal} />,
    //     modalClass: "crop-customer-modal smart-modal-80",
    //     bodyClose: true,
    //   };
    //   openModal(modelObject);
    // };

    // const openPrivacyForm = () => {

    //   let modelObject = {
    //     body: <PrivacyDataRightsView header={false} closeModal={closeModal} />,
    //     modalClass: "crop-customer-modal smart-modal-80",
    //     bodyClose: true,
    //   };
    //   openModal(modelObject);
    // };

    // const openFAQForm = () => {

    //   let modelObject = {
    //     body: <AppFAQsView header={false} closeModal={closeModal} />,
    //     modalClass: "crop-customer-modal smart-modal-80",
    //     bodyClose: true,
    //   };
    //   openModal(modelObject);
    // };


    return (
      <>
        <div className="column ">
          <p className="customer-footer-head is-size-5 mb-3"> My CROP</p>
          <ul className="customer-footer-type-none has-text-weight-medium">
            <li>
              <Link to="/faq" className="has-text-black ">
                {" "}
                Frequently Asked Questions
              </Link>
            </li>
            <li>
              <Link to="/trems-and-conditions" className="has-text-black ">
                {" "}
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link
                // onClick={() => openPrivacyForm()}
                to="/privacy-and-data-policy"
                className="has-text-black "
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  };
  
  const Followus = () => {
    const shareToFacebook = () => {
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(window.location.href)
      );
    };
    return (
      <>
        <div className="column ">
          <p className="customer-footer-head is-size-5 mb-3">Follow us</p>
          <span className="pr-3 facebook-instagram-linkedin">
            <Link
              to="https://www.facebook.com/CROPServicesPtyLtd"
              className="has-text-black "
              // onClick={() => shareToFacebook()}
              target="_blank"
  
            >
              <img src={FACE_BOOK_PIC} alt=""></img>
            </Link>
          </span>
          <span className="pr-3 facebook-instagram-linkedin">
            <Link
              to="https://www.instagram.com/crop_global_services/"
              className="has-text-black "
              target="_blank"
            >
              <img src={INSTAGRAM_PIC} alt=""></img>
            </Link>
          </span>
          <span className="pr-3 facebook-instagram-linkedin">
            <Link
              to="https://www.linkedin.com/company/cropservicesptyltd"
              className="has-text-black "
              target="_blank"
            >
              {" "}
              <img src={LINKEDIN_PIC} alt=""></img>
            </Link>
          </span>
        </div>
      </>
    );
  };
  
  const applegooglebtn = () => {
    const gotoAppStor = () => {
   
      window.open(
        "https://apps.apple.com/in/app/crop-loyalty/id6503723149" 
  
      );
    };
    const gotoPlayStor = () => {
      window.open("https://play.google.com/store/apps/details?id=com.crop.storeapp", "_blank", "noopener,noreferrer");
  };
    return (
      <>
        <div className="columns">
          <div className="column ">
            <button className="button p-5 mt-4 " onClick={()=>gotoAppStor()}>
              <span className="icon">
                <i className="fa fa-apple is-size-2 mr-2"></i>
              </span>
              <div className=" customer-primary-font">
                <div className="is-size-7">Download on the</div>
                <div className="has-text-weight-bold">App Store</div>
              </div>
            </button>
          </div>
  
          <div className="column ">
            <button className="button p-5 mt-4" onClick={()=>gotoPlayStor()}>
              <span className="pt-2 google_play_pic mr-2">
              
                  <img src={GOOGLE_PLAY_PIC} alt=""></img>
            
              </span>
              <div className=" customer-primary-font">
                <div className="is-size-7">Get it on </div>
                <div className="has-text-weight-bold">Google Play</div>
              </div>
            </button>
          </div>
        </div>
      </>
    );
  };
  
  const backgroundfooterimages = () => {
    return (
      <>
        <div className="column  mediabackgroundfooterimages is-hidden-mobile">      
          <div className="customer-footer-background-image-one ">
            <img src={SHAPE_BG_TWO} alt="one"></img>
          </div>
          <div className="customer-footer-background-image-two">
            <img src={SNAPE_BG_FIVE} alt="two"></img>
          </div>
          <div className="customer-footer-background-image-three">
            <img src={SHAPE_BG_FOUR} alt="three"></img>
          </div>
          <div className="customer-footer-text-copy-right  fredoka-font-family">
            Copyright © 2023 by CROP Services Pty Ltd All rights reserved.
          </div>
        </div>
      </>
    );
  };
  
  const BusinessFooter = () => {
    return (
      <>
        <div className="CustomerFooter ">
          <div className="customer-footer-content pl-6 pr-6 mb-7 pb-6">
            <div className="columns primary-font-family">
              <QuickLinks  />
              <MyCROP />
              {Followus()}
              {applegooglebtn()}           
            </div>        
          </div>
          <div className="columns">{backgroundfooterimages()}</div>
        </div>
      </>
    );
  };
  
  export default BusinessFooter;
  