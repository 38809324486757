// Rating.js
import React from 'react';

const Star = ({ filled }) => {
  return (
    <span className=" has-text-warning is-size-4">{filled ? "★" : ""}</span>
  );
};

const Rating = ({ rating }) => {
  const totalStars = 5;

  return (
    <div className="rating">
      {[...Array(totalStars)].map((_, index) => (
        <Star key={index} filled={index < rating} />
      ))}
      {/* <span className="rating-number">{rating}</span> */}
    </div>
  );
};

export default Rating;
