import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SmartButton from "soft_digi/dist/forms/SmartButton";

import { MANAGE_OFFER_IMAGE_URLS } from "../../../../api/Business/OffersUrls";
import { ORDER_ITEMS_URLS } from "../../../../api/OrderUrls";
import SmartImageDisplay from "../../../../components/site/FormElements/SmartImageDisplay";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import {
  decrypt_data,
  getSessionStorage,
} from "../../../../services/sessionService";
import { post } from "../../../../services/smartApiService";
import OrderCancellation from "./OrderCancellation";
import OrderUploade from "./OrderUploade";
// import moment from "moment";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import {
  changeDateTimeZoneFormat,
  changeDateTimeZoneWithTime,
} from "../../../../services/core/CommonService";
import "./Order.css";

const OrderInfo = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  const { setLoading, closeModal, openModal, refreshUser } = useSiteContext();
  const navigate = useNavigate();

  // const formattedDate = moment(data?.order_time).format("DD-MM-YYYY,h:mm:ss");
  // const [isOpen, setIsOpen] = useState(false);
  const handleBack = () => {
    let test_session = getSessionStorage("crop_business_order_type");
    navigate("/manage-program/orders/" + test_session);
  };
  // console.log("decrypted id = ",decrypted_id, " id = " , id);
  // const toggleFormOpen = () => {
  //   setIsOpen(!isOpen);
  // };
  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: decrypted_id };
    const subscription = post(
      ORDER_ITEMS_URLS.GET_ONE,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      // console.log(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [decrypted_id]);

  // const offer_title_disp = (data) => {
  //   if (!data || !data?.variant_info) {
  //   }

  //   let varient_data = data?.variant_info;
  //   const parsedData = JSON.parse(varient_data);

  //   return (
  //     <>
  //       <p>
  //         {parsedData.map((item, index) => {
  //           return (
  //             <span key={index}>
  //               <b>{item?.variant_group}</b> : {item?.variant_name}
  //               <br />
  //             </span>
  //           );
  //         })}
  //       </p>
  //     </>
  //   );
  // };
  const offer_title_disp = (data) => {
    if (!data || !data.variant_info) {
      return <p>No variant information available.</p>;
    }

    let varient_data = data.variant_info;
    let parsedData;

    try {
      parsedData = JSON.parse(varient_data);
    } catch (error) {
      return <p>Invalid variant information.</p>;
    }

    return (
      <p>
        {parsedData.map((item, index) => (
          <span key={index}>
            <b>{item.variant_group}</b> : {item.variant_name}
            <br />
          </span>
        ))}
      </p>
    );
  };

  const OrderNumber = () => {
    return (
      <>
        <div
          key={"order-number-columns"}
          className="columns smart-order-number-view"
        >
          <div key={"order-one"} className="column is-6">
            <div className=" has-text-weight-bold">Order No</div>
            <div className="is-size-5">{data?.order_id}</div>
          </div>
          <div key={"order"} className="column is-6  mediya-screen-date-time">
            <div className=" has-text-weight-bold">Order Date & Time</div>
            <div className="is-size-5">
              {changeDateTimeZoneWithTime(data?.order_time)}
            </div>
          </div>
        </div>

        {/* <div className="columns">
          <div className="column">State
          
          </div>
        </div> */}
      </>
    );
  };
  const Delivery_Address = () => {
    return (
      <div className="columns">
        {data?.offer_mode === "DELIVERY" ? (
          <>
            <div className="column is-12">
              <div className="offer-Price">
                {/* Dispatch Address */}
                <span className="has-text-weight-bold is-size-5">
                  Delivery Address
                </span>
                <div className="my-1">
                  {data?.addr_data?.addr_one &&
                    data?.addr_data?.addr_one.length > 0 && (
                      <p className="mt-2 has-text-weight-medium">
                        {data?.addr_data.addr_one},
                      </p>
                    )}
                  {data?.addr_data?.addr_two &&
                    data?.addr_data?.addr_two.length > 0 && (
                      <p className="mt-2 has-text-weight-medium">
                        {data?.addr_data.addr_two},
                      </p>
                    )}
                  {data?.addr_data?.city &&
                    data?.addr_data?.city.length > 0 && (
                      <p className="mt-2 has-text-weight-medium">
                        {data?.addr_data.city},
                      </p>
                    )}
                  <div className="is-flex">
                    <p className="mt-2 has-text-weight-medium">
                      {data?.addr_data.statename}
                    </p>
                    <p className="mt-2 ml-2 has-text-weight-medium">
                      - {data?.addr_data.pincode}
                    </p>
                  </div>
                  {/* {data?.addr_data.addr_one},{data?.addr_data.addr_two},
                  State
                  <br />
                  {data?.addr_data.statename},{data?.addr_data.city},
                  Zip Code
                  <br />
                  {data?.addr_data.pincode}, */}
                </div>
                {/* <div className="columns">
              <div className="column is-3">
                State
                <div className="has-text-weight-bold">
                  {data?.addr_data.statename}
                </div>
              </div>
              <div className="column is-3">
                Zip Code
                <div className="has-text-weight-bold">
                  {data?.addr_data.pincode}
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </>
        ) : (
          " "
        )}
      </div>
    );
  };

  const ProductImage = () => {
    let url = MANAGE_OFFER_IMAGE_URLS.GET_ONE_IMAGE_ID;
    let id = data?.sd_offers_id !== undefined ? data.sd_offers_id : 0;

    return (
      <>
        <div className="mt-4">
          <div className="product-offer-title my-2"></div>
          <div className="is-size-5 has-text-weight-bold my-1 ml-5">
            {data?.offer_title}
          </div>
          <div className="offer-Price">
            <span className="is-size-5 has-text-weight-bold">Variants</span>
            <div className="is-size-6 has-text-weight-normal my-1">
              {offer_title_disp(data)}
            </div>
            {data?.custom_note?.length > 0 && (
              <div className="">Customer Note: {data.custom_note}</div>
            )}
          </div>
          <div className="order-info-image mt-2">
            <SmartImageDisplay srcType="URL" url={url} id={id} />
          </div>
        </div>
      </>
    );
  };
  const ProductDecs = () => {
    return (
      <>
        <div className="mt-4">
          {/* <div className="order-type ">Order Type</div> */}
          <div className="is-size-6 has-text-weight-bold my-1">
            {/* <div className="order-type ">Order Type</div>
          <div className="is-size-5 has-text-weight-bold my-1">
            {data?.order_type}
          </div> */}
            <div className="columns is-multiline is-mobile">
              <div className="column is-4 is-flex-mobile">
                <div className="offer-Price  ">
                  <span className="is-size-5 has-text-weight-bold">
                    Order Type
                  </span>
                  <div className="is-size-6  has-text-weight-normal my-1">
                    {data?.order_type}
                  </div>
                </div>
              </div>
              <div className="column is-flex-mobile is-4">
                <div className="offer-Price">
                  <span className="is-size-5 has-text-weight-bold">Mode</span>
                  <div className="is-size-6 has-text-weight-normal my-1">
                    {data?.offer_mode}
                  </div>
                </div>
              </div>
            </div>

            <div className="columns is-multiline is-mobile">
              {data?.order_type === "EARN" ? (
                <>
                  <div className="column is-4 is-flex-mobile">
                    <div className="offer-Price">
                      <span className="is-size-5 has-text-weight-bold">
                        Price (A$)
                      </span>
                      <div className="is-size-6 has-text-weight-normal my-1">
                        {data?.total_price}
                      </div>
                    </div>
                  </div>
                  <div className="column  is-4 is-flex-mobile">
                    <div className="offer-Price">
                      <span className="is-size-5 has-text-weight-bold">
                        Earned
                      </span>
                      <div className="is-size-6 has-text-weight-normal my-1">
                        {data?.order_crops}
                      </div>
                    </div>
                  </div>
                  <div className="column is-flex-mobile is-4">
                    <div className="offer-Price">
                      <span className="is-size-5 has-text-weight-bold">
                        Quantity
                      </span>
                      <div className="is-size-6 has-text-weight-normal my-1 ml-1">
                        {data?.quantity}
                      </div>
                    </div>
                  </div>
                  {/* <div className="column is-12">
                    <div className="offer-Price">
                    Dispatch Address
                      <div className="is-size-5 has-text-weight-bold my-1">
                        {data?.addr_data.addr_one},
                         {data?.addr_data.addr_two},
                         {data?.addr_data.statename},
                         {data?.addr_data.city},
                         {data?.addr_data.pincode},
                      </div>
                    </div>
                  </div> */}
                </>
              ) : (
                ""
              )}
              {/* <div className="columns">
                {data?.offer_mode === "DELIVERY" ? (
                  <>
                    <div className="column is-12">
                      <div className="offer-Price">
                        Dispatch Address
                        <div className="is-size-5 has-text-weight-bold my-1">
                          {data?.addr_data.addr_one},{data?.addr_data.addr_two},
                          {data?.addr_data.statename},{data?.addr_data.city},
                          {data?.addr_data.pincode},
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  " "
                )}
              </div> */}

              {data?.order_type !== "EARN" ? (
                <div className="column is-flex-mobile is-6">
                  <div className="offer-Price">
                    Redeemed
                    <div className="is-size-5 has-text-weight-bold my-1">
                      {data?.order_crops}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  const ProductStatus = () => {
    return (
      <>
        <div className="mt-3 is-flex columns">
          <div className="offer-Price column is-3">
            <span className="is-size-5 has-text-weight-bold">Status</span>
            <div className="is-size-6 has-text-weight-normal my-1">
              {data?.status_short}
            </div>
          </div>
        </div>
      </>
    );
  };

  // const trackerDisplay = () => {
  //   // console.log("maked ", data)
  //   return data.track.map((item, index) => {
  //     return (
  //       <div className="columns is-mobile is-multiline" key={index}>
  //        <div className={item.status?"column  is-flex-mobile is-6 smart-track-ball-container-left-Active":"column is-6 smart-track-ball-container-left"}>
  //           {item.last_modified_time &&
  //             changeDateTimeZoneWithTime(
  //               item.last_modified_time,
  //               "DD-MM-YYYY"
  //             )}{" "}
  //           <br />
  //           {item.last_modified_time &&
  //             changeDateTimeZoneWithTime(item.last_modified_time, "HH:MM")}
  //           {/* <div className="smart-track-ball"></div> */}
  //           <div className={item.status?"smart-track-ball TrackerFirstLineActive":"smart-track-ball  TrackerFirstLine"}></div>
  //         </div>
  //         <div className="column is-6 smart-track-ball-container-right is-flex-mobile">
  //           <span className="">{item?.status_value}</span>
  //           <br />{" "}
  //           <span className="is-size-7 word-drop-ball-text">
  //             {item.remarks}
  //           </span>
  //         </div>
  //       </div>
  //     );
  //   });
  // };

  const trackerDisplay = () => {
    // console.log("maked ", data)
    return data.track.map((item, index) => {
      // const formattedDate = moment(item.last_modified_time).format('DD-MM-YYYY,h:mm:ss');
      // const formattedDateOnly = moment(item.last_modified_time).format(
      //   "DD-MM-YYYY  hh:mm"
      // );
      let ball_classes = [
        "column is-flex-mobile is-6 smart-track-ball-container",
      ];

      //ball_classes.push("smart-ball-is-active");
      let left_classes = [...ball_classes];
      left_classes.push("has-text-right");
      left_classes.push("smart-track-ball-container-left");
      if (item.status) left_classes.push("smart-ball-is-active");
      if (index === data.track.length - 1)
        left_classes.push("smart-track-cont-last");

      let right_classes = [...ball_classes];
      right_classes.push("has-text-left");

      return (
        <div
          className="columns smart-crop-tracker is-mobile is-multiline"
          key={index}
        >
          <div className={left_classes.join(" ")}>
            {item.last_modified_time &&
              changeDateTimeZoneFormat(item.last_modified_time, "DD-MM-YYYY")}
            <br />
            {item.last_modified_time &&
              changeDateTimeZoneFormat(item.last_modified_time, "H:mm")}
            {/* <div className="smart-track-ball"></div> */}
            <div
              className={
                item.status
                  ? "smart-track-ball TrackerFirstLineActive"
                  : "smart-track-ball  TrackerFirstLine"
              }
            ></div>
            {/* {index===data.track.length-1 && 
              <div className="smart-track-ball TrackerFirstLineActive smart-track-ball-last"></div>} */}
          </div>
          <div className={right_classes.join(" ")}>
            {item?.status_value} <br /> <small>{item.remarks}</small>
          </div>
        </div>
      );
    });
  };
  const Tracker = () => {
    return (
      <>
        <div className="card p-4 tracker-trackerDisplay ">
          {data && data.track && trackerDisplay()}
        </div>
      </>
    );
  };

  // const Total = () => {
  //   return (
  //     <>
  //       <div className="columns is-vertical is-centered">
  //         <div className="column is-4 is-offset-8 is-flex is-size-5">
  //           <div className="">
  //             {data?.quantity}
  //             <span className="mx-2">Item</span>
  //           </div>
  //           <div className="">Price A$: {data?.total_price}</div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const openNewForm = (title) => {
    let modelObject = {
      title: title,
      body: (
        <OrderCancellation
          data={data}
          setLoading={setLoading}
          closeModal={closeModal}
          loadOrderData={loadTableData}
          refreshUser={refreshUser}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const openNImageForm = (title) => {
    let modelObject = {
      title: title,
      body: (
        <OrderUploade
          data={data}
          setLoading={setLoading}
          closeModal={closeModal}
          loadOrderData={loadTableData}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const Buttons = () => {
    return (
      <>
        <div className="columns mt-3 is-multiline">
          <div className="column has-text-right ">
            {data && parseInt(data.status) === 10 && (
              <SmartButton
                label="Update"
                classList={["smart-customer-primary-button "]}
                onClick={() => openNewForm("Update Form")}
              />
            )}
            {data && parseInt(data.status) === 96 && (
              <SmartButton
                label="Update"
                classList={["smart-customer-primary-button "]}
                onClick={() => openNewForm("Update Form")}
              />
            )}
            {data && parseInt(data.status) === 15 && (
              <SmartButton
                label="Update"
                classList={["smart-customer-primary-button"]}
                onClick={() => openNewForm("Update Form")}
              />
            )}
            {data && parseInt(data.status) === 20 && (
              <SmartButton
                label="Update"
                classList={["smart-customer-primary-button "]}
                onClick={() => openNewForm("Update Form")}
              />
            )}

            {/* <div className="has-text-centered">
              {data && parseInt(data.status) === 30 && (
                <SmartButton
                  label="Update5"
                  classList={["crop-login-action-button is-rounded "]}
                  onClick={() => openNewForm("Update Return Order")}
                />
              )}
            </div> */}

            {data && parseInt(data.status) === 35 && (
              <SmartButton
                label="Update"
                classList={["smart-customer-primary-button"]}
                onClick={() => openNewForm("Update Form")}
              />
            )}
            {data && parseInt(data.status) === 90 && (
              <SmartButton
                label="Update"
                classList={["smart-customer-primary-button"]}
                onClick={() => openNewForm("Order Cancellation Form")}
              />
            )}
            {/* {data && parseInt(data.status) === 92 && ( 
            <SmartButton
              label="Update Cancel Refund Request"
              classList={["crop-login-action-button is-rounded "]}
              onClick={()=>openNewForm("Order Cancellation Form")}
            />
           )} */}
            {/* {data && parseInt(data.status) === 20 && ( 
            <SmartButton
              label="Update Delivered"
              classList={["crop-login-action-button is-rounded "]}
              onClick={()=>openNewForm("Order Update")}
            />
           )} */}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="smart-bussiness-header pb-3 pl-3 pt-4 is-flex is-justify-content-space-between">
        {/* <span className="ml-5">Order Info</span>          */}
        <div className="pl-1 ">
          <SmartHeader title={"Manage Program"} sub_title={"Order Info"} />
        </div>
      </div>
      <div
        className="smart-customer-back has-text-info has-text-right mr-6 is-hidden-mobile pointer p-2"
        onClick={() => handleBack()}
      >
        <div onClick={() => handleBack()}>
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          {""} Go Back
        </div>
      </div>
      <div className="columns">
        <div className="column is-12">
          <div className="container m-5">
            <span>{OrderNumber()}</span>
            <div className="columns">
              <div className="column is-4">
                {Delivery_Address()}
                <div className="">{ProductImage()}</div>
              </div>
              <div className="column is-4">
                {ProductDecs()}
                <div className="">{ProductStatus()}</div>
              </div>
              <div className="column is-4">{Tracker()}</div>
            </div>

            <div className="columns smart-order-number-view pb-2">
              <div className="column is-6">
                {" "}
                {/* <div className=" mb-6">{ProductImage()}</div> */}
                <div className="columns">
                  {data?.status === 30 || data?.status === 35 ? (
                    <div className="column image-icon-color mt-6">
                      <p>
                        <span className="has-text-weight-bold">
                          Return Reason:
                        </span>
                        {data.cancel_reason}
                      </p>
                      <p>
                        <span className="has-text-weight-bold">
                          Return Remarks:
                        </span>
                        {data.cancel_remarks}
                      </p>
                      <div
                        onClick={() => openNImageForm()}
                        className="Return-image-view"
                      >
                        <i
                          class="fa fa-hand-o-right has-text-success-dark image-icon-color"
                          aria-hidden="true"
                        ></i>
                        <u className="ml-2 has-text-success-blue">
                          Return Image
                        </u>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div className="column is-3">{ProductDecs()}</div>
              <div className="column is-3 ">{ProductStatus()}</div> */}
              {/* <div className="column is-4">{Tracker()}</div> */}
            </div>
            <div className="columns">
              {/* <div className="column is-6">
                {ProductImage()}
                <div className="columns">
                  {data?.status == 30 ? (
                    <div className="column">
                      <div onClick={() => openNImageForm()} className="">
                        <i
                          class="fa fa-picture-o has-text-success-dark image-icon-color"
                          aria-hidden="true"
                        ></i>
                        Return Image
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div> */}
              {/* <div className="column is-3">{Tracker()}</div> */}
            </div>
            {/* <div className="columns">
              {data?.status == 30 ? (
                <div className="column">
                  <div onClick={() => openNImageForm()} className="">
                    <i
                      class="fa fa-picture-o has-text-success-dark image-icon-color"
                      aria-hidden="true"
                    ></i>
                    Return Image
                  </div>
                </div>
              ) : (
                ""
              )}
            </div> */}
            <div className="columns smart-order-number-view pb-4 is-centered">
              {/* {data?.status == 30 ? (
                <div className="column is-6">Return image and remarks</div>
              ) : (
                ""
              )} */}

              <div className="column is-2 has-text-centered">{Buttons()}</div>
            </div>
            <div className="columns is-centered">
              <div className="column is-2 has-text-centered">
                {data && parseInt(data.status) === 30 && (
                  <SmartButton
                    label="Update"
                    classList={["smart-customer-primary-button "]}
                    onClick={() => openNewForm("Update Return Order")}
                  />
                )}
              </div>
            </div>
            {/* <div>{Buttons()}</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInfo;
