import { useState } from 'react';
import { SmartSoftButton, SmartSoftForm, SmartValid } from 'soft_digi';
import { ValidateFormNew } from 'soft_digi/dist/services/smartValidationService';
import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import { useSiteContext } from '../../../../contexts/SiteProvider';
import { showNotification } from '../../../../services/notifyService';
import { ALLOW_FLOAT } from "../../../../services/PatternSerivce";
import { post } from '../../../../services/smartApiService';

const CustomerTransactionAddNewFrom = ({ closeModal,setLoading,loadTableData ,  navigate}) => {
  const [formData, setFormData] = useState({crop_point:0});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);  
  const { user  } =useSiteContext();

  const handleInputChange = (name, value) => {
    //console.log(" name ", name, "   value ", value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const totalPrice = () => {
    let total_price = formData.price;
    return !isNaN(total_price) ? total_price : 0;
    
  };

  const earnCrops =()=>{
    return parseInt(totalPrice()) * user?.earnFraction;
  }

  const redeemCrops =()=>{
    return parseInt(totalPrice()) * user?.redeemFraction;
  }


  const FormValidations = {
    offer_title: [SmartValid.required("Offer Title is Required")],
      price: [SmartValid.required("Amount is Required")],
      gst: [SmartValid.required("GST is Required")],
    };
  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "offer_title",
      element: {
        label: "Offer Title",
        validations: FormValidations.offer_title,
        //  pattern:"[A-Za-z0-9 ]*",
         isRequired: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "price",
      element: {
        label: "Amount A$",
        validations: FormValidations.price,
         pattern: ALLOW_FLOAT,
         isRequired: true,
        // isHorizontal: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "gst_value",
      element: {
        // inputType: "BORDER_LABEL_FOCUS",
        validations: FormValidations.gst,
        label: "GST (%)",
        pattern: ALLOW_FLOAT,
        isRequired: true,
        max: 4,
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "crop_point",
      element: {       
        label: "Earn CROPs",      
        // valueFunction: () => {
        //   let earn_points = formData.price ? parseFloat(formData.price) : 0;
        //   return "" + parseInt(earn_points * 1);
        // },
        valueFunction: () => {
          //  let earn_points = totalPrice();
          const result = !isNaN(earnCrops()) ? earnCrops() : 0;
return "" + result;
          },
        inputProps: {
          disabled: true,
        },
      },
    },
      {
        type: "TEXT_BOX",
        width: "6",
        name: "crop_point_redeem",
        element: {       
          label: "Redeem CROPs",      
          // valueFunction: () => {
          //   let earn_points = formData.price ? parseFloat(formData.price) : 0;
          //   return "" + parseInt(earn_points * 20);
          // },
          valueFunction: () => { 
            const result = !isNaN(redeemCrops()) ? redeemCrops() : 0;
            return "" + result;        
          
          },
          inputProps: {
            disabled: true,
          },
        },
    },
  ]

  const handle_redirection = (id, selection) => {
    if (selection === "no") {
      navigate("/manage-program/customer-transitions-scan")
    } 
  };

  
  const submitData = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    setLoading(true, "Submitting....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    let _data = {...formData};  
    let url = MANAGE_OFFER_URLS.INSERT_DIRECT;   
    const subscription = post(url, _data, handleError).subscribe(
      (response) => {
        closeModal()
        setLoading(false);
        loadTableData()
        // navigate("/manage-program/customer-transitions-scan");
        //setTabData(response.data);
        //setLoading(false);
        // showYesOrNoAlert(
        //   "Do you wish to post another offer?",
        //   (selection) => handle_redirection(response.data, selection),
        //   "success"
        // );
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };


  return (
    <>
      <div>
        <div className="smart-customer-input">
          <div className="is-flex is-justify-content-space-between">
            <div className="is-size-4 has-text-weight-bold ">
              Add New Offer
            </div>
            <div className="mb-3" onClick={closeModal}>
              <div className=" ">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div className="">
            <div className="p-3">
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
              <div className="has-text-centered">
                <SmartSoftButton
                  label="Submit"
                  classList={["smart-crop-action-button "]}
                  onClick={()=>submitData()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerTransactionAddNewFrom