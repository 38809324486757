import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftButton } from "soft_digi";

import QrReader from "react-qr-scanner";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";

const CustomerTransitionsScan = () => {
  const navigate = useNavigate();
  const [scan, setScan] = useState(false);
  const handleFormSubmit = () => {
    navigate("/manage-program/customer-transitions-scan-table");
  };

  const handleScan = (data) => {
    // console.log("scneed data ", data);
    if (data && data.text) {
      navigate("/manage-program/customer-transitions-scan-table", {
        state: { id: data.text },
      });
      //C-006139345
    }
  };
  // 146
  useEffect(() => {
    //handleScan({ text: "CUS-0000151" });
  }, []);

  const handleError = (data) => {
    console.log("error data ", data);
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  const scannerDisplay = () => {
    return (
      <QrReader
        delay={500}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
      />
    );
  };

  return (
    <>
      <div>
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          {/* <span className="ml-5">Customer Transactions</span> */}
          <div className="pl-1">
            <SmartHeader
              title={"Manage Program"}
              sub_title={"Customer Transactions"}
            />
          </div>
        </p>
      </div>

      <div className="columns is-multiline">
        <div className="has-text-centered column is-12">
          <SmartSoftButton
            label="Scan"
            classList={["smart-crop-action-button", "mt-6"]}
            onClick={() => setScan(true)}
          />
        </div>
        {scan && (
          <div className="column is-12 has-text-centered">
            {scannerDisplay()}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerTransitionsScan;
