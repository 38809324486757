const ADMIN_LOGIN_URLS = {
    LOGIN:"auth/admin_login",
    LOGIN_BUSINESS:"auth/business_login",
    REFRESH:"auth/refresh",
     HEALTH_CHECK:"auth/health_check",
     CHECK_USER: "auth/check_user",
    
}

const BUSINESS_LOGIN_URLS = {
    SEND_OTP:"auth/send_otp",
    VERIFY_BUSINESS_DETAILS:"auth/verify_business",
    SEND_OTP_FORGOT:"auth/send_otp_forgot",
    RESET_PASSWORD_OTP:"auth/reset_pass_otp"
}

const USER_URLS = {
    INSERT_BUSINESS:"user/insert_business",
    GET_ONE_PROFILE:"user/get_one_profile",
    GET_ALL_ADMIN:"user/get_all",
    UPDATE_STATUS:"user/update",
    CHANGE_PASSWORD:"/user/change_password",
    UPDATE_BUSINESS_PROFILE:"/user/update_bus_profile",
    GET_ALL_SELECT_STATES:"/user/get_all_select_states",
    SENT_OTP_EMAIL:"/auth/send_otp_email",
    SEND_OTP_EMAIL_NEW:"/auth/send_otp_email_new",
    SEND_OTP_MOBILE:"/auth/send_otp_mobile",
    SEND_OTP_MOBILE_NEW:"/auth/send_otp_mobile_new",
    UPDATE_EMAIL_ID:"/user/update_email_id",
    UPDATE_MOBILE_NUMBER:"/user/update_mobile_no",
    USER_GET_ONE_CUSTOMER:"/user/get_one_customer"
}

export {
    ADMIN_LOGIN_URLS,
    BUSINESS_LOGIN_URLS,
    USER_URLS
}
