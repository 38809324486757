import { useEffect, useState } from "react";
//import './CustomerLayoutCss.css';
import { Link, useNavigate } from "react-router-dom";
import { SERVICES_NOTIFICATION_URLS } from "../../api/Services/ServiceUrls";
import { useSiteContext } from "../../contexts/SiteProvider";
import NotificationMessage from "../../pages/Business/Notification/NotificationMessage";
import { getTimeAgo } from "../../services/core/CommonService";
import { showAlertAutoClose } from "../../services/notifyService";
import { post } from "../../services/smartApiService";

const SingleNotification = ({ type,setActive }) => {
  const [data, setData] = useState([]);
  const { setLoading, refreshUser } = useSiteContext();
  const navigate = useNavigate();
  const baseNav = "";

  const loadTableData = () => {
    // setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData([]);
      //  setLoading(false);
    };
    let post_data = {
      notification_type: type,
    };
    const subscription = post(
      SERVICES_NOTIFICATION_URLS.GET_ALL,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      refreshUser();
      //setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

 const handleClick=()=>{
  setActive(false);
 }

  return (
    <>
      <div className="smart-notification-accord">
        {data.data &&
          data.data.length > 0 &&
          data.data.map((obj, index) => {
            return (
              <div
                className={
                  obj.notification_status == 0
                    ? "crop-customer-notification-list-item has-text-danger"
                    : "crop-customer-notification-list-item"
                }
                key={index}
              >
                <NotificationMessage
                  message={obj.notification_message}
                  pats={data.pats}
                />
                {/* {obj.notification_message} */}
              </div>
            );
          })}
        {/* {data && data.length > 0 && (
        <div
          onClick={() => navigateLink("Notification/notification-view")}
          className="has-text-centered customer-notification-text has-text-weight-semibold "
        >
          view
        </div>
           )} */}
        {data.data && data.data.length < 1 && (
          <div className="crop-customer-notification-list-item No-notification">
            No Notifications
          </div>
        )}
      </div>
      <div className="is-flex is-justify-content-space-between">
        <div className="crop-customer-notification-list-time">
          {data.data && data.data.length > 0 && (
            <div className="">
              {data.time && data.time.length > 0 && getTimeAgo(data.time)}
            </div>
          )}
        </div>
        {/* {data.data && */}
        {/* data.data.length > 0 &&  */}
        <div className="">
          {data && data.count && data.count > 5 && (
            <Link to={"/notification/" + type} onClick={handleClick}> More</Link>
          )}
        </div>

        {/* } */}
      </div>
    </>
  );
};

export default SingleNotification;
