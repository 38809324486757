import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import { CONTACTUS } from "../../../../services/ImageService";

const ContactUsView = () => {
  const sendMail = (e, index) => {
    let mail_to_send = "admin@cropglobalservices.com";
    if (index == "ge" || index == "be") {
      mail_to_send = "info@cropglobalservices.com";
    }
    const mailto = "mailto:" + mail_to_send;
    window.location.href = mailto;
    e.preventDefault();
  };

  const contactData = [
    {
      title: "Technical Support",
      description:
        // "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type ..",
        "For any issues related to system performance and technical troubleshooting.",
      color: "technical-design",
      index: "ts",
    },
    {
      title: "General Enquiries",
      description:
        // "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type ..",
        "For all general queries related to your account and membership.",
      color: "general-design",
      index: "ge",
    },
    {
      title: "Business Enquiries",
      description:
        // "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type ..",
        "For any queries related to business of CROP and its participating brands.",
      color: "business-design",
      index: "be",
    },
    {
      title: "Complaint Follow-Up",
      description:
        // "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type ..",
        "For any complaint that is unresolved beyond agreed turnaround time.",
      color: "complaint-design",
      index: "cf",
    },
  ];

  const ContactCard = ({ title, description, color, index }) => {
    return (
      <div
        className={`dashboard-card-view poppins-font-family dashboard-card-design ${color}`}
        onClick={(event) => sendMail(event, index)}
      >
        <div className="dashboard-card-items">
          <div className="dashboard-card-main-items ">
            <div className="is-flex is-justify-content-center has-text-weight-bold has-text-white is-size-6">
              <div className="dashboard-card-title">{title}</div>
            </div>
            <div className="dashboard-card-description is-size-7 has-text-white has-text-centered">
              {description}
              <p className="has-text-centered ContactCard-icon-contactus  is-size-4">
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
        {/* <span className="ml-5">Help</span> */}
        <div className="pl-1">
          <SmartHeader title={"Support"} sub_title={"Contact Us"} />
        </div>
      </p>
      <div className="columns mx-2 mt-5">
        {contactData.map((data, index) => (
          <div className="column is-3 contactus-media-screen pointer" key={index}>
            <ContactCard {...data} />
          </div>
        ))}
      </div>
      <div className="is-flex is-justify-content-center my-6">
        <img src={CONTACTUS} alt="icon" className="image contactus-image" />
      </div>
    </>
  );
};

export default ContactUsView;
