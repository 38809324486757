import { useEffect, useState } from "react";
// import SmartImageDisplay from '../../../../components/site/FormElements/SmartImageDisplay';
import { SmartSoftButton } from "soft_digi";
import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import ProductVariens from "./ProductVariens";

const ProductVariant = ({
  item,
  closeModal,
  setLoading,
  type,
  executeCart,
}) => {
  //  const [slide,showSlide] = useState(1);
  const [data, setData] = useState([]);
  const [itemData, setItemData] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [customNote, setCustomNote] = useState("");

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  //   const handleInputChangeNew = (name, value) => {
  //     //console.log(" name ", name, "   value ", value)
  //     setItemData((prev) => ({ ...prev, [name]: value }));
  //     if (handleInputChange && handleInputChange instanceof Function) {
  //       handleInputChange(name, value);
  //     }
  //   };

  const getData = () => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading....Please Wait");
    let url = MANAGE_OFFER_URLS.GET_ONE;
    let data_in = { id: item.ID, image: 0 };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);
        setItemData(response.data);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    getData();
  }, [item]);

  const itemPrice = () => {
    const total_price = parseFloat(item.price) + parseFloat(itemData.price);
    return !isNaN(total_price) ? total_price : 0;
  };

  const itemTotalPrice = () => {
    const total_price = itemPrice();
    const discounted_price = (total_price * itemData.discount) / 100;
    let t = total_price - discounted_price;
    return !isNaN(t) ? t : 0;
  };

  const earn_crops = () => {
    return parseInt(parseInt(itemTotalPrice()) * itemData["earnFraction"]);
  };

  const redeem_crops = () => {
    let crop = parseInt(
      parseInt(itemTotalPrice()) * itemData["redeemFraction"]
    );
    return !isNaN(crop) ? crop : 0;
  };

  const getVariantList = () => {
    let person = { ...selectedVariants };
    let variantList = [];
    for (const key in person) {
      if (person.hasOwnProperty(key)) {
        let children = itemData.variants.find((item) => item.index == key);
        if (children) {
          let cost = children.children.find((item) => item.id == person[key]);
          if (cost) {
            variantList.push({
              variant_group: children.name,
              variant_name: cost.name,
            });
          }
          //console.log("cost  ", cost);
        }
      }
    }
    return variantList;
  };

  const addToCart = () => {
    if (selectedVariants && Object.entries(selectedVariants).length > 0) {
      let itemNew = item;
      itemNew["total_price"] = itemTotalPrice();
      itemNew["earn_crop_points"] = earn_crops();
      itemNew["redeem_crop_points"] = redeem_crops();
      itemNew["variants"] = selectedVariants;
      itemNew["customnote"] = customNote;
      itemNew["variantList"] = getVariantList();
      executeCart(itemNew);
      closeModal();
    } else {
      showAlertAutoClose("Please Select the Variant", "error");
      return false;
    }

    //closeModal();
  };

  return (
    <>
      <div className="container customer-primary-font  customer-quick-view-container P-5">
        {/* <div className="smart-modal-close" onClick={() => closeModal()}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div> */}
        <div className="columns p-4">
          <div className="column is-12" key="keytwo">
            {/* <div className="column is-12  is-size-5">
                 Please Select the Variant
              </div>    */}

            <div className="">
              <div className="columns">
                <div className="column">
                  {itemData.variants && Array.isArray(itemData.variants) && (
                    <ProductVariens
                      data_in={itemData.variants}
                      setSelectedVariants={setSelectedVariants}
                      setItemData={setItemData}
                      setCustomNote={setCustomNote}
                      customNote={customNote}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="column is-12 has-text-right">
              {type == "EARN" && <span>Total Price : {itemTotalPrice()}</span>}
              {type == "REDEEM" && <span>Total CROPs : {redeem_crops()}</span>}
            </div>
            <div className="column is-12 has-text-centered">
              {/* <button
                className="smart-crop-trancation-button"
                onClick={() => addToCart()}
              >
                {"Add Product"}
              </button> */}
              <SmartSoftButton
                label={"Add Product"}
                classList={["smart-crop-action-button "]}
                onClick={() => addToCart()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductVariant;

/*
 <div className='columns is-multiline'>
              <div className='column is-12'>
                <div className='quick-view-image'>
                  {offer_image(item)}
                </div>

              </div>
              <div className='column is-4'>

              </div>
              <div className='column is-4'>
                {offer_image(item)}
              </div>
              <div className='column is-4'>
                {offer_image(item)}
              </div>
            </div>

        */
