// import React from 'react'
import moment_timzone from "moment-timezone";
import { useEffect, useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { SERVICE_SETTINGS } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { changeDateTimeZoneWithTime } from "../../../../services/core/CommonService";
import { INTERROGATION_IMAGE } from "../../../../services/ImageService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import WorkingDaysHolidaysToolTip from "./WorkingDaysHolidaysToolTip";
import moment from "moment";
const OperatinghoursAndCustomDays = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [formData, setFormData] = useState({});
  const { setLoading, closeModal, refreshUser } = useSiteContext();
  const [minEndDate, setMinEndDate] = useState();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleInputChange = (name, value) => {
    //console.log("name ", name, " value ", value);
    setFormData((prev) => ({ ...prev, [name]: value }));
    // if (name === "apply_to_all" && value === "1") {
    //   mark_all();
    // }
    //  if(name=="start_time" || name=="end")
    // } else {
    //   // console.log(" all to check " ,name)
    //   // setFormData((prev) => ({
    //   //   ...prev,
    //   //   ["apply_to_all"]: check_mark_all(formData),
    //   // }));
    // }
  };

  const handleSubChange = (name, value) => {
    // console.log("name " , name , " value " , value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  const days = [
    { value: "1", label: "SU" },
    { value: "2", label: "MO" },
    { value: "3", label: "TU" },
    { value: "4", label: "WE" },
    { value: "5", label: "TH" },
    { value: "6", label: "FR" },
    { value: "7", label: "SA" },
  ];

  const mark_all = () => {
    // let output = [];
    days.forEach((item) => {
      let stime_index = "start_time_" + item.value;
      let etime_index = "end_time_" + item.value;
      let open_close_index = "open_close_" + item.value;
      let update_data = {};
      update_data[stime_index] = formData.start_time;
      update_data[etime_index] = formData.end_time;
      update_data[open_close_index] = "" + formData.open_close;
      // console.log("update_data ", update_data);
      setFormData((prev) => ({
        ...prev,
        ...update_data,
      }));
      // handleSubChange(stime_index, formData.start_time);
      // handleSubChange(etime_index, formData.end_time);
      //handleSubChange(open_close_index, "" + formData.open_close);
    });
  };

  useEffect(() => {
    let apply_all_value = formData.apply_to_all;
    if (apply_all_value == "1") {
      mark_all();
    }
    //console.log("apply_all_value " , apply_all_value);
  }, [
    formData.apply_to_all,
    formData.start_time,
    formData.end_time,
    formData.open_close,
  ]);

  const applyToAllEnable =
    formData && formData.apply_to_all && formData.apply_to_all == "1"
      ? true
      : false;

  const mark_all_selected = () => {
    return { value: "1", label: "Apply to All" };
  };

  const getTimestamp = (date) => {
    return date ? date.getTime() : null; // Returns timestamp in milliseconds
  };

  const compareTime = (stime, etime) => {
    let _index = changeDateTimeZoneWithTime(stime, "HH:MM");
    let _global = changeDateTimeZoneWithTime(etime, "HH:MM");
    return _index != _global;
    // console.log(getTimestamp(stime), "etime ", getTimestamp(etime));
    //  return getTimestamp(stime) !== getTimestamp(etime) ? true : false;
  };

  const process_utc = (time) => {
    try {
      // Get the client's time zone
      let _time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // Convert the input time to the client's time zone
      let final_time = moment_timzone(time, _time_zone);
      // Convert the time to UTC and return the formatted string
      return final_time.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    } catch (error) {
      //console.error("Error processing UTC time:", error);
      // Optionally, return a default value or null if there's an error
      return null;
    }
  };

  const preprocess_data = () => {
    let data_in = { ...formData };
    let _tzone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //console.log("data _in " , formData);
    let standard_data = {
      day_type: "ALL",
      day_number: 0,
      open_close: data_in?.open_close,
      start_time: process_utc(data_in?.start_time),
      end_time: process_utc(data_in?.end_time),
      all_checked: "" + data_in?.apply_to_all,
      timezone: _tzone,
    };
    let output = [standard_data];
    days.forEach((item) => {
      let stime_index = "start_time_" + item.value;
      let etime_index = "end_time_" + item.value;
      let open_close_index = "open_close_" + item.value;
      //  console.log(" stme_inde " , stime_index, " etime " , etime_index);
      let open_close_val =
        data_in[open_close_index] !== undefined
          ? data_in[open_close_index]
          : "";
      if (data_in?.open_close != "1") {
        open_close_val = "0";
      }
      //  console.log("open close vale " , open_close_val , " data close " , data_in.open_close);
      output.push({
        day_type: "WEEKDAY",
        open_close: open_close_val,
        day_number: item.value,
        start_time:
          data_in[stime_index] !== undefined
            ? process_utc(data_in[stime_index])
            : "",
        end_time:
          data_in[etime_index] !== undefined
            ? process_utc(data_in[etime_index])
            : "",
        timezone: _tzone,
      });
    });
    return output;
  };

  const handleOperatingFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, [...operatingElements])) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Updating...Please Wait");
    let url = SERVICE_SETTINGS.INSERT_HOUR;
    let post_data = preprocess_data();
    //  console.log(post_data);
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose("Settings Saved Successfully", "success");
        closeModal();
        setLoading(false);
        refreshUser();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const format_time = (selectedTime) => {
    if (selectedTime.length > 4) {
      try {
        const today_set = moment().format("YYYY-MM-DD");
        // Parse the selected time as UTC (assuming time string is in HH:mm:ss format)
        const utcTime = moment_timzone(`${today_set}T${selectedTime}Z`);
        // const localTime = utcTime.clone().tz(userTimezone);
        const localTime = utcTime.local();
        // Get the local time components
        const today = new Date();
        today.setFullYear(
          localTime.year(),
          localTime.month(),
          localTime.date()
        );
        today.setHours(
          localTime.hours(),
          localTime.minutes(),
          localTime.seconds()
        );
        return today;
      } catch (error) {
        // If an error occurs (e.g., invalid date), return the original string
        return null;
      }
      // const utcDate = new Date(`1970-01-01T${selectedTime}Z`); // Z indicates UTC
      // // Convert to local time
      // const localHours = utcDate.getHours();
      // const localMinutes = utcDate.getMinutes();
      // const localSeconds = utcDate.getSeconds();
      // const localTime = `${localHours
      //   .toString()
      //   .padStart(2, "0")}:${localMinutes
      //   .toString()
      //   .padStart(2, "0")}:${localSeconds.toString().padStart(2, "0")}`;

      // const today = new Date();
      // // Split the selected time into hours and minutes
      // const [hours, minutes] = localTime.split(":").map(Number);
      // // Set the time on the date object
      // today.setHours(hours);
      // today.setMinutes(minutes);
      // // let timeFormat = changeDateTimeZoneWithTime(today.,"date");
      // //  let check_for_date = new Date(timeFormat);
      // //console.log("time format ", today);
      // return today;
    } else {
      return null;
    }
  };

  const post_process_data = (data_in) => {
    let standard_day = data_in.find((item) => item.day_type === "ALL");
    // console.log("standard_day ", standard_day);
    let out = {
      open_close: standard_day?.open_close
        ? "" + standard_day?.open_close
        : "0",
    };
    out["start_time"] =
      standard_day && standard_day.start_time
        ? format_time(standard_day.start_time)
        : "";
    out["end_time"] =
      standard_day && standard_day.end_time
        ? format_time(standard_day.end_time)
        : "";
    // console.log("dayta " , standard_day);
    out["apply_to_all"] = "" + standard_day?.all_checked;

    days.forEach((item) => {
      out["open_close_" + item.value] = "1";
      out["start_time_" + item.value] = out["start_time"];
      out["end_time_" + item.value] = out["end_time"];
    });
    //console.log("data_in ", data_in);
    data_in.forEach((item) => {
      if (item.day_type === "WEEKDAY") {
        out["open_close_" + item.day_number] = "" + item?.open_close;
        out["start_time_" + item.day_number] = format_time(item.start_time);
        out["end_time_" + item.day_number] = format_time(item.end_time);
      }
    });
    // out["apply_to_all"] = check_mark_all(out);
    //  console.log("out ", out);
    return out;
  };

  const loadData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let url = SERVICE_SETTINGS.GET_ALL;
    const subscription = post(url, {}, handleError).subscribe((response) => {
      console.log(response);
      // setTabData(response.data);
      setFormData(post_process_data(response.data));
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadData();
  }, []);

  const extractTimeFromDate = (dateString) => {
    const date = new Date(dateString); // Convert the date string to a Date object
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    // Return the time in HH:mm:ss format
    return `${hours}:${minutes}:${seconds}`;
  };

  const FormValidations = {
    start_time: [SmartValid.required("Required")],
    end_time: [
      SmartValid.required("Required"),
      SmartValid.custom((value) => {
        //console.log("start time ", formData.start_time, "  v = ", value);
        return extractTimeFromDate(formData.start_time) >
          extractTimeFromDate(value)
          ? "End Time Cannot be more the Start Time"
          : "";
      }),
    ],
    // in_days:[SmartValid.required("Enter the Days")]
  };

  // const options = [{ value: "1", label: "Closed" }];
  const operatingElements = [
    {
      type: "CHECK_RADIO",
      width: "3",
      name: "open_close",
      element: {
        label: "Closed",
        isHorizontal: true,
        switchMode: true,
        //type:"switch",
        options: [{ value: "1", label: "Open" }],
      },
    },

    {
      type: "DATE",
      width: "3",
      name: "start_time",
      element: {
        label: "From",
        inputType: "BORDER_LABEL",
        showTimeSelect: true,
        showTimeSelectOnly: true,
        dateFormat: "h:mm aa",
        rightIcon: "fa fa-clock-o",
        validations: FormValidations.start_time,
        dateProps: {
          minTime: new Date().setHours(0, 30, 0),
          maxTime: formData.end_time
            ? new Date(formData.end_time.getTime() - 30 * 60000)
            : new Date().setHours(23, 30, 0),
          //maxTime:new Date().setHours(23, 30, 0)
        },
      },
      hideFunction: (data) => {
        return formData["open_close"] === "1" ? false : true;
      },
    },

    {
      type: "DATE",
      width: "3",
      name: "end_time",
      element: {
        label: "To ",
        inputType: "BORDER_LABEL",
        showTimeSelect: true,
        showTimeSelectOnly: true,
        dateFormat: "h:mm aa",
        rightIcon: "fa fa-clock-o",
        dateProps: {
          minTime: formData.start_time
            ? new Date(formData.start_time.getTime() + 30 * 60000)
            : new Date().setHours(0, 30, 0),
          maxTime: new Date().setHours(23, 30, 0),
        },
        //minDate:formData.start_time,
        validations: FormValidations.end_time,
      },
      hideFunction: (value) => {
        return formData["open_close"] === "1" ? false : true;
      },
    },
    {
      type: "CHECK_RADIO",
      width: "3",
      name: "apply_to_all",
      element: {
        // label: "Closed",
        isHorizontal: true,
        // switchMode:true,
        //type:"switch",
        options: [{ value: "1", label: "Apply to All" }],
      },
      hideFunction: (data) => {
        return formData["open_close"] === "1" ? false : true;
      },
    },
  ];

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const handleDayClick = (dayValue) => {
    setSelectedDay(dayValue);
    handleSelectDay(dayValue); // Call handleSelectDay with the selected day value
  };
  const handleSelectDay = (day) => {
    // console.log("Selected day:", day);
    // You can perform any actions here when a day is selected
  };

  const CustomDaysElements = (day) => {
    let start_time_index = "start_time_" + day;
    let end_time_index = "end_time_" + day;
    return [
      {
        type: "CHECK_RADIO",
        width: "3",
        name: "open_close_" + day,
        element: {
          label: "Closed",
          isHorizontal: true,
          switchMode: true,
          inputProps: {
            disabled: applyToAllEnable,
          },
          //type:"switch",
          options: [{ value: "1", label: "Open" }],
        },
      },
      {
        type: "DATE",
        width: "3",
        name: "start_time_" + day,
        element: {
          label: "From",
          inputType: "BORDER_LABEL",
          showTimeSelect: true,
          showTimeSelectOnly: true,
          dateFormat: "h:mm aa",
          rightIcon: "fa fa-clock-o",
          inputProps: {
            disabled: applyToAllEnable,
          },
          dateProps: {
            minTime: new Date().setHours(0, 30, 0),
            maxTime: formData[end_time_index]
              ? new Date(formData[end_time_index].getTime() - 30 * 60000)
              : new Date().setHours(23, 30, 0),
            //maxTime:new Date().setHours(23, 30, 0)
          },
        },
        hideFunction: (value) => {
          return formData["open_close_" + day] === "1" ? false : true;
        },
      },
      {
        type: "DATE",
        width: "3",
        name: "end_time_" + day,
        element: {
          label: "To ",
          inputType: "BORDER_LABEL",
          showTimeSelect: true,
          showTimeSelectOnly: true,
          dateFormat: "h:mm aa",
          rightIcon: "fa fa-clock-o",
          inputProps: {
            disabled: applyToAllEnable,
          },
          dateProps: {
            minTime: formData[start_time_index]
              ? new Date(formData[start_time_index].getTime() + 30 * 60000)
              : new Date().setHours(0, 30, 0),
            maxTime: new Date().setHours(23, 30, 0),
          },
          //m
        },
        hideFunction: (value) => {
          return formData["open_close_" + day] === "1" ? false : true;
        },
      },
    ];
  };

  const compareChanges = (dayNumber, dt) => {
    let stime_index = "start_time_" + dayNumber;
    let etime_index = "end_time_" + dayNumber;

    if (compareTime(dt[stime_index], dt?.start_time)) {
      return true;
    }
    if (compareTime(dt[etime_index], dt?.end_time)) {
      return true;
    }
    // if (dt[open_close_index] != dt?.open_close) {
    //   return true;
    // }
    return false;
  };

  const compareOpenClose = (dayNumber, dt) => {
    let open_close_index = "open_close_" + dayNumber;
    if (dt[open_close_index] != dt?.open_close) {
      return true;
    }
    return false;
  };

  const getDayClassName = (dayNumber) => {
    let class_list = ["operatinghours-rounded-day"];
    const comapre_date = compareChanges(dayNumber, formData);
    if (comapre_date) {
      class_list.push("operatinghours-modified");
    }
    if (compareOpenClose(dayNumber, formData)) {
      class_list.push("operatinghours-closed");
    }
    if (selectedDay === dayNumber) {
      class_list.push("operatinghours-selected");
    }

    return class_list.join(" ");
  };

  return (
    <div className="OperatinghoursAndCustomDays">
      <p className=" has-text-weight-bold">Business Hours</p>
      <br />
      <div className="smart-crop-business-forms">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={operatingElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </div>

      <p className="mt-4 has-text-weight-bold">
        Business Hours (Daywise)
        <span
          className="ml-1 pointer"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ position: "relative" }}
        >
          <img src={INTERROGATION_IMAGE} alt="" />
          {showTooltip && (
            <div className="tooltip-container">
              <WorkingDaysHolidaysToolTip text="Select a day below to customise settings for a specific day." />
            </div>
          )}
        </span>
      </p>
      <div className="operatinghours-rounded-days-container">
        {days.map((day, index) => (
          <div
            key={index}
            className={getDayClassName(day.value)}
            onClick={() => handleDayClick(day.value)}
          >
            {day.label}
          </div>
        ))}
      </div>
      <br />
      {selectedDay && (
        <div className="smart-crop-business-forms">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={CustomDaysElements(selectedDay)}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>
      )}
      <div className="is-justify-content-end  is-flex mt-6 ">
        <SmartSoftButton
          label="Save"
          // leftIcon="fa fa-download"
          classList={["smart-crop-action-button"]}
          onClick={handleOperatingFormSubmit}
        />
      </div>
    </div>
  );
};

export default OperatinghoursAndCustomDays;
