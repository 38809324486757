import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import BusinessRoute from "./Business/BusinessRoute";
// const basePath = config.REACT_APP_BASE_PATH || '';

const SiteRoute = () => {
  return (
    <>
      <Router basename={process.env.REACT_APP_BASE_PATH || ''}>
         <BusinessRoute />
      </Router>
      <ToastContainer />
    </>
  );
};

export default SiteRoute;

