import { useEffect, useState } from "react";
import {
  ACCOUNT_MAN_SIT_LAPTOP,
  CARD_INFINITY_IMAGE,
  CARD_NAME
} from "../../../../services/ImageService";
import "./MyCard.css";
// import { useNavigate } from "react-router-dom";
import { CUSTOMER_MY_ACCOUNT } from "../../../../api/Business/OffersUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { get } from "../../../../services/smartApiService";
// import moment from "moment";
import QrCode from "../../../../components/site/QrCode";
import { changeDateTimeZoneWithout } from "../../../../services/core/CommonService";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import RequestAndComplaintView from "../../Feedback/Request/RequestAndComplaintView";
import AddVariantForm from "../../ManageService/CreateOffers/AddVariantForm";
import QRCodeOpen from "./QRCodeOpen";

const MyCardFormview = () => {
  const [data, setData] = useState(null);
  const { openModal, closeModal } = useSiteContext();
  const { setLoading } = useSiteContext();

  const loadProfileData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = get(
      CUSTOMER_MY_ACCOUNT.GET_PROFILE,
      handleError
    ).subscribe((response) => {
      setData(response.data.userData);
      console.log("test data", response.data);
      // setFormAddressData(response.data.addrData);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadProfileData();
  }, []);
  // const formattedDateOnly = moment(data?.created_time).format("MM/YY");
  // const navigate = useNavigate();
  const baseNav = "/home";
  // const navigateLink = (index) => {
  //   navigate(baseNav + "/" + index);
  // };

  const displayCropCord=(crop_card)=>{
    let card_display = "";
    if(crop_card.length >= 5){
      card_display += crop_card.substring(0, 5)+" ";
    }
    if(crop_card.length >= 8){
      card_display += crop_card.substring(5, 8)+" ";
    }
    if(crop_card.length >= 10){
      card_display += crop_card.substring(8, 11)+" ";
    }
   // console.log("crop card " , crop_card , " display   ", card_display)
    return card_display;
  }
  const getQRCode = (data) => {
    console.log('QrCode clicked!', data);
    let modelObject = {
      body: (
        <QRCodeOpen
            item={data}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90 modal-card",
      bodyClose: true,
    };
    openModal(modelObject);
  
  };



  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
       
        <div className="pl-1">
            <SmartHeader title={"Manage Program"} sub_title={"CROP Card"} />
          </div>
      </p>
      {data && data.ID && 
      <div className="container">
       
          

        <div className="my-crop-card-body">
            <div className="card_header">
              <div className="columns is-multiline p-0 m-0 is-mobile">
                <div className="column is-8 p-0 m-0 is-flex-mobile">
                {/* <div className="scannercode" onClick={getQRCode()}>
                {data && data.crop_id && (
                     <QrCode item={data?.crop_id} />
                )  
                }

                </div> */}

              <div className="scannercode mt-2">
                  {data && data.crop_id && (
                      <div onClick={() => getQRCode(data.crop_id)}>
                          <QrCode item={data.crop_id} size={65} />
                      </div>
                  )}
              </div>
                </div>
                <div className="column is-4 p-0 m-0">
                <div className="cropname mt-1">
                  <img src={CARD_NAME} alt="" />
                </div>
                  </div>
                  <div className="column is-12 p-0 m-0">
              <h2 className="has-text-black is-size-4">  {displayCropCord(data?.crop_id)} </h2>
              </div>
              <div className="column is-8 p-0 m-0 ">
              <div className="is-size-4 has-text-medium mt-1 has-text-black">
                    <h1>Member Since</h1>
                  </div>
              </div>
              <div className="column is-4 p-0 m-0 ">
              <h6 className="has-text-black mt-1 is-size-4">Valid Thru</h6>
                </div>
                <div className="column is-8 p-0 m-0 ">
                <div className=" is-size-4 mt-1 has-text-black">{changeDateTimeZoneWithout(data?.created_time)}</div>
                </div>
                <div className="column is-4 p-0 m-0 ">
                <img
                      className="icon has-text-centered mt-1"
                      src={CARD_INFINITY_IMAGE}
                      alt=""
                      style={{ width: "50px", height: "40px" }}
                    />
                </div>
                {/* <div className="column is-12 p-0 m-0 ">
                <h1 className="is-size-6 has-text-medium has-text-black">
                    BUSINESS NAME
                  </h1>
                </div> */}
                <div className="column is-12 p-0 m-0 "></div>
                <h6 className="is-size-4 has-text-black">
                {data?.business_name}
                  </h6>
              </div>
             
          
            </div>
          </div>


          
          {/* <div
            key={"MyCardFormview-column-three"}
            className="column-6 smart-flex-container"
          >
            <div className="card-image mt-6 ">
              <img
                className="my-card-view-form-image mt-6"
                src={ACCOUNT_MAN_SIT_LAPTOP}
                alt="Account Statement"
              />
            </div>
          </div> */}
        </div>
      
      }
    </>
  );
};

export default MyCardFormview;
