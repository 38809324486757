import moment from "moment";
import moment_timzone from "moment-timezone";
const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};

const formatDate = (dateString: string, format: string) => {
  try {
    const formattedDate = moment(dateString).format(format);
    return formattedDate;
  } catch (error) {
    // If an error occurs (e.g., invalid date), return the original string
    return dateString;
  }
};

const changeDateTimeZone = (
  dateString: string,
  timeZone: string = "Asia/Kolkata"
) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const selectedDateInUTC = moment_timzone.utc(dateString); // Assume the date is already in UTC
  const selectedDateInTimeZone = selectedDateInUTC.tz(userTimezone);
  const dateAlone = selectedDateInTimeZone.format("YYYY-MM-DD");
  return dateAlone;
};
const monthNameDisplay = (monthNumber: number) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return months[monthNumber - 1];
};
/**
 *
 * @param title
 * @param lengthDisplay
 * @returns
 */
const displayDots = (title: string, lengthDisplay: number = 25) => {
  return title && title.length > lengthDisplay
    ? `${title.substring(0, 30)}...`
    : title;
};
const displayHolidayDots = (title: string, lengthDisplay: number = 15) => {
  return title && title.length > lengthDisplay
    ? `${title.substring(0, 15)}...`
    : title;
};

const displayFiftyDots = (title: string, lengthDisplay: number = 40) => {
  return title && title.length > lengthDisplay
    ? `${title.substring(0, 40)}...`
    : title;
};

const getTimeAgo = (timestamp: any) => {
  const currentTime: any = new Date(new Date().toISOString());
  const previousTime: any = new Date(timestamp + "Z");
  const timeDifference: any = currentTime - previousTime;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30.44); // Average days in a month

  if (months > 0) {
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  } else if (weeks > 0) {
    return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else {
    return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
  }
};

const maskEmail = (email: string) => {
  if (!email) return email;
  const atIndex = email.indexOf("@");
  const prefix = email.substring(0, atIndex);
  const maskedPrefix = prefix.slice(0, Math.max(prefix.length - 3, 0)) + "***";
  return maskedPrefix + email.substring(atIndex);
};

const getCountryCode = (input: string) => {
  let codes: any = {
    IN: "+91",
    AU: "+61",
  };
  return codes[input] !== undefined ? codes[input] : input;
};

const maskMobileNumber = (country_code: string, mob_no: string) => {
  let country_number = getCountryCode(country_code);
  let number = country_number + mob_no;
  if (!number || number.length < 7) return number;
  const firstVisibleDigits = 5; // Number of digits to remain visible at the beginning
  const lastVisibleDigits = 2; // Number of digits to remain visible at the end
  // Ensure the number is long enough to apply the masking
  if (number.length <= firstVisibleDigits + lastVisibleDigits) {
    return number; // If the number is too short, return it as is
  }
  const maskedPart =
    number.slice(0, firstVisibleDigits) +
    "*".repeat(number.length - firstVisibleDigits - lastVisibleDigits) +
    number.slice(-lastVisibleDigits);

  return maskedPart;
};

const changeDateTimeZoneFormat = (
  dateString: string,
  format: string = "DD-MM-YYYY H:mm",
  timeZone: string = "Asia/Kolkata"
) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const selectedDateInUTC = moment_timzone.utc(dateString); // Assume the date is already in UTC
  const selectedDateInTimeZone = selectedDateInUTC.tz(userTimezone);
  const dateAlone = selectedDateInTimeZone.format(format);
  return dateAlone;
};

const changeDateTimeZoneWithout = (
  dateString: string,
  format: string = "MM/YY",
  timeZone: string = "Asia/Kolkata"
) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const selectedDateInUTC = moment_timzone.utc(dateString); // Assume the date is already in UTC
  const selectedDateInTimeZone = selectedDateInUTC.tz(userTimezone);
  const dateAlone = selectedDateInTimeZone.format(format);
  return dateAlone;
};

const changeDateTimeZoneWithTime = (
  dateString: string,
  format: string = "DD-MM-YYYY H:mm",
  timeZone: string = "Asia/Kolkata"
) => {
  //console.log("date ", dateString);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const selectedDateInUTC = moment_timzone.utc(dateString); // Assume the date is already in UTC

  const selectedDateInTimeZone = selectedDateInUTC.tz(userTimezone);
  // console.log("selected Date ", selectedDateInTimeZone);
  const dateAlone =
    format == "date"
      ? selectedDateInTimeZone.toDate()
      : selectedDateInTimeZone.format(format);
  return dateAlone;
};

export {
  changeDateTimeZone,
  changeDateTimeZoneFormat,
  changeDateTimeZoneWithTime,
  changeDateTimeZoneWithout,
  displayDots,
  displayFiftyDots, displayHolidayDots, formatDate,
  getTimeAgo,
  isEmptyObject,
  maskEmail,
  maskMobileNumber,
  monthNameDisplay
};

