import SmartAccordion from "../../../../components/site/FormElements/SmartAccordion";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";

const AppFAQs = ({header=true,closeModal}) => {
  const Crop_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p>
            CROP is a reward program that unifies customer loyalty across
            multiple businesses and participating brands through a common point
            system. This effectively means increased flexibility for customers
            to utilize their loyalty points across our ecosystem and without
            having to worry about managing trade specific loyalty programs. The
            points earned through our program can be redeemed across all our
            participating brands irrespective of the source of earnings. We
            bring Australia’s favorite brands and partners together to offer you
            incredible benefits, all in one platform.
          </p>
        </div>
      </>
    );
  };
  const Benefits_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            The most significant benefit of being a CROP member is the ability
            to offer a customer loyalty program without having to worry about
            its day-to-day operations and management. Yes! you heard it right.
            While you focus on the core essentials of your business our platform
            built with AI, automation and analytics drives customer loyalty for
            you. We take great pride in associating ourselves as a ‘Customer
            Rewards Management Entity’ with a key focus on customer loyalty. We
            not only help design your offers, but also extend the option to
            market them across a wide of customers registered on our platform.
            Other benefits include:
          </p>
          <ul>
            <li>
              DIY (Do it Yourself) features to create, amend and market offers.{" "}
            </li>
            <li>
              Sharp analytics and intelligence to drive business growth through
              loyalty.{" "}
            </li>
            <li>Requests and complaints automation for instant resolution.</li>
          </ul>
        </div>
      </>
    );
  };

  const SignUp_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            Our process of sign up is simple and extremely user friendly. You
            can sign up for the program either through our website or through
            our mobile app. You will require either an email ID or mobile number
            to sign up.{" "}
          </p>
          <ul>
            <li>
              To sign up through our website, access
              <span> https://cropglobalservices.com/</span>click on ‘Business
              Users’ option provided and follow the steps to sign up.{" "}
            </li>
            <li>
              To sign up through our mobile app, download the CROP app from your
              play store if you are using an android device or the app store if
              you are using an apple device, click on ‘Business Users’ option
              provided and follow the steps to sign up.{" "}
            </li>
            <li>
              You will be asked to verify your details through an OTP (One-time
              password) sent on mobile if you registered using your mobile
              number or a verification code sent on email if you have registered
              using your email ID. Your registration process is complete upon
              successful verification.
            </li>
          </ul>
        </div>
      </>
    );
  };

  const Otp_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            The OTP can take up to 30 seconds to arrive. If you haven't received
            the OTP from CROP beyond the stated time, then click the 'Resend
            OTP' link on the registration screen. If your verification OTP still
            hasn't arrived within 30 seconds of your second effort, then the
            mobile number you have entered may be incorrect. If it is, just
            begin the sign-up process again, entering the correct mobile number.
          </p>
        </div>
      </>
    );
  };

  const Email_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            The verification email can take up to 10 minutes to arrive. If you
            haven't received an email from CROP with the verification code, then
            please check your spam or junk folders first. If it's not there,
            then click the 'Resend email' link on the registration screen. If
            your verification email still hasn't arrived within 10 minutes of
            your second effort, then the spelling of your email may be
            incorrect. If it is, just begin the sign-up process again, entering
            the correct email address.
          </p>
        </div>
      </>
    );
  };

  const Subscription_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            There are no subscription charges for our program. Our service is
            totally free of cost and you are not required to pay anything either
            for sign up or for being an active CROP member.{" "}
          </p>
        </div>
      </>
    );
  };

  const AnotherPerson_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            Only one person can be linked to each CROP account. Additional
            members can register separately to create their own account
          </p>
        </div>
      </>
    );
  };

  const LoginPin_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            To reset your CROP Pin, please click on Forgot Pin link on the login
            page. You will be redirected to a new page where you are required to
            enter your email ID or mobile number. Enter your details here and
            click send code to receive an OTP on your email ID or mobile number.
            Upon entering the correct OTP and successfully verification, you
            would get redirected to the Pin reset page. Once your Pin is
            successfully reset, you would be able to login in once again using
            your new Pin.
          </p>
        </div>
      </>
    );
  };

  const Customer_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            Easy! Our FAQs on our website mostly address commonly asked
            questions. If you still haven’t got what you are looking for, just
            connect with our customer support team via the<span> Help </span>tab
            available on our website and/or mobile app. Depending on your
            selection our support team would engage with you. We usually respond
            to all emails within 3 business days (excluding weekends and public
            holidays).{" "}
          </p>
        </div>
      </>
    );
  };

  const Complaint_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            We will provide you with a reference number for your request or
            complaint. Your ticket will then be addressed by a member of our
            customer support team. You will receive a notification from us once
            its resolved. We usually address all tickets within 3 business days
            (excluding weekends and public holidays).
          </p>
        </div>
      </>
    );
  };

  const Market_body = () => {
    return (
      <>
        <div className="is-size-6">
          <p className="">
            You can market your offers on CROP in 2 ways. You can either opt to
            list your offers for ‘Top Rank’ in which case your offers would be
            visible upfront to customers from the list of all offers. You need
            to click on<span> Market Offers</span> under Manage Services on the
            Home Page and follow the steps to Top Rank your offer. You can also
            opt to list your offers under ‘Promo’ to increase visibility of your
            offers. You also have the flexibility to list your offers both for
            ‘Top Rank’ as well as ‘Promo’ for enhanced visibility. Kindly note
            that these are paid services and requisite payments would apply.{" "}
          </p>
        </div>
      </>
    );
  };

  const data = [
    {
      title: "What is CROP?",
      bodyFunction: Crop_body(),
    },
    {
      title: "What are the benefits of being a CROP member?",
      bodyFunction: Benefits_body(),
    },
    {
      title: "How can I sign up for the program?",
      bodyFunction: SignUp_body(),
    },
    {
      title: "‍What do I do if I haven't received the OTP?",
      bodyFunction: Otp_body(),
    },
    {
      title:
        "What do I do if I haven't received the verification code over email?",
      bodyFunction: Email_body(),
    },
    {
      title: "What are the subscription charges for the program?",
      bodyFunction: Subscription_body(),
    },
    {
      title: "Can I add another person to my membership?",
      bodyFunction: AnotherPerson_body(),
    },
    {
      title: "I forgot my CROP login Pin. How can I reset it?",
      bodyFunction: LoginPin_body(),
    },
    {
      title: "If I have got any questions, how can I contact customer support?",
      bodyFunction: Customer_body(),
    },
    {
      title: "What happens after I submit a complaint or request?",
      bodyFunction: Complaint_body(),
    },

    {
      title: "How can I market my offers on CROP? (Business)",
      bodyFunction: Market_body(),
    },
  ];
  return (
    <>
      {!header && <div class="smart-modal-close-button cursor-pointer" onClick={()=>closeModal()}> <i class="fa fa-times" aria-hidden="true"></i> </div>}
      <div className="smart-terms-conditions privacy-and-policy-border">
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
          {/* <span className="ml-5">
          
           Frequently Asked Questions
           </span> */}

<div className="pl-1">
            <SmartHeader title={"Important Information"} sub_title={"Frequently Asked Questions"} />
          </div>
        </p>

        <div></div>
        <div className="mx-5 mt-5 app-faq TermAndConditions-text">
          <SmartAccordion data={data} />
        </div>
      </div>
    </>
  );
};

export default AppFAQs;
