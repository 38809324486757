import React from 'react'
import { CROP_LOGO } from '../../services/ImageService'

const BusinessLoginHeader = () => {

    const handleClick = () => {
        window.location.href = "https://cropglobalservices.com/";
      };
  return (
  <>
  <div className='Business-login-header'>
<div >
<div className=" is-2 pl-6 ml-3 py-3" key="mn1">
          <img className="crops-logo" src={CROP_LOGO} alt="" />
        </div>
</div>
<div className='mr-3 pr-6' >
<button
                key="business"
                className="button is-rounded crop-customer-laytout-top-button-home"
                onClick={handleClick}
              >
              For  Customer
              </button>
</div>
  </div>
  </>
  )
}

export default BusinessLoginHeader