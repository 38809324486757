const SERVICES_REQUEST_URLS = {
    INSERT:"/services/request/insert",
    GET_ALL_ADMIN:"/services/request/get_all/admin",
    GET_ALL_USER:"/services/request/get_all/user",
    UPDATE:"/services/request/update",
    GET_ONE:"/services/request/get_one",
}

const SERVICES_COMPLAINT_URLS = {
    INSERT:"/services/complain/insert",
    GET_ALL_ADMIN:"/services/complain/get_all/admin",
    GET_ALL_USER:"/services/complain/get_all/user",
    UPDATE:"/services/complain/update",
    GET_ONE:"/services/complain/get_one",
    GET_DROP_DOWN:"/services/user_site/get_all_const"
}

const SUPPORT_BUSINESS_URLS = {
    GET_ALL_BUSINESS:"/business/missing_crops/get_all_business",
    GET_ONE:"/business/missing_crops/get_one",
    UPDATE_CROP:"/business/missing_crops/update"
}

const NOTIFICATION_SETTINGS = {
NOTIFICATION_INSERT:"/services/user_site/insert",
NOTIFICATION_GET_ONE:"/services/user_site/get_one",
}

const FEEDBACK_URL = {
    FEEDBACK_INSERT:"/services/feedback/insert"
}

const SERVICES_NOTIFICATION_URLS = {
    GET_ALL:"/services/notification/get_all", 
    MARK_ALL_READ:"/services/notification/update_by_user_id",  
}
const USER_STATEMENT_URL ={
    GET_ALL_STATEMENT:"/services/user/get_all_statement",
    GET_ALL_YEAR:"/services/user/get_all_year",
    GET_ALL_MONTH:"/services/user/get_all_month",
    GET_FILE:"/services/user/download_statement",
}
const RATTING_URLS={
    GET_ALL_RATTING:"/business/ratings/get_all_business",
    GET_ALL_COMMENTS:"/business/ratings/get_all_offer",
}

export {
    SERVICES_COMPLAINT_URLS,
    SERVICES_REQUEST_URLS,
    SUPPORT_BUSINESS_URLS,
    NOTIFICATION_SETTINGS,
    FEEDBACK_URL,
    SERVICES_NOTIFICATION_URLS,
    USER_STATEMENT_URL,
    RATTING_URLS
}