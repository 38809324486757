import { Route, Routes } from "react-router-dom";
import { CreatePromosOfferForm, MyPromosTable, RatedOffersTable, ViewComentsCard } from '../../pages/Business';

const PromosButton = () => {
  return (
    <Routes>
   
    <Route path="/my-promos-list" element={<MyPromosTable />} /> 
    <Route path="/create-promos-offer-form" element={<CreatePromosOfferForm />} /> 
    <Route path="/rated-offers-list" element={<RatedOffersTable />} /> 
    <Route path="/view-comments-card" element={<ViewComentsCard />} /> 
 
  </Routes>
  )
}

export default PromosButton