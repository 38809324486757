import { useEffect, useState } from "react";
import {
  SmartSoftDate,
  // SmartSoftDate,
  SmartSoftInput,
  SmartSoftTable
} from "soft_digi";

// import {
//   ACCOUNT_MAN_SIT_LAPTOP,
//   REQUEST_LOGO,
// } from "../../../../services/ImageService";
import { SERVICES_COMPLAINT_URLS } from "../../../../api/Services/ServiceUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
// import { showNotification } from "../../../../services/notifyService";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import {
  changeDateTimeZoneFormat,
  displayFiftyDots
} from "../../../../services/core/CommonService";
import {
  filterDate,
  filterIncludes,
} from "../../../../services/core/FilterService";
import { CROP_BUSINESS_REQUEST_IMAGE } from "../../../../services/ImageService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import {
  // get,
  post,
} from "../../../../services/smartApiService";
import RequestAndComplaint from "./RequestAndComplaint";
import RequestAndComplaintView from "./RequestAndComplaintView";

const RequestForm = () => {
  const [data, setData] = useState([]);
  // const [filterData, setFilterData] = useState({});
  const { setLoading, closeModal } = useSiteContext();
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [minEndDate, setMinEndDate] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [filterData, setFilterData] = useState([]);
  const [tab, setTab] = useState("NEW");
  const handleTabs = (index) => {
    // console.log("index " , index);
    setTab(index);
    loadTableData();
  };

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "start_date") {
      setMinEndDate(value);
    }
    // if(name == 'search_string'){
    //   const out_data = data.filter(filterFunction);
    //   setFilterData(out_data);
    // }
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  function capitalizeFirstLetter(str) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const DescriptionShowFunction = (item) => {
    return (
      <>
        <div>{displayFiftyDots(item.complaint_description)}</div>
      </>
    );
  };
  const TypeShowFunction = (item) => {
    return (
      <>
        <div>{capitalizeFirstLetter(item.type)}</div>
      </>
    );
  };
  const showStatus = (item) => {
    return (
      <>
        <div className="customer-order-status-text ">
          {" "}
          {item.complaint_status == "PROGRESS"
            ? "IN PROGRESS"
            : item.complaint_status}
        </div>
      </>
    );
  };

  const CategoryShowFunction = (item) => {
    return (
      <>
        <div>{displayFiftyDots(item.comp_type)}</div>
      </>
    );
  };

  const ResolutionShowFunction = (item) => {
    return (
      <>
        <div>{displayFiftyDots(item.complaint_resolution)}</div>
      </>
    );
  };
  const ClosedDateShowFunction = (item) => {
    return (
      <>
        {item.complaint_status == "CLOSED" ? (
          <div>
            {changeDateTimeZoneFormat(item.last_modified_time, "DD-MM-YYYY")}
          </div>
        ) : (
          "-"
        )}
      </>
    );
  };

  const request_complaint = (data) => {
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <>
        <div
          className="is-flex is-clickable has-text-info"
          onClick={() => openNewForm({...data})}
        >
          {data["complaint_id"] || ""}
        </div>
      </>
    );
  };

  const tabs = [
    //  { index: "CROP", label: "Customer Orders" },
    { index: "NEW", label: "New Request or Complaint", icon: "fa fa-suitcase" },
    {
      index: "OLD",
      label: "Existing Request or Complaint",
      icon: "fa fa-stack-exchange",
    },
  ];

  const tabs_display = () => {
    return (
      <div className="tabs  smart-business-tabs mx-4">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={
                  tab === item.index ? "order-active-tab" : "tabs-list-items"
                }
              >
                <a href="#">
                  <span
                    className={
                      tab === item.index
                        ? "order-active-tab-text pr-5 pl-5"
                        : "pr-5 pl-5"
                    }
                  >
                    {item.label}
                  </span>
                  <span className="icon is-small mr-0 pr-0">
                    <i className={item.icon} aria-hidden="true"></i>
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const toCamelCase = (str) => {
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .replace(/[_-\s]+(.)?/g, (match, chr) => (chr ? chr.toUpperCase() : "")) // Replace underscores, hyphens, and spaces
      .replace(/^[a-z]/, (match) => match.toUpperCase()); // Ensure the first letter is uppercase
    //.replace(/^[A-Z]/, (match) => match.toLowerCase()); // Ensure the first letter is lowercase
  };

  const openNewForm = (data) => {
    data.complaint_status =
      data?.complaint_status == "PROGRESS"
        ? "IN PROGRESS"
        : data.complaint_status;
        data.complaint_status =toCamelCase(data.complaint_status)
    data.type = toCamelCase(data.type);
    data.complaint_status =toCamelCase(data.complaint_status)
    data.last_modified_time = changeDateTimeZoneFormat(data?.last_modified_time,"DD-MM-YYYY")
    let modelObject = {
      body: <RequestAndComplaintView closeModal={closeModal} data={data} />,
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const columns = [
    { title: "Type", index: "type", valueFunction: TypeShowFunction },
    {
      title: "Category",
      index: "comp_type",
      valueFunction: CategoryShowFunction,
      width: "15",
    },
    {
      title: "Description",
      index: "complaint_description",
      valueFunction: DescriptionShowFunction,
      width: "15",
    },
    {
      title: "Number",
      index: "complaint_id",
      valueFunction: request_complaint,
      width: "10",
    },

    {
      title: "Date Raised",
      index: "complaint_date",
      dateFormat: "DD-MM-YYYY",
      type: "date",
      width: "10",
      autoTimeZone: true,
    },
    {
      title: "Status",
      index: "complaint_status",
      valueFunction: showStatus,
      width: "10",
    },
    {
      title: "Resolution",
      index: "complaint_resolution",
      valueFunction: ResolutionShowFunction,
    },

    {
      title: "Last Modified",
      index: "last_modified_time",
      dateFormat: "DD-MM-YYYY",
      type: "date",
      width: "10",
      autoTimeZone: true,
    },

    {
      title: "Date Closed",
      index: "last_modified_time",
      valueFunction: ClosedDateShowFunction,
      // type: "date",
      dateFormat: "DD-MM-YYYY",
      width: "10",
    },
  ];

  const loadTableData = () => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    // let post_data = {...formData};
    // post_data["start_date"] = post_data["start_date"]?changeDateTimeZone(formData.start_date):'';
    // post_data["end_date"] = post_data["end_date"]?changeDateTimeZone(formData.end_date):'';
    // setLoading(true, "Loading.... Please Wait");
    let url = SERVICES_COMPLAINT_URLS.GET_ALL_USER;
    const subscription = post(url, {}, handleError).subscribe((response) => {
      setLoading(false);
      setData(response.data);
      setFilterData(response.data);
      // console.log(formData)
      if (formData.search_string) searchData();
      // console.log("data type",response.data)
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    searchData();
  }, [formData]);

  useEffect(() => {
     handleInputChange("end_date","");
  }, [formData.start_date]);

  const filterFunction = (item) => {
    let output = filterDate(
      item,
      "complaint_date",
      formData.start_date,
      formData.end_date
    );
    output =
      output &&
      filterIncludes(
        item,
        ["complaint_id", "complaint_description", "complaint_status"],
        formData.search_string
      );
    return output;
  };

  const searchData = () => {
    const out_data = data.filter(filterFunction);
    setFilterData(out_data);
  };

  const { openModal } = useSiteContext();

  const openFormModal = (id, name) => {
    let modalObject = {
      // title: "Request or Complaints Form",
      title: "Request or Complaint",
      body: <RequestAndComplaint loadTableData={loadTableData} dataIn={{}} />,
    };
    openModal(modalObject);
  };

  // const dateFormElements = [
  //   {
  //     type: "DATE",
  //     width: "12",
  //     name: "start_date",
  //     element: {
  //       label: "From",
  //       placeHolder: "dd-mm-yyyy",
  //       isHorizontal: true,
  //       maxDate: currentDate,
  //     },
  //   },
  // ];
  // const dateFormTwoElements = [
  //   {
  //     type: "DATE",
  //     width: "12",
  //     name: "end_date",
  //     element: {
  //       label: "To",
  //       placeHolder: "dd-mm-yyyy",
  //       isHorizontal: true,
  //       minDate: minEndDate,
  //       maxDate: currentDate,
  //     },
  //   },
  // ];

  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        <div className="pl-1">
          <SmartHeader title={"Support"} sub_title={"Request or Complaint"} />
        </div>
      </p>

      <div className="mt-3 ">{tabs_display()}</div>

      {tab === "NEW" && (
        <>
          <div className="columns p-6">
            <div className="column is-6">
              <RequestAndComplaint
                loadTableData={loadTableData}
                dataIn={{}}
                handleTabs={handleTabs}
              />
              ,
            </div>

            <div className="column is-6">
              <div className="mb-6 has-text-centered">
                <img src={CROP_BUSINESS_REQUEST_IMAGE} className="" alt="" />
              </div>
            </div>
          </div>
        </>
      )}

      {tab === "OLD" && (
        <div>
          {/* <div className="is-size-5 mt-3 fredoka-font-family has-text-weight-bold has-text-centered customer-primary-font">
                   Request or Complaint
                 </div> */}
          <div className="columns is-centered is-10  mt-3 ml-5 mr-5 pl-4 pr-3 smart-crop-business-forms">
            {/* <div className="column is-3" key={"1"}>
                   <SmartSoftForm
                  formData={formData}
                  setFormData={handleInputChange}
                  elements={dateFormElements}
                  handleErrorChange={handleErrorChange}
                  onChange={(value) => setFilterData("from_date", value)}
                />
                   </div> */}

            {/* <div className="column is-3" key={"2"}>
                   <SmartSoftForm
                  formData={formData}
                  setFormData={handleInputChange}
                  elements={dateFormTwoElements}
                  handleErrorChange={handleErrorChange}
                  onChange={(value) => setFilterData("from_date", value)}
                />
                   </div> */}

            <div className="column is-3" key={"1"}>
              <SmartSoftDate
                key="text-1"
                label="From"
                value={formData?.start_date || ""}
                onChange={(value) => handleInputChange("start_date", value)}
                inputProps={{ isFocussed: true }}
                dateFormat="dd/MM/yyyy"
                placeHolder="DD/MM/YYYY"
                maxDate={new Date()}
              />
            </div>

            <div className="column is-3" key={"2"}>
              <SmartSoftDate
                key="text-2"
                label="To"
                value={formData?.end_date || ""}
                onChange={(value) => handleInputChange("end_date", value)}
                inputProps={{ isFocussed: true }}
                dateFormat="dd/MM/yyyy"
                placeHolder="DD/MM/YYYY"
                minDate={minEndDate}
                maxDate={new Date()}
              />
            </div>

            <div className="smart-customer-earn-crop-input-statement column is-4">
              <SmartSoftInput
                type="text"
                // rightIcon="fa fa-search"
                placeHolder="Search by Request or Complaint No."
                label="Search"
                value={formData?.search_string || ""}
                onChange={(value) => handleInputChange("search_string", value)}
              />
              {/* <div className="smart-customer-button mr-3 ">
                       <SmartSoftButton
                         label="Search"
                         classList={["smart-customer-primary-button-three", ""]}
                         onClick={() => searchData()}
                       />
                     </div> */}
            </div>
          </div>
          <div className="columns mt-3 mb-3">
            {/* {data && data.length > 0 && (
                     )} */}
            <div className="column mx-3">
              <SmartSoftTable
                columns={columns}
                data={filterData}
                tableProps={{ className: "crop-bussiness-table-layout" }}
              />
              {filterData && filterData.length < 1 && (
                <div className="no-record-found-text-two">
                  There are no contents to display for your current search
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestForm;
