import { Route, Routes } from "react-router-dom";
import { HomeView, MyProfileForm } from '../../pages/Business';

const HomeButton = () => {
  return (
    <Routes>
   
    <Route path="/home-view" element={<HomeView />} /> 
    <Route path="/my-profile-form" element={<MyProfileForm />} /> 
 
  
  </Routes>
  )
}

export default HomeButton