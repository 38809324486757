const MANAGE_OFFER_URLS = {
  INSERT: "/business/offers/insert",
  INSERT_DIRECT: "/business/offers/insert_direct",
  GET_ALL: "/business/offers/get_all_business",
  GET_ALL_DIRECT: "/business/offers/get_all_business_direct",
  UPDATE: "/business/offers/get_one",
  GET_ONE: "/business/offers/get_one",
  GET_ALL_IMAGES: "/business/offers/get_all_images",
  DELETE: "/business/offers/delete_one",
  DELETE_PRODUCT: "/business/offers/soft_delete",
  GET_PRICE: "/business/market_offers/bid_calculate",
  INSERT_BID: "/business/market_offers/insert",
  GET_ALL_BID: "/business/market_offers/get_all",
  PAYMENT_INIT: "/business/market_offers/payment_init",
  PAYMENT_UPDATE: "/business/market_offers/update_payment",
  GET_ONE_BID: "/business/market_offers/get_one",
  OFFER_UPDATE: "/business/offers/update",
  BID_CANCEL: "/business/market_offers/cancel_bid",
  BID_AMED: "/business/market_offers/amend_bid",
  BID_DELETE: "/business/market_offers/delete_one",
  INVOICE_DOWNLOAD: "/business/market_offers/invoice_download",
  GET_ALL_SELECT_WITH_USERID: "/business/offers/get_all_select_with_userid",
};

const MANAGE_OFFER_IMAGE_URLS = {
  INSERT: "/business/offers_images/insert",
  GET_ALL: "/business/offers_images/get_all",
  UPDATE: "/business/offers_images/update",
  GET_ONE: "/business/offers_images/get_one",
  DELETE: "/business/offers_images/delete_one",
  GET_ONE_IMAGE: "business/offers/get_image_offer",
  GET_ONE_IMAGE_ID: "business/offers/get_image_offer_id",
};

const CUSTOMER_MY_ACCOUNT = {
  GET_PROFILE_IMAGE: "/user/get_user_image",
  GET_PROFILE: "user/get_one_profile",
  DELETE_ACCOUNT: "/user/delete_account",
};

const OFFER_LIKES_URLS = {
  INSERT: "/business/likes/insert",
  GET_ALL_USER: "/business/likes/get_all_user",
  GET_ALL_OFFER: "/business/likes/get_all_offer",
};

const OFFER_RATINGS_URLS = {
  INSERT: "/business/ratings/insert",
  GET_ALL_USER: "/business/ratings/get_all_user",
  GET_ALL_OFFER: "/business/ratings/get_all_offer",
};

const OFFER_BIT_URLS = {
  GET_PRICE: "/business/market_offers/bid_calculate",
};

const BUSINESS_SETTINGS = {
  INSERT: "/services/user_site/insert",
  GET_ALL: "/services/user_site/get_all",
};

export {
  MANAGE_OFFER_IMAGE_URLS,
  MANAGE_OFFER_URLS,
  OFFER_LIKES_URLS,
  OFFER_RATINGS_URLS,
  OFFER_BIT_URLS,
  CUSTOMER_MY_ACCOUNT,
  BUSINESS_SETTINGS,
};
