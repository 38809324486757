import React, { useEffect } from "react";
import SmartAccordion from "../../../../components/site/FormElements/SmartAccordion";
import "./FAQnew.css";
// import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import { FAQ_IMAGE } from "../../../../services/ImageService";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import { Link } from "react-router-dom";
const FAQnew = ({ header = true, closeModal }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const Crop_body = () => {
    return (
      <>
        <p className="info-text-FAQnew">
          CROP is a reward program that unifies customer loyalty across multiple
          businesses and participating brands through a common point system.
          This effectively means increased flexibility for customers to utilise
          their loyalty points across our ecosystem and without having to worry
          about managing trade specific loyalty programs. The points earned
          through our program can be redeemed across all our participating
          brands irrespective of the source of earnings. We bring Australia’s
          favorite brands and partners together to offer you incredible
          benefits, all in one platform.
        </p>
      </>
    );
  };
  const Benefits_body = () => {
    return (
      <>
        <span className="info-text-FAQnew">
          The most significant benefit of being a CROP member is the ability to
          offer a customer loyalty program without having to worry about its
          day-to-day operations and management. Yes! you heard it right. While
          you focus on the core essentials of your business our platform built
          with AI, automation and analytics drives customer loyalty for you. We
          take great pride in associating ourselves as a ‘Customer Rewards
          Management Entity’ with a key focus on customer loyalty. We not only
          help design your offers, but also extend the option to market them
          across a wide of customers registered on our platform. Other benefits
          include:
          <ul className="smart-ul-list-view">
            <li>
              DIY (Do it Yourself) features to create, amend and market offers.{" "}
            </li>
            <li>
              Sharp analytics and intelligence to drive business growth through
              loyalty.
            </li>
            <li>Requests and complaints automation for instant resolution.</li>
          </ul>
        </span>
      </>
    );
  };
  const SignUp_body = () => {
    return (
      <>
        {" "}
        <span className="info-text-FAQnew">
          <p>
            Our process of sign up is simple and extremely user friendly. You
            can sign up for the program either through our website or through
            our mobile app. You will require either an email ID or mobile number
            to sign up.
          </p>
          <ul className="smart-ul-list-view">
            <li>
              To sign up through our website, access{" "}
              <a href="https://business.cropglobalservices.com/">
                <u className="smart-text-color-changes">
                  https://cropglobalservices.com/</u>
              </a>{" "}
              click on ‘Business Users’ option provided and follow the steps to
              sign up.
            </li>
            <li>
              To sign up through our mobile app, download the CROP app from your
              play store if you are using an android device or the app store if
              you are using an apple device, click on ‘Business Users’ option
              provided and follow the steps to sign up.
            </li>
            <li>
              You will be asked to verify your details through an OTP (One-time
              password) sent on mobile if you registered using your mobile
              number or a verification code sent on email if you have registered
              using your email ID. Your registration process is complete upon
              successful verification.
            </li>
          </ul>
        </span>
      </>
    );
  };
  const Otp_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew">
          The OTP can take up to 120 seconds to arrive. If you haven't received
          the OTP from CROP beyond the stated time, then click the 'Resend OTP'
          link on the registration screen. If your verification OTP still hasn't
          arrived within 120 seconds of your second effort, then the mobile
          number you have entered may be incorrect. If it is, just begin the
          sign-up process again, entering the correct mobile number.
        </p>
      </>
    );
  };
  const Email_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew">
          The verification email can take up to 10 minutes to arrive. If you
          haven't received an email from CROP with the verification code, then
          please check your spam or junk folders first. If it's not there, then
          click the 'Resend email' link on the registration screen. If your
          verification email still hasn't arrived within 10 minutes of your
          second effort, then the spelling of your email may be incorrect. If it
          is, just begin the sign-up process again, entering the correct email
          address.
        </p>
      </>
    );
  };
  const Subscription_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew">
          There are no subscription charges for our program. Our service is
          totally free of cost and you are not required to pay anything either
          for sign up or for being an active CROP member.
        </p>
      </>
    );
  };
  const Registered_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew">
          Well, the short answer is immediate. To elaborate, once you have
          registered on CROP you are ready to enjoy the benefits offered by us
          as well as our participating brands. In addition to deals and offers,
          the benefits include earning CROP for purchase transactions and a wide
          range of redemption options.
        </p>
      </>
    );
  };
  const AnotherPerson_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew">
          Only one person can be linked to each CROP account. Additional members
          can register separately to create their own account.
        </p>
      </>
    );
  };
  const LoginPin_body = () => {
    return (
      <>
        <p className="info-text-FAQnew">
          To reset your CROP Password or Pin, please click on Forgot Password or Pin link on the login page. You will be redirected to a new page where you are required to enter your email ID or mobile number. Enter your details here and click send code to receive an OTP on your email ID or mobile number. Upon entering the correct OTP and successful verification, you would get redirected to the Password or Pin reset page. Once your Password or Pin is successfully reset, you would be able to login in once again using your new Password or Pin.
        </p>
      </>
    );
  };
  const Customer_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew">
          Easy! Our FAQs on our website mostly address commonly asked questions.
          If you still haven’t got what you are looking for, just connect with
          our customer support team via the{" "}
          <Link to="/help/contact-us-view"><u className="smart-text-color-changes">Help</u></Link> tab available on our
          website and/or mobile app. Depending on your selection our support
          team would engage with you. We usually respond to all emails within 3
          business days (excluding weekends and public holidays).
        </p>
      </>
    );
  };
  const Complaint_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew">
          We will provide you with a reference number for your request or
          complaint. Your ticket will then be addressed by a member of our
          customer support team. You will receive a notification from us once
          its resolved. We usually address all tickets within 3 business days
          (excluding weekends and public holidays).
        </p>
      </>
    );
  };
  const Credited_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew">
          You can market your offers on CROP in 2 ways. You can either opt to
          list your offers for ‘Top Rank’ in which case your offers would be
          visible upfront to customers from the list of all offers. You need to
          click on{" "}
          <Link to="/manage-services/my-offer-list"><u className="smart-text-color-changes">Market Offers</u></Link> under
          Manage Services on the Home Page and follow the steps to Top Rank your
          offer. You can also opt to list your offers under ‘Promo’ to increase
          visibility of your offers. You also have the flexibility to list your
          offers both for ‘Top Rank’ as well as ‘Promo’ for enhanced visibility.
          Kindly note that these are paid services and requisite payments would
          apply.
        </p>
      </>
    );
  };
  const DeleteAccount_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew ">
          We're sorry to see you go! If you wish to delete your CROP membership
          account, please follow these steps:
        </p>
        <ol className="has-text-white smart-info-text">
          <li>
            <p>
              <b>1a. Contact Customer Support:</b> Send an email with your full
              name and CROP ID from your registered Email ID to our support team
              through Help {'>'} Contact Us. Use subject line "Delete CROP Membership
              Account."{" "}
            </p>
            <p>
              <b>1b. Log A Request:</b> Raise a ticket on our website or app
              through Help {'>'} Request or Complaint. When logging a ticket select
              type ‘Request’ and category ‘Discontinuation of Membership’.{" "}
            </p>
            <p>
              <b>1c. Use Delete My Account:</b> Submit a request for deletion of
              account through ‘My Profile’. This feature is available at the
              bottom of ‘My Profile’ screen.
            </p>
          </li>
          <li>
            <b>2. Verify Your Identity:</b> For security purposes, our customer
            support team may ask you to verify your identity. This might include
            answering security questions or providing additional information
            related to your account.
          </li>
          <li>
            <b>3. Confirmation:</b> Once your request and identity have been
            verified, our team will process your request and delete your loyalty
            membership account.
          </li>
        </ol>
        <p className="my-2 has-text-white">
          Please note that deleting your CROP membership account is permanent
          and cannot be undone. You will lose all offers, rewards, and benefits
          associated with your account.
        </p>
        <p className="my-2 has-text-white">
          If you have any further questions or need assistance, please don't
          hesitate to contact our customer support team.
        </p>
      </>
    );
  };

  const Payment_body = () => {
    return (
      <>
        {" "}
        <p className="info-text-FAQnew">
          All credit cards (Amex, Mastercard and Visa) and debit cards are
          accepted payment methods. In addition, you can also use third party
          payment services such as Afterpay , and PayPal.
        </p>
      </>
    );
  };

  const data_one = [
    {
      title: "What is CROP?",
      bodyFunction: Crop_body(),
    },
    {
      title: "What are the benefits of being a CROP member?",
      bodyFunction: Benefits_body(),
    },
    {
      title: "How can I sign up for the program?",
      bodyFunction: SignUp_body(),
    },
    {
      title: "‍What do I do if I haven't received the OTP?",
      bodyFunction: Otp_body(),
    },
    {
      title:
        "What do I do if I haven't received the verification code over email?",
      bodyFunction: Email_body(),
    },
    {
      title: "What are the subscription charges for the program?",
      bodyFunction: Subscription_body(),
    },
  ];

  const data_two = [
    // {
    //   title:
    //     "Once I have registered for CROP how long would it take before I can start enjoying the benefits?",
    //   bodyFunction: Registered_body(),
    // },
    {
      title: "Can I add another person to my membership?",
      bodyFunction: AnotherPerson_body(),
    },
    {
      title: "I forgot my CROP login Password or Pin. How can I reset it?",
      bodyFunction: LoginPin_body(),
    },
    {
      title: "If I have got any questions, how can I contact customer support?",
      bodyFunction: Customer_body(),
    },
    {
      title: "What happens after I submit a complaint or request?",
      bodyFunction: Complaint_body(),
    },
    {
      title: "How can I market my offers on CROP?",
      bodyFunction: Credited_body(),
    },
    {
      title: "How can I delete my CROP account?",
      bodyFunction: DeleteAccount_body(),
    },
    // {
    //   title: "What payment methods are accepted?",
    //   bodyFunction: Payment_body(),
    // },
  ];

  return (
    <>
      <div className="FAQnew">
        <div className="container-FAQnew">
          <div className="columns">
            <div className="column">
              <div className="smart-bussiness-header pl-3">
                <SmartHeader
                  // title={"Important Information"}
                  sub_title={"Frequently Asked Questions"}
                />
              </div>
            </div>
          </div>
          <div className="faq-image-top-FAQnew">
            <img src={FAQ_IMAGE} alt="CROP Logo" style={{ width: "45%" }} />
          </div>
          <div className="header-FAQnew baumans-font-family">
            <h1>Ask away, we've got you covered.</h1>
          </div>

          <div className="columns mt-4 media-screen-PrivacyAndDataRightsNew">
            <div className="column is-6  ">
              <div className="FAQnew-new baumans-font-family">
                <SmartAccordion data={data_one} />
              </div>
            </div>

            <div className="column is-6 ">
              <div className="FAQnew-new baumans-font-family">
                <SmartAccordion data={data_two} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQnew;
