import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  SmartSoftForm,
  SmartSoftSelect,
  SmartValid,
} from "soft_digi";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
// import { USER_URLS } from "../../../../api/Admin/AurhUrls";
import moment_timzone from "moment-timezone";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { SERVICE_SETTINGS } from "../../../../api/Admin/OperationsUrl";
import {
  changeDateTimeZoneFormat,
  displayHolidayDots,
} from "../../../../services/core/CommonService";
import { INTERROGATION_IMAGE } from "../../../../services/ImageService";
import { admin_states_select } from "../../../../services/site/SelectBoxServices";
import WorkingDaysHolidaysToolTip from "./WorkingDaysHolidaysToolTip";
import moment from "moment";
const HolidayForm = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [formData, setFormData] = useState({});
  const [formDataNew, setFormDataNew] = useState({});
  const { setLoading, closeModal } = useSiteContext();
  const [states, setStates] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleInputNewChange = (name, value) => {
    //console.log("name " , name , " value " , value);
    setFormDataNew((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (name, value) => {
    //console.log("name " , name , " value " , value);
    setFormData((prev) => ({ ...prev, [name]: value }));
    // if (name === "apply_to_all" && value === "1") {
    //   mark_all();
    // }
  };

  const yearOptions = () => {
    let out = [];
    for (let i = 2024; i < 2030; i++) {
      out.push({
        value: i,
        label: i,
      });
    }
    return out;
  };

  // const days = [
  //   { value: "1", label: "SUN" },
  //   { value: "2", label: "MON" },
  //   { value: "3", label: "TUE" },
  //   { value: "4", label: "WED" },
  //   { value: "5", label: "THU" },
  //   { value: "6", label: "FRI" },
  //   { value: "7", label: "SAT" },
  // ];

  const mark_all = () => {
    // let output = [];
    holidays.forEach((item) => {
      let stime_index = "start_time_" + item.ID;
      let etime_index = "end_time_" + item.ID;
      let open_close_index = "open_close_" + item.ID;
      let update_data = {};
      update_data[stime_index] = formData.start_time;
      update_data[etime_index] = formData.end_time;
      update_data[open_close_index] = "" + formData.open_close;
      // console.log("update_data ", update_data);
      setFormData((prev) => ({
        ...prev,
        ...update_data,
      }));
      //handleInputChange(stime_index, formData.start_time);
      // handleInputChange(etime_index, formData.end_time);
      // handleInputChange(open_close_index, formData.open_close);
    });
  };

  useEffect(() => {
    let apply_all_value = formData.apply_to_all;
    if (apply_all_value == "1") {
      mark_all();
    }
    //console.log("apply_all_value " , apply_all_value);
  }, [
    formData.apply_to_all,
    formData.start_time,
    formData.end_time,
    formData.open_close,
  ]);

  const process_utc = (time) => {
    try {
      // Get the client's time zone
      let _time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // Convert the input time to the client's time zone
      let final_time = moment_timzone(time, _time_zone);
      // Convert the time to UTC and return the formatted string
      return final_time.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    } catch (error) {
      //console.error("Error processing UTC time:", error);
      // Optionally, return a default value or null if there's an error
      return null;
    }
  };

  const preprocess_data = () => {
    let data_in = { ...formData };
    // console.log("data _in " , formData);
    let standard_data = {
      day_type: "HOLIDAY_ALL",
      day_number: 0,
      sd_admin_holidays_id: 0,
      open_close: data_in?.open_close,
      start_time: process_utc(data_in?.start_time),
      end_time: process_utc(data_in?.end_time),
      all_checked: "" + data_in?.apply_to_all,
    };
    let output = [standard_data];

    holidays.forEach((item) => {
      let stime_index = "start_time_" + item.ID;
      let etime_index = "end_time_" + item.ID;
      let open_close_index = "open_close_" + item.ID;
      let open_close_val =
        data_in[open_close_index] !== undefined
          ? "" + data_in[open_close_index]
          : "";
      if (data_in?.open_close != "1") {
        open_close_val = "0";
      }
      //  console.log(" stme_inde " , stime_index, " etime " , etime_index);
      output.push({
        day_type: "HOLIDAY_ONE",
        open_close: open_close_val,
        day_number: item.value,
        start_time:
          data_in[stime_index] !== undefined
            ? process_utc(data_in[stime_index])
            : "",
        end_time:
          data_in[etime_index] !== undefined
            ? process_utc(data_in[etime_index])
            : "",
        sd_admin_holidays_id: item.ID,
      });
    });
    return output;
  };

  const handleOperatingFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, [...operatingElements])) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Updating...Please Wait");
    let url = SERVICE_SETTINGS.INSERT_HOLIDAY;
    let post_data = preprocess_data();
    console.log(post_data);
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose("Settings Saved Successfully", "success");
        closeModal();
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const format_time = (selectedTime) => {
    if (selectedTime.length > 4) {
      const today_set = moment().format("YYYY-MM-DD");
      // Parse the selected time as UTC (assuming time string is in HH:mm:ss format)
      const utcTime = moment_timzone(`${today_set}T${selectedTime}Z`);
      // const localTime = utcTime.clone().tz(userTimezone);
      const localTime = utcTime.local();
      // Get the local time components
      const today = new Date();
      today.setFullYear(localTime.year(), localTime.month(), localTime.date());
      today.setHours(
        localTime.hours(),
        localTime.minutes(),
        localTime.seconds()
      );
      return today;
    } else {
      return null;
    }
  };

  const post_process_data = (data_in) => {
    let standard_day = data_in.find((item) => item.day_type === "HOLIDAY_ALL");
    // console.log("standard_day " , standard_day);
    let out = {
      open_close: standard_day?.open_close
        ? "" + standard_day?.open_close
        : "0",
    };
    out["start_time"] =
      standard_day && standard_day.start_time
        ? format_time(standard_day.start_time)
        : "";
    out["end_time"] =
      standard_day && standard_day.end_time
        ? format_time(standard_day.end_time)
        : "";
    out["apply_to_all"] = "" + standard_day?.all_checked;

    holidays.forEach((item) => {
      out["open_close_" + item.ID] = "1";
      out["start_time_" + item.ID] = out["start_time"];
      out["end_time_" + item.ID] = out["end_time"];
    });
    data_in.forEach((item) => {
      console.log("item ", item);
      if (item.day_type == "HOLIDAY_ONE") {
        console.log("entered insdie ", item);
        out["open_close_" + item.ID] = "" + item.open_close;
        out["start_time_" + item.ID] = format_time(item.start_time);
        out["end_time_" + item.ID] = format_time(item.end_time);
      }
    });
    console.log("output data ", out);
    return out;
  };

  // const loadData = () => {
  //   setLoading(true, "Loading....Please Wait");
  //   const handleError = (errorMessage) => {
  //     showAlertAutoClose(errorMessage, "error");
  //     setLoading(false);
  //   };
  //   let url = SERVICE_SETTINGS.GET_ALL;
  //   const subscription = post(url, {}, handleError).subscribe((response) => {
  //     console.log(response);
  //     // setTabData(response.data);
  //     setFormData(post_process_data(response.data));
  //     setLoading(false);
  //   });
  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // };

  useEffect(() => {
    admin_states_select((data) => setStates(data));
    //  loadData();
  }, []);
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  const loadHolidaysData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    // let post_data = {};
    let url = SERVICE_SETTINGS.GET_ALL_HOLIDAY;
    const subscription = post(url, formDataNew, handleError).subscribe(
      (response) => {
        //console.log("response " , response.data);
        let _data_in = response.data;
        setFormData(post_process_data(response.data));
        if (_data_in && _data_in[0] && _data_in[0].ID === 0) {
          // myArray.slice(0, 1);
          _data_in.shift();
          // let _mdata = _data_in.slice(0, 1);
          // console.log("data attempt ", _mdata , "  dt " , _data_in);
          if (_data_in.length > 0) {
            setHolidays(_data_in);
          }
        } else {
          setHolidays(_data_in);
        }
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    if (formDataNew.state && formDataNew.year) {
      // console.log("enterec in holiday data")
      loadHolidaysData();
    }
  }, [formDataNew]);

  const applyToAllEnable =
    formData && formData.apply_to_all && formData.apply_to_all == "1"
      ? true
      : false;

  const extractTimeFromDate = (dateString) => {
    const date = new Date(dateString); // Convert the date string to a Date object
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    // Return the time in HH:mm:ss format
    return `${hours}:${minutes}:${seconds}`;
  };

  const FormValidations = {
    start_time: [SmartValid.required("Required")],
    end_time: [
      SmartValid.required("Required"),
      SmartValid.custom((value) => {
        return extractTimeFromDate(formData.start_time) >
          extractTimeFromDate(value)
          ? "End Time Cannot be more the Start Time"
          : "";
      }),
    ],
    // in_days:[SmartValid.required("Enter the Days")]
  };

  // const options = [{ value: "1", label: "Closed" }];
  const operatingElements = [
    {
      type: "CHECK_RADIO",
      width: "3",
      name: "open_close",
      element: {
        label: "Closed",
        isHorizontal: true,
        switchMode: true,
        //type:"switch",
        options: [{ value: "1", label: "Open" }],
      },
    },
    {
      type: "DATE",
      width: "3",
      name: "start_time",
      element: {
        label: "From",
        inputType: "BORDER_LABEL",
        validations: FormValidations.start_time,
        showTimeSelect: true,
        showTimeSelectOnly: true,
        dateFormat: "h:mm aa",
        rightIcon: "fa fa-clock-o",
        dateProps: {
          minTime: new Date().setHours(0, 30, 0),
          maxTime: formData.end_time
            ? new Date(formData.end_time.getTime() - 30 * 60000)
            : new Date().setHours(23, 30, 0),
          //maxTime:new Date().setHours(23, 30, 0)
        },
      },
      hideFunction: (data) => {
        return formData["open_close"] === "1" ? false : true;
      },
    },

    {
      type: "DATE",
      width: "3",
      name: "end_time",
      element: {
        label: "To ",
        inputType: "BORDER_LABEL",
        showTimeSelect: true,
        showTimeSelectOnly: true,
        dateFormat: "h:mm aa",
        rightIcon: "fa fa-clock-o",
        validations: FormValidations.end_time,
        dateProps: {
          minTime: formData.start_time
            ? new Date(formData.start_time.getTime() + 30 * 60000)
            : new Date().setHours(0, 30, 0),
          maxTime: new Date().setHours(23, 30, 0),
        },
      },
      hideFunction: (value) => {
        return formData["open_close"] === "1" ? false : true;
      },
    },
    {
      type: "CHECK_RADIO",
      width: "3",
      name: "apply_to_all",
      element: {
        // label: "Closed",
        isHorizontal: true,
        // switchMode:true,
        //type:"switch",
        options: [{ value: "1", label: "Apply to All" }],
      },
      hideFunction: (data) => {
        return formData["open_close"] === "1" ? false : true;
      },
    },
  ];

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const handleDayClick = (dayValue) => {
    setSelectedDay(dayValue);
    handleSelectDay(dayValue); // Call handleSelectDay with the selected day value
  };
  const handleSelectDay = (day) => {
    // console.log("Selected day:", day);
    // You can perform any actions here when a day is selected
  };

  const CustomDaysElements = (day) => {
    let start_time_index = "start_time_" + day;
    let end_time_index = "end_time_" + day;
    return [
      {
        type: "CHECK_RADIO",
        width: "3",
        name: "open_close_" + day,
        element: {
          label: "Closed",
          isHorizontal: true,
          switchMode: true,
          inputProps: {
            disabled: applyToAllEnable,
          },
          //type:"switch",
          options: [{ value: "1", label: "Open" }],
        },
      },
      {
        type: "DATE",
        width: "3",
        name: "start_time_" + day,
        element: {
          label: "From",
          inputType: "BORDER_LABEL",
          showTimeSelect: true,
          rightIcon: "fa fa-clock-o",
          showTimeSelectOnly: true,
          dateFormat: "h:mm aa",
          inputProps: {
            disabled: applyToAllEnable,
          },
          dateProps: {
            minTime: new Date().setHours(0, 30, 0),
            maxTime: formData[end_time_index]
              ? new Date(formData[end_time_index].getTime() - 30 * 60000)
              : new Date().setHours(23, 30, 0),
            //maxTime:new Date().setHours(23, 30 0)
          },
        },
        hideFunction: (value) => {
          return formData["open_close_" + day] === "1" ? false : true;
        },
      },
      {
        type: "DATE",
        width: "3",
        name: "end_time_" + day,
        element: {
          label: "To ",
          inputType: "BORDER_LABEL",
          showTimeSelect: true,
          rightIcon: "fa fa-clock-o",
          showTimeSelectOnly: true,
          dateFormat: "h:mm aa",
          inputProps: {
            disabled: applyToAllEnable,
          },
          dateProps: {
            minTime: formData[start_time_index]
              ? new Date(formData[start_time_index].getTime() + 30 * 60000)
              : new Date().setHours(0, 30, 0),
            maxTime: new Date().setHours(23, 30, 0),
          },
        },
        hideFunction: (value) => {
          return formData["open_close_" + day] === "1" ? false : true;
        },
      },
    ];
  };

  const getTimestamp = (date) => {
    return date ? date.getTime() : null; // Returns timestamp in milliseconds
  };

  const compareTime = (stime, etime) => {
    return getTimestamp(stime) !== getTimestamp(etime) ? true : false;
  };

  const compareChanges = (dayNumber) => {
    let stime_index = "start_time_" + dayNumber;
    let etime_index = "end_time_" + dayNumber;
    let open_close_index = "open_close_" + dayNumber;
    // console.log("stime " , getTimestamp(formData[stime_index]) ,  " stime " , formData.start_time);

    if (
      compareTime(formData[stime_index], formData?.start_time) ||
      compareTime(formData[etime_index], formData?.end_time) ||
      formData[open_close_index] !== formData.open_close
    ) {
      //console.log(" inside this testing ")
      return true;
    }
    return false;
  };

  const getDayClassName = (dayNumber) => {
    let class_list = ["column is-2 holiday-div"];
    if (compareChanges(dayNumber)) {
      class_list.push("holidays-modified");
    }
    if (selectedDay === dayNumber) {
      class_list.push("holidays-selected");
    }
    return class_list.join(" ");
  };

  const holidayTopField = () => {
    return (
      <div className="columns smart-crop-business-forms">
        <div className="column is-6">
          <SmartSoftSelect
            label="State"
            placeHolder="State"
            inputType="BORDER_LABEL_FOCUS"
            classList={["has-text-left"]}
            value={formDataNew?.state || ""}
            inputProps={{ isFocussed: true }}
            options={states}
            onChange={(value) => handleInputNewChange("state", value)}
          />
        </div>
        <div className="column is-6">
          <SmartSoftSelect
            label="Year"
            placeHolder="Year"
            inputType="BORDER_LABEL_FOCUS"
            value={formDataNew?.year || ""}
            classList={["has-text-left"]}
            inputProps={{ isFocussed: true }}
            options={yearOptions()}
            onChange={(value) => handleInputNewChange("year", value)}
          />
        </div>
      </div>
    );
  };

  const businessHoursDisplay = () => {
    return (
      <>
        <p className=" has-text-weight-bold">Business Hours</p>
        <br />
        <div className="smart-crop-business-forms">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={operatingElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>

        <p className="   mb-4 mt-4 has-text-weight-bold">
          Business Hours (Daywise)
          <span
            className="ml-1 pointer"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: "relative" }}
          >
            <img src={INTERROGATION_IMAGE} alt="" />
            {showTooltip && (
              <div className="tooltip-container">
                <WorkingDaysHolidaysToolTip text="Select a day below to customise settings for a specific day." />
              </div>
            )}
          </span>
        </p>
        <div className="columns is-multiline holiday-container">
          {holidays.map((day, index) => (
            <div
              key={index}
              className={getDayClassName(day.ID)}
              onClick={() => handleDayClick(day.ID)}
            >
              <div>
                <span title={day.holiday_name}>
                  {displayHolidayDots(day.holiday_name)}
                </span>{" "}
                <br />
                {changeDateTimeZoneFormat(day.holiday_date, "DD-MM-YYYY")}
                {/* {day.holiday_date} */}
              </div>
            </div>
          ))}
        </div>
        <br />
        {selectedDay && (
          <div className="smart-crop-business-forms">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={CustomDaysElements(selectedDay)}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
            />
          </div>
        )}
        <div className="is-justify-content-end mt-3 is-flex">
          <SmartSoftButton
            label="Save"
            // leftIcon="fa fa-download"
            classList={["smart-crop-action-button"]}
            onClick={handleOperatingFormSubmit}
          />
        </div>
      </>
    );
  };

  return (
    <div className="OperatinghoursAndCustomDays">
      {holidayTopField()}
      {holidays && holidays.length > 0 && businessHoursDisplay()}
    </div>
  );
};

export default HolidayForm;
