import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftCheckRadioSwitch, SmartSoftDate } from "soft_digi";

const OtherServiceForm = () => {
  const navigate = useNavigate();
  const baseNav = "/home";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const [formData, setFormData] = useState({});
  // const [formSubmit, setFormSubmit] = useState(false);
  // const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const options = [{ value: "1", label: "Blue Days" }];
  const days = [
    { value: "1", label: "All" },
    { value: "2", label: "Sun" },
    { value: "3", label: "Mon" },
    { value: "4", label: "Tue" },
    { value: "5", label: "Wed" },
    { value: "6", label: "Thu" },
    { value: "7", label: "Fri" },
    { value: "8", label: "Sat" },
  ];

  return (
    <>
      <div className="other-service-form-container m-0">
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          <span className="icon mt-1" onClick={() => navigateLink("home-view")}>
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span>
          <span className="ml-5"> Other Services</span>
        </p>

        <div key={"OtherServiceForm-colums-one"} className="columns is-multiline mt-4 mx-5">
          <div key={"OtherServiceForm-colum-one"} className="column is-12">
            <div key={"OtherServiceForm-colums-two"} className="columns">
              <div key={"OtherServiceForm-colum-two"} className="column is-6 is-flex is-justify-content-space-between">
                <div className="has-text-left mt-2">
                  <SmartSoftCheckRadioSwitch
                    options={options}
                    name="checkboxone"
                    value={formData?.input_eight || null}
                    onChange={(value) =>
                      handleInputChange("input_eight", value)
                    }
                  />
                </div>
                <div className=" has-text-right is-flex">
                  <SmartSoftDate
                    key="text-4"
                    label="From Date"
                    value={formData?.input_four || ""}
                    onChange={(value) => handleInputChange("input_four", value)}
                    rightIcon="fa fa-calendar"
                    placeHolder="dd/mm/yyyy"
                    isHorizontal={true}
                    inputProps={{ isFocussed: true }}
                  />
                </div>
              </div>
              <div key={"OtherServiceForm-colum-three"} className="column is-6 ">
                <div className=" has-text-right is-flex">
                  <SmartSoftDate
                    key="text-4"
                    label="To Date"
                    value={formData?.input_four || ""}
                    onChange={(value) => handleInputChange("input_four", value)}
                    // inputType="BORDER_LABEL_FOCUS"
                    isHorizontal={true}
                    placeHolder="dd/mm/yyyy"
                    rightIcon="fa fa-calendar"
                    inputProps={{ isFocussed: true }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div key={"OtherServiceForm-colum-four"} className="column is-12 is-flex mt-4 smart-service-form-input">
            <span className=" has-text-weight-bold  mr-5">
              Days <span className="has-text-danger">*</span>{" "}
            </span>
            <SmartSoftCheckRadioSwitch
              isMulti={true}
              options={days}
              name="checkboxtwo"
              value={formData?.checkboxtwo || []}
              onChange={(value) => handleInputChange("checkboxtwo", value)}
            />
          </div>
        </div>
        <span className=" has-text-weight-bold mt-4 mx-5">
          List Of Holidays
        </span>
        <div key={"OtherServiceForm-colums-three"} className="columns mx-5 ">
          <div key={"OtherServiceForm-colum-five"} className="column is-6">3</div>
          <div key={"OtherServiceForm-colum-six"} className="column is-6">4</div>
        </div>
      </div>
    </>
  );
};

export default OtherServiceForm;
