import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftDate,
  SmartSoftForm,
} from "soft_digi";
import { CARD_IMAGE } from "../../../../services/ImageService";
import { ALLOW_NUMERIC } from "../../../../services/PatternSerivce";

const HappyHoursForm = () => {
  const navigate = useNavigate();
  const baseNav = "/home";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const numericValidations = [
    {
      type: "required",
      msg: "Numeric Value is Required",
    },
    {
      type: "pattern",
      msg: "Please Enter Correct Numeric Value",
      pattern: "[0-9]+",
    },
  ];

  const [formData, setFormData] = useState({});
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [formSubmit] = useState(false);
  const [setFormErrors] = useState(false);
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const fromTo = () => {
    return (
      <>
        <div className="HappyHoursForm">
          <p className="smart-bussiness-header pb-3 pl-3 pt-4">
            <span
              className="icon mt-1"
              onClick={() => navigateLink("home-view")}
            >
              <i className="fa fa-caret-left" aria-hidden="true"></i>
              <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
            </span>
            <span className="ml-5"> Happy Hours</span>
          </p>

          <div key={"HappyHoursForm-columns-one"} className="columns">
            <div key={"HappyHoursForm-column-one"} className="column is-flex is-justify-content-end  mt-2 is-6">
              <div className=" has-text-right is-flex">
                <SmartSoftDate
                  key="text-4"
                  label="From Date"
                  value={formData?.input_four || ""}
                  onChange={(value) => handleInputChange("input_four", value)}
                  rightIcon="fa fa-calendar"
                  placeHolder="dd/mm/yyyy"
                  isHorizontal={true}
                  inputProps={{ isFocussed: true }}
                />
              </div>
            </div>
            <div key={"HappyHoursForm-columns-two"} className="column is-5 is-flex is-justify-content-start mt-2">
              <SmartSoftDate
                key="text-4"
                label="To Date"
                value={formData?.input_four || ""}
                onChange={(value) => handleInputChange("input_four", value)}
                rightIcon="fa fa-calendar"
                placeHolder="dd/mm/yyyy"
                isHorizontal={true}
                inputProps={{ isFocussed: true }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const nuberOfDays = () => {
    // const options = [
    //   { value: '1', label: 'Days' },
    // ];
    const Days = [
      { value: "1", label: "All" },
      { value: "2", label: "Sun" },
      { value: "3", label: "Men" },
      { value: "4", label: "Tue" },
      { value: "5", label: "Wed" },
      { value: "6", label: "Thu" },
      { value: "7", label: "Fri" },
      { value: "8", label: "Set" },
    ];
    return (
      <>
        <div key={"HappyHoursForm-columns-two"} className="columns">
          <div key={"HappyHoursForm-column-three"} className="column is-flex is-justify-content-center">
            <span className="ml-6">Days</span>
            <span className="ml-6 pl-6">
              <SmartSoftCheckRadioSwitch
                isMulti={true}
                options={Days}
                name="checkboxone"
                value={formData?.input_day || null}
                onChange={(value) => handleInputChange("input_day", value)}
              />
            </span>
          </div>
        </div>
      </>
    );
  };

  const StartTime = () => {
    const formElements = [
      {
        type: "TEXT_BOX",
        width: "12",
        name: "input_Percentage",
        element: { placeHolder: "40", validations: numericValidations },
      },
    ];
    return (
      <>
        <div key={"HappyHoursForm-columns-three"} className="columns">
          <div key={"HappyHoursForm-column-four"} className="column ml-6">
            <SmartSoftDate
              key="text-4"
              label="Start Time"
              value={formData?.input_four || ""}
              onChange={(value) => handleInputChange("input_four", value)}
              rightIcon="fa fa-clock-o"
              placeHolder="10:0"
              isHorizontal={true}
              inputProps={{ isFocussed: true }}
            />
          </div>
          <div key={"HappyHoursForm-column-five"} className="column ">
            <SmartSoftDate
              key="text-4"
              label="Start Time"
              value={formData?.input_four || ""}
              onChange={(value) => handleInputChange("input_four", value)}
              rightIcon="fa fa-clock-o"
              placeHolder="10:0"
              isHorizontal={true}
              inputProps={{ isFocussed: true }}
            />
          </div>
          <div key={"HappyHoursForm-column-six"} className="column is-4 is-flex">
            <span>Percentage %*</span>
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElements}
              formSubmit={formSubmit}
              pattern={ALLOW_NUMERIC}
              handleErrorChange={handleErrorChange}
            />
          </div>
        </div>
      </>
    );
  };

  const ByOneGetOne = () => {
    // const options_ByoneGetFree = [
    //   { value: '2', label: 'ByoneGetFree' },
    // ];

    const ByoneGetFree = [
      { value: "1", label: "By One Get One Free" },
      { value: "2", label: "Complimentary Offer" },
    ];
    return (
      <>
        <div key={"HappyHoursForm-columns-four"} className="columns">
          <div key={"HappyHoursForm-column-seven"} className="column ml-6 ">
            <span className="ml-6 pl-6">
              <SmartSoftCheckRadioSwitch
                isMulti={true}
                options={ByoneGetFree}
                name="checkboxByoneGetFree"
                value={formData?.input_ByoneGetFree || null}
                onChange={(value) =>
                  handleInputChange("input_ByoneGetFree", value)
                }
              />
            </span>
          </div>
        </div>
      </>
    );
  };

  const UploadSpendAmountTitleAdd = () => {
    const newElement = [
      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_Images",
        element: {
          placeHolder: "Uploade Offer Images",
          validations: numericValidations,
        },
      },
      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_SpendAmound",
        element: {
          placeHolder: "Spend Amound*",
          validations: numericValidations,
        },
      },
      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_Title",
        element: { placeHolder: "Title", validations: numericValidations },
      },
      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_Description",
        element: {
          placeHolder: "Description*",
          validations: numericValidations,
        },
      },
      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_Addbtn",
        element: { placeHolder: "Add", validations: numericValidations },
      },
    ];
    return (
      <>
        <div key={"HappyHoursForm-columns-five"} className="columns">
          <div key={"HappyHoursForm-column-eight"} className="column ml-6 is-12 is-flex">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={newElement}
              formSubmit={formSubmit}
              pattern={ALLOW_NUMERIC}
              handleErrorChange={handleErrorChange}
            />
          </div>
        </div>
      </>
    );
  };

  const CardFirstTwoCard = () => {
    return (
      <>
        <div key={"HappyHoursForm-columns-six"} className="columns">
          <div key={"HappyHoursForm-column-nine"} className="column ml-6 is-3">
            <div className="HappyHoursForm-CardFirstTwoCard-card">
              <div className="is-flex">
                <span className="ml-2 mt-2">
                  <img src={CARD_IMAGE} alt="" height="100" width="100" />
                </span>
                <span className="mt-4 ml-5 has-text-white">
                  <p>Spend Amount:80</p>
                  <p>Title:Toy</p>
                  <p>Description:Teddy</p>
                </span>
              </div>
              <div className="centerbtn is-flex is-justify-content-center mt-1">
                <SmartSoftButton
                  label="Remove"
                  classList={["smart-crop-action-button"]}
                />
              </div>
            </div>
          </div>
          <div key={"HappyHoursForm-column-ten"} className="column ml-0 is-3">
            <div className="HappyHoursForm-CardFirstTwoCard-card ml-6">
              <div className="is-flex">
                <span className="ml-2 mt-2">
                  <img src={CARD_IMAGE} alt="" height="100" width="100" />
                </span>
                <span className="mt-4 ml-5 has-text-white">
                  <p>Spend Amount:200</p>
                  <p>Title:Toy</p>
                  <p>Description:Multiple toys</p>
                </span>
              </div>
              <div className="centerbtn is-flex is-justify-content-center mt-2">
                <SmartSoftButton
                  label="Remove"
                  classList={["smart-crop-action-button"]}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SpandAmountDiscountAdd = () => {
    const newinputs = [
      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_Spend",
        element: {
          placeHolder: "Spend Amount*",
          validations: numericValidations,
        },
      },

      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_DiscountPercentage",
        element: {
          placeHolder: "Discount Percentage*",
          validations: numericValidations,
        },
      },

      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_DiscountUpto",
        element: {
          placeHolder: "Discount Upto*",
          validations: numericValidations,
        },
      },

      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_ExpiryDurationDays",
        element: {
          placeHolder: "Expiry Duration Days*",
          validations: numericValidations,
        },
      },

      {
        type: "TEXT_BOX",
        width: "2",
        name: "input_AddAddbtn",
        element: { placeHolder: "Add", validations: numericValidations },
      },
    ];
    return (
      <>
        <div key={"HappyHoursForm-columns-seven"} className="columns">
          <div key={"HappyHoursForm-column-eleven"} className="column ml-6 is-12 is-flex">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={newinputs}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
            />
          </div>
        </div>
      </>
    );
  };

  const SecoundCardOne = () => {
    return (
      <>
        <div key={"HappyHoursForm-columns-eight"} className="columns">
          <div key={"HappyHoursForm-column-twelve"} className="column ml-6 is-3">
            <div className="HappyHoursForm-SecoundCardOne-card">
              <div className="is-flex">
                <span className="mt-4 ml-5 has-text-white">
                  <p>Spend Amount:800</p>
                  <p>Discount:30%</p>
                  <p>Promo Code:BV3WG2HH</p>
                  <p>Discount Upto:100 Days</p>
                  <p>Discount Duration:12 Days</p>
                  <p>Remove</p>
                </span>
              </div>
              <div className="centerbtn is-flex is-justify-content-center mt-1">
                <SmartSoftButton
                  label="Remove"
                  classList={["smart-crop-action-button"]}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const HappyHoursChackboxTwo = () => {
    // const Appliedt = [{ value: "2", label: "Appliedto" }];
    const Appliedto = [
      { value: "1", label: "All" },
      { value: "2", label: "Mobiles and Watch With Toy" },
    ];
    return (
      <>
        <div key={"HappyHoursForm-columns-nine"} className="columns">
          <div key={"HappyHoursForm-column-thirteen"} className="column ml-6">
            <span className="">Happy Hours Not applied to</span>
            <span className="mt-6">
              <SmartSoftCheckRadioSwitch
                isMulti={true}
                options={Appliedto}
                name="checkboxAppliedto"
                value={formData?.input_Appliedto || null}
                onChange={(value) =>
                  handleInputChange("input_Appliedto", value)
                }
              />
            </span>
          </div>
        </div>
      </>
    );
  };

  const HappyHoursNotApplied = () => {
    // const Appliednott = [{ value: "3", label: "Appliednotto" }];
    const Appliednotto = [
      { value: "1", label: "Mobiles and Watch with Toy" },
      { value: "2", label: "Mobile" },
      { value: "3", label: "Toys" },
      { value: "4", label: "Prdl" },
      { value: "5", label: "Product" },
      { value: "6", label: "Newones" },
    ];
    return (
      <>
        <div key={"HappyHoursForm-columns-ten"} className="columns">
          <div key={"HappyHoursForm-column-fourteen"} className="column ml-6">
            <span className="">Happy Hours Not applied to</span>
            <span className="mt-6">
              <SmartSoftCheckRadioSwitch
                isMulti={true}
                options={Appliednotto}
                name="checkboxAppliednotto"
                value={formData?.input_Appliednotto || null}
                onChange={(value) =>
                  handleInputChange("input_Appliednotto", value)
                }
              />
            </span>
          </div>
        </div>
        <div key={"HappyHoursForm-columns-eleven"} className="columns">
          <div key={"HappyHoursForm-column-fifteen"} className="column ml-6">
            <SmartSoftButton
              label="Save"
              classList={["smart-crop-action-button "]}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {fromTo()}
      {nuberOfDays()}
      {StartTime()}
      {ByOneGetOne()}
      {UploadSpendAmountTitleAdd()}
      {CardFirstTwoCard()}
      {SpandAmountDiscountAdd()}
      {SecoundCardOne()}
      {HappyHoursChackboxTwo()}
      {HappyHoursNotApplied()}
    </div>
  );
};

export default HappyHoursForm;
