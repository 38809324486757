import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftDate,
  SmartSoftForm,
} from "soft_digi";
import { ALLOW_NUMERIC } from "../../../../services/PatternSerivce";

const ExtendBonusForm = () => {
  const navigate = useNavigate();
  const baseNav = "/home";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  // const options = [{ value: "1", label: "BonusCROPs" }];
  const BonusCROPs = [
    { value: "1", label: "All" },
    { value: "2", label: "Sun" },
    { value: "3", label: "Men" },
    { value: "4", label: "Tue" },
    { value: "5", label: "Wed" },
    { value: "6", label: "Thu" },
    { value: "7", label: "Fri" },
    { value: "8", label: "Set" },
  ];
  // const options_BonusCRO = [{ value: "2", label: "BonusCRO" }];
  const BonusCRO = [
    { value: "1", label: "All" },
    { value: "2", label: "Mobiles and Watch With Toy" },
    { value: "3", label: "Watch" },
    { value: "4", label: "Mobile" },
    { value: "5", label: "Toys" },
    { value: "6", label: "Prdl" },
  ];
  // const options_BonusCROnotapplied = [
  //   { value: "3", label: "BonusCROnotapplied" },
  // ];
  const BonusCROnotapplied = [
    { value: "1", label: "Mobiles and Watch With Toy" },
    { value: "2", label: "Mobile" },
    { value: "3", label: "Toys" },
    { value: "4", label: "Prdl" },
    { value: "5", label: "Product" },
  ];
  const [formData, setFormData] = useState({});
  const [formSubmit] = useState(false);
  const [setFormErrors] = useState(false);
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  // const handleFormSubmit = () => {
  //   console.log("formdata", formData);
  //   setFormSubmit(true);
  //   console.log("form errors ", formErrors);
  // };
  const numericValidations = [
    {
      type: "required",
      msg: "Numeric Value is Required",
    },
    {
      type: "pattern",
      msg: "Please Enter Correct Numeric Value",
      pattern: "[0-9]+",
    },
  ];
  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "input_one",
      element: { placeHolder: "100", validations: numericValidations },
    },
  ];
  return (
    <div className="ExtendBonusForm">
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        <span className="icon mt-1" onClick={() => navigateLink("home-view")}>
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span>
        <span className="ml-5">Extend Bonus CROPs</span>
      </p>

      <div key={"ExtendBounsFrom-columns-one"} className="columns">
        <div key={"ExtendBounsFrom-column-one"}  className="column is-flex is-justify-content-end mt-3 is-6">
          <SmartSoftDate
            key="text-4"
            label="From Date"
            value={formData?.input_four || ""}
            onChange={(value) => handleInputChange("input_four", value)}
            rightIcon="fa fa-calendar"
            placeHolder="dd/mm/yyyy"
            isHorizontal={true}
            inputProps={{ isFocussed: true }}
          />
        </div>
        <div key={"ExtendBounsFrom-column-two"}  className="column is-5 is-flex is-justify-content-start mt-3">
          <SmartSoftDate
            key="text-4"
            label="From Date"
            value={formData?.input_four || ""}
            onChange={(value) => handleInputChange("input_four", value)}
            rightIcon="fa fa-calendar"
            placeHolder="dd/mm/yyyy"
            isHorizontal={true}
            inputProps={{ isFocussed: true }}
          />
        </div>
      </div>
      <div key={"ExtendBounsFrom-columns-two"} className="columns">
        <div key={"ExtendBounsFrom-column-three"}  className="column  is-flex ml-6 is-justify-content-center">
          <SmartSoftCheckRadioSwitch
            isMulti={true}
            options={BonusCROPs}
            name="checkboxone"
            value={formData?.input_Bonus || null}
            onChange={(value) => handleInputChange("input_Bonus", value)}
          />
        </div>
      </div>
      <div key={"ExtendBounsFrom-columns-three"} className="columns">
        <div key={"ExtendBounsFrom-column-four"}  className="column ml-6 is-6 is-flex">
          <span className="mr-4 mt-2">Bonus CROP %</span>
          <span>
            {" "}
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElements}
              formSubmit={formSubmit}
              pattern={ALLOW_NUMERIC}
              handleErrorChange={handleErrorChange}
            />
          </span>
        </div>
      </div>
      <span className="ml-6">Bonus CROPs Applied to</span>
      <div key={"ExtendBounsFrom-columns-four"} className="columns ">
        <div key={"ExtendBounsFrom-column-five"}  className="column  is-flex ml-6 is-justify-content-center">
          <SmartSoftCheckRadioSwitch
            isMulti={true}
            options={BonusCRO}
            name="checkboxBonusCRO"
            value={formData?.input_BonusCRO || null}
            onChange={(value) => handleInputChange("input_BonusCRO", value)}
          />
        </div>
      </div>
      <span className="ml-6">Bonus CROPs Applied to</span>
      <div key={"ExtendBounsFrom-columns-five"} className="columns ">
        <div  key={"ExtendBounsFrom-column-five"} className="column  is-flex ml-6 is-justify-content-center">
          <SmartSoftCheckRadioSwitch
            isMulti={true}
            options={BonusCROnotapplied}
            name="checkboxBonusCROnotapplied"
            value={formData?.input_BonusCROnotapplied || null}
            onChange={(value) =>
              handleInputChange("input_BonusCROnotapplied", value)
            }
          />
        </div>
      </div>
      <div key={"ExtendBounsFrom-columns-six"} className="columns">
        <div key={"ExtendBounsFrom-column-six"} className="column ml-6">
          <SmartSoftButton
            label="Save"
            classList={["smart-crop-action-button"]}
          />
        </div>
      </div>
    </div>
  );
};

export default ExtendBonusForm;
