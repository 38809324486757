// ThemeContext.js
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import PageLoader from '../components/site/PageLoader';
import { setUserSession, getUserSession,setCsrf } from '../services/sessionService';
import SmartModal from './SmartModal';
// import sessionActivityService from '../services/sessionActivityService';
import { showAlertAutoClose } from '../services/notifyService';
import { get } from "../services/smartApiService";
import { ADMIN_LOGIN_URLS } from "../api/Admin/AurhUrls";

const SiteContext = createContext();

export const useSiteContext = () => {
  return useContext(SiteContext);
};

export const SiteProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(false);
  const [user, setUserData] = useState(getUserSession());
  // these are modal contexts 
  const [modalOptions, setModalOptions] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 
  const setLoading = (status, msg = "") => {
    setIsLoading(status);
    setLoadingMsg(msg)
  }
  const setUser = (data) => {
    setUserData(data);
    setUserSession(data);
  }

  const openModal = useCallback((options) => {
    setModalOptions(options);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOptions(null);
    setIsModalOpen(false);
  }, []);

  const updateUserOnLoad = () => {
    let session_data = getUserSession();
    if (session_data) {
      setUserData(session_data);
      if (session_data.ename !== undefined) {
        refreshUser();
      }
    }
  }

  const logout = () => {
    // Handle logout (e.g., redirect to login page)
    // showAlertAutoClose("Logged Out Successfully","success");
    showAlertAutoClose("Log Out Successful", "success");
    setUser(null);
  };

  /**
   *  session related services like session activity start and inactivity monitoring
   * 
   */

  // const handleInactivity = () => {
  //     // Handle inactivity (e.g., show notification)
  //     console.log("inactivity  sdfasdf af after login in")
  //     // 
  //     sessionActivityService.startExpiryTimer(logout, 120  * 1000); // Logout in 1 minute
  //   };

  const startSessionAct = () => {
    // Start the inactivity timer when the component mounts
    // sessionActivityService.startInactivityTimer(handleInactivity);
    /*
    console.log("started the session")
    // Attach event listeners to reset the inactivity timer on user activity
    const handleUserActivity = () => {
      //  console.log("invacitiviy time mout or cleared inavitivitiryr");
        sessionActivityService.resetInactivityTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Cleanup event listeners on component unmount
    return () => {
      //  window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
    };
    */
    //  sessionActivityService.startExpiryTimer(logout, 30 * 1000);
  }

  const refreshUser = () => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
    };
    //console.log("testing that person");
    let url = ADMIN_LOGIN_URLS.REFRESH;
    const subscription = get(url, handleError).subscribe((response) => {
      if (response.data.crop_balance !== undefined) {
        setUserData((prev) => ({
          ...prev,
          crop_balance: response.data.crop_balance,
          cart: response.data?.cart,
          wishlist: response.data?.wishlist,
          notification: response.data?.notification,
          profile_image: response.data?.profile_image,
          pickUpDelivery: response.data?.pickUpDelivery
        }));
      }
      //console.log(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };


  const health_check = () => {
    const handleError = (errorMessage) => {
    };
    let url = ADMIN_LOGIN_URLS.HEALTH_CHECK;
    const subscription = get(url, handleError).subscribe((response) => {
      const csrf = response.data ? response.data.csrf : "";
      setCsrf(csrf);
      // console.log(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };



  useEffect(() => {
    health_check();
    // this is to get the data from session storage 
    updateUserOnLoad()
    //
    // have health check and get the csrf token
    const interval = setInterval(health_check, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <SiteContext.Provider value={{
      setLoading, user, setUser, openModal, closeModal,
      startSessionAct, logout, refreshUser
    }}>
      {children}
      <PageLoader loading={isLoading} msg={loadingMsg} />
      {isModalOpen && <SmartModal modalOptions={modalOptions} closeModal={closeModal} />}
    </SiteContext.Provider>
  );
};