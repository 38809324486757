import { useEffect, useRef, useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-image-crop/src/ReactCrop.scss';

const SmartImageEditor = (props) => {
    const {
        src,
        setImage,
        closeModal,
        enableOthers=false
    } = props;
    //console.log("src " , src);
    const canvasRef = useRef(null);
    const [crop, setCrop] = useState();
    const [imgSrc, setImg] = useState(null)
    const [brightnessFactor, setbrightnessFactor] = useState(1)
    const [contrastFactor, setcontrastFactor] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [flag, setFlag] = useState(0);
    const [backgroundColor, setBackGround] = useState("white");
    //  const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)



    const updateConvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
            // console.log("image onload called " );
            canvas.width = Math.abs(Math.cos(rotation * Math.PI / 180) * img.width) + Math.abs(Math.sin(rotation * Math.PI / 180) * img.height);
            canvas.height = Math.abs(Math.sin(rotation * Math.PI / 180) * img.width) + Math.abs(Math.cos(rotation * Math.PI / 180) * img.height);
            //   if(backgroundColor.length > 0){
           // console.log("brightness entrty ", backgroundColor);
            ctx.fillStyle = backgroundColor;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            // }           
            ctx.filter = `brightness(${brightnessFactor})  contrast(${contrastFactor})`; // Adjust brightness
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((rotation * Math.PI) / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
        };
        // console.log("canvas updated to current reference " , canvas, img);   
    }

    useEffect(() => {
        //console.log("updating the use customer canvas is called " , img)
        updateConvas()
    }, [imgSrc, rotation, brightnessFactor, contrastFactor]);


    useEffect(() => {
        let imag_src = "data:image/png;base64," + src;
        setImg(imag_src)
    }, [src]);


    const handleRotationChange = (angle) => {
        setRotation(angle);
    };

    const getImageDataUrl = () => {
        const canvas = canvasRef.current;
        let image_base64 = canvas?.toDataURL(); // This will return the image data in base64 format
        // console.log("base 64 " , image_base64);
        return image_base64;
    };

    const handleBrightnessEvent = (event) => {
        setbrightnessFactor(event.target.value);
    }

    const handleContrastEvent = (event) => {
        setcontrastFactor(event.target.value);
    }

    const handleImageOk = () => {
        if (flag == 3) {
            handleCropImage()
        } else {
            let image_64 = getImageDataUrl();
            setImage(image_64);
            closeModal()
        }
    }




    const normalButtons = () => {
        return (
            <>
                <button onClick={() => setFlag(1)}><i className="fa fa-rotate-left"></i></button>
                <button onClick={() => setFlag(3)}> <i className="fa fa-crop"></i></button>
                <button onClick={() => setFlag(10)}> <i className="fa fa-file"></i></button>
                <button onClick={() => setFlag(11)}> <i className="fa fa-adjust"></i></button>
            </>
        )
    }

    const rotateButtons = () => {
        return (
            <>
                <button className="button is-link is-small" onClick={() => { setFlag(0); handleRotationChange(rotation - 90) }} >
                    <i className="fa fa-rotate-left"></i></button>
                <button className="button is-link is-small" onClick={() => { setFlag(0); handleRotationChange(rotation + 90) }}>
                    <i className="fa fa-rotate-right"></i></button>
                {/* <button className="button is-link is-small" onClick={() => setFlag(3)}> <i className="fa fa-crop">

                </i></button> */}
                {enableOthers && 
                 <>
                <button className="button is-link is-small" onClick={() => setFlag(10)}> <i className="fa fa-sun-o"></i></button>
                <button className="button is-link is-small" onClick={() => setFlag(11)}> <i className="fa fa-adjust"></i></button>
                </>
                }
                <button className="button is-success is-small" onClick={() => handleImageOk()}><i className="fa fa-check"></i></button>
                <button className="button is-danger is-small" onClick={() => handleImageOk()}><i className="fa fa-close"></i></button>

            </>
        )
    }

    const brightNessButtons = () => {
        return (
            <input class="slider is-fullwidth is-success" step="0.1" min="0" max="2"
                value={brightnessFactor} type="range" onChange={(event) => handleBrightnessEvent(event)} />
        )
    }

    const contrastButtons = () => {
        return (
            <input class="slider is-fullwidth is-success" step="0.1" min="0" max="2"
                value={contrastFactor} type="range" onChange={(event) => handleContrastEvent(event)} />
        )
    }

    const handleCropImage = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();
        console.log("crop  ", crop)
        img.src = imgSrc;
        img.onload = () => {
            canvas.width = crop.width;
            canvas.height = crop.height;
            ctx.drawImage(
                img,
                crop.x,
                crop.y,
                crop.width,
                crop.height,
                0,
                0,
                crop.width,
                crop.height
            );
            let image_base64 = canvas?.toDataURL();
            // console.log(image_base64);
            setImg(image_base64);
            setImage(image_base64);
            closeModal()
            //setFlag(0)
        };

    }

    const cropButtons = () => {
        return (
            <button onClick={handleCropImage}>
                <i className="fa fa-check"></i>
            </button>
        )
    }

    //console.log(" canvas ref " , canvasRef)

    return (
        <div >
            <div className="columns is-multiline smart-image-editor">
                <div className="column is-12 smart-image-editor-bottom-buttons">
                    {rotateButtons()}
                </div>
                {/* <div className="column is-4 has-text-right">                    
                    <button className="button is-danger is-small" onClick={() => handleImageOk()}><i className="fa fa-close"></i></button>
                </div> */}
                <div className="column is-12 is-flex is-justify-content-center">
                    <canvas ref={canvasRef} className={flag == 3 ? "smart_hide" : ""} />
                </div>
                <div className="column is-12 is-flex is-justify-content-center">
                    {flag == 3 &&
                        <>
                            <div style={{ position: "relative" }}>
                                <ReactCrop crop={crop} onChange={c => setCrop(c)}>
                                    <img src={imgSrc} />
                                </ReactCrop>
                            </div>
                            {/* {cropButtons()} */}
                        </>
                    }
                </div>
                <div className="column is-12 smart-image-editor-bottom-buttons">
                    {flag == 10 && brightNessButtons()}
                    {flag == 11 && contrastButtons()}


                    {/* {flag==1 && rotateButtons()}
                    
                    {flag==11 && contrastButtons()} */}
                </div>
            </div>

        </div>
    )
}

export default SmartImageEditor;