import { useEffect, useState } from "react";
import { SmartSoftButton, SmartValid } from "soft_digi";
import {
  // ACCOUNT_MAN_SIT_LAPTOP,
  SETTINGS_PIN_CHANGE,
} from "../../../../services/ImageService";
// import SmartPassWord from "../../../../components/site/FormElements/SmartPassWord";
import { SmartSoftForm } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { USER_URLS } from "../../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose, showNotification } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";
import { NOTIFICATION_SETTINGS } from "../../../../api/Services/ServiceUrls";
// ../../../components/site/FormElements/SmartPassWord
// import { useNavigate } from "react-router-dom";
// import { VALID_PASSWORD } from "../../../../services/PatternSerivce";

const PinChangeForm = () => {
  // const navigate = useNavigate();
  // const baseNav = "/home";
  // const navigateLink = (index) => {
  //   navigate(baseNav + "/" + index);
  // };

  const [formData, setFormData] = useState({});

  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading,refreshUser } = useSiteContext();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleErrorChange = (name, value) => {
    // console.log(" name " ,name , "   value " , value)
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const pre_process_data=(data)=>{
    let acc_communication = [];
    if(data.user_app_notification==="Yes"){
     acc_communication.push("1");
    }
    if(data.user_sms_notification==="Yes"){
     acc_communication.push("2");
    }
    if(data.user_email_notification==="Yes"){
     acc_communication.push("3");
    }
    return {app_communication : acc_communication};
}

 const loadTableData = () => {
   setLoading(true, "Loading.... Please Wait");
   const handleError = (errorMessage) => {
     showNotification("error", errorMessage);
     setLoading(false);
   };
   const subscription = get(
     NOTIFICATION_SETTINGS.NOTIFICATION_GET_ONE,
     handleError
   ).subscribe((response) => {
     setFormData(pre_process_data(response.data));
     setLoading(false);
   });
   return () => {
     subscription.unsubscribe();
   };
 };
 useEffect(() => {
   loadTableData();
 }, []);

 const handleFormSubmitOne = () => {
  // console.log(formData);
  setFormSubmit(true);
  if (!ValidateFormNew(formData, formElementOne)) {
   // console.log("error is avialble");
    return false;
  }
  let data_in = {};   
  data_in.user_app_notification = formData.app_communication?.indexOf("1")==-1 ? 'No' : 'Yes'
  data_in.user_sms_notification = formData.app_communication?.indexOf("2")==-1 ? 'No' : 'Yes'
  data_in.user_email_notification = formData.app_communication?.indexOf("3")==-1 ? 'No' : 'Yes'
//  console.log("formdata", data_in);
  setFormSubmit(true);
  const handleError = (errorMessage) => {
    showNotification("error", errorMessage);
    setLoading(false);
  };
  setLoading(true, "Updating....Please Wait");
  let url = NOTIFICATION_SETTINGS.NOTIFICATION_INSERT;
  const subscription = post(url, data_in, handleError).subscribe(
    (response) => {
      showAlertAutoClose("Communication Preference updated Successfully", "success");
      setLoading(false);
      setFormSubmit(false);
      loadTableData()

    }
  );
  return () => {
    subscription.unsubscribe();
  };
  // setFormSubmit(true);
  // console.log("form errors ", formErrors);
};


  // const numericValidations = [
  //   {
  //     type: "required",
  //     msg: "Numeric Value is Required",
  //   },
  //   {
  //     type: "pattern",
  //     msg: "Please Enter Correct Numeric Value",
  //     pattern: "[0-9]+",
  //   },
  // ];

  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData,formElementPassword)) {
      return false;
    }

    const handleError = (errorMessage) => {
      // showNotification("error", errorMessage);
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Updating....Please Wait");
    // console.log(formData);
    let url = USER_URLS.CHANGE_PASSWORD;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        // showNotification("success", "Password Changed Successfully");
        showAlertAutoClose("Password Changed Successfully", "success");
        setLoading(false);
        refreshUser()
        // console.log(response.data);
        setFormData({});
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const FormValidations = {
    old_password: [
      SmartValid.required("Old Password Required"),
      //  SmartValid.maxLength("Number should be maximum 20 digit",20)
    ],
    new_password: [
      SmartValid.required("New Password Required"),
      // SmartValid.maxLength("Number should be maximum 20 digit",20)
    ],
    confirm_password: [
      SmartValid.required("Confirm Password Required"),
      // SmartValid.maxlength("Number should be maximum 20 digit",20)
    ],
  };

  // const formElements = [
  //   {
  //     type: "TEXT_BOX",
  //     width: "12",
  //     name: "input_one",
  //     element: {
  //       placeHolder: "Enter Old Pin",
  //       validations: numericValidations,
  //     },
  //   },

  //   {
  //     type: "TEXT_BOX",
  //     width: "12",
  //     name: "input_one",
  //     element: {
  //       placeHolder: "Enter New Pin",
  //       validations: numericValidations,
  //     },
  //   },

  //   {
  //     type: "TEXT_BOX",
  //     width: "12",
  //     name: "input_one",
  //     element: {
  //       placeHolder: "Confirm New Pin",
  //       validations: numericValidations,
  //     },
  //   },
  // ];

  const formElementPassword = [
    {
      type: "PASSWORD",
      width: "12",
      name: "currentPassword",
      element: {
        // key: "currentPassword",
        // value: formData?.currentPassword || "",
        // handleInputChange: handleInputChange,
        // inputType: "BORDER_LABEL_FOCUS",

        inputType: "BORDER_LABEL",
        label: " Current Password",

        placeHolder: " Current Password",
        passwordValidator: true,
        validations: FormValidations.old_password,
        isRequired: true,
        // errorEnable: formSubmit,
        // errorUpdate: (value) => handleErrorChange("old_password", value),
        min:4,
        max:16,
      },
    },

    {
      type: "PASSWORD",
      width: "12",
      name: "newPassword",
      element: {
        placeHolder: " New Password",
        passwordValidator: true,
        validations: FormValidations.new_password,
        isRequired: true,
        inputType: "BORDER_LABEL",
        label: "New Password",
        min:4,
        max:16,
      },
    },

    {
      type: "PASSWORD",
      width: "12",
      name: "confirmPassword",
      element: {
        inputType: "BORDER_LABEL",
        label: "Confirm Password",
        placeHolder: "Confirm Password",
        passwordValidator: true,
        validations: FormValidations.confirm_password,
        isRequired: true,
        min:4,
        max:16,
      },
    },
  ];

  const validations = [
    // SmartValid.required("Required"),
     SmartValid.custom((value) => {     
       return value && Array.isArray(value) && value.length > 0 ? "": "Required";
     }),   
   ]

  const formElementOne = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "app_communication",
      element: {
        options: [
          // { value: "1", "label": "App Notification" },
          { value: "2", "label": "SMS " },
          { value: "3", "label": "Email " }
        ],
        isMulti: true,
        validations: validations
      },

    },
    // {
    //   type: "BUTTON",
    //   width: "12",
    //   element: {
    //     label: "Save",
    //     classList: ["is-rounded", "smart-crop-save-button"],
    //     // onClick: () => handleLogin(),
    //     onClick: () => handleFormSubmitOne(),
    //     //  disabled:{!isEmptyObject(formErrors)}
    //   },
    // }
  ];

  return (
    <>
      <p className="smart-bussiness-header pb-3 pl-3 pt-4">
        {/* <span className="icon mt-1">
          <i className="fa fa-caret-left" aria-hidden="true"></i>
          <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
        </span> */}
        <span className="ml-5"> Settings</span>
      </p>

      <div className=" smart-crop-business-forms m-6">
      <div className="mt-4 has-text-weight-bold px-4 is-size-5">Password Change</div>
        <div
          key={"PinchangeFron-column-one"}
          className="columns image-form-content is-flex"
        >
          <div
            key={"PinchangeFron-column-two"}
            className="column is-6"
          >
            <div className="px-3">
              {/* <SmartPassWord
                key="currentPassword"
                name="currentPassword"
                value={formData?.currentPassword || ""}
                handleInputChange={handleInputChange}
                inputType="BORDER_LABEL_FOCUS"
                label="Enter Old Password"
                passwordValidator={true}
                validations={FormValidations.old_password}
                errorEnable={formSubmit}
                errorUpdate={(value) =>
                  handleErrorChange("old_password", value)
                }
              /> */}
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElementPassword}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
            </div>
            <div className="pb-3">
              {/* <SmartPassWord
                key="newPassword"
                name="newPassword"
                inputType="BORDER_LABEL_FOCUS"
                label="Enter New Password"
                value={formData?.newPassword || ""}
                handleInputChange={handleInputChange}
                passwordValidator={true}
                validations={FormValidations.new_password}
                errorEnable={formSubmit}
                errorUpdate={(value) =>
                  handleErrorChange("new_password", value)
                }
              /> */}
            </div>
            <div className="pb-3">
              {/* <SmartPassWord
                key="confirmPassword"
                name="confirmPassword"
                value={formData?.confirmPassword || ""}
                handleInputChange={handleInputChange}
                inputType="BORDER_LABEL_FOCUS"
                label="Confirm New Password"
                passwordValidator={true}
                validations={FormValidations.confirm_password}
                errorEnable={formSubmit}
                errorUpdate={(value) =>
                  handleErrorChange("confirm_password", value)
                }
              /> */}
            </div>
            <div className="is-flex is-justify-content-center pt-4">
              <SmartSoftButton
                label="Update"
                onClick={() => handleFormSubmit()}
                classList={["is-rounded","smart-crop-action-button"]}
                // disabled={!isEmptyObject(formErrors)}
              />
            </div>

<div className="mt-4 has-text-weight-bold px-4 is-size-5">Communication Preference</div>
            <div key={"CommunitionPreFrom-column-one"}
            className="is-flex p-2 is-align-content-center app-communication-form">

            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElementOne}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
              
            />
          </div> 

          <div className="is-flex is-justify-content-center pt-4">
              <SmartSoftButton
                label="Save"
                onClick={() => handleFormSubmitOne()}
                classList={["is-rounded","smart-crop-save-button"]}
                // disabled={!isEmptyObject(formErrors)}
              />
            </div>
          
          </div>
          <div key={"PinchangeFron-column-three"} className="column is-6 ">
            <div className="my-card-view-form-image p-6 ">
              <img src={SETTINGS_PIN_CHANGE} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3"></div>
    </>
  );
};

export default PinChangeForm;
