import { useEffect, useState } from "react";
import { SmartSoftButton, SmartSoftForm } from "soft_digi";
import { MANAGE_OFFER_URLS } from "../../../../api/Business/OffersUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { CROP_C_LOGO, CROP_LOGO_C } from "../../../../services/ImageService";
import { filterIncludes } from "../../../../services/core/FilterService";
import { showNotification } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";
import CustomerTransactionAddNewFrom from "./CustomerTransactionAddNewFrom";
import NoResultComponents from "./NoResultComponents";
import { ORDER_URLS } from "../../../../api/OrderUrls";
import { useNavigate } from "react-router-dom";
import ProductVariant from "./ProductVariant";

const CustomerTrancationProduct = ({
  setProducts,
  type,
  subtype,
  customer_id,
  setItem,
}) => {
  const [tabData, setTabData] = useState([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [formData, setFormData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const { openModal, closeModal, setLoading } = useSiteContext();
  const navigate = useNavigate();

  //console.log("type " , type, "subtype " , subtype );

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    search_data();
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const addProduct = (newItem) => {
    if (newItem.variant_av && newItem.variant_av > 0) {
      openRateModal(newItem, type);
      return false;
    }
    newItem["earn_crop_points"] =
      parseInt(newItem["total_price"]) * newItem["earnFraction"];
    newItem["redeem_crop_points"] =
      parseInt(newItem["total_price"]) * newItem["redeemFraction"];
    addProductNew(newItem);
  };

  const addProductNew = (newItem) => {
    newItem["quantity"] = 1;
    newItem["sd_offers_id"] = newItem.ID;
    newItem["pickupDelivery"] = 1;
    if (type == "EARN") {
      newItem["crop_points"] = newItem["earn_crop_points"];
    } else if (type == "REDEEM") {
      newItem["crop_points"] = newItem["redeem_crop_points"];
    }
    console.log("new item ", newItem);
    setProducts((prevItems) => {
      // Check if an item with the same ID already exists
      const exists = prevItems.some((item) => item.ID === newItem.ID);
      // If it does not exist, add the new item
      if (!exists) {
        return [...prevItems, newItem];
      }
      // Otherwise, return the existing state
      return prevItems;
    });
  };

  const openRateModal = (item) => {
    let modelObject = {
      body: (
        <ProductVariant
          item={item}
          setLoading={setLoading}
          closeModal={closeModal}
          type={type}
          executeCart={addProductNew}
        />
      ),
      title: "Please Select Variant",
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const openNewForm = () => {
    let modelObject = {
      body: (
        <CustomerTransactionAddNewFrom
          closeModal={closeModal}
          setLoading={setLoading}
          type={type}
          navigate={navigate}
          loadTableData={loadTableData}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90 modal-card",
      bodyClose: true,
    };
    openModal(modelObject);
  };

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    let _data = { type: type, customer_id: customer_id };
    let url = MANAGE_OFFER_URLS.GET_ALL_DIRECT;
    if (type == "RETURN") {
      url = ORDER_URLS.GET_ALL_DIRECT;
    }
    const subscription = post(url, _data, handleError).subscribe((response) => {
      setTabData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const getReturnItems = (item) => {
    setItem(item);
    setLoading(true, "Loading.... Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    let _data = { order_id: item.ID };
    let url = ORDER_URLS.GET_ALL_ITEMS;
    const subscription = post(url, _data, handleError).subscribe((response) => {
      setProducts(response.data);
      //console.log(response.data);
      // setTabData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const filterFunction = (item) => {
    let output;
    output = filterIncludes(item, ["offer_title"], formData.search_value);
    return output;
  };

  const searchData = () => {
    const out_data = tabData.filter(filterFunction);
    setFilteredData(out_data);
  };

  useEffect(() => {
    // Filter data based on the search term and set it to 'filteredData' state
    if (tabData) {
      if (formData.search_value && formData.search_value.length > 1) {
        searchData();
      } else {
        setFilteredData(tabData); // console.log("data Details",data)
      }
    }
  }, [tabData, formData]);

  const search_data = (search_value) => {
    // let search_value = formData.search_value;
    if (!search_value || search_value.length < 3) {
      return tabData;
    }

    const filteredArray = tabData.filter((item) =>
      item.offer_title.toLowerCase().includes(search_value.toLowerCase())
    );
    return filteredArray;
    //setData(filteredArray);
  };

  const formElements = [
    {
      type: "TEXT_BOX",
      width: "11",
      name: "search_value",
      element: {
        placeHolder: "Search by Offer Title",
        classList: ["customer-trancation-search"],
        rightIcon: "fa-search",
      },
    },
  ];

  const earn_crops = (item) => {
    return parseInt(parseInt(item["total_price"]) * item["earnFraction"]);
  };

  const redeem_crops = (item) => {
    return parseInt(parseInt(item["total_price"]) * item["redeemFraction"]);
  };

  const displaySingleItem = (item) => {
    return (
      <div className="is-flex is-justify-content-space-between ">
        <div className="">
          {item.offer_title} <br />
          {type == "EARN" && (
            <div className="is-flex ">
              <div> A$ {item.total_price}</div>
              <div className="is-flex">
                <img
                  className="ml-3  mt-1 smart-transcation-image"
                  src={CROP_LOGO_C}
                  alt=""
                />
                <div className="mb-2 ml-1">{earn_crops(item)}</div>
              </div>
            </div>
          )}
          {type == "REDEEM" && (
            <>
              <div className="is-flex">
                <img
                  className="ml-3 mt-1 smart-transcation-image"
                  src={CROP_LOGO_C}
                  alt=""
                />
                <span className="mb-2 ml-2">{redeem_crops(item)}</span>
              </div>
            </>
          )}
        </div>
        <div className="ml-3 has-text-right">
          <SmartSoftButton
            label="Select"
            classList={["smart-crop-trancation-button is-small"]}
            onClick={() => addProduct(item)}
          />
        </div>
      </div>
    );
  };

  const displayReturnItem = (item) => {
    return (
      <div className="columns">
        <div className="column is-8">
          {item.invoice_number} <br />
          {item.order_type == "EARN" && <>A$ {item.order_total}</>}
          {item.order_type == "REDEEM" && (
            <>
              <img
                className="ml-3 mt-5 smart-transcation-image"
                src={CROP_LOGO_C}
                alt=""
              />
              <span className="mb-4 ml-2">{item.order_earn_crop}</span>
            </>
          )}
        </div>
        <div className="ml-3 mt-4 has-text-right">
          <SmartSoftButton
            // width="6"
            label="Select"
            classList={["smart-crop-trancation-button is-small"]}
            onClick={() => getReturnItems(item)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="customer-transaction-right-content">
        <div className="mt-3 ml-5 crop-business-login-form crop-business-form">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
            onChange={(value) => handleInputChange("search_value", value)}
          />
        </div>
        {type !== "RETURN" && (
          <div
            className="has-text-right mr-4 px-5 mt-2 smart-customer-transaction"
            onClick={() => openNewForm()}
          >
            <u>Add New Offer</u>
          </div>
        )}
        <div className="customer-transaction-product-list has-text-justified">
          {filteredData.map((item, index) => (
            <div className="columns is-multiline mx-3 mt-1 mr-6" key={index}>
              <div className="smart-customer-transaction-product-view card column is-12">
                <div className="">
                  {type != "RETURN" && displaySingleItem(item)}
                  {type == "RETURN" && displayReturnItem(item)}
                </div>
              </div>
            </div>
          ))}
        </div>
        {filteredData && filteredData.length < 1 && (
          <NoResultComponents title={"No Results Found"} />
        )}
      </div>
    </>
  );
};

export default CustomerTrancationProduct;
